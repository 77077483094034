import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel, Button,
    ButtonProps,
    Flex,
    FlexProps,
    HStack,
    Icon,
    Image,
    Link,
    LinkProps,
    Stack,
    Text,
    TextProps,
    useColorModeValue as mode
} from '@chakra-ui/react'
import _ from 'lodash'
import { BsBook, BsBriefcaseFill, BsBuilding, BsFillClipboard2CheckFill, BsFillGrid1X2Fill, BsFolder, BsTagFill } from "react-icons/bs"
import { FaBitbucket, FaChalkboardTeacher, FaRegCalendarAlt } from "react-icons/fa"
import { FiArrowRight, FiArrowUpRight, FiGrid, FiHome } from 'react-icons/fi'
import { HiMiniClipboardDocumentCheck } from 'react-icons/hi2'
import { ImBooks, ImManWoman } from 'react-icons/im'
import { IconType } from 'react-icons/lib'
import { MdSubject } from 'react-icons/md'
import { Link as ReactRouterLink, NavigateFunction, useNavigate } from 'react-router-dom'
import { usePathMetaProvider } from '../../hooks'
// import { XceedLogo } from '../logo/Logo'
import { GrDocumentUpdate } from "react-icons/gr"
import { SiElectronbuilder } from "react-icons/si"
import XceedLogo from '../../../assets/images/cams-logo.jpg'
import { SIDEBAR } from '../../../consts/methods.consts'
import { DROPDOWN_TAGS, EXAM_TAGS } from '../../../consts/tags.consts'
import { useGetQuery } from '../../../services/api.service'
import { TbLogs } from "react-icons/tb";
import { LuFileEdit, LuFolderEdit } from "react-icons/lu"


interface NavbarProps extends FlexProps {
    onClose?: () => void
}

type ILinks = {
    icon: IconType,
    label: string,
    onClick?: () => any,
    path?: string,
    disabled?: boolean,
    children?: Array<ILinks>,
    to?: string
}
type INavElement = { heading: string, links: Array<ILinks> }

export const SideBar = (props: NavbarProps) => {
    const navigate: NavigateFunction = useNavigate();
    const activePath: string = usePathMetaProvider();

    const { data } = useGetQuery({
        method: SIDEBAR.GET_LINKS,
        body: {},
        providesTags: [EXAM_TAGS.VIEW, DROPDOWN_TAGS.LIST]

    })

    const _navConfig = _.get(data, 'message', [])

    const navConfig: Array<any> = _navConfig

    const navMarkup = navConfig.map((line: INavElement) => {
        return (
            <Stack spacing="1">
                <NavHeading>{line.heading}</NavHeading>
                <Stack spacing="1">
                    {line.links.map((link: ILinks) => {
                        if (link?.children && !_.isEmpty(link.children)) {
                            return (
                                <ParentNavLink {...link} activePath={activePath} />
                            )
                        }
                        else {
                            return (
                                <NavLink
                                    fontWeight={"semibold"}
                                    pointerEvents={link.disabled ? 'none' : 'auto'}
                                    className={link.disabled ? 'opacity-50' : ''}
                                    aria-current={activePath === link.path ? 'page' : undefined}
                                    onClick={link.onClick}
                                    to={link?.to}
                                    icon={link.icon}>
                                    {link.label}
                                </NavLink>
                            )
                        }
                    })}
                </Stack>
            </Stack>
        )
    });

    return (
        <Flex as="nav" height="full" direction="column" justify="space-between" {...props}>
            <Stack className='pb-3' spacing="3">
                <HStack spacing={0} ml={2} display={'flex'} p={3} justifyContent={'start'} alignItems={"center"}>
                    <div>
                        <Image className='' height="8" src={XceedLogo}></Image>
                    </div>

                </HStack>
                <Stack px="3" spacing="5">
                    <Stack spacing="1">
                        <NavLink to='/app'
                            aria-current={activePath === 'home' ? 'page' : undefined} icon={'FiHome'}>Home</NavLink>
                    </Stack>
                    {navMarkup}
                </Stack>
            </Stack>
        </Flex>
    )
}

const NavButton = (props: ButtonProps) => (
    <Button
        width="full"
        borderRadius="0"
        variant="ghost"
        size="lg"
        fontSize="sm"
        _hover={{ bg: mode('gray.100', 'gray.700') }}
        _active={{ bg: mode('gray.200', 'gray.600') }}
        _focus={{ boxShadow: 'none' }}
        _focusVisible={{ boxShadow: 'outline' }}
        {...props}
    />
)

interface NavLinkProps extends LinkProps {
    icon: any
    to?: string
}



export const NavLink = (props: NavLinkProps) => {
    const navigate = useNavigate()
    const { icon, ...linkProps }: any = props


    return (
        <Link
            as={ReactRouterLink}
            to={props?.to}
            px="2"
            py="1.5"
            borderRadius="md"
            _hover={{ bg: mode('gray.100', 'gray.700') }}
            _activeLink={{
                bg: 'gray.700',
                color: 'white',
            }}
            {...linkProps}
        >

            <HStack justify="space-between">
                <HStack as="a" spacing="3">

                    <Icon as={_.get(iconConfig, icon)} />
                    {/* <FiArrowRight/> */}
                    <Text fontWeight={"semibold"} as="span" fontSize="sm" lineHeight="1.25rem">
                        {props.children}
                    </Text>

                </HStack>
                {props.isExternal && (
                    <Icon as={FiArrowUpRight} boxSize="4" color={mode('gray.600', 'gray.400')} />
                )}
            </HStack>
        </Link>
    )
}

const ParentNavLink = (props: any) => {

    const isActive = () => {
        try {
            if (props?.children.some((line: any) => props.activePath == line.path)) {
                return [0]
            }

            return []
        } catch (error) {
            return []
        }
    }

    return (
        <Accordion border={0} borderRadius={"md"} defaultIndex={isActive()} allowMultiple>
            <AccordionItem borderRadius={"md"} border={0}>
                <HStack
                    borderRadius="md"
                    // _hover={{ bg: mode('gray.100', 'gray.700') }}
                    _activeLink={{
                        bg: 'gray.700',
                        color: 'white',
                    }}>
                    <AccordionButton borderRadius={"md"} _hover={{ bg: "gray.100" }} py={1.5} px={2}>
                        <HStack justifyContent={"space-between"} w={"full"}>
                            <HStack as="a" spacing="3">
                                <Icon as={_.get(iconConfig, props?.icon)} />
                                <Text fontWeight={"semibold"} as="span" fontSize="sm" lineHeight="1.25rem">
                                    {props?.label}
                                </Text>
                            </HStack>
                            <AccordionIcon />
                        </HStack>
                    </AccordionButton>
                </HStack>
                <AccordionPanel pb={0}>
                    <Stack spacing="1">
                        {props.children.map((link: any) => (
                            <NavLink
                                fontWeight={"semibold"}
                                pointerEvents={link.disabled ? 'none' : 'auto'}
                                className={link.disabled ? 'opacity-50' : ''}
                                aria-current={props.activePath === link.path ? 'page' : undefined}
                                onClick={link.onClick}
                                to={link?.to}
                                icon={link.icon}>{link.label}
                            </NavLink>
                        ))}
                    </Stack>

                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    )
}

export const NavHeading = (props: TextProps) => (
    <Text
        as="h4"
        fontSize="xs"
        fontWeight="semibold"
        px="2"
        lineHeight="1.25"
        color={mode('gray.600', 'gray.400')}
        {...props}
    />
)

const iconConfig = {
    "ImBooks": ImBooks,
    "BsBook": BsBook,
    "FiGrid": FiGrid,
    "HiMiniClipboardDocumentCheck": HiMiniClipboardDocumentCheck,
    "MdSubject": MdSubject,
    "FaBitbucket": FaBitbucket,
    "FiArrowRight": FiArrowRight,
    "BsBuilding": BsBuilding,
    "ImManWoman": ImManWoman,
    "BsFillClipboard2CheckFill": BsFillClipboard2CheckFill,
    "FaChalkboardTeacher": FaChalkboardTeacher,
    "BsBriefcaseFill": BsBriefcaseFill,
    "BsFillGrid1X2Fill": BsFillGrid1X2Fill,
    "BsTagFill": BsTagFill,
    "BsFolder": BsFolder,
    "FiHome": FiHome,
    "FaRegCalendarAlt": FaRegCalendarAlt,
    "SiElectronbuilder": SiElectronbuilder,
    "GrDocumentUpdate": GrDocumentUpdate,
    "TbLogs": TbLogs,
    "LuFileEdit": LuFileEdit,
    "LuFolderEdit":LuFolderEdit
} 