import _ from "lodash";
import { useFormik } from "formik";
import { useEffect } from "react";
import { SimpleGrid } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { SectionCard } from "../../../../common/section-card/SectionCard";
import { FormTextArea } from "../../../../common/form-controllers/FormTextArea";
import { setLocalStoreModel } from "@dladio/hooks/dist/store/slices/local-store";

const OtherInformation = ({ OtherInformationRef, initialValues }: any) => {

    const dispatch: any = useDispatch()

    const formik = useFormik({
        initialValues: {},

        onSubmit: async (values: any) => {
            return values

        }
    })

    OtherInformationRef.current = formik

    useEffect(() => {
        dispatch(setLocalStoreModel({ path: 'resource-person.other_information', model: formik.values }))
    }, [formik.values])


    useEffect(() => {
        if (!_.isEmpty(initialValues)) {
            formik.setValues(initialValues)
        }

    }, [])

    return (
        <SectionCard backgroundColor=" bg-white" title="Other Information" p={3}>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 3 }} spacing={3}>
                <FormTextArea label="Reasons for Applying" formik={formik} name="reasons_for_applying" />
                <FormTextArea label="Referee 01" formik={formik} name="referee_01" />
                <FormTextArea label="Referee 02" formik={formik} name="referee_02" />
            </SimpleGrid>
        </SectionCard>

    )
}

export default OtherInformation