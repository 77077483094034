import _ from "lodash";
import { useState } from "react";

export interface IUseObjectChange {
    isChanged: boolean,
    setInitialObj: Function
}

export const useObjChange = (model: any): IUseObjectChange => {
    const [initialObj, setInitialObj] = useState<any>({});

    return {
        isChanged: !_.isEqual(model, initialObj),
        setInitialObj
    }
}