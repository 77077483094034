import { SimpleGrid } from "@chakra-ui/layout";
import { Button, FormLabel, useDisclosure, useToast } from "@chakra-ui/react";
import { chakraComponents } from "chakra-react-select";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { EMPLOYEE, EXAM_ENROLLMENT, PRINT, SUBJECT } from "../../../consts/methods.consts";
import { DROPDOWN_TAGS, EMPLOYEE_TAGS, EXAM_ENROLLMENT_TAGS } from "../../../consts/tags.consts";
import apiService, { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { FormCheckBox } from "../../common/form-controllers/FormCheckBox";
import { FormDropdown } from "../../common/form-controllers/FormDropdown";
import { FormInput } from "../../common/form-controllers/FormInput";
import InnerLoading from "../../common/inner-loading/InnterLoading";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { SectionCard, SectionCardCreatable } from "../../common/section-card/SectionCard";
import { useObjChange } from "../../hooks";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import ChakraModal from "../../common/chakra-modal/ChakraModal";
import FormSelect from "../../common/form-controllers/FormSelect";
import { useDispatch } from "react-redux";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";
import { useSearchParams } from "react-router-dom";
import { FormSeletectNavigationController } from "../../common/form-seletect-navigation-controller/FormSeletectNavigationController";
import { callMethod, postMutation, printAction } from "../../../actions";
import { customeJsonParser } from "../../../utils/utils";

const ViewExamEnrollmentPage = () => {
    let [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(false)
    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());
    const dispatch: any = useDispatch()
    const disclosure = useDisclosure()
    const [create] = usePostMutation();

    const { data: _data, isLoading } = useGetQuery({
        method: EXAM_ENROLLMENT.GET,
        body: { id: searchParams.get('id') },
        providesTags: [EXAM_ENROLLMENT_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {})

    const stringifyData = JSON.stringify(data)

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            name_with_initials: Yup.string().required("Name with Initials is required"),
            nic: Yup.string().matches(/^([0-9]{9}[x|X|v|V]|[0-9]{12})$/, 'Enter valid NIC number').required('NIC is required'),
            full_name: Yup.string().required("Full name is required"),
            mobile: Yup.string().matches(/^\d{10}$/, 'Please enter a valid mobile number').required("Mobile number is required"),
            telephone_personal_land_line: Yup.string().matches(/^\d{10}$/, 'Please enter a valid mobile number'),
            institution: Yup.string().required("Institution is required"),
            email_address: Yup.string().email('Invalid email address').required("Email_address is required"),
            designation: Yup.string().required("Designation is required"),
            official_address: Yup.string().required("Official address is required"),
            telephone_office_land_line: Yup.string().matches(/^\d{10}$/, 'Please enter a valid mobile number').required("Telephone (Office Land Line) is required"),
            service: Yup.string().required("service is required"),
            name_of_the_examination: Yup.string().required("Examination name is required"),
            medium: Yup.string().required("medium is required"),
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: EXAM_ENROLLMENT.UPDATE,
                    body: values,
                    invalidatesTags: [EXAM_ENROLLMENT_TAGS.VIEW, EXAM_ENROLLMENT_TAGS.LIST, DROPDOWN_TAGS.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription("Exam registration details have been saved").showSuccessToast();

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const service = _.get(formik.values, 'service')
    const exam = _.get(formik.values, 'name_of_the_examination')

    useEffect(() => {
        if (service !== _.get(data, 'service')) formik.setFieldValue('name_of_the_examination', '')
    }, [service])


    const childFormik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            subject: Yup.string().required("subject is required"),
        }),
        onSubmit: async (values: any) => {

            const alreadyExsist = _.get(formik.values, 'subjects', [])?.findIndex((line: any) => (line.name === values.subject))

            if (alreadyExsist > -1) {
                toast.setTitle("Error").setDescription("This Subject Already exists").showErrorToast();
                return
            }

            disclosure.onClose()

            try {
                const res = await dispatch(apiService.endpoints.get.initiate({
                    method: EXAM_ENROLLMENT.GET_ES_DETAILS,
                    body: { id: values.subject },
                    providesTags: []
                })).unwrap()

                let current_subjects: any = _.isArray(_.get(formik.values, 'subjects')) ? _.get(formik.values, 'subjects') : []

                if (_.get(res, 'message')) formik.setFieldValue('subjects', [
                    ...current_subjects,
                    { ...values, ..._.get(res, 'message', []) }
                ])

                childFormik.resetForm()

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable add subject. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const { data: initData } = useGetQuery({
        method: EXAM_ENROLLMENT.INIT,
        body: {
            exam: _.get(formik.values, 'name_of_the_examination'),
            service: _.get(formik.values, 'service'),
        },
        providesTags: [EXAM_ENROLLMENT_TAGS.VIEW, DROPDOWN_TAGS.LIST]

    })

    const services = _.get(initData, 'message.services', [])
    const subjects = _.get(initData, 'message.subjects', [])
    const exams = _.get(initData, 'message.exams', [])
    const is_first_attempt = _.get(formik.values, 'first_attempt')


    const deleteHandler = async () => {
        try {
            await create({
                method: EXAM_ENROLLMENT.DELETE,
                body: { id: searchParams.get('id') },
                invalidatesTags: [EXAM_ENROLLMENT_TAGS.LIST, DROPDOWN_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Exam registration is deleted successfully.").showSuccessToast();

            setTimeout(() => {
                navigate(`/app/exam-registration`);
            }, 1500)

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        }
    }

    const { isChanged, setInitialObj } = useObjChange(formik.values)

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
        }
    }, [stringifyData])

    const pymentDetailsMarkup = (
        <>
            <FormInput isRequired type="number" label="Number of Attempts" formik={formik} name="number_of_times_that_sat_for_the_examination" />
            <FormInput isRequired type="number" label="Paid Amount" formik={formik} name="paid_amount" />
            <FormInput isRequired label="Payment Receipt No:" formik={formik} name="payment_receipt_no" />
            <div>
                <FormLabel className={'!opacity-75 !text-[13px]'}>Receipt</FormLabel>
                <a className=" text-blue-500" href={'https://xceed.dlad.io/' + _.get(formik.values, 'receipt')} target="_blank" rel="noopener noreferrer">
                    View Receipt
                </a>
            </div>
        </>
    )

    const printAdmissionHandler = async () => {
        printAction(PRINT.PRINT_ADMISSION, { id: searchParams.get('id') }, setLoading).catch((error: any) => {
            console.log('error')
        })

    }

    const markAsAccepted = async () => {
        try {

            await create({
                method: EXAM_ENROLLMENT.MARK_ACCEPT_REJECT,
                body: {
                    id: searchParams.get('id'),
                    accepted: _.get(data, 'accepted') ? 0 : 1
                },
                invalidatesTags: [EXAM_ENROLLMENT_TAGS.VIEW, EXAM_ENROLLMENT_TAGS.LIST, DROPDOWN_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription(`Exam registration ${_.get(data, 'accepted') ? 'reject' : 'accept'}.`).showSuccessToast();

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        }

    }

    const moreActions: PageHeaderActions = [
        {
            text: "View Admission",
            isDisabled: !_.get(data, 'admission'),
            confirmation: false,
            onClick: () => {
                window.open(`https://xceed.dlad.io/${_.get(data, 'admission')}`, '_blank')
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "New Admission",
            confirmation: false,
            onClick: printAdmissionHandler,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: _.get(data, 'accepted') ? 'Reject' : 'Accept',
            onClick: markAsAccepted,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
    ]


    const actions: PageHeaderActions = [
       
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Delete",
            onClick: deleteHandler,
            buttonVariant: "solid",
            buttonColorScheme: "red"
        }
    ]

    const creatable: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            disclosure.onOpen();
        }
    }

    const EXAM_ENROLLMENT_SUBJECT_TABLE: ChakraTableColumns = [
        {
            header: "Subject",
            accessor: "subject_name",
        },
        {
            header: "Start Date and Time",
            accessor: "start_date_and_time",
        },
        {
            header: "End Date and Time",
            accessor: "end_date_and_time",
        },
        {
            header: "Exam Center",
            accessor: "exam_center",
        },
        {
            header: "Action",
            accessor: "",
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick(item: any) {
                        const newItems = _.get(formik.values, 'subjects', []).filter((i: any) => (
                            i?.name !== item?.name
                        ))
                        formik.setFieldValue('subjects', newItems)
                    },
                }
            ]
        },
    ]


    if (isLoading || loading) {
        return (
            <>
                <PageHeader isLoading={isLoading || loading} enableBackButton actions={actions} title={''} />
                <InnerLoading />
            </>
        )
    }

    return (
        <div>
            <PageHeader moreActions={moreActions} statusColorSchemes={_.get(emailStatusColours, (_.get(data, 'email_sent', 0)))} status={_.get(emailStatus, (_.get(data, 'email_sent', 0)))} enableBackButton actions={actions} title={_.get(data, 'full_name', '')} subtitle={'Exam Registration'} />

            <SectionCard backgroundColor="bg-white" title="Basic Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormInput linkTo={`/app/student/${_.get(formik.values, 'student')}`}
                        isDisabled isRequired type="text" label="Student" formik={formik} name="student" />
                    <FormInput isRequired label="Name with Initials" formik={formik} name="name_with_initials" />
                    <FormInput isRequired label="Full Name" formik={formik} name="full_name" />
                    <FormInput isRequired label="National Identity Card No" formik={formik} name="nic" />

                    <FormInput isRequired label="Institution" formik={formik} name="institution" />
                    <FormInput isRequired label="Designation" formik={formik} name="designation" />
                    <FormSelect options={services} formik={formik}
                        isRequired
                        components={customComponents}
                        name={'service'}
                        label={'Service'} />

                    <FormSelect options={exams} formik={formik}
                        extraProps={{ linkTo: `/app/exam/${_.get(formik.values, 'name_of_the_examination')}` }}
                        components={{ Control: FormSeletectNavigationController, Option: customComponents.Option }}
                        isRequired
                        name={'name_of_the_examination'}
                        label={'Name of the Examination'} />

                    <FormSelect options={mediums} formik={formik}
                        isRequired
                        components={customComponents}
                        name={'medium'}
                        label={'Medium'} />
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} mt={3} spacing={3}>
                    <FormCheckBox isRequired label="Certification of Inforamtion Accuracy?" formik={formik} name="is_certified" />
                </SimpleGrid>
            </SectionCard>
            {/* <SectionCard>
                <SimpleGrid>
                    
                </SimpleGrid>
            </SectionCard> */}

            <SectionCard backgroundColor=" bg-white" title="Payment Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormDropdown options={attempt} formik={formik} isRequired
                        name={'first_attempt'}
                        autoFocus
                        label={'Is first attempt?'} />
                    {is_first_attempt == 'NO' && pymentDetailsMarkup}
                </SimpleGrid>
            </SectionCard>

            <SectionCard backgroundColor=" bg-white" title="Contact Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormInput isRequired label="Mobile" formik={formik} name="mobile" />
                    <FormInput label="Telephone (Personal Land Line)" formik={formik} name="telephone_personal_land_line" />
                    <FormInput isRequired label="Telephone (Office Land Line)" formik={formik} name="telephone_office_land_line" />
                    <FormInput isRequired label="Email Address" formik={formik} name="email_address" />
                    <FormTextArea rows={7} isRequired label="Official Address" formik={formik} name="official_address" />
                </SimpleGrid>
            </SectionCard>

            <SectionCard creatable={creatable} title="Subjects" p={3}>
                <ChakraTable size={"sm"} header
                    columns={EXAM_ENROLLMENT_SUBJECT_TABLE}
                    data={_.get(formik.values, 'subjects', [])} />
                <ChakraModal primaryAction={childFormik.submitForm} primaryButtonText={"Add"}
                    modalTitle={'Add new Subject'} {...disclosure}>
                    <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                        <FormSelect components={customComponents} options={subjects} isRequired formik={childFormik} name={'subject'}
                            label={'Subject'} />
                    </SimpleGrid>
                </ChakraModal>
            </SectionCard>

        </div>
    )
}

export default ViewExamEnrollmentPage

const mediums = [
    { value: 'Sinhala', label: 'Sinhala' },
    { value: 'English', label: 'English' },
]

const attempt = [
    { value: 'YES', label: 'Yes' },
    { value: 'NO', label: 'No' },
]

const emailStatus = {
    0: 'Addmission not Emailed',
    1: 'Addmission Emailed'
}

const emailStatusColours = {
    0: 'red',
    1: 'green'
}

const customComponents = {
    Option: ({ children, ...props }: any) => (
        <chakraComponents.Option {...props}>
            <div className="gap-2">
                <div className=" font-bold">
                    {children}
                </div>
                <div className=" italic flex  gap-3">
                    <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                        {props.data.code}
                    </span>
                    {props.data.group && (<span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                        {props.data.group}
                    </span>)}
                </div>
            </div>
        </chakraComponents.Option >
    )
};



