import { useToast } from "@chakra-ui/react";
import _ from "lodash";
import { useRef } from "react";
import Experience from "./components/Experience";
import GeneralForm from "./components/GeneralForm";
import SubjectArea from "./components/SubjectArea";
import InnerLoading from "../../../common/inner-loading/InnterLoading";
import PaymentDetails from "./components/paymentDetails";
import KeyFunctionAreas from "./components/KeyFunctionAreas";
import { ToastService } from "../../../../services/toast.service";
import OtherInformation from "./components/OtherInformation";
import { RESOURCE_PERSON } from "../../../../consts/methods.consts";
import LanguageProficiency from "./components/LanguageProficiency";
import ResourcePersonHeader from "./components/Header";
import AcademicQualification from "./components/AcademicQualification";
import ProfessionalQualification from "./components/ProfessionalQualification";
import { useGetQuery, usePostMutation } from "../../../../services/api.service";
import PageHeader, { PageHeaderActions } from "../../../common/page-header/PageHeader";
import { DROPDOWN_TAGS, RESOURCE_PERSON_TAGS } from "../../../../consts/tags.consts";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { customeJsonParser } from "../../../../utils/utils";

const ViewResourcePersonPage = () => {
    const { id } = useParams()
    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());
    const keyFunctionRef: any = useRef()
    const subjectAreaRef: any = useRef()
    const languageProfRef: any = useRef()
    const academicQualificationRef: any = useRef()
    const professionalQualificationRef: any = useRef()
    const mainFormRef: any = useRef()
    const otherInformationRef: any = useRef()
    const experienceRef: any = useRef()
    const paymentDetailsRef: any = useRef()
    const [create] = usePostMutation();

    const { data: _data, isLoading } = useGetQuery({
        method: RESOURCE_PERSON.GET,
        body: { id },
        providesTags: [RESOURCE_PERSON_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {})

    const onSubmit = async () => {

        const generalInformation = await mainFormRef.current.submitForm()
        const keyfunctionAreas = await keyFunctionRef.current.submitForm()
        const subjectAreas = await subjectAreaRef.current.submitForm()
        const otherInformation = await otherInformationRef.current.submitForm()
        const paymentDetails = await paymentDetailsRef.current.submitForm()
        const academicQualifications = academicQualificationRef.current
        const professionalQualifications = professionalQualificationRef.current
        const experience = experienceRef.current
        const languageProficiency = languageProfRef.current

        if (mainFormRef.current.isValid && keyFunctionRef.current.isValid && subjectAreaRef.current.isValid && otherInformationRef.current.isValid) {
            try {
                const res = await create({
                    method: RESOURCE_PERSON.UPDATE,
                    body: {
                        "name": id,
                        ...keyfunctionAreas,
                        ...generalInformation,
                        ...subjectAreas,
                        ...otherInformation,
                        ...paymentDetails,
                        academicQualifications,
                        professionalQualifications,
                        experience,
                        languageProficiency
                    },

                    invalidatesTags: [RESOURCE_PERSON_TAGS.VIEW, RESOURCE_PERSON_TAGS.LIST, DROPDOWN_TAGS.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription("Resource person details have been saved.").showSuccessToast();

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    }

    // const deleteHandler = async () => {
    //     try {
    //         await create({
    //             method: RESOURCE_PERSON.DELETE,
    //             body: { id },
    //             invalidatesTags: [RESOURCE_PERSON_TAGS.LIST, DROPDOWN_TAGS.LIST]
    //         }).unwrap();

    //         toast.setTitle("Success").setDescription("Resource person is deleted successfully.").showSuccessToast();

    //         setTimeout(() => {
    //             navigate(`/app/resource-person`);
    //         }, 1500)

    //     } catch (error: any) {
    //         let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
    //         if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
    //         // toast.setTitle("Error").setDescription('Failed to delete the resource person.').showErrorToast();
    //     }
    // }

    const actions: PageHeaderActions = [
        {
            text: "Save",
            isDisabled: true,
            onClick: onSubmit,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
    ]

    if (isLoading) {
        return (
            <>
                <PageHeader isLoading={isLoading} enableBackButton actions={actions} title={'Resource Person'} />
                <InnerLoading />
            </>
        )
    }

    return (
        <div>
            <ResourcePersonHeader data={data} onSubmit={onSubmit} />

            <GeneralForm mainFormRef={mainFormRef} initialValues={_.get(data, 'general')} />

            <KeyFunctionAreas keyFunctionRef={keyFunctionRef} initialValues={_.get(data, 'key_function_areas')} />

            <SubjectArea subjectAreaRef={subjectAreaRef} initialValues={_.get(data, 'subject_area')} />

            <PaymentDetails paymentDetailsRef={paymentDetailsRef} initialValues={_.get(data, 'payment_details')} />

            <OtherInformation OtherInformationRef={otherInformationRef} initialValues={_.get(data, 'other_information')} />

            <AcademicQualification academicQualificationRef={academicQualificationRef} initialValues={_.get(data, 'academic_qualifications', [])} />

            <ProfessionalQualification professionalQualificationRef={professionalQualificationRef} initialValues={_.get(data, 'professional_qualifications', [])} />

            <Experience experienceRef={experienceRef} initialValues={_.get(data, 'experience', [])} />

            <LanguageProficiency languageProfRef={languageProfRef} initialValues={_.get(data, 'language_proficiency', [])} />

        </div>
    )
}

export default ViewResourcePersonPage

