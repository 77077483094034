import _ from "lodash"

type UseTableDataProviderR = {
    dataList: Array<any>,
    paginationDetails?: {
        pageCount?: number
        totalResults?: number
        pevResultCount?: number
        currentResultCount?: number
    }
}

type Options = {
    withoutPagination: boolean
}

export const useTableDataProvider = (response: Object, options: Options | undefined = undefined): UseTableDataProviderR => {

    if (options?.withoutPagination) {
        return {
            dataList: _.get(response, ["message"], [])
        }
    }

    return {
        dataList: _.get(response, ["message", "data"], []),
        paginationDetails: {
            pageCount: _.get(response, ["message", "page_count"]),
            totalResults: _.get(response, ["message", "total_results"]),
            pevResultCount: _.get(response, ["message", "pev_result_count"]),
            currentResultCount: _.get(response, ["message", "current_result_count"])
        }

    }
}