import _ from "lodash";
import React from "react";
import { useState } from "react";
import { DefaultCell } from "../report/components/cells";
import { printAction } from "../../../actions";
import { useGetQuery } from "../../../services/api.service";
import { PROGRAM_TAGS } from "../../../consts/tags.consts";
import { PRINT, PROGRAM } from "../../../consts/methods.consts";
import { useSearchParams } from "react-router-dom";
import { PageHeaderActions } from "../../common/page-header/PageHeader";
import { useTableDataProvider } from "../../hooks";
import { InnerLoading, PageHeader } from "../../index";
import { NavigateFunction, useNavigate } from "react-router";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";

const PROGRAM_LIST_COLUMNS: ChakraTableColumns = [
    {
        header: "Code",
        accessor: "code",
        width: 150
    },
    {
        header: "Program Name",
        width: 450,
        accessor:
            "title",
    },
    {
        header: "Organization",
        width: 450,
        accessor:
            "organization",
        customRenderer: (value: any, item: any) => (
            React.createElement(DefaultCell, { navigateTo: `/app/organization/${_.get(item, 'organization_id')}`, value: value })
        ),
    },
    {
        header: "Coordinator",
        width: 180,
        customRenderer: (value: any, item: any) => (
            React.createElement(DefaultCell, { navigateTo: `/app/employee/${_.get(item, 'coordinator_id')}`, value: value })
        ),
        accessor:
            "coordinator",

    },
    // {
    //     header: "Officer",
    //     width: 180,
    //     customRenderer: (value: any, item: any) => (
    //         React.createElement(DefaultCell, { navigateTo: `/app/employee/${_.get(item, 'officer_id')}`, value: value })
    //     ),
    //     accessor:
    //         "officer",
    // },
    {
        header: "assistant",
        width: 180,
        customRenderer: (value: any, item: any) => (
            React.createElement(DefaultCell, { navigateTo: `/app/employee/${_.get(item, 'assistance_id')}`, value: value })
        ),
        accessor:
            "assistance",
    }
]

const ProgramListPage = () => {

    const navigate: NavigateFunction = useNavigate()
    const [searchKey, setSearchKey]: any = useState()
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)

    const { data, isLoading, isFetching } = useGetQuery({
        method: PROGRAM.LIST,
        body: {
            page: page,
            key: searchKey,
            length: Number(length),
        },
        providesTags: [PROGRAM_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const printDailyProgram = () => {
        printAction(PRINT.PRINT_DAILY_PROGRAM, {}, setLoading)
    }

    const actions: PageHeaderActions = [

        {
            text: "Create New",
            onClick: () => navigate("new"),
            confirmation: false,
            isDisabled: loading,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const tableLineClickHandler = (line: any) => {
        navigate(`${_.get(line, 'name')}`)
    }

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }

    const onLengthChange = (length: any) => {
        setPage(1)
        setLength(Number(length))
    }

    const onPage = (page:any)=>{
        setPage(page)
    }

    if (isLoading || loading) {
        return (
            <>
                <PageHeader subtitle="View and Manage Programs" isLoading={true} enableBackButton actions={actions} title={`Program`} />
                <InnerLoading />
            </>
        )
    }

    return (
        <div>
            <PageHeader subtitle="View and Manage Programs" title={`Program`} actions={actions} />
            <ChakraTable onPage={onPage} onLength={onLengthChange} lengthPaginations isFetching={isFetching} onSearch={onSearchHandler} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} header
                title="Programs"
                onSelected={tableLineClickHandler} columns={PROGRAM_LIST_COLUMNS} data={dataList} />
        </div>

    )
}

export default ProgramListPage