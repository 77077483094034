import axios from "axios";
import _ from "lodash";
import React from "react";
import { BASE_URL } from "../../../consts/app.consts";
import { DefaultCell } from "./components/cells";
import { printAction } from "../../../actions";
import { PRINT } from "../../../consts/methods.consts";

const programConfig = {
    searchApi: 'xceed.api.report.program_generic_report',
    alert: 'Reminder: Specify a date range to receive reports. Use other fields to further narrow down data within the required date range.',
    extraActionsAsMenu: true,
    excelFileName: 'program-report',
    title: 'Program Report',
    sections: [{
        selectionTitle: "Date Range",
        grid: { base: 1, md: 2, lg: 4, xl: 4 },
        filters: [
            {
                is_required: true,
                label: 'From',
                type: 'date',
                key: 'from'
            },
            {
                is_required: true,
                label: 'To',
                type: 'date',
                key: 'to'
            },
        ]
    },
    {
        selectionTitle: "Filters",
        grid: { base: 1, md: 2, lg: 4, xl: 4 },
        filters: [
            // {
            //     label: 'Code',
            //     key: 'program_code',
            //     type: 'text'
            // },
            {
                label: 'Program Name',
                key: 'program_name',
                type: 'text'
            },
            // {
            //     label: 'File',
            //     key: 'file',
            //     type: 'text'
            // },
            {
                label: 'Organization',
                key: 'organization',
                type: 'form-select',
                optionMethod: 'xceed.api.report.get_organizations'
            },
            {
                label: 'Module Basket',
                key: 'module_basket',
                type: 'form-select',
                optionMethod: 'xceed.api.report.get_module_buckets'
            },
            {
                label: 'Mode',
                key: 'mode',
                type: 'drop-down',
                options: [
                    { value: 'Online', label: 'Online' },
                    { value: 'Physical', label: 'Pysical' },
                    { value: 'Hybrid', label: 'Hybrid' },
                ]
            },
            {
                label: 'Category',
                key: 'category',
                type: 'form-select',
                optionMethod: 'xceed.api.report.get_categoryies'
            },
            {
                label: 'Type',
                key: 'type',
                type: 'form-select',
                optionMethod: 'xceed.api.report.get_types',
                methodBody: {
                    "category": "category"
                }
            },
            {
                label: 'Sub type',
                key: 'sub_type',
                type: 'form-select',
                optionMethod: 'xceed.api.report.get_sub_type',
                methodBody: {
                    "type": "type"
                }
            },
            {
                label: 'Program Coordinator',
                key: 'program_coordinator',
                type: 'form-select',
                optionMethod: 'xceed.api.report.get_program_coordinator'
            },
            // {
            //     label: 'Program Officer',
            //     key: 'program_officer',
            //     type: 'form-select',
            //     optionMethod: 'xceed.api.report.get_program_officers'
            // },
            {
                label: 'Program Assistant',
                key: 'program_assistant',
                type: 'form-select',
                optionMethod: 'xceed.api.report.get_program_assistant'
            },
            {
                label: 'Order By',
                type: 'drop-down',
                key: 'order_by',
                options: [
                    { value: 'P.code', label: 'Code' },
                    { value: 'P.title', label: 'Program name' },
                    { value: 'P.from', label: 'Program start date' },
                    { value: 'P.to', label: 'Program end date' },
                ]

            },
            // {
            //     label: 'Order',
            //     type: 'drop-down',
            //     key: 'order',
            //     options: [
            //         { value: 'DESC', label: 'DESC' },
            //         { value: 'ASC', label: 'ASC' },
            //     ]

            // }
        ]
    }],
    tableConfig: {
        column: [
            {
                header: "Start",
                accessor: "from",
                width: 200,

            },
            {
                header: "end",
                accessor: "to",
                width: 200,

            },
            {
                header: "Code",
                accessor: "code",
                width: 100,

            },
            {
                header: "Program Name",
                accessor: "program_name",
                width: 400,
                // customRenderer: (value: any, item: any) => (
                //     React.createElement(DefaultCell, { navigateTo: `/app/program/${_.get(item, 'name')}`, value: value })
                // ),

            },
            {
                header: "File",
                accessor: "file_number",
                width: 100,

            },
            {
                header: "Mode",
                accessor: "mode",
                width: 50,

            },
            {
                header: "Category",
                accessor: "program_category",
                width: 50,

            },
            {
                header: "Type",
                accessor: "type",
                width: 50,

            },
            {
                header: "Sub type",
                accessor: "sub_type",
                width: 50,

            },

            {
                header: "Program Coordinator",
                accessor: "coordinator",
                width: 200,
                // customRenderer: (value: any, item: any) => (
                //     React.createElement(DefaultCell, { navigateTo: `/app/employee/${_.get(item, 'coordinator_id')}`, value: value })
                // ),
            },
            {
                header: "Program Officer",
                accessor: "officer",
                width: 200,
                // customRenderer: (value: any, item: any) => (
                //     React.createElement(DefaultCell, { navigateTo: `/app/employee/${_.get(item, 'officer_id')}`, value: value })
                // ),

            },
            {
                header: "Program Assistant",
                accessor: "assistance",
                width: 200,
                // customRenderer: (value: any, item: any) => (
                //     React.createElement(DefaultCell, { navigateTo: `/app/employee/${_.get(item, 'assistance_id')}`, value: value })
                // ),

            },
            {
                header: "Organization",
                accessor: "organization",
                width: 200,
                customRenderer: (value: any, item: any) => (
                    React.createElement(DefaultCell, { navigateTo: `/app/organization/${_.get(item, 'organization_id')}`, value: value })
                ),
            }
        ]
    },

    actions: [
        {
            text: "Export to Excel",
            onClick: async (filter: any, data: any, setLoading: any) => {
                if (filter.to && filter.from) {
                    setLoading(true)
                    try {
                        let response = await axios.post(`${BASE_URL}/method/xceed.api.export_excel.program_report.export_program_report`, { filter }, {
                            withCredentials: true,
                            responseType: "blob"
                        })
                        if (response) {
                            console.log(response)
                            const href = URL.createObjectURL(response.data);

                            const link = document.createElement('a');
                            link.href = href;
                            link.setAttribute('download', `Programs-export.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);
                        }
                        setLoading(false)

                    } catch (error: any) {
                        setLoading(false)
                        const newError: any = new Error('Unable to Generate Excel file');
                        newError.title = 'Error';
                        newError.status = 'error';
                        throw newError
                    }
                }
                else {
                    const error: any = new Error('Please select from date and to date');
                    error.title = 'Warning';
                    error.status = 'warning';
                    throw error
                }
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Print Training Programs Schedule",
            onClick: async (filter: any, data: any, setLoading: any) => {
                if (filter.to && filter.from) {
                    try {
                        await printAction(PRINT.PRINT_TRAINING_PROGRAMMES_SCHEDULE, filter, setLoading)

                    } catch (error: any) {
                        throw error
                    }
                }
                else {
                    const error: any = new Error('Please select from date and to date');
                    error.title = 'Warning';
                    error.status = 'warning';
                    throw error
                }
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Print Monthly Programs Schedule",
            onClick: async (filter: any, data: any, setLoading: any) => {
                if (filter.to && filter.from) {
                    try {
                        await printAction(PRINT.PRINT_MONTHLY_PROGRAMMES_SCHEDULE, filter, setLoading)

                    } catch (error: any) {
                        throw error
                    }
                }
                else {
                    const error: any = new Error('Please select from date and to date');
                    error.title = 'Warning';
                    error.status = 'warning';
                    throw error
                }
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Print Weekly Programs Schedule",
            onClick: async (filter: any, data: any, setLoading: any) => {
                if (filter.to && filter.from) {
                    try {
                        await printAction(PRINT.PRINT_WEEKLY_PROGRAMMES_SCHEDULE, filter, setLoading)

                    } catch (error: any) {
                        throw error
                    }
                }
                else {
                    const error: any = new Error('Please select from date and to date');
                    error.title = 'Warning';
                    error.status = 'warning';
                    throw error
                }
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },


    ]
}

export default programConfig