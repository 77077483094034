import _ from "lodash";
import * as Yup from "yup";
import { MODULE } from "../../../consts/methods.consts";
import { useRef } from "react";
import { FormInput } from "../../common/form-controllers/FormInput";
import { useFormik } from "formik";
import { SimpleGrid } from "@chakra-ui/layout";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";
import { ToastService } from "../../../services/toast.service";
import { usePostMutation } from "../../../services/api.service";
import { PageHeaderActions } from "../../common/page-header/PageHeader";
import { SectionCardCreatable } from "../../common/section-card/SectionCard";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { NavigateFunction, useNavigate } from "react-router";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { ChakraModal, PageHeader, SectionCard } from "../../index";
import { DROPDOWN_TAGS, MODULE_BUCKET_TAGS, MODULE_TAGS } from "../../../consts/tags.consts";
import { FormDropdown } from "../../common/form-controllers/FormDropdown";

const NewModulePage = () => {
    const navigate: NavigateFunction = useNavigate();
    const disclosure = useDisclosure()
    const [create, { isLoading }] = usePostMutation();
    const ref: any = useRef('')
    const toast = new ToastService(useToast());


    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            code: Yup.string().required("Code is required").matches(/^\S+$/, 'Module name cannot contain white spaces'),
            title: Yup.string().required("Title is required"),
            credit: Yup.number().required("Credit is required"),
            duration_hours: Yup.number().required("Duration (hours) is required"),
            duration_days: Yup.number().required("Duration (days) is required"),
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: MODULE.ADD,
                    body: {
                        ...values,
                    },
                    invalidatesTags: [MODULE_TAGS.LIST, MODULE_BUCKET_TAGS.VIEW, DROPDOWN_TAGS.LIST]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Unable to create module. Please try again")
                }

                toast.setTitle("Success").setDescription("New Module has been created successfully").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/modules/${newId}`);
                }, 1500)

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to create module. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const validationSchema = {
        0: {
            title: Yup.string().required("Title is required"),
        },
        1: {
            title: Yup.string().required("Title is required"),
        },
        2: {
            description: Yup.string().required("Description  is required"),
        },
        3: {
            title: Yup.string().required("Description is required"),
        }
    }

    const childFormik = useFormik({
        initialValues: {},
        validationSchema: Yup.object(_.get(validationSchema, ref.current)),
        onSubmit: async (values: any) => {

            if (ref.current === 0) {
                const alreadyExsist: any = _.get(formik.values, 'module_methodology', []).findIndex((item: any) => (item?.title === values.title))
                if (alreadyExsist > -1) {
                    toast.setTitle("Warning").setDescription("This Methodology already exists").setStatus('warning').show()
                    return
                }
                formik.setValues({
                    ...formik.values ? formik.values : {},
                    module_methodology: [..._.get(formik.values, 'module_methodology', []), values]
                })
            }

            if (ref.current === 1) {
                const alreadyExsist: any = _.get(formik.values, 'content', []).findIndex((item: any) => (item?.title === values.title))
                if (alreadyExsist > -1) {
                    toast.setTitle("Warning").setDescription("This Content already exists").setStatus('warning').show()
                    return
                }
                formik.setValues({
                    ...formik.values ? formik.values : {},
                    content: [..._.get(formik.values, 'content', []), values]
                })
            }

            if (ref.current === 2) {
                const alreadyExsist: any = _.get(formik.values, 'specific_objectives', []).findIndex((item: any) => (item?.description === values.description))
                if (alreadyExsist > -1) {
                    toast.setTitle("Warning").setDescription("This Specific objective already exists").setStatus('warning').show()
                    return
                }

                formik.setValues({
                    ...formik.values ? formik.values : {},
                    specific_objectives: [..._.get(formik.values, 'specific_objectives', []), values]
                })
            }

            if (ref.current === 3) {
                const existsItemIndex = _.get(formik.values, 'learning_outcomes', []).findIndex((line: any) => (line.title === values.title))
                if (existsItemIndex > -1) {
                    toast.setTitle("Warning").setDescription("This learning outcome already exists").setStatus('warning').show();
                    return
                }

                const newModuleContent = [{ ...values }, ..._.get(formik.values, 'learning_outcomes', [])]
                formik.setFieldValue('learning_outcomes', newModuleContent)

            }

            childFormik.setValues({})
            disclosure.onClose()
        }
    })

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
    ]

    const createMethod: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            ref.current = 0
            disclosure.onOpen();
        }
    }

    const createContent: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            ref.current = 1
            disclosure.onOpen();
        }
    }

    const specificObjectives: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            ref.current = 2
            disclosure.onOpen();
        }
    }

    const learningOutComesTableAction: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            ref.current = 3
            disclosure.onOpen();
        }
    }

    const SPECIFIC_OBJECTIVES_TAB_COLUMNS: ChakraTableColumns = [
        {
            header: "DESCRIPTION",
            accessor: "description",
        },
        {
            header: "Action",
            accessor: "",
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick(item: any) {
                        const newItems = _.get(formik.values, 'specific_objectives', []).filter((i: any) => (
                            i?.description !== item?.description
                        ))
                        formik.setValues({
                            ...formik.values ? formik.values : {},
                            specific_objectives: [...newItems]
                        })
                        console.log(newItems)
                    },
                }
            ]
        },
    ]

    const METHOD_TAB_COLUMNS: ChakraTableColumns = [
        {
            header: "Description",
            accessor: "title",
        },
        {
            header: "Action",
            accessor: "",
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick(item: any) {
                        const newItems = _.get(formik.values, 'module_methodology', []).filter((i: any) => (
                            i?.title !== item?.title
                        ))
                        formik.setValues({
                            ...formik.values ? formik.values : {},
                            module_methodology: [...newItems]
                        })
                        console.log(newItems)
                    },
                }
            ]
        },

    ]

    const CONTENT_TAB_COLUMNS: ChakraTableColumns = [
        {
            header: "Description",
            accessor: "title",

        },
        {
            header: "Action",
            accessor: "",
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick(item: any) {
                        const newItems = _.get(formik.values, 'content', []).filter((i: any) => (
                            i?.title !== item?.title
                        ))
                        formik.setValues({
                            ...formik.values ? formik.values : {},
                            content: [...newItems]
                        })
                    },
                }
            ]
        },
    ]

    const LERNING_OUTCOMES_TAB_COLUMN: ChakraTableColumns = [
        {
            header: "Description",
            accessor: "title",
        },
        {
            header: "Action",
            accessor: "",
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick(item: any) {
                        const newItems = _.get(formik.values, 'learning_outcomes', []).filter((line: any) => (
                            line?.title !== item?.title
                        ))
                        formik.setFieldValue('learning_outcomes', newItems)
                    },
                }
            ]
        },
    ]


    return (
        <div >
            <PageHeader isLoading={isLoading} enableBackButton actions={actions} title="Create New Module" subtitle="Module" />
            <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 3 }} spacing={3}>
                    <FormInput isRequired label="Module Code" formik={formik} name="code" />
                    <FormInput isRequired label="Module Name" formik={formik} name="title" />
                    <FormDropdown options={groups} formik={formik}
                        name={'group'}
                        autoFocus
                        label={'Group'} />
                    <FormInput isRequired type={'number'} label="No of Credits" formik={formik} name="credit" />
                    <FormInput isRequired type={'number'} label="Duration (hours)" formik={formik} name="duration_hours" />
                    <FormInput isRequired type={'number'} label="Duration(days)" formik={formik} name="duration_days" />

                </SimpleGrid>
            </SectionCard>
            <SectionCard backgroundColor=" bg-white" title="Objectives and Assesment Criteria" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 2 }} spacing={3}>
                    <FormTextArea rows={7} label="Overall Objective" formik={formik} name="overall_objective" />
                    <FormTextArea rows={7} label="Assesment Criteria" formik={formik} name="assesment_critria" />
                </SimpleGrid>
            </SectionCard>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 2 }} spacing={3}>
                <SectionCard creatable={createContent} subTitle="" title="Content" p={3}>
                    <ChakraTable pageCount={10} isLoading={false} size={"sm"}
                        title="" columns={CONTENT_TAB_COLUMNS} data={_.get(formik.values, 'content', [])} />
                </SectionCard>

                <SectionCard creatable={learningOutComesTableAction} title="Learning Outcomes" p={3}>
                    <ChakraTable size={"sm"}
                        columns={LERNING_OUTCOMES_TAB_COLUMN}
                        data={_.get(formik.values, 'learning_outcomes', [])} />
                </SectionCard>

                <SectionCard creatable={specificObjectives} subTitle="" title="Specific Objectives" p={3}>
                    <ChakraTable pageCount={10} isLoading={false} size={"sm"} title=""
                        columns={SPECIFIC_OBJECTIVES_TAB_COLUMNS}
                        data={_.get(formik.values, 'specific_objectives', [])} />
                </SectionCard>

                <SectionCard creatable={createMethod} subTitle="" title="Module Methodology" p={3}>
                    <ChakraTable pageCount={10} isLoading={false} size={"sm"}
                        title="" columns={METHOD_TAB_COLUMNS}
                        data={_.get(formik.values, 'module_methodology', [])} />
                </SectionCard>

            </SimpleGrid>
            <ChakraModal primaryAction={childFormik.submitForm} primaryButtonText={"Add"}
                modalTitle={_.get(titles, ref.current, '')} {...disclosure}>
                <FormTextArea rows={7} isRequired formik={childFormik} name={_.get(fieldName, ref.current)} autoFocus
                    label={_.get(label, ref.current)} />
            </ChakraModal>
        </div>
    )
}

export default NewModulePage

const titles = {
    0: 'Create Methodology',
    1: 'Create Content',
    2: 'Create Specific Objectives',
    3: 'Create Learning Outcome'
}

const fieldName = {
    0: 'title',
    1: 'title',
    2: 'description',
    3: 'title'
}

const label = {
    0: 'Description',
    1: 'Description',
    2: 'Description',
    3: 'Description'
}

const groups = [
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' }
]