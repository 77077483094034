import _ from "lodash";
import { PageHeader } from "../../index";
import { useGetQuery } from "../../../services/api.service";
import { MODULE_BUCKET } from "../../../consts/methods.consts";
import { useSearchParams } from "react-router-dom";
import { PageHeaderActions } from "../../common/page-header/PageHeader";
import { MODULE_BUCKET_TAGS } from "../../../consts/tags.consts";
import { useTableDataProvider } from "../../hooks";
import { NavigateFunction, useNavigate } from "react-router";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { useState } from "react";

export const MODULE_BUCKET_COLUMNS: ChakraTableColumns = [
    {
        header: "Name",
        accessor: "title",
    },
    // {
    //     header: "Description",
    //     accessor: "description",
    // }
]
const ModuleBucketListPage = () => {

    const navigate: NavigateFunction = useNavigate()
    const [page, setPage] = useState(1)
    const [searchKey, setSearchKey]: any = useState()

    const { data, isLoading, isFetching } = useGetQuery({
        method: MODULE_BUCKET.LIST,
        body: {
            page: page,
            key: searchKey
        },
        providesTags: [MODULE_BUCKET_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const actions: PageHeaderActions = [

        {
            text: "Create New",
            onClick: () => navigate("new"),
            confirmation:false,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const tableLineClickHandler = (line: any) => {
        navigate(`${_.get(line, 'name')}`)
    }

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)
        
        deb()
    }
    
    const onPage = (page:any)=>{
        setPage(page)
    }

    return (
        <div>
            <PageHeader subtitle="View and Manage Module Basket" title={`Module Basket`} actions={actions} />
            <ChakraTable onPage={onPage} isFetching={isFetching} onSearch={onSearchHandler} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} header title="Module Basket"
                onSelected={tableLineClickHandler} columns={MODULE_BUCKET_COLUMNS} data={dataList} />
        </div>
    )
}

export default ModuleBucketListPage