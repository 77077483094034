import { SignInForm } from "./components/SignInForm";
import loginBackground from "../../../assets/images/login-background.png"
import logo from "../../../assets/images/logo.png"
import { Box, Image, Center, Flex, HStack, Heading, SimpleGrid, Stack, Text, useBreakpointValue, VStack, Button } from "@chakra-ui/react";
import XceedLogo from '../../../assets/images/cams-logo.jpg'
import { NavigateFunction, useNavigate } from "react-router-dom";

const LoginPage = () => {
    const navigator: NavigateFunction = useNavigate()
    return (

        <SimpleGrid columns={{ base: 1, md: 2 }} minH={{ base: 'auto', md: '100vh' }}>
            <Box className="md:bg-gradient-to-t px-6 from-green-200 via-green-50 to-green-50" minH={{ base: 'auto', md: '100vh' }}>
                <Flex direction="column" px={{ base: '4', md: '8' }} height="full" color="on-accent">
                    <Flex className=" w-full md:w-auto" align="center" alignSelf="flex-start" h="24" paddingLeft="4">
                        <HStack spacing={0} className="w-[110px] h-[24px] lg:w-full" alignItems={"start"} color={"white"}>
                            <Image className='' height="8" src={XceedLogo}></Image>
                        </HStack>
                        {/* <div className="flex w-full items-center justify-end sm:hidden">
                            <Button size={'xs'} onClick={() => navigator('/application')}>
                                Examination portal
                            </Button>
                        </div> */}
                    </Flex>
                    <Flex flex="1" align="center" color={'white'} paddingLeft="4">
                        <Stack spacing="8">
                            <Stack spacing="6" display={{ base: 'none', md: 'block' }}>
                                <Text className="font-[Poppins]  text-black font-semibold text-3xl  leading-normal " size={useBreakpointValue({ md: 'lg', xl: 'xl' })}>
                                    Sri Lanka Institute of Development Administration
                                </Text>
                                <Text className="font-[Poppins]  text-[#20232066]" maxW="md" fontWeight="medium">
                                    Premier public sector training organization in Sri Lanka, for the development of knowledge and improvement of skills in Public Administration and Management.
                                </Text>

                            </Stack>
                            <Image src={loginBackground} className="object-cover" height={{ base: '45vw', md: '25vw' }} paddingX={{ base: '40px', md: '0px' }} alt="login page" />
                        </Stack>
                    </Flex>
                    <Flex align="center" display={{ base: 'none', md: 'block' }} paddingLeft="4" marginBottom="4">
                        <Text className={"font-[Poppins] opacity-[0.8] font-normal text-xs text-opacity-60 text-[#202320] "}>
                            © 2023 DLAD Software Solution. All rights reserved.
                        </Text>
                    </Flex>
                </Flex>
            </Box>

            <SignInForm
                width="100%"
                maxW="md"
            />

        </SimpleGrid>
        // <div className="w-full flex flex-col lg:flex-row h-[100vh]">
        //     <div className="w-full lg:flex flex-col md:flex-1 lg:bg-gradient-to-t pt-14 px-6 from-green-200 via-green-50 to-green-50">
        //         <div className="w-[110px] h-[24px] lg:w-full">
        //             <img className="" src={logo} />
        //         </div>
        //         <Box display={{ base: 'none', lg: 'flex' }} className="flex-col mt-28 space-y-2">
        //             <Text className="font-[Poppins] font-semibold text-3xl  leading-normal ">
        //                 Sri Lanka Institute of Development Administration
        //             </Text>
        //             <Text className={"font-[Poppins]  text-[#20232066]"} maxW="md" >
        //                 Premier public sector training organization in Sri Lanka, for the development of knowledge and improvement of skills in Public Administration and Management.
        //             </Text>
        //         </Box>
        //         <Box className="flex items-center justify-center md:h-[30vh] lg:h-[40vh] xl:h-[50vh] lg:w-full px-[60px] lg:px-[30px] w-full">
        //             <img className="object-fill  w-full" src={loginBackground} />
        //         </Box>
        //         <Box display={{ base: 'none', lg: 'flex' }} className="pb-[40px] items-end h-full">
        //             <Text className={"font-[Poppins] opacity-[0.8] font-normal text-xs text-opacity-60 text-[#202320] "} maxW="md" >
        //                 © 2023 DLAD Software Solution. All rights reserved.
        //             </Text>
        //         </Box>
        //     </div>
        //     <div className="flex md:flex-1 w-full px-6 justify-center">
        //         <SignInForm
        //             px={{ base: '30px', md: '0' }}
        //             py={{ base: '40px', md: '0' }}
        //             width="100%"
        //             maxW="md"
        //         />
        //     </div>
        // </div>

    )
}

export default LoginPage;