import axios from "axios";
import _, { get } from "lodash";
import React from "react";
import { BASE_URL } from "../../../consts/app.consts";
import { BadgeCell, DefaultCell, ResourcePersonCell } from "./components/cells";

const ClassroomConfig = {
  searchApi: "xceed.api.report.classroom.generate_classroom_report",
  alert:
    "Reminder: Specify a date range to receive reports. Use other fields to further narrow down data within the required date range.",
  excelFileName: "classroom-report",
  title: "Classroom Allocation Report",
  extraActionsAsMenu: true,
  sections: [
    {
      selectionTitle: "Date Range",
      grid: { base: 1, md: 2, lg: 4, xl: 4 },
      filters: [
        {
          is_required: true,
          label: "From",
          type: "date",
          key: "from",
        },
        {
          is_required: true,
          label: "To",
          type: "date",
          key: "to",
        },
      ],
    },
    {
      selectionTitle: "Filters",
      grid: { base: 1, md: 2, lg: 4, xl: 4 },
      filters: [
        {
          is_required: true,
          label: "Classroom",
          key: "classroom",
          type: "form-select",
          isMulti: true,
          optionMethod: "xceed.api.report.classroom.get_class_rooms",
        },
      ],
    },
  ],

  tableConfig: {
    column: [
      {
        header: "Class Room",
        accessor: "class_name",
        width: 80,
      },
      {
        header: "External Booking",
        accessor: "rstatus",
        customRenderer: (value: any, item: any) =>
          React.createElement(BadgeCell, {
            config: statusBadgeConfig,
            value: value,
          }),
        width: 50,
      },
      {
        header: "Session",
        accessor: "session_title",
        width: 200,
      },
      {
        header: "Resource Persons",
        accessor: "resource_person",
        customRenderer: (value: any, item: any) =>
          React.createElement(ResourcePersonCell, {
            navigateTo: `/app/employee/${_.get(item, "name")}`,
            values: value,
          }),
        width: 200,
      },
      {
        header: "Program Assistant",
        accessor: "program_assistant",
        width: 200,
      },
      {
        header: "Duration",
        accessor: "session_durationhours",
        width: 200,
      },
      {
        header: "Start",
        accessor: "start_date_andtime",
        width: 200,
      },
      {
        header: "End",
        accessor: "end_date_and_time",
        width: 200,
      },
    ],
  },
  actions: [
    {
      text: "Export to Excel",
      onClick: async (filter: any, data: any, setLoading: any) => {
        if (filter.to && filter.from && _.isArray(filter.classroom)) {
          setLoading(true);
          try {
            let response = await axios.post(
              `${BASE_URL}/method/xceed.api.export_excel.class_room.export_excel_generic_report`,
              { filter },
              {
                withCredentials: true,
                responseType: "blob",
              }
            );
            if (response) {
              console.log(response);
              const href = URL.createObjectURL(response.data);

              const link = document.createElement("a");
              link.href = href;
              link.setAttribute("download", `class-room-report.xlsx`);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            }
            setLoading(false);
          } catch (error: any) {
            setLoading(false);
            const newError: any = new Error("Unable to Generate Excel file");
            newError.title = "Error";
            newError.status = "error";
            throw newError;
          }
        } else {
          const error: any = new Error(
            "Please select from date, to date and at least one classroom"
          );
          error.title = "Warning";
          error.status = "warning";
          throw error;
        }
      },
      buttonVariant: "solid",
      buttonColorScheme: "teal",
    },
    {
      text: "Generate Classroom report",
      onClick: async (filter: any, data: any, setLoading: any) => {
        if (filter.to && filter.from && _.isArray(filter.classroom)) {
          setLoading(true);
          try {
            let response = await axios.post(
              `${BASE_URL}/method/xceed.api.export_excel.class_room.export_class_room_and_lab_report`,
              { filter },
              {
                withCredentials: true,
                responseType: "blob",
              }
            );
            if (response) {
              console.log(response);
              const href = URL.createObjectURL(response.data);

              const link = document.createElement("a");
              link.href = href;
              link.setAttribute("download", `class-room-v2-report.xlsx`);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            }
            setLoading(false);
          } catch (error: any) {
            setLoading(false);
            const newError: any = new Error("Unable to Generate Excel file");
            newError.title = "Error";
            newError.status = "error";
            throw newError;
          }
        } else {
          const error: any = new Error(
            "Please select from date, to date and at least one classroom"
          );
          error.title = "Warning";
          error.status = "warning";
          throw error;
        }
      },
      buttonVariant: "solid",
      buttonColorScheme: "teal",
    },
  ],
};

export default ClassroomConfig;

const statusBadgeConfig = {
  NO: "bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded",
  YES: "bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded",
};
