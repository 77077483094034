import { SimpleGrid } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/react";
import { chakraComponents } from "chakra-react-select";
import { useFormik } from "formik";
import _ from "lodash";
import { NavigateFunction, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { EMPLOYEE, EXAM_ENROLLMENT } from "../../../consts/methods.consts";
import { DROPDOWN_TAGS, EMPLOYEE_TAGS, EXAM_ENROLLMENT_TAGS } from "../../../consts/tags.consts";
import { usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { FormDropdown } from "../../common/form-controllers/FormDropdown";
import { FormInput } from "../../common/form-controllers/FormInput";
import FormSelect from "../../common/form-controllers/FormSelect";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { SectionCard } from "../../common/section-card/SectionCard";
import { FormCheckBox } from "../../common/form-controllers/FormCheckBox";

const NewExamRegistrationPage = () => {

    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());
    const [create] = usePostMutation();

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            nic: Yup.string().matches(/^([0-9]{9}[x|X|v|V]|[0-9]{12})$/, 'Enter valid NIC number').required('NIC is required'),
            mobile: Yup.string().matches(/^\d{9}$/, 'Please enter a valid mobile number').required("Mobile number is required"),
            institution: Yup.string().required("Institution is required"),
            designation: Yup.string().required("Designation is required"),
            official_address: Yup.string().required("Official address is required"),
            // telephone_office_land_line: Yup.string().matches(/^\+\d{1,3}\d{10}$/,'Please enter a valid mobile number').required("telephone_office_land_line is required"),
            service: Yup.string().required("service is required"),
            name_of_the_examination: Yup.string().required("nExamination name is required"),
            medium: Yup.string().required("medium is required"),
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: EXAM_ENROLLMENT.ADD,
                    body: values,
                    invalidatesTags: [EXAM_ENROLLMENT_TAGS.VIEW, EXAM_ENROLLMENT_TAGS.LIST, DROPDOWN_TAGS.LIST]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("New Exam Enrollment creation failed")
                }

                toast.setTitle("Success").setDescription("New Exam Enrollment has been created successfully.").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/exam-registration/${newId}`);
                }, 1500)

            } catch (error: any) {
                // let message = JSON.parse(_.get(error?.data, '_server_messages', {}))
                // if (message) toast.setTitle("Error").setDescription(JSON.parse(message)?.message).showErrorToast();
                toast.setTitle("Error").setDescription('New Exam Enrollment creation failed').showErrorToast();
            }
        }
    })

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    return (
        <div>
            <PageHeader enableBackButton actions={actions} title={'Create New Exam Registration'} subtitle={'Exam Registration'} />
            <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormInput isRequired label="Name with Initials" formik={formik} name="name_with_initials" />
                    <FormInput isRequired label="Full Name" formik={formik} name="full_name" />
                    <FormInput isRequired label="National Identity Card No" formik={formik} name="nic" />

                    <FormInput isRequired label="Institution" formik={formik} name="institution" />
                    <FormInput isRequired label="Designation" formik={formik} name="designation" />

                    <FormInput isRequired label="Service" formik={formik} name="service" />
                    <FormInput isRequired label="Name of the Examination" formik={formik} name="name_of_the_examination" />
                    <FormDropdown options={mediums} formik={formik}
                        name={'medium'}
                        autoFocus
                        label={'Medium'} />
                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} mt={3} spacing={3}>

                    <FormCheckBox isRequired label="Is this your first attempt in this examination" formik={formik} name="first_attempt" />
                    <FormCheckBox isRequired label="I certified that the above information is accurate" formik={formik} name="is_certified" />
                </SimpleGrid>
            </SectionCard>
            <SectionCard backgroundColor=" bg-white" title="Contact Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormInput isRequired type="number" label="Mobile" formik={formik} name="mobile" />
                    <FormInput label="Telephone (Office Land Line)" type="number" formik={formik} name="telephone_personal_land_line" />
                    <FormInput isRequired label="Email Address" formik={formik} name="email_address" />
                    {/* <FormInput isRequired label="Email Address" formik={formik} name="email_address" /> */}
                    <FormInput isRequired label="Official Address" formik={formik} name="official_address" />
                </SimpleGrid>
            </SectionCard>
        </div>
    )
}

export default NewExamRegistrationPage

const mediums = [
    { value: 'Sinhala', label: 'Sinhala' },
    { value: 'English', label: 'English' },
]

const customComponents = {
    Option: ({ children, ...props }: any) => (
        <chakraComponents.Option {...props}>
            <div className="gap-2">
                <div className=" font-bold">
                    {children}
                </div>
                <div className=" italic flex  gap-3">
                    <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                        {props.data.code}
                    </span>
                    {props.data.group && (<span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                        {props.data.group}
                    </span>)}
                </div>
            </div>
        </chakraComponents.Option >
    )
};