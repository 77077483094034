import _ from "lodash";
import * as Yup from "yup";
import ChakraModal from "../../../../common/chakra-modal/ChakraModal";
import { useState } from "react";
import { useFormik } from "formik";
import { ChakraTable } from "../../../../index";
import { FormDropdown } from "../../../../common/form-controllers/FormDropdown";
import { ChakraTableColumns } from "../../../../common/chakra-table/ChakraTable";
import { SimpleGrid, useDisclosure } from "@chakra-ui/react";
import { SectionCard, SectionCardCreatable } from "../../../../common/section-card/SectionCard";

const LanguageProficiency = ({languageProfRef}: any) => {
    const disclosure = useDisclosure()
    const [tableData, setTableData]: any = useState([])

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            language: Yup.string().required("Language is required"),
            proficiency_level: Yup.string().required("Proficiency Level is required")
        }),
        onSubmit: async (values: any) => {
            setTableData([values, ..._.isArray(tableData) ? tableData : []])
            disclosure.onClose()
            formik.resetForm()
        }
    })

    languageProfRef.current = tableData
      
    const languageProficiencyCreatable: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            disclosure.onOpen();
        }
    }

    const onlanguageProficiencyDelete = (item: any) => {
        const newItems = tableData.filter((i: any) => (
            i !== item
        ))
        setTableData(newItems)
    }

    const languageProficiencyTableActions = {
        header: "Action",
        accessor: "",
        width: 100,
        options: [
            {
                label: "Delete",
                onClick: onlanguageProficiencyDelete,
            }
        ]
    }

    return (
        <SectionCard creatable={languageProficiencyCreatable} title="Language Proficiency" p={3}>
            <ChakraTable size={"sm"} searchable={false}
                         columns={[...LANGUAGE_PROFICIENCY, languageProficiencyTableActions]}
                         data={tableData}/>
            <ChakraModal primaryAction={formik.submitForm} primaryButtonText={"Add"}
                         modalTitle={'Add Language Proficiency'} {...disclosure}>
                <SimpleGrid columns={{base: 1, md: 1, lg: 1, xl: 1}} spacing={3}>
                    <FormDropdown options={languageOptions} isRequired formik={formik} name={'language'}
                                  autoFocus
                                  label={'Language'}/>
                    <FormDropdown options={proficiencyOptions} isRequired formik={formik}
                                  name={'proficiency_level'}
                                  autoFocus
                                  label={'Proficiency Level'}/>

                </SimpleGrid>
            </ChakraModal>
        </SectionCard>
    )
}

export default LanguageProficiency

const languageOptions = [
    {value: 'Sinhala', label: 'Sinhala'},
    {value: 'Tamil', label: 'Tamil'},
    {value: 'English', label: 'English'},
    {value: 'Other', label: 'Other'}
]

const proficiencyOptions = [
    {value: 'Basic', label: 'Basic'},
    {value: 'Proficient', label: 'Proficient'},
    {value: 'Fluent', label: 'Fluent'},
]


const LANGUAGE_PROFICIENCY: ChakraTableColumns = [
    {
        header: "Language",
        accessor: "language",
    },
    {
        header: "Proficiency Level",
        accessor: "proficiency_level",
    }
]
