import _ from "lodash";
import { useState } from "react";
import { BiHide, BiShow } from 'react-icons/bi';
import { AiOutlineClose } from 'react-icons/ai';
import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputProps, InputRightElement, } from "@chakra-ui/react";

type MutatedTypes = Omit<InputProps, 'onChange' | 'name' | 'isRequired' | 'isInvalid' | 'defaultValue'>

export interface FormInputProps extends MutatedTypes {
    containerClassNames?: string,
    formik: any,
    name: string,
    label?: string,
    isRequired?: boolean,
    customMessage?: any,
    type?: string
}

export const FormFile = ({ rounded = "md", size = 'sm', customMessage, type = 'file', formik, isRequired = false, name, label, containerClassNames = '', ...restProps }: FormInputProps) => {
    const [showPassword, setShowPassowrd] = useState<boolean>(false)

    const handleFileChange = (e: any) => {
        formik.setFieldValue('attach', e.target.files[0])
    }

    const onClear = (e:any)=>{
        formik.setFieldValue('attach', '')
        const fileInput:any = document.getElementById('attach');
        fileInput.value = ''; // Clear the selected file
    }

    return (
        <FormControl isRequired={isRequired} className={containerClassNames} isInvalid={Boolean(formik.submitCount && formik.errors[name])}>
            {label && <FormLabel className={'!opacity-75 !text-[13px]'}>{label}</FormLabel>}
            <InputGroup size='md'>
                <Input id="attach" type={showPassword ? 'text' : type} _ shadow={"sm"} borderColor={'gray.300'} size={size} {...restProps} name={name} onChange={handleFileChange} rounded={rounded} />
              {/* (<InputRightElement className="!h-[100%] !justify-end pr-2 hover:cursor-pointer" width='2.5rem' onClick={onClear}>
                    <AiOutlineClose className={'w-[24px] h-[24px]'} fill-opacity="0.5" fill={'#BEBEBF'} size='1.4rem' /> 
                </InputRightElement>) */}
            </InputGroup>
            {(formik.submitCount && formik.errors[name]) ? <FormErrorMessage mt={0}>{formik.errors[name]}</FormErrorMessage> : customMessage && customMessage}
        </FormControl>
    )
}