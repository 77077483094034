import { Badge, Center, HStack, Link, Menu, MenuButton, MenuItem, MenuList, Td, Text, Tr } from "@chakra-ui/react";
import { FaEllipsisH } from "react-icons/fa";
import _ from "lodash";

import "../chakra-table.css"
import { showConfirmDialog } from "../../../../store/reducers/confirm-modal";
import { useDispatch } from "react-redux";

const TrComponent = ({ item, columns, onSelected, disablePointer }: any) => {
    const dispatch = useDispatch()

    function selectedRow(row: any) {
        onSelected(row)
    }

    const tdResolver = (type: any = '', className: any = '', value: any = '') => {
        if (type == 'link') {
            return (
                <Link className={className} href={value} isExternal>
                    {value}
                </Link>
            )
        }

        return (
            <Text className={className}>{value}</Text>
        )
    }

    const onClickHandler = (col: any) => {
        if (!col?.options && col?.type != 'link' && !col?.customRenderer) {
            selectedRow(item)
            return;
        }
    }

    const defaultmessage = {
        title: "Confirmation Required!",
        subtitle: "Are you sure you want to continue the action?",
    }

    const columnActionHandler = ({ confirm, message = defaultmessage }: any, action = (item:any) => { }) => {
        if (confirm || confirm === undefined || confirm === null) {
            dispatch(
                showConfirmDialog({
                    title: _.get(message,'title'),
                    subtitle: _.get(message,'subtitle'),
                    onConfirm: (() => { action(item) }) || (() => { })
                }))

        } else {
            action(item)
        }
    }

    return (
        <Tr _hover={{ bg: disablePointer ? '#fff' : "#EBEBEB" }} className="!w-fit" cursor={disablePointer ? '' : 'pointer'}>
            {columns.map((col: any, index: any) => <Td className={`${col?.customRenderer ? 'ctcrs' : null}`} onClick={() => onClickHandler(col)} key={index}>
                {col?.customRenderer ? col.customRenderer(item[columns[index].accessor], item) : (
                    <>
                        {!_.isEmpty(_.get(col, 'conditions', false)) ? <Badge
                            colorScheme={col.conditions[item[columns[index].accessor]]}>{item[columns[index].accessor]}</Badge> :
                            tdResolver(columns[index]?.type, columns[index]?.className ? columns[index]?.className : undefined, item[columns[index]?.accessor])
                        }

                        {(col.options && !col?.disabled) ? <Center>
                            <HStack spacing={5}>
                                <Menu>
                                    <MenuButton>
                                        <FaEllipsisH />
                                    </MenuButton>
                                    <MenuList>
                                        {col.options.map((actionItem: any, index: any) => <MenuItem onClick={() => {
                                            columnActionHandler(
                                                {
                                                    confirm: _.get(actionItem, 'confirmation'),
                                                    message: _.get(actionItem, 'confirmationMessage')
                                                },
                                                actionItem.onClick
                                            )
                                        }} key={index}>{actionItem.label}</MenuItem>)}
                                    </MenuList>
                                </Menu>
                            </HStack>
                        </Center> : null}
                    </>
                )}
            </Td>
            )
            }
        </Tr >
    )
}
export default TrComponent;