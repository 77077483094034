import { useDisclosure, useToast } from "@chakra-ui/react";
import _ from "lodash";
import { useState } from "react";
import * as Yup from "yup";
import { NavigateFunction, useNavigate } from "react-router";
import { useParams, useSearchParams } from "react-router-dom";
import { SERVICE, SUBJECT } from "../../../consts/methods.consts";
import { DROPDOWN_TAGS, SERVICE_TAGS, SUBJECT_TAGS } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { useTableDataProvider } from "../../hooks";
import { ChakraModal, PageHeader } from "../../index";
import { useFormik } from "formik";
import { FormInput } from "../../common/form-controllers/FormInput";
import { PageHeaderActions } from "../../common/page-header/PageHeader";

const SubjectListPage = () => {

    const disclosure = useDisclosure()
    const [searchKey, setSearchKey]: any = useState()
    const [page, setPage] = useState(1)
    const toast = new ToastService(useToast())
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [create] = usePostMutation();

    const { data, isLoading, isFetching } = useGetQuery({
        method: SUBJECT.LIST,
        body: {
            page: page,
            key: searchKey
        },
        providesTags: [SUBJECT_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const formik = useFormik({
        initialValues: {
            title: ''
        },
        validationSchema: Yup.object({
            subject_code: Yup.string().required("Code is required"),
            subject_name: Yup.string().required("Name is required")
        }),
        onSubmit: async (values) => {
            try {
                disclosure.onClose();
                await create({
                    method: isSelected ? SUBJECT.UPDATE : SUBJECT.ADD,
                    body: values,
                    invalidatesTags: [SUBJECT_TAGS.LIST, DROPDOWN_TAGS.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription(`${isSelected ? "Subject have been saved" : "Subject has been created successfully"}`).showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription(`${isSelected ? 'Faild to save subject details. Please try again' : 'Subject creation faild'}`).showErrorToast();
                console.error(error);
            } finally {
                setIsSelected(false);
            }
        }
    })

    const ondeleteHandler = async (item: any) => {
        try {
            const res = await create({
                method: SUBJECT.DELETE,
                body: { id: item?.name },
                invalidatesTags: [SUBJECT_TAGS.LIST, DROPDOWN_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Subject is deleted successfully.").showSuccessToast();

        } catch (error: any) {
            let message = JSON.parse(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(JSON.parse(message)?.message).showErrorToast();
        }

    }

    const SERVICE_COLUMNS: ChakraTableColumns = [
        {
            header: "Code",
            accessor:
                "subject_code",
        },
        {
            header: "Name",
            accessor:
                "subject_name",
        },
        {
            header: "Actions",
            accessor: '',
            disabled: false,
            width: 100,
            options: [
                {
                    label: "Edit",
                    confirmation: false,
                    onClick(item) {
                        formik.setFieldValue("subject_code", _.get(item, "subject_code"))
                        formik.setFieldValue("subject_name", _.get(item, "subject_name"))
                        formik.setFieldValue("name", _.get(item, "name"))
                        setIsSelected(true)
                        disclosure.onOpen()
                    },
                },
                {
                    label: "Delete",
                    onClick: ondeleteHandler,
                }
            ]
        }
    ]


    const actions: PageHeaderActions = [

        {
            text: "Create New",
            confirmation: false,
            onClick: () => {
                setIsSelected(false)
                formik.resetForm();
                disclosure.onOpen()
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }

    const onPage = (page:any)=>{
        setPage(page)
    }

    return (
        <>
            <div>
                <PageHeader subtitle="View and Manage Subject" title={`Subject`} actions={actions} />
                <ChakraTable onPage={onPage} onSearch={onSearchHandler} isFetching={isFetching} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} header
                    title="Subjects"
                    columns={SERVICE_COLUMNS} data={dataList} />
                <ChakraModal primaryAction={formik.submitForm} primaryButtonText={"Save"} modalTitle={`${isSelected ? 'Edit subject' : 'New subject'}`} {...disclosure}>
                    <FormInput isRequired formik={formik} name="subject_code" autoFocus label="Subject Code" />
                    <FormInput isRequired formik={formik} name="subject_name" autoFocus label="Subject Name" />
                </ChakraModal>
            </div>
        </>
    )
}
export default SubjectListPage