import _ from "lodash";
import React from "react";
import { useState } from "react";
import { DefaultCell } from "../report/components/cells";
import { printAction } from "../../../actions";
import * as Yup from "yup";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { DROPDOWN_TAGS, MODULE_ENROLLMENT_REQUEST_TAGS, PROGRAM_TAGS } from "../../../consts/tags.consts";
import { MODULE_ENROLLMENT_REQUEST, PRINT, PROGRAM } from "../../../consts/methods.consts";
import { Link, useSearchParams } from "react-router-dom";
import { PageHeaderActions } from "../../common/page-header/PageHeader";
import { useTableDataProvider } from "../../hooks";
import { InnerLoading, PageHeader } from "../../index";
import { NavigateFunction, useNavigate } from "react-router";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { ToastService } from "../../../services/toast.service";
import { useToast } from "@chakra-ui/react";
import { customeJsonParser } from "../../../utils/utils";


const ListModuleEnrollmentRequestPage = () => {
    const [searchKey, setSearchKey]: any = useState()
    const [page, setPage] = useState(1)
    const [create] = usePostMutation();
    const toast = new ToastService(useToast())
    const [length, setLength] = useState(15)

    const { data, isLoading, isFetching } = useGetQuery({
        method: MODULE_ENROLLMENT_REQUEST.LIST,
        body: {
            page: page,
            key: searchKey,
            length: Number(length),
        },
        providesTags: [MODULE_ENROLLMENT_REQUEST_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const ondeleteHandler = async (item: any) => {
        try {
            const res = await create({
                method: MODULE_ENROLLMENT_REQUEST.DELETE,
                body: { id: item?.name },
                invalidatesTags: [MODULE_ENROLLMENT_REQUEST_TAGS.LIST, DROPDOWN_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Module enrollment request is deleted successfully.").showSuccessToast();

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        }

    }

    const onAcceptHandler = async (item: any) => {
        try {
            const res = await create({
                method: MODULE_ENROLLMENT_REQUEST.ACCEPT,
                body: { id: item?.name },
                invalidatesTags: [MODULE_ENROLLMENT_REQUEST_TAGS.LIST, DROPDOWN_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Module enrollement is accepted successfully.").showSuccessToast();

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        }
    }

    const onRejectHandler = async (item: any) => {
        try {
            const res = await create({
                method: MODULE_ENROLLMENT_REQUEST.REJECT,
                body: { id: item?.name },
                invalidatesTags: [MODULE_ENROLLMENT_REQUEST_TAGS.LIST, DROPDOWN_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Module enrollement is reject successfully.").showSuccessToast();

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        }
    }


    const actions: PageHeaderActions = [

    ]

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }

    const onLengthChange = (length: any) => {
        setPage(1)
        setLength(Number(length))
    }

    const onPage = (page: any) => {
        setPage(page)
    }


    const PROGRAM_LIST_COLUMNS: ChakraTableColumns = [
        {
            header: "nic",
            accessor: "nic",
            width: 150
        },
        {
            header: "Fullname",
            width: 450,
            accessor:
                "full_name",
            customRenderer(value, item) {
                return (
                    <Link className=" text-blue-400" to={`/app/student/${_.get(item, 'student_id')}`} >
                        {value}
                    </Link>
                )
            },
        },
        {
            header: "Program",
            width: 450,
            accessor:
                "program",
            customRenderer(value, item) {
                return (
                    <Link className=" text-blue-400" to={`/app/program/${_.get(item, 'program_id')}`} >
                        {value}
                    </Link>
                )
            },

        },
        {
            header: "Module",
            width: 180,
            accessor:
                "module",
            customRenderer(value, item) {
                return (
                    <Link className=" text-blue-400" to={`/app/modules/${_.get(item, 'module_id')}`} >
                        {value}
                    </Link>
                )
            },
        },
        {
            header: "date",
            width: 180,
            accessor:
                "date",
        },
        {
            header: "status",
            width: 180,
            accessor:
                "status",
        },
        {
            header: "Actions",
            accessor: '',
            disabled: false,
            width: 100,
            options: [
                {
                    label: "Accept",
                    onClick(item: any) {

                        if (item.status === 'Approved') {
                            toast.setTitle("Error").setStatus('warning').setDescription('This request already accepted').show();
                            return
                        }
                        onAcceptHandler(item)
                    },
                },
                {
                    label: "Reject",
                    onClick(item: any) {

                        if (item.status === 'Rejected') {
                            toast.setTitle("Error").setStatus('warning').setDescription('This request already rejected').show();
                            return
                        }
                        onRejectHandler(item)
                    },
                },
                {
                    label: "Delete",
                    onClick: ondeleteHandler,
                }
            ]
        }
    ]


    if (isLoading) {
        return (
            <>
                <PageHeader subtitle="View and Manage Enrollment Request" enableBackButton actions={actions} title={`Program`} />
                <InnerLoading />
            </>
        )
    }

    return (
        <div>
            <PageHeader subtitle="View and Manage Enrollment Request" title={`Enrollment Request`} actions={actions} />
            <ChakraTable onPage={onPage} onLength={onLengthChange} lengthPaginations isFetching={isFetching} onSearch={onSearchHandler} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} header
                title="Enrollment Requests"
                columns={PROGRAM_LIST_COLUMNS} data={dataList} />
        </div>

    )
}

export default ListModuleEnrollmentRequestPage