import _ from "lodash";
import * as Yup from "yup";
import { MODULE } from "../../../consts/methods.consts";
import ChakraModal from "../../common/chakra-modal/ChakraModal";
import { FormInput } from "../../common/form-controllers/FormInput";
import { FastField, useFormik } from "formik";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";
import { ToastService } from "../../../services/toast.service";
import { useObjChange } from "../../hooks";
import { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import React, { useEffect, useRef, useState } from "react";
import { ChakraTable, InnerLoading } from "../..";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { SectionCard, SectionCardCreatable } from "../../common/section-card/SectionCard";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import { DROPDOWN_TAGS, MODULE_BUCKET_TAGS, MODULE_TAGS } from "../../../consts/tags.consts";
import { SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, useToast } from "@chakra-ui/react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { FormDropdown } from "../../common/form-controllers/FormDropdown";
import { customeJsonParser } from "../../../utils/utils";
import DocumentHeaderTitle from "../../common/document-header-title/DocumentHeaderTitle";

const ViewModulePage = () => {
    const { id } = useParams()
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());
    const disclosure = useDisclosure()
    const [create, { isLoading: creating }] = usePostMutation();
    const ref: any = useRef<number>()
    const indexRef: any = useRef<number>()

    const { data: _data, isLoading, isFetching } = useGetQuery({
        method: MODULE.GET,
        body: { id },
        providesTags: [MODULE_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {})

    const stringifiedData = JSON.stringify(data)

    const formik = useFormik({
        initialValues: { specific_objectives: [], methodology: [] },
        validationSchema: Yup.object({
            code: Yup.string().required("Code is required").matches(/^\S+$/, 'Module name cannot contain white spaces'),
            title: Yup.string().required("Title is required"),
            credit: Yup.number().required("Credit is required"),
            duration_hours: Yup.number().required("Duration (hours) is required"),
            duration_days: Yup.number().required("Duration (days) is required"),
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: MODULE.UPDATE,
                    body: values,
                    invalidatesTags: [MODULE_BUCKET_TAGS.VIEW, MODULE_BUCKET_TAGS.LIST, MODULE_TAGS.VIEW, MODULE_TAGS.LIST, DROPDOWN_TAGS.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription("Module details have been saved").showSuccessToast();

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const deleteHandler = async () => {
        try {
            await create({
                method: MODULE.DELETE,
                body: { id },
                invalidatesTags: [MODULE_BUCKET_TAGS.VIEW, MODULE_BUCKET_TAGS.LIST, MODULE_TAGS.LIST, DROPDOWN_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Module is deleted successfully.").showSuccessToast();

            setTimeout(() => {
                navigate(`/app/modules`);
            }, 1500)

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            // toast.setTitle("Error").setDescription('Failed to delete the module').showErrorToast();
        }
    }


    const childValidation = {
        0: {
            title: Yup.string().required("Title is required"),
            index: Yup.number().required("Index is required"),
        },
        1: {
            description: Yup.string().required("Description is required"),
            index: Yup.number().required("Index is required"),
        },
        2: {
            title: Yup.string().required("Description is required"),
            // index: Yup.number().required("Index is required"),
        },
        3: {
            title: Yup.string().required("Description is required"),
            index: Yup.number().required("Index is required"),
        }
    }

    const childFormik = useFormik({
        initialValues: {},
        validationSchema: Yup.object(_.get(childValidation, ref.current)),
        onSubmit: async (values: any) => {
            try {
                if (ref.current === 0) {

                    if (isSelected) {
                        const ModuleContent: any = [..._.get(formik.values, 'module_content', [])]
                        ModuleContent[indexRef.current] = { ...values }
                        formik.setFieldValue('module_content', ModuleContent)
                        console.log('aaa', ModuleContent)
                        disclosure.onClose()
                        childFormik.resetForm()
                        setIsSelected(false);
                        return
                    }

                    const existsItemIndex = _.get(formik.values, 'module_content', []).findIndex((line: any) => (line.title === values.title))
                    if (existsItemIndex > -1) {
                        toast.setTitle("Error").setDescription("Title already exists").showErrorToast();
                        return
                    }

                    const newModuleContent = [{ ...values }, ..._.get(formik.values, 'module_content', [])]
                    formik.setFieldValue('module_content', newModuleContent)

                }

                if (ref.current === 1) {
                    const existsItemIndex = _.get(formik.values, 'specific_objectives', []).findIndex((line: any) => (line.description === values.description))

                    if (isSelected) {
                        const specificObjectives: any = [..._.get(formik.values, 'specific_objectives', [])]
                        specificObjectives[indexRef.current] = { ...values }
                        formik.setFieldValue('specific_objectives', specificObjectives)
                        disclosure.onClose()
                        childFormik.resetForm()
                        setIsSelected(false);
                        return
                    }

                    if (existsItemIndex > -1) {
                        toast.setTitle("Error").setDescription("Description already exists").showErrorToast();
                        return
                    }

                    const newModuleContent = [{ ...values }, ..._.get(formik.values, 'specific_objectives', [])]
                    formik.setFieldValue('specific_objectives', newModuleContent)

                }

                if (ref.current === 2) {
                    const existsItemIndex = _.get(formik.values, 'methodology', []).findIndex((line: any) => (line.title === values.title))

                    if (isSelected) {
                        const methodology: any = [..._.get(formik.values, 'methodology', [])]
                        methodology[indexRef.current] = { ...values }
                        formik.setFieldValue('methodology', methodology)
                        disclosure.onClose()
                        childFormik.resetForm()
                        setIsSelected(false);
                        return
                    }

                    if (existsItemIndex > -1) {
                        toast.setTitle("Error").setDescription("Title already exists").showErrorToast();
                        return
                    }

                    const newModuleContent = [{ ...values }, ..._.get(formik.values, 'methodology', [])]
                    formik.setFieldValue('methodology', newModuleContent)

                }

                if (ref.current === 3) {
                    const existsItemIndex = _.get(formik.values, 'learning_outcomes', []).findIndex((line: any) => (line.title === values.title))

                    if (isSelected) {
                        const learningOutcomes: any = [..._.get(formik.values, 'learning_outcomes', [])]
                        learningOutcomes[indexRef.current] = { ...values }
                        formik.setFieldValue('learning_outcomes', learningOutcomes)
                        disclosure.onClose()
                        childFormik.resetForm()
                        setIsSelected(false);
                        return
                    }

                    if (existsItemIndex > -1) {
                        toast.setTitle("Error").setDescription("Title already exists").showErrorToast();
                        return
                    }

                    const newModuleContent = [{ ...values }, ..._.get(formik.values, 'learning_outcomes', [])]
                    formik.setFieldValue('learning_outcomes', newModuleContent)

                }

                disclosure.onClose()
                childFormik.resetForm()

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to add content. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const { isChanged, setInitialObj } = useObjChange(formik.values)

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
        }
    }, [stringifiedData])

    const SPECIFIC_OBJECTIVES_TAB_COLUMNS: ChakraTableColumns = [
        {
            header: "DESCRIPTION",
            accessor: "description",
        },
        {
            header: "Index",
            accessor: "index",
        },
        {
            header: "Action",
            accessor: "",
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick(item: any) {
                        const newItems = _.get(formik.values, 'specific_objectives', []).filter((i: any) => (
                            i?.description !== item?.description
                        ))
                        formik.setFieldValue('specific_objectives', newItems)
                    },
                },
                {
                    label: "Edit",
                    confirmation: false,
                    onClick(item: any) {
                        ref.current = 1
                        indexRef.current = _.get(formik.values, 'specific_objectives', []).findIndex(
                            (line: any) => (line.description === item.description)
                        )
                        setIsSelected(true)
                        childFormik.setFieldValue('description', _.get(item, 'description'))
                        childFormik.setFieldValue('index', _.get(item, 'index'))
                        disclosure.onOpen()
                    },
                }
            ]
        },
    ]

    const METHOD_TAB_COLUMNS: ChakraTableColumns = [
        {
            header: "Description",
            accessor: "title",
        },
        {
            header: "Action",
            accessor: "",
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick(item: any) {
                        const newItems = _.get(formik.values, 'methodology', []).filter((line: any) => (
                            line?.title !== item?.title
                        ))
                        formik.setFieldValue('methodology', newItems)
                    },
                },
                {
                    label: "Edit",
                    confirmation: false,
                    onClick(item: any) {
                        ref.current = 2
                        indexRef.current = _.get(formik.values, 'methodology', []).findIndex(
                            (line: any) => (line.title === item.title)
                        )
                        setIsSelected(true)
                        childFormik.setFieldValue('title', _.get(item, 'title'))
                        childFormik.setFieldValue('index', _.get(item, 'index'))
                        disclosure.onOpen()
                    },
                }
            ]
        },
    ]

    const CONTENT_TAB_COLUMNS: ChakraTableColumns = [
        {
            header: "Description",
            accessor: "title",
            width: 800
        },
        {
            header: "Index",
            accessor: "index",
        },
        {
            header: "Action",
            accessor: "",
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick(item: any) {
                        const newItems = _.get(formik.values, 'module_content', []).filter((line: any) => (
                            line?.title !== item?.title
                        ))
                        formik.setFieldValue('module_content', newItems)
                    },
                },
                {
                    label: "Edit",
                    confirmation: false,
                    onClick(item: any) {
                        ref.current = 0
                        indexRef.current = _.get(formik.values, 'module_content', []).findIndex(
                            (line: any) => (line.title === item.title)
                        )
                        setIsSelected(true)
                        childFormik.setFieldValue('title', _.get(item, 'title'))
                        childFormik.setFieldValue('index', _.get(item, 'index'))
                        disclosure.onOpen()
                    },
                }
            ]
        },

    ]

    const LERNING_OUTCOMES_TAB_COLUMN: ChakraTableColumns = [
        {
            header: "Description",
            accessor: "title",
        },
        {
            header: "Index",
            accessor: "index",
        },
        {
            header: "Action",
            accessor: "",
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick(item: any) {
                        const newItems = _.get(formik.values, 'learning_outcomes', []).filter((line: any) => (
                            line?.title !== item?.title
                        ))
                        formik.setFieldValue('learning_outcomes', newItems)
                    },
                },
                {
                    label: "Edit",
                    confirmation: false,
                    onClick(item: any) {
                        ref.current = 3
                        indexRef.current = _.get(formik.values, 'learning_outcomes', []).findIndex(
                            (line: any) => (line.title === item.title)
                        )
                        setIsSelected(true)
                        childFormik.setFieldValue('title', _.get(item, 'title'))
                        childFormik.setFieldValue('index', _.get(item, 'index'))
                        disclosure.onOpen()
                    },
                }
            ]
        },
    ]

    const actions: PageHeaderActions = [
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Delete",
            onClick: deleteHandler,
            buttonVariant: "solid",
            buttonColorScheme: "red"
        }
    ]

    if (isLoading) {
        return (
            <>
                <PageHeader isLoading={isLoading} enableBackButton actions={actions} title={'Module'}
                />
                <InnerLoading />
            </>
        )
    }

    const contentTableAction: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            ref.current = 0
            setIsSelected(false)
            childFormik.resetForm()
            disclosure.onOpen();
        }
    }

    const specificObjectivesTableAction: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            ref.current = 1
            setIsSelected(false)
            childFormik.resetForm()
            disclosure.onOpen();
        }
    }

    const methodologyTableAction: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            ref.current = 2
            setIsSelected(false)
            childFormik.resetForm()
            disclosure.onOpen();
        }
    }

    const learningOutComesTableAction: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            ref.current = 3
            setIsSelected(false)
            childFormik.resetForm()
            disclosure.onOpen();
        }
    }

    const contentModalMarkup = (
        <>
            <FormInput type="number" isRequired formik={childFormik} name="index" autoFocus label="Index" />
            <FormTextArea rows={7} isRequired formik={childFormik} name="title" autoFocus label="Description" />
        </>

    )

    const specialObjectiveModalMarkup = (
        <>
            <FormInput type="number" isRequired formik={childFormik} name="index" autoFocus label="Index" />
            <FormTextArea rows={7} isRequired formik={childFormik} name="description" autoFocus label="Description" />
        </>
    )

    const methodologyModalMarkup = (
        <>
            {/* <FormInput type="number" isRequired formik={childFormik} name="index" autoFocus label="Index" /> */}
            <FormTextArea rows={7} isRequired formik={childFormik} name="title" autoFocus label="Description" />
        </>
    )

    const learningOutcomesModalMarkup = (
        <>
            <FormInput type="number" isRequired formik={childFormik} name="index" autoFocus label="Index" />
            <FormTextArea rows={7} isRequired formik={childFormik} name="title" autoFocus label="Description" />
        </>
    )

    const modalContent = {
        0: contentModalMarkup,
        1: specialObjectiveModalMarkup,
        2: methodologyModalMarkup,
        3: learningOutcomesModalMarkup
    }

    const modalTitle = {
        0: 'Content',
        1: "Special Objective",
        2: "Methodology",
        3: "Learning Outcomes"
    }

    return (
        <div>
            <PageHeader backButtonCofirmation={isChanged} isLoading={isLoading || creating} enableBackButton actions={actions} title={_.get(data, 'title', '')}
                subtitle={'Module'} />
            <Tabs className="mt-3">
                <TabList>
                    <Tab>General Information</Tab>
                    <Tab>Content</Tab>
                    <Tab>Learning Outcomes</Tab>
                    <Tab>Specific Objectives</Tab>
                    <Tab>Methodology</Tab>
                </TabList>

                <TabPanels className="animation-form">
                    <TabPanel className="!p-0 !pt-3">
                        <DocumentHeaderTitle>
                            {_.get(data, 'title', '')}
                        </DocumentHeaderTitle>
                        <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 3 }} spacing={3}>
                                <FormInput isRequired label="Module Code" formik={formik} name="code" />
                                <FormInput isRequired label="Module Name" formik={formik} name="title" />
                                <FormDropdown options={groups} formik={formik}
                                    name={'group'}
                                    autoFocus
                                    label={'Group'} />
                                <FormInput type={'number'} isRequired label="No of Credits" formik={formik} name="credit" />
                                <FormInput type={'number'} isRequired label="Duration(hours)" formik={formik} name="duration_hours" />
                                <FormInput type={'number'} isRequired label="Duration(days)" formik={formik} name="duration_days" />
                            </SimpleGrid>
                        </SectionCard>
                        <SectionCard backgroundColor=" bg-white" title="Objectives and Assesment Criteria" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 2 }} spacing={3}>
                                <FormTextArea rows={7} label="Overall Objective" formik={formik} name="overall_objective" />
                                <FormTextArea rows={7} label="Assesment Criteria" formik={formik} name="assesment_critria" />
                            </SimpleGrid>
                        </SectionCard>
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <SectionCard backgroundColor="bg-white" creatable={contentTableAction} title="Content" p={3}>
                            <ChakraTable size={"sm"} title=""
                                columns={CONTENT_TAB_COLUMNS}
                                data={_.get(formik.values, 'module_content', [])} />
                        </SectionCard>
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <SectionCard backgroundColor="bg-white" creatable={learningOutComesTableAction} title="Learning Outcomes" p={3}>
                            <ChakraTable size={"sm"}
                                columns={LERNING_OUTCOMES_TAB_COLUMN}
                                data={_.get(formik.values, 'learning_outcomes', [])} />
                        </SectionCard>
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <SectionCard backgroundColor="bg-white" creatable={specificObjectivesTableAction} title="Specific Objectives" p={3}>
                            <ChakraTable size={"sm"}
                                columns={SPECIFIC_OBJECTIVES_TAB_COLUMNS}
                                data={_.get(formik.values, 'specific_objectives', [])} />
                        </SectionCard>

                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <SectionCard backgroundColor="bg-white" creatable={methodologyTableAction} title="Methodology" p={3}>
                            <ChakraTable size={"sm"}
                                columns={METHOD_TAB_COLUMNS}
                                data={_.get(formik.values, 'methodology', [])} />
                        </SectionCard>
                    </TabPanel>


                </TabPanels>
            </Tabs>
            <ChakraModal primaryAction={childFormik.submitForm} primaryButtonText={"Add"} modalTitle={`${isSelected ? 'Edit ' + _.get(modalTitle, ref.current) : 'Add ' + _.get(modalTitle, ref.current)}`}
                {...disclosure}>
                {_.get(modalContent, ref.current)}
            </ChakraModal>
        </div>
    )
}

export default ViewModulePage

const groups = [
    { label: '2023', value: '2023' },
    { label: '2024', value: '2024' },
    { label: '2025', value: '2025' }
]