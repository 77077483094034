import { SimpleGrid, useToast } from "@chakra-ui/react";
import { ToastService } from "../../../../../services/toast.service"
import * as Yup from "yup";
import { useFormik } from "formik";
import { printAction } from "../../../../../actions";
import { AMENDMENTS, PRINT, PROGRAMMED_MODULE } from "../../../../../consts/methods.consts";
import ChakraModal from "../../../../common/chakra-modal/ChakraModal";
import { FormDropdown } from "../../../../common/form-controllers/FormDropdown";
import { useGetQuery, usePostMutation } from "../../../../../services/api.service";
import { AMENDMENT_TAG, PROGRAMMED_MODULE_TAGS } from "../../../../../consts/tags.consts";
import { FormCheckBox } from "../../../../common/form-controllers/FormCheckBox";
import { get, isArray, size } from "lodash";
import { FormTextArea } from "../../../../common/form-controllers/FormTextArea";
import { customeJsonParser } from "../../../../../utils/utils";
import { useDispatch } from "react-redux";
import { showConfirmDialog } from "../../../../../store/reducers/confirm-modal";
import async from "react-select/dist/declarations/src/async/index";

const AmendmentRequestModal = ({ disclosure, programed_module, setLoading }: any) => {

    const { data: _data, isLoading } = useGetQuery({
        method: AMENDMENTS.GET_AMENDMENT_ALLOW_FIELDS,
        body: { "doctype": "Programmed Module" },
        providesTags: []
    })

    const [create, { isLoading: creating }] = usePostMutation();
    const dispatch = useDispatch()

    const allowed_fields = get(_data, 'message')

    const toast = new ToastService(useToast())

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            "comment": Yup.string().required("Remarks is required")
        }),
        onSubmit: async (values: any) => {
            const modified_data = Object.keys(values).filter((item: any) => values[item] && item !== 'comment').map((key: any) => {
                if (values[key]) {
                    return { label: allowed_fields.find((item: any) => item.value === key).label, key };
                }
            });
            if (size(modified_data) <= 0) {
                toast.setTitle('Warning').setDescription('Select at least one field').setStatus('warning').show()
                return
            }
            disclosure.onClose()

            dispatch(
                showConfirmDialog({
                    title: "Confirmation Required!",
                    subtitle: "Are you sure you want to create amendment request?",
                    onConfirm: async () => {
                        try {

                            await create({
                                method: AMENDMENTS.CREATE_AMENDMENT_REQUEST,
                                body: {
                                    doctype: 'Programmed Module',
                                    type: 'Program Module',
                                    comment: get(values, 'comment'),
                                    amendment_request_fields: modified_data,
                                    document: programed_module
                                },
                                invalidatesTags: [PROGRAMMED_MODULE_TAGS.VIEW, PROGRAMMED_MODULE_TAGS.LIST, AMENDMENT_TAG.LIST]
                            }).unwrap();

                            toast.setTitle("Success").setDescription("Amendment Request has been created successfully").showSuccessToast();
                            formik.resetForm()
                            disclosure.onClose()


                        } catch (error: any) {
                            let message = customeJsonParser(get(error?.data, '_server_messages', {}))
                            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
                        }
                    }
                }))
        }
    })

    const fieldsMarkUp = isArray(allowed_fields) ? allowed_fields.map((item: any, index: any) =>
        <FormCheckBox key={index} label={item.label} formik={formik} name={item.value} />

    ) : null

    return (
        <ChakraModal isLoading={creating} size={'xl'} primaryAction={formik.submitForm} primaryButtonText={"Create"} modalTitle={`Amendment Request`} {...disclosure}>
            <SimpleGrid className={'rounded p-3'} columns={{ base: 2, md: 2, lg: 2, xl: 2 }} spacing={3}>
                {fieldsMarkUp}
            </SimpleGrid>
            <SimpleGrid className={'rounded p-3'} columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                <FormTextArea label="Remarks" isRequired formik={formik} name="comment" />
            </SimpleGrid>
        </ChakraModal>
    )
}

export default AmendmentRequestModal