import _ from "lodash";
import { Checkbox, CheckboxProps, FormControl, FormErrorMessage, FormLabel, HStack, Textarea, TextareaProps, VStack } from "@chakra-ui/react";

type MutatedTypes = Omit<CheckboxProps, 'onChange' | 'name' | 'isRequired' | 'isInvalid' | 'defaultValue'>

export interface FormCheckBoxProps extends MutatedTypes {
    containerClassNames?: string,
    formik: any,
    name: string,
    label?: string,
    isRequired?: boolean,
    customMessage?: any,
}

export const FormCheckBox = ({ rounded = "md", size = "md", customMessage, formik, isRequired = false, name, label, containerClassNames = '', ...restProps }: FormCheckBoxProps) => {

    return (
        <FormControl display={'flex'} alignItems={"end"} isRequired={isRequired} className={containerClassNames} isInvalid={Boolean(formik.submitCount && formik.errors[name])}>
            <VStack alignItems={"flex-start"}>
                <HStack>
                    <Checkbox borderColor={'gray.300'} size={size} {...restProps} name={name} isChecked={_.isEmpty(formik.values) ? false : Boolean(formik.values[name])} onChange={formik.handleChange} rounded={rounded} />
                    {label && <FormLabel  className="!mb-0.5 !opacity-75 !text-[13px]">{label}</FormLabel>}
                </HStack>
                {(formik.submitCount && formik.errors[name]) ? <FormErrorMessage mt={0}>{formik.errors[name]}</FormErrorMessage> : customMessage && customMessage}
            </VStack>
        </FormControl>
    )
}