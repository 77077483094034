import * as Yup from "yup";
import _ from "lodash";
import { useState } from "react";
import { useFormik } from "formik";
import ChakraModal from "../../common/chakra-modal/ChakraModal";
import { FormInput } from "../../common/form-controllers/FormInput";
import { ToastService } from "../../../services/toast.service";
import { useSearchParams } from "react-router-dom";
import { MODULE_BUCKET_TYPE } from "../../../consts/methods.consts";
import { useTableDataProvider } from "../../hooks";
import { MODULE_BUCKET_TYPE_TAGS } from "../../../consts/tags.consts";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { customeJsonParser } from "../../../utils/utils";

const ModuleBucketTypeListPage = () => {
    const disclosure = useDisclosure()
    const toast = new ToastService(useToast())
    const [page, setPage] = useState(1)
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [create] = usePostMutation();

    const { data, isLoading, isFetching } = useGetQuery({
        method: MODULE_BUCKET_TYPE.LIST,
        body: {
            page: page,
        },
        providesTags: [MODULE_BUCKET_TYPE_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const formik = useFormik({
        initialValues: {
            title: ''
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Name is required"),
        }),
        onSubmit: async (values) => {
            try {
                await create({
                    method: isSelected ? MODULE_BUCKET_TYPE.UPDATE : MODULE_BUCKET_TYPE.ADD,
                    body: values,
                    invalidatesTags: [MODULE_BUCKET_TYPE_TAGS.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription(`${isSelected ? "Module basket type details have been saved" : "Module Basket Type has been created successfully"}`).showSuccessToast();

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            } finally {
                disclosure.onClose();
                setIsSelected(false);
            }
        }
    })

    const ondeleteHandler = async (item: any) => {
        try {
            const res = await create({
                method: MODULE_BUCKET_TYPE.DELETE,
                body: { "name": item?.name },
                invalidatesTags: [MODULE_BUCKET_TYPE_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Module basket type is deleted successfully.").showSuccessToast();

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        }

    }

    const MODULE_BUCKET_TYPE_COLUMNS: ChakraTableColumns = [
        {
            header: "Name",
            accessor: "title",
        },
        {
            header: "Actions",
            accessor: '',
            disabled: false,
            options: [
                {
                    label: "Edit",
                    onClick(item) {
                        formik.setFieldValue("title", _.get(item, "title"))
                        formik.setFieldValue("name", _.get(item, "name"))
                        setIsSelected(true)
                        disclosure.onOpen()
                    },
                },
                {
                    label: "Delete",
                    onClick: ondeleteHandler,
                }
            ]
        }
    ]

    const actions: PageHeaderActions = [
        {
            text: "Create New",
            confirmation: false,
            onClick: () => {
                setIsSelected(false)
                formik.resetForm();
                disclosure.onOpen()
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const onPage = (page: any) => {
        setPage(page)
    }


    return (
        <div>
            <PageHeader subtitle="View and Manage Module Basket Types" title={`Module Basket Type`} actions={actions} />
            <div className="grid gap-3 grid-cols-1">
                <ChakraTable onPage={onPage} isFetching={isFetching} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} header title="Module Basket Types" columns={MODULE_BUCKET_TYPE_COLUMNS} data={dataList} />
            </div>

            <ChakraModal primaryAction={formik.submitForm} primaryButtonText={"Save"} modalTitle={`${isSelected ? 'Edit Module Basket Type' : 'New Module Basket Type'}`} {...disclosure}>
                <FormInput isRequired formik={formik} name="title" autoFocus label="Name" />
            </ChakraModal>
        </div>
    )
}

export default ModuleBucketTypeListPage