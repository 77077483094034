import { useToast, SimpleGrid } from "@chakra-ui/react";
import _ from "lodash";
import { useState } from "react";
import { usePostMutation } from "../../../../services/api.service";
import { ToastService } from "../../../../services/toast.service";
import { customeJsonParser } from "../../../../utils/utils";
import ChakraModal from "../../../common/chakra-modal/ChakraModal";
import config from "../../report/config";

interface ExcelModalTypes {
    disclosure: any
    config: {
        programModule: string,
        session: string,
        invalidateTags: string[]
    }
}


const UploadAttendanceModal = ({ disclosure, config }: ExcelModalTypes)=>{
    const toast = new ToastService(useToast())
    const [create] = usePostMutation();
    const [file, setFile]: any = useState(null);

    const handleFileChange = (e: any) => {
        setFile(e.target.files[0]);
    };

    const onSumbitAttachment = async () => {
        if (file && _.get(file, 'type') == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
            let formData = new FormData();
            formData.append('file', file);
            formData.append('program_module', _.get(config, 'programModule'));
            formData.append('session', _.get(config, 'session'));

            create({ method: _.get(config, 'uploadMethod', ''), body: formData, invalidatesTags: _.get(config, 'invalidateTags', []) }).unwrap().then(() => {
                disclosure.onClose()
                toast.setTitle("Succsess").setDescription("Uploading started").showSuccessToast();

            }).catch((error: any) => {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            })

        } else {
            toast.setTitle("Error").setDescription("Please select a valid file").setStatus('warning').show();
        }

    }

    return (
        <ChakraModal primaryAction={onSumbitAttachment} primaryButtonText={"Upload"} modalTitle={`Select a file`} {...disclosure}>
            <SimpleGrid className={'rounded p-3'} columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                <input type="file" accept=".xlsx" placeholder="Document Name" onChange={handleFileChange} />
            </SimpleGrid>
        </ChakraModal>
    )
}

export default UploadAttendanceModal