import { useDisclosure } from "@chakra-ui/react"
import _ from "lodash"
import { useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { MODULE_ENROLLMENT_UPLOAD_LOG, PROGRAM_SUBTYPE } from "../../../consts/methods.consts"
import { PROGRAM_SUBTYPE_TAGS, DROPDOWN_TAGS } from "../../../consts/tags.consts"
import { useGetQuery } from "../../../services/api.service"
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable"
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader"
import { useTableDataProvider } from "../../hooks"

const ViewEnrolmentUploadLogs = () => {
    const disclosure = useDisclosure()
    const [page, setPage] = useState(1)
    const navigate = useNavigate()
    const { id } = useParams()

    const { data, isLoading, isFetching } = useGetQuery({
        method: MODULE_ENROLLMENT_UPLOAD_LOG.GET_MODULE_ENROLMENT_LOGS,
        body: {
            id
        },
        providesTags: []
    })

    const logs = _.get(data, 'message', [])

    const PROGRAM_SUBTYPE_COLUMNS: ChakraTableColumns = [
        {
            header: "Row Number",
            accessor:
                "row_number",
            width: 50
        },
        {
            header: "NIC",
            accessor:
                "nic",
            width: 150
        },
        {
            header: "Status",
            accessor:
                "status",
            width: 150
        },
        {
            header: "Message",
            accessor:
                "message",
        }
    ]

    const actions: PageHeaderActions = [


    ]

    const onPage = (page: any) => {
        setPage(page)
    }
    return (
        <>
            <div>
                <PageHeader enableBackButton subtitle="View Upload Logs" title={`Upload Logs`} actions={actions} />
                <ChakraTable searchable={false} onPage={onPage} isFetching={isFetching} isLoading={isLoading} size={"sm"} header
                    title="Logs"
                    columns={PROGRAM_SUBTYPE_COLUMNS} data={logs} />
            </div>
        </>
    )
}

export default ViewEnrolmentUploadLogs
