import * as Yup from 'yup';
import { printAction } from "../../../actions";
import { PRINT } from "../../../consts/methods.consts";

const StudentEnrollmentConfig = {
    searchApi: "xceed.api.report.module_enrollment.generate_student_enrolment_report",
    alert:
        "Reminder: Specify a date range to receive reports. Use other fields to further narrow down data within the required date range.",
    excelFileName: "resource-person-report",
    title: "Student Enrolment Report",
    extraActionsAsMenu: true,
    sections: [
        {
            selectionTitle: "Date Range",
            grid: { base: 1, md: 2, lg: 4, xl: 4 },
            filters: [
                {
                    is_required: true,
                    label: "From",
                    // onChange: (formik: any) => {
                    //     formik.setFieldValue('pa', '')
                    // },
                    type: "date",
                    key: "from",
                    validationSchema: Yup.string().required('From date is required')
                },
                {
                    is_required: true,
                    label: "To",
                    // onChange: (formik: any) => {
                    //     formik.setFieldValue('pa', '')
                    // },
                    type: "date",
                    key: "to",
                    validationSchema: Yup.string().required('To date is required')
                },
            ],
        },
        {
            selectionTitle: "Filters",
            grid: { base: 1, md: 2, lg: 4, xl: 4 },
            filters: [
                {
                    is_required: true,
                    label: "Program Assistant",
                    key: "pa",
                    type: "form-select",
                    // onChange: (formik: any) => {
                    //     formik.setFieldValue('program', '')
                    // },
                    methodBody: { "from": "from", "to": "to" },
                    optionMethod: "xceed.api.report.module_enrollment.get_pa_list",
                    validationSchema: Yup.string().required('Program Assistance is required')
                },
                {
                    is_required: true,
                    label: "Program",
                    key: "program",
                    // onChange: (formik: any) => { formik.setFieldValue('module', '') },
                    type: "form-select",
                    methodBody: { "from": "from", "to": "to", "pa": "pa" },
                    optionMethod: "xceed.api.report.module_enrollment.get_program_list",
                    validationSchema: Yup.string().required('Program is required')
                },
                {
                    label: "Module",
                    key: "module",
                    type: "form-select",
                    methodBody: { "program": "program" },
                    optionMethod: "xceed.api.report.module_enrollment.get_module_list",
                },
            ],
        },
    ],
    tableConfig: {
        column: [
            {
                header: "Start Date",
                accessor: "start_date",
                width: 300,
            },
            {
                header: "End Date",
                accessor: "end_date",
                width: 300,
            },
            {
                header: "Student Enrolled",
                accessor: "student_enrolled",
                width: 200,
            },
            {
                header: "No of Student enrolled",
                accessor: "no_of_student_enrolled",
                width: 200,
            }
        ],
    },
    actions: [
        {
            text: "Generate Bulk Report",
            onClick: async (filter: any, data: any, setLoading: any) => {
                if (filter.year && filter.month) {
                    setLoading(true);
                    try {
                        await printAction(PRINT.PRINT_CONSULTANT_MODULE_ALLOCATION_BULK_REPORT, { filter }, setLoading);
                    } catch (error: any) {
                        setLoading(false);
                        const newError: any = new Error("Unable to print report");
                        newError.title = "Error";
                        newError.status = "error";
                        throw newError;
                    }
                } else {
                    const error: any = new Error(
                        "Please select year and month "
                    );
                    error.title = "Warning";
                    error.status = "warning";
                    throw error;
                }
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal",
        },
        {
            text: "Generate Report",
            onClick: async (filter: any, data: any, setLoading: any) => {
                if (filter.year && filter.month && filter.mc) {
                    setLoading(true);
                    try {
                        await printAction(PRINT.PRINT_CONSULTANT_MODULE_ALLOCATION_REPORT, { filter }, setLoading);
                    } catch (error: any) {
                        setLoading(false);
                        const newError: any = new Error("Unable to print report");
                        newError.title = "Error";
                        newError.status = "error";
                        throw newError;
                    }
                } else {
                    const error: any = new Error(
                        "Please select from date, to date and module coordinator "
                    );
                    error.title = "Warning";
                    error.status = "warning";
                    throw error;
                }
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal",
        },
    ],
};

export default StudentEnrollmentConfig;

const statusBadgeConfig = {
    No: "bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded",
    Yes: "bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded",
};
