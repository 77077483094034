import PageHeader from "../../common/page-header/PageHeader";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { useSelector } from "react-redux";
import _ from "lodash";
import reportPermission from "./ReportPermission";
import reportTableRows from "./ReportTableRows";

const PROGRAM_LIST_COLUMNS: ChakraTableColumns = [
    {
        header: "Report Name",
        accessor: "report_name",
        width: 200,
    }
]

const ReportHomePage = () => {
    const navigate: NavigateFunction = useNavigate()
    const roles = useSelector((state: any) => _.get(state, 'common.user.roles', []))

    const tableLineClickHandler = (line: any) => {
        navigate(`/app/report/${line.report}`)
    }

    const data = reportTableRows.filter((row:any) => {
        const allowedRoles = reportPermission[row.report];
        return allowedRoles?.some((role:any) => roles.includes(role));
    });

    return (

        <div>
            <PageHeader enableBackButton subtitle="View and Manage Reports" title={`Report`} />
            <ChakraTable size={"sm"}
                title="Reports"
                onSelected={tableLineClickHandler} columns={PROGRAM_LIST_COLUMNS} data={data} />
        </div>

    )
}

export default ReportHomePage