import axios from "axios";
import _, { get } from "lodash";
import React from "react";
import { BASE_URL } from "../../../consts/app.consts";
import { BadgeCell, DefaultCell, ResourcePersonCell } from "./components/cells";
import * as Yup from 'yup';
import { printAction } from "../../../actions";
import { PRINT } from "../../../consts/methods.consts";
import { months } from "moment";

const ModuleAllocationPAConfig = {
    searchApi: "xceed.api.report.programmed_module.generate_program_assistant_module_allocation_report",
    alert:
        "Reminder: Specify a date range to receive reports. Use other fields to further narrow down data within the required date range.",
    excelFileName: "resource-person-report",
    title: "Module Allocation for Program Assistant Report",
    extraActionsAsMenu: true,
    sections: [
        {
            selectionTitle: "Date Range",
            grid: { base: 1, md: 2, lg: 4, xl: 4 },
            filters: [
                {
                    is_required: true,
                    label: "Select Year",
                    type: "drop-down",
                    key: "year",
                    options: Array.from({ length: 11 }, (_, i) => new Date().getFullYear() - i).map((year: any) => ({ "label": year, "value": year })),
                    validationSchema: Yup.string().required('Year is required')
                },
                {
                    is_required: true,
                    label: "Select Month",
                    type: "drop-down",
                    key: "month",
                    options: Array.from({ length: 12 }, (_, i) => ({
                        label: new Date(0, i).toLocaleString("default", { month: "long" }),
                        value: String(i + 1).padStart(2, "0"),
                    })),
                    validationSchema: Yup.string().required('Month is required')
                },
            ],
        },
        {
            selectionTitle: "Filters",
            grid: { base: 1, md: 2, lg: 4, xl: 4 },
            filters: [
                {
                    is_required: true,
                    label: "Program Assistant",
                    key: "pa",
                    type: "form-select",
                    methodBody:{"month":"month","year":"year"},
                    optionMethod: "xceed.api.report.programmed_module.get_pa_list_for_pa_module_allocation_report",
                    validationSchema: Yup.string().required('Module coordinator is required')
                },
            ],
        },
    ],
    tableConfig: {
        column: [
            {
                header: "Module plan Name",
                accessor: "module_title",
                width: 300,
            },
            {
                header: "Duration",
                accessor: "duration",
                width: 200,
            },
            {
                header: "Specific Time Frame",
                accessor: "days",
                width: 200,
            }
        ],
    },
    actions: [
        {
            text: "Generate Bulk Report",
            onClick: async (filter: any, data: any, setLoading: any) => {
                if (filter.year && filter.month) {
                    setLoading(true);
                    try {
                        await printAction(PRINT.PRINT_PROGRAM_ASSISTANCE_MODULE_ALLOCATION_BULK_REPORT, { filter }, setLoading);
                    } catch (error: any) {
                        setLoading(false);
                        const newError: any = new Error("Unable to print report");
                        newError.title = "Error";
                        newError.status = "error";
                        throw newError;
                    }
                } else {
                    const error: any = new Error(
                        "Please select year and month "
                    );
                    error.title = "Warning";
                    error.status = "warning";
                    throw error;
                }
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal",
        },
        {
            text: "Generate Report",
            onClick: async (filter: any, data: any, setLoading: any) => {
                if (filter.year && filter.month && filter.pa) {
                    setLoading(true);
                    try {
                        await printAction(PRINT.PRINT_PROGRAM_ASSISTANCE_MODULE_ALLOCATION_REPORT, { filter }, setLoading);
                    } catch (error: any) {
                        setLoading(false);
                        const newError: any = new Error("Unable to print report");
                        newError.title = "Error";
                        newError.status = "error";
                        throw newError;
                    }
                } else {
                    const error: any = new Error(
                        "Please select year, month and program assistant"
                    );
                    error.title = "Warning";
                    error.status = "warning";
                    throw error;
                }
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal",
        },
    ],
};

export default ModuleAllocationPAConfig;

const statusBadgeConfig = {
    No: "bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded",
    Yes: "bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded",
};
