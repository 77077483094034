import Search from "./components/Search"
import {FiHelpCircle, FiMenu, FiSettings, FiUser, FiX} from "react-icons/fi"
import {
    Avatar,
    Box,
    ButtonGroup,
    Flex,
    HStack,
    IconButton,
    MenuButton,
    MenuItem,
    MenuList,
    useToast
} from "@chakra-ui/react"
import {NavigateFunction, useNavigate} from "react-router-dom"
import {useLogoutMutation} from "../../../services/api.service"
import {ToastService} from "../../../services/toast.service"
import { FaRunning } from "react-icons/fa"
import Menu from "./components/Menu"

interface INavbarProps {
    isSideBarOpen: boolean,
    closeSideBar: () => void,
    openSideBar: () => void
}

const Navbar = ({isSideBarOpen, closeSideBar, openSideBar}: INavbarProps) => {
    const navigate: NavigateFunction = useNavigate();
    const [logout, {}] = useLogoutMutation();
    const toast = new ToastService(useToast());

    const logoutHandler = async () => {
        try {
            await logout({}).unwrap();

            navigate("/", {replace: true})
            window.location.reload()

        } catch (error) {
            console.error(error);
            toast.setTitle("Error").setDescription("Unable to logout. Something went wrong").showErrorToast();
        }
    }


    return (
        <Box left={isSideBarOpen ? 64 : 0}
             className="border-b transition-all ease-in-out duration-200 bg-gray-50 fixed right-0 z-50" as="section"
             p={2} pr={{base: 'auto', sm: '4'}}>
            <Flex className="flex items-center w-full justify-between">
                <HStack spacing={0} alignItems={"center"}>
                    <Box className="md:hidden">
                        <IconButton
                            onClick={isSideBarOpen ? closeSideBar : openSideBar}
                            variant="ghost"
                            icon={isSideBarOpen ? <FiX fontSize="1.25rem"/> : <FiMenu fontSize="1.25rem"/>}
                            aria-label="Open Menu"
                        />
                    </Box>
                    <Search/>
                </HStack>
                <HStack spacing="4">
                    <Menu/>
                </HStack>
            </Flex>
        </Box>
    )
}

export default Navbar;