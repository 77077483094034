import _ from "lodash";
import { FormControl, FormErrorMessage, FormLabel, Textarea, TextareaProps } from "@chakra-ui/react";

type MutatedTypes = Omit<TextareaProps, 'onChange' | 'name' | 'isRequired' | 'isInvalid' | 'defaultValue'>

export interface FormTextAreaProps extends MutatedTypes {
    containerClassNames?: string,
    formik: any,
    name: string,
    label?: string,
    isRequired?: boolean,
    customMessage?: any,
}

export const FormTextArea = ({ rounded = "md", size = "sm", customMessage, formik, isRequired = false, name, label, containerClassNames = '', ...restProps }: FormTextAreaProps) => {

    return (
        <FormControl isRequired={isRequired} className={containerClassNames} isInvalid={Boolean(formik.submitCount && formik.errors[name])}>
            {label && <FormLabel className={'!opacity-75 !text-[13px]'}>{label}</FormLabel>}
            <Textarea borderColor={'gray.300'} size={size} {...restProps} name={name} value={_.isEmpty(formik.values) ? '' : formik.values[name]} onChange={formik.handleChange} rounded={rounded} />
            {(formik.submitCount && formik.errors[name]) ? <FormErrorMessage mt={0}>{formik.errors[name]}</FormErrorMessage> : customMessage && customMessage}
        </FormControl>
    )
}