import { Badge, SimpleGrid, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { AMENDMENTS } from "../../../consts/methods.consts";
import { AMENDMENT_TAG, PROGRAMMED_MODULE_TAGS, SESSION_TAGS } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { customeJsonParser } from "../../../utils/utils";
import ChakraTable from "../../common/chakra-table/ChakraTable";
import CommentSelection from "../../common/comment-section/CommentSection";
import { FormInput } from "../../common/form-controllers/FormInput";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";
import NewComment from "../../common/new-comment/NewComment";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { SectionCard } from "../../common/section-card/SectionCard";
import { useObjChange } from "../../hooks";


const ViewAmendmentPage = () => {
    const { id } = useParams()
    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());
    const [create, { isLoading: creating }] = usePostMutation();

    const { data: _data, isLoading } = useGetQuery({
        method: AMENDMENTS.GET,
        body: { "_id": id },
        providesTags: [AMENDMENT_TAG.VIEW]
    })

    const data = _.get(_data, ['message'], {})
    const requested_fields = _.get(data, ['amendment_field'], [])
    const workflowActions = _.get(data, 'workflow_actions', []).map((item: any, index: any) => (
        {
            text: item?.action,
            onClick: () => { onClickAction(item?.action) },
            buttonVariant: "solid",
            buttonColorScheme: ["rej", "not"].some(keyword => item?.action?.toLowerCase().includes(keyword)) ? "red" : "teal"
        }
    ))

    const stringifyData = JSON.stringify(data)

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
        }),
        onSubmit: async (values: any) => {
        }
    })


    const { isChanged, setInitialObj } = useObjChange(formik.values)

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
        }
    }, [stringifyData])


    const onClickAction = async (action: any) => {
        try {
            await create({
                method: AMENDMENTS.EXECUTE_WORKFLOW_ACTION,
                body: { id, action },
                invalidatesTags: [PROGRAMMED_MODULE_TAGS.LIST, PROGRAMMED_MODULE_TAGS.VIEW, AMENDMENT_TAG.LIST, AMENDMENT_TAG.VIEW]
            }).unwrap();
            toast.setTitle("Success").setDescription("Your action has been completed.").showSuccessToast();

            setTimeout(() => {
                navigate(`/app/amendment-request`);
            }, 1500)

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        }
    }

    const actions: PageHeaderActions = [
        ...workflowActions
    ]

    const statusRenderer = (
        <>
            <Badge className="mt-[6px]" variant='solid' colorScheme={_.get(colourByStatus, _.get(data, 'approved_status'))}>
                {_.get(statusByAmendmentStatus,_.get(data, 'approved_status'))}
            </Badge>
        </>
    )

    return (
        <div>
            <PageHeader statusRenderer={statusRenderer} backButtonCofirmation={isChanged} isLoading={creating} enableBackButton actions={actions} title={_.get(data, 'name')}
                subtitle={'Amendment Request'} />
            <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 3 }} spacing={3}>
                    <FormInput isDisabled isRequired label="Amendment Document Type" formik={formik} name="type" />
                    <FormInput linkTo={_.get(formik.values, 'navigate_to')} isDisabled isRequired label="Document" formik={formik} name="document_title" />
                    <FormTextArea isDisabled isRequired label="Remarks" formik={formik} name="comment" />
                </SimpleGrid>
            </SectionCard>
            <SectionCard backgroundColor=" bg-white" title="Requested Field" p={3}>
                <ChakraTable isFetching={false} isLoading={isLoading} size={"sm"}
                    columns={[{
                        header: "Field",
                        accessor:
                            "Field_name",
                    }]} data={requested_fields} />
            </SectionCard>
            <SectionCard backgroundColor=" bg-white" title="Add a comment" p={3}>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                    <NewComment doctype={'Amendment Request'} id={id} tag={AMENDMENT_TAG.COMMENTS} />
                </SimpleGrid>
            </SectionCard>
            <CommentSelection doctype={'Amendment Request'} doc={id} tag={AMENDMENT_TAG.COMMENTS} />
        </div>
    )
}

export default ViewAmendmentPage

const colourByStatus = {
    0: "green",
    1: "green",
    "-1": "red",
    2: "green",
    "-2": "red",
    3: "green",
    "-3": "red",
    "-4":"green",
    5:"gray"

}

const statusByAmendmentStatus = {
    0: "Revision Request Pending – Registrar",
    1: "Revision Request Pending – MC",
    "-1": "Revision Request Not Recommended – Registrar",
    2: "Revision Request Pending – ADG",
    "-2": "Revision Request Not Recommended – MC",
    3: "Revision Request Approved– ADG",
    "-3": "Revision Request Rejected– ADG",
    "-4": "Revised",
    "5": "No Revision"
}