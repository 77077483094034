import * as Yup from "yup";
import { useFormik } from "formik";
import { SimpleGrid } from "@chakra-ui/react";
import { FormCheckBox } from "../../../../common/form-controllers/FormCheckBox";
import { SectionCard } from "../../../../common/section-card/SectionCard";

const KeyFunctionAreas = ({ keyFunctionRef }: any) => {
    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({

        }),
        onSubmit: async (values: any) => {
            return values
        }
    })
    keyFunctionRef.current = formik

    return (
        <SectionCard backgroundColor=" bg-white" title="Key functional areas of work experience" p={3}>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                <FormCheckBox label="Lecturing" formik={formik} name="lecturing" />
                <FormCheckBox label="Research" formik={formik} name="research" />
                <FormCheckBox label="Consulting" formik={formik} name="consulting" />
                <FormCheckBox label="Training" formik={formik} name="training" />
            </SimpleGrid>
        </SectionCard>
    )
}
export default KeyFunctionAreas