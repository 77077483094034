import _ from "lodash"
import React from "react"
import { DefaultCell } from "./components/cells"
import { BASE_URL } from "../../../consts/app.consts"
import axios from "axios"

const AssesmentConfig = {
    searchApi: 'xceed.api.report.assesment.generate_assesment_report',
    excelFileName: 'attendance-report',
    title: 'Assesment Report',
    extraActionsAsMenu: true,
    sections: [{
        selectionTitle: "Filters",
        grid: { base: 1, md: 2, lg: 4, xl: 4 },
        filters: [
            // {
            //     label: 'Student',
            //     key: 'student',
            //     type: 'form-select',
            //     optionMethod: 'xceed.api.report.assesment.get_students',
            // },
            {
                label: 'Program',
                key: 'program',
                type: 'form-select',
                optionMethod: 'xceed.api.report.assesment.get_programs_by_student',
                methodBody: {
                    "student": "student"
                }
            },
            {
                label: 'Program Module',
                key: 'program_module',
                type: 'form-select',
                optionMethod: 'xceed.api.report.assesment.get_program_modules_by_student_and_program',
                methodBody: {
                    "student": "student",
                    "program": "program"
                }
            },
        ]
    }],
    // extraActionsAsMenu: true,

    tableConfig: {
        column: [
            {
                header: "Student Name",
                accessor: "student_name",
                customRenderer: (value: any, item: any) => (
                    React.createElement(DefaultCell, { navigateTo: `/app/student/${_.get(item, 'student_id')}`, value: value })
                ),
                width: 200
            },
            {
                header: "NIC",
                accessor: "nic",
                width: 200
            },
            {
                header: "Program",
                accessor: "program_title",
                // customRenderer: (value: any, item: any) => (
                //     React.createElement(DefaultCell, { navigateTo: `/app/program/${_.get(item, 'program_id')}`, value: value })
                // ),
                width: 200
            },
            {
                header: "Program Module",
                accessor: "title",
                // customRenderer: (value: any, item: any) => (
                //     React.createElement(DefaultCell, { navigateTo: `/app/program-module/${_.get(item, 'program_module_id')}`, value: value })
                // ),
                width: 200
            },
            {
                header: "Marks",
                accessor: "mark",
                width: 200
            }
        ]
    },
    actions: [

        // {
        //     text: "Export to Excel",
        //     onClick: async (filter: any, data: any, setLoading: any) => {
        //         if (filter.to && filter.from) {
        //             setLoading(true)
        //             try {
        //                 let response = await axios.post(`${BASE_URL}/method/xceed.api.export_excel.exam_registration.export_exam_registration_report`, { filter }, {
        //                     withCredentials: true,
        //                     responseType: "blob"
        //                 })
        //                 if (response) {
        //                     console.log(response)
        //                     const href = URL.createObjectURL(response.data);

        //                     const link = document.createElement('a');
        //                     link.href = href;
        //                     link.setAttribute('download', `Exam-medium-count-export.xlsx`);
        //                     document.body.appendChild(link);
        //                     link.click();
        //                     document.body.removeChild(link);
        //                     URL.revokeObjectURL(href);
        //                 }
        //                 setLoading(false)

        //             } catch (error: any) {
        //                 setLoading(false)
        //                 const newError: any = new Error('Unable to Generate Excel file');
        //                 newError.title = 'Error';
        //                 newError.status = 'error';
        //                 throw newError
        //             }
        //         }
        //         else {
        //             const error: any = new Error('Please select from date and to date');
        //             error.title = 'Warning';
        //             error.status = 'warning';
        //             throw error
        //         }
        //     },
        //     buttonVariant: "solid",
        //     buttonColorScheme: "teal"
        // },
        {
            text: "Generate Course Marksheet",
            onClick: async (filter: any, data: any, setLoading: any) => {
                if (filter.program) {
                    setLoading(true)
                    try {
                        let response = await axios.post(`${BASE_URL}/method/xceed.api.export_excel.assesment.export_course_mark_sheet`, { filter }, {
                            withCredentials: true,
                            responseType: "blob"
                        })
                        if (response) {
                            console.log(response)
                            const href = URL.createObjectURL(response.data);

                            const link = document.createElement('a');
                            link.href = href;
                            link.setAttribute('download', `course-mark-sheet.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);
                        }
                        setLoading(false)

                    } catch (error: any) {
                        setLoading(false)
                        const newError: any = new Error('Unable to Generate Excel file');
                        newError.title = 'Error';
                        newError.status = 'error';
                        throw newError
                    }
                }
                else {
                    const error: any = new Error('Please select a program');
                    error.title = 'Warning';
                    error.status = 'warning';
                    throw error
                }
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Generate Module Wise Mark Sheet",
            onClick: async (filter: any, data: any, setLoading: any) => {
                if (filter.program) {
                    setLoading(true)
                    try {
                        let response = await axios.post(`${BASE_URL}/method/xceed.api.export_excel.assesment.export_module_wise_mark_sheet`, { filter }, {
                            withCredentials: true,
                            responseType: "blob"
                        })
                        if (response) {
                            console.log(response)
                            const href = URL.createObjectURL(response.data);

                            const link = document.createElement('a');
                            link.href = href;
                            link.setAttribute('download', `module-wise-mark-sheet.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);
                        }
                        setLoading(false)

                    } catch (error: any) {
                        setLoading(false)
                        const newError: any = new Error('Unable to Generate Excel file');
                        newError.title = 'Error';
                        newError.status = 'error';
                        throw newError
                    }
                }
                else {
                    const error: any = new Error('Please select a program');
                    error.title = 'Warning';
                    error.status = 'warning';
                    throw error
                }
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
    ]
}

export default AssesmentConfig