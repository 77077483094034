import * as Yup from "yup";
import { useState } from 'react';
import { FormInput } from '../../../common/form-controllers/FormInput';
import { useFormik } from 'formik';
import { ToastService } from '../../../../services/toast.service';
import { usePostMutation } from '../../../../services/api.service';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import { Box, Button, FormLabel, HStack, Icon, Stack, StackProps, Text, useToast } from '@chakra-ui/react';
import { AUTH } from "../../../../consts/methods.consts";
import { customeJsonParser } from "../../../../utils/utils";
import _ from "lodash";

export const ChangePasswordForm = (props: StackProps) => {
    const navigator: NavigateFunction = useNavigate()
    const toast = new ToastService(useToast())
    const { key } = useParams()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [create] = usePostMutation();

    const formik = useFormik({
        initialValues: {
            pwd: "",
            confirm_pwd: "",
        },
        validationSchema: Yup.object({
            pwd: Yup.string().required("Email is required"),
            confirm_pwd: Yup.string().required("Password is required")
                .oneOf([Yup.ref("pwd"), ""], "Passwords must match")
        }),
        onSubmit: async (values: any) => {
            try {
                setIsLoading(true);
                await create({
                    method: AUTH.CHANGE_PASSWORD,
                    body: {
                        new_pwd: values.confirm_pwd,
                        key: key
                    },
                    invalidatesTags: []
                }).unwrap()
                navigator("/")
                toast.setTitle("Success").setDescription("Your password has been successfully changed.").showSuccessToast();
            } catch (error:any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            } finally {
                setIsLoading(false)
            }
        }
    })

    return (
        <div className="flex flex-col h-full">
            <Box px={{ base: '30px', md: '0' }}
                py={{ base: '40px', md: '0' }} className={' flex items-center justify-center flex-1'}>

                <div className=' space-y-4 lg:space-y-4 lg:mb-[20px]'>
                    {/* <Text className={'font-[Poppins] text-opacity-60 text-gray-400 text-center lg:text-left font-normal text-xs lg:text-base leading-none'}>
                    Welcome back
                </Text> */}
                    <Text className={'font-[Poppins] text-center lg:text-left font-semibold text-xl lg:text-3xl leading-none'}>
                        Reset your password
                    </Text>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack spacing="6">
                            <Stack spacing="4">
                                <Box>
                                    <FormLabel fontFamily={'Poppins'} className={'!font-[Poppins] !text-sm lg:!text-lg !font-medium text-[#5A5A5D]'}>Password</FormLabel>
                                    <FormInput fontSize={{ base: '12px', md: '16px' }} fontFamily={'Poppins'} _placeholder={{ base: { color: '#BEBEBF', fontStyle: 'normal' }, md: { color: '#BEBEBF', fontFamily: 'Poppins', fontStyle: 'normal' } }} height={'48px'} placeholder="Password" type={'password'} borderWidth={'0.746px'} focusBorderColor="#7CA7FA38" isRequired formik={formik} name='pwd' borderColor={"#BEBEBF"} borderRadius={'5.969px'} />
                                </Box>
                                <Box>
                                    <FormLabel fontFamily={'Poppins'} className={'!font-[Poppins] !text-sm lg:!text-lg !font-medium text-[#5A5A5D]'}>Confirm Password</FormLabel>
                                    <FormInput fontSize={{ base: '12px', md: '16px' }} fontFamily={'Poppins'} _placeholder={{ base: { color: '#BEBEBF', fontStyle: 'normal' }, md: { color: '#BEBEBF', fontFamily: 'Poppins', fontStyle: 'normal' } }} height={'48px'} placeholder="Confirm Password" type={'password'} borderWidth={'0.746px'} focusBorderColor="#7CA7FA38" isRequired formik={formik} name='confirm_pwd' borderColor={"#BEBEBF"} borderRadius={'5.969px'} />
                                </Box>

                            </Stack>
                            <HStack justify="space-between">
                                {/* <Button onClick={isResetDisclosure.onOpen} variant="link" colorScheme="blue" size="sm">
                                Forgot password
                            </Button> */}
                            </HStack>
                            <Stack spacing="4">
                                <Button isLoading={isLoading} type={'submit'}
                                    className="!rounded-lg !font-[Poppins] !bg-[#09B562] !text-white hover:!bg-green-500 !transition-all !h-[45px] lg:!h-[52px] !text-base lg:!text-lg !font-semibold !leading-none">Change Password</Button>
                            </Stack>
                        </Stack>
                    </form>
                </div>
            </Box>
        </div>
    )
}
