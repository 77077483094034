import { useDisclosure, useToast } from "@chakra-ui/react";
import _ from "lodash";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { MODULE_ENROLLMENT_UPLOAD_LOG, PROGRAM_SUBTYPE } from "../../../consts/methods.consts";
import { DROPDOWN_TAGS, MODULE_ENROLMENT_LOGS, PROGRAM_SUBTYPE_TAGS } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { useTableDataProvider } from "../../hooks";

const ModuleEnrollmentUploadLogListPage = () => {
    const disclosure = useDisclosure()
    const [page, setPage] = useState(1)
    const navigate = useNavigate()

    const { data, isLoading, isFetching } = useGetQuery({
        method: MODULE_ENROLLMENT_UPLOAD_LOG.GET_LIST,
        body: {
            page: page,
        },
        providesTags: [MODULE_ENROLMENT_LOGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const PROGRAM_SUBTYPE_COLUMNS: ChakraTableColumns = [
        {
            header: "File Name",
            accessor:
                "file_name",
        },
        {
            header: "Date",
            accessor:
                "creation",
        },
        {
            header: "Upload By",
            accessor:
                "owner",
        }
    ]

    const actions: PageHeaderActions = [


    ]

    const onPage = (page: any) => {
        setPage(page)
    }

    const tableLineClickHandler = (line: any) => {
        navigate(`${_.get(line, 'name')}`)
    }

    return (
        <>
            <div>
                <PageHeader subtitle="View Module Enrolment Uploads" title={`Module Enrolment Uploads`} actions={actions} />
                <ChakraTable  onSelected={tableLineClickHandler} searchable={false} onPage={onPage} isFetching={isFetching} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} header
                    title="Uploads"
                    columns={PROGRAM_SUBTYPE_COLUMNS} data={dataList} />
            </div>
        </>
    )

}

export default ModuleEnrollmentUploadLogListPage