import { Box, Button, HStack, IconButton, Input, Spinner, Stack, Table, Tbody, Td, Text, Th, Thead, ThemeTypings, ThemingProps, Tr, VStack } from "@chakra-ui/react";
import _ from "lodash";
import search from "multi-search";
import { useEffect, useState } from 'react';
import TableTr from "./components/TableTr";
import { FaPlus } from "react-icons/fa";
import { FiArrowDown, FiArrowLeft, FiArrowRight, FiMenu } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";
import TablePagination from "./components/TablePagination";
import LengthPaginations from "./components/LengthPaginations";
import ColumnSort from "./components/ColumnSort";

const EMPTY_FUNC = () => { }

export interface IColumn {
    header: string,
    width?: number,
    accessor: string | '',
    conditions?: ChakraTableColumnCondition,
    className?: string,
    customRenderer?: (value: string | number | null, item: object) => JSX.Element,
    disabled?: boolean,
    options?: Array<ChakraTableColumnOption>
    type?: 'link'
}

export type ChakraTableColumnOption = {
    label: string,
    confirmation?: boolean,
    onClick: (item: object) => void,
    confirmationMessage?: { title: string, subtitle: string }
}

export interface ChakraTableColumnCondition {
    [key: string | number]: ThemeTypings["colorSchemes"]
}

export type ChakraTableCreatable = {
    text?: string | undefined,
    onClick: any,
    isLoading?: boolean,
    buttonColorScheme?: ThemeTypings["colorSchemes"]
}

export type ChakraTableColumns = Array<IColumn>

export interface IChakraTableProps {
    title?: string,
    paginationDetails?: {},
    columns: ChakraTableColumns,
    onSearch?: Function,
    data: Array<object>,
    onSelected?: (line: object) => void,
    disableRowSelection?: boolean,
    outerPadding?: boolean,
    header?: boolean,
    searchable?: boolean,
    creatable?: ChakraTableCreatable,
    customFilterRenderer?: JSX.Element,
    size?: ThemingProps<"Table">["size"],
    height?: number,
    lengthPaginations?: boolean,
    maxH?: number,
    onLength?: (length: number) => void,
    pagination?: boolean,
    pageCount?: number,
    totalResult?: number,
    isLoading?: boolean,
    totalResults?: number,
    isFetching?: boolean,
    onPage?: (page: number) => void
}

const SHIMMER_DATA = [1, 1, 1, 1, 1];

const ChakraTable = ({
    isFetching,
    paginationDetails,
    totalResult = 0,
    isLoading,
    size = 'md',
    height,
    maxH,
    onSearch,
    lengthPaginations,
    title,
    creatable,
    customFilterRenderer,
    searchable = true,
    header = false,
    columns,
    data,
    onSelected = EMPTY_FUNC,
    disableRowSelection,
    pagination,
    pageCount = 1,
    onLength,
    onPage,
    outerPadding = false
}: IChakraTableProps) => {

    const [tableData, setTableData] = useState([])

    useEffect(() => {
        setTableData(data as any)
    }, [JSON.stringify(data)])

    function selectedRow(row: any) {
        onSelected(row)
    }

    const searchHandler = (e: any) => {
        if (onSearch) { onSearch(e.target.value) }
        else {
            let filtered = search(data, e.target.value)
            setTableData(filtered as any)
        }
    }

    const shimmerMarkup = SHIMMER_DATA.map((col: any, index: any) => <TrShimmer key={index} columns={columns} />)

    const rowMarkup = (tableData || []).map((item: any, index: any) => (
        <TableTr disablePointer={disableRowSelection} onSelected={(selectedItem: any) => {
            selectedRow(selectedItem)
        }} key={index} index={index} columns={columns} item={item} />
    ))

    return (
        <>
            <Stack border={'1px'} spacing={0} borderColor={"gray.200"} bg={"white"} borderRadius={"md"} mx={outerPadding ? 3 : 0}>
                {
                    // Table Header
                    header ? (
                        <HStack mx={0} p={3} pb={5} pt={5} justifyContent={"space-between"} bg={'gray.50'} rounded={'md'}>
                            {/* Table header title */}
                            {title ? <Text fontSize={size == 'sm' ? 'lg' : 'xl'} fontWeight={"semibold"}>{title}</Text> : null}
                            <HStack>
                                {/* Custom filter elements */}
                                {customFilterRenderer ? customFilterRenderer : null}
                                {searchable ?
                                    <Input opacity={isLoading ? 0 : 1} className="transition-opacity !h-[35px] duration-100"
                                        size={size} onChange={searchHandler} shadow={"sm"} borderColor={'gray.300'} width={250}
                                        placeholder={'Search'} borderRadius='md' type='text' /> : null}

                                {/* Creatable */}
                                {!_.isEmpty(creatable) ? (
                                    <Button size={size} aria-label="Add new" isDisabled={creatable?.isLoading} onClick={creatable?.onClick || EMPTY_FUNC} colorScheme={creatable.buttonColorScheme || 'blue'} leftIcon={<FaPlus />}>
                                        <HStack alignItems={"center"}>
                                            {creatable?.text ? <Text>{creatable.text}</Text> : null}
                                        </HStack>
                                    </Button>
                                ) : null}
                            </HStack>
                        </HStack>
                    ) : null
                }

                {header ? <hr></hr> : null}

                <Box overflowY={"auto"} maxH={maxH ? maxH : undefined} h={height ? height : undefined}>
                    <Table id={'chakra-table'} size={size} className="rounded-md" style={{ marginTop: header ? '0px' : '0px !important' }} variant='simple'>
                        <Thead>
                            <Tr>
                                {columns?.map((item: any, index: any) => {
                                    if (!item?.disabled) {
                                        return (
                                            <Th
                                                style={{ width: item?.width ? item.width : 'unset' }}
                                                textAlign={item?.header == "Actions" ? "center" : "left"} py={2}
                                                key={index}>
                                                <div className={`flex items-start ${_.get(item, 'headerOptions.className')}`}>
                                                    {item.header}
                                                    {!item.disableSorting && <ColumnSort item={item} setTableData={setTableData} tableData={tableData} />}
                                                </div>
                                            </Th>
                                        )
                                    }
                                })}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {isFetching && shimmerMarkup}
                            {!isFetching && rowMarkup}
                        </Tbody>
                    </Table>
                </Box>
                {(!isFetching && _.isEmpty(tableData)) ? <Text className="animation-no-data" pb={3} w={'full'} fontWeight={'semibold'} textAlign={'center'}>No Data</Text> : null}
                <div className="flex">
                    {lengthPaginations &&
                        <LengthPaginations onLength={onLength} isLoading={isLoading || isFetching} totalResult={_.get(paginationDetails, 'totalResults', 0)} />}
                    {pagination && <TablePagination onPage={onPage} paginationDetails={paginationDetails} isLoading={isFetching} />}
                </div>
                {/* {pagination && <TablePagination paginationDetails={paginationDetails} isLoading={isFetching} />} */}
            </Stack>
        </>
    )
}

const TrShimmer = ({ columns = [] }: any) => {
    return (
        <Tr>
            {columns.map((col: any, index: any) => <Td key={index}><div className="animate-pulse w-[75px] h-[15px] bg-gray-100 rounded-md border"></div></Td>)}
        </Tr>
    )
}

export default ChakraTable