import { SimpleGrid } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { EXAM, SUBJECT } from "../../../consts/methods.consts";
import { DROPDOWN_TAGS, EXAM_ENROLLMENT_TAGS, SUBJECT_TAGS } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { FormInput } from "../../common/form-controllers/FormInput";
import InnerLoading from "../../common/inner-loading/InnterLoading";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { SectionCard } from "../../common/section-card/SectionCard";
import { useObjChange } from "../../hooks";
import { customeJsonParser } from "../../../utils/utils";

const ViewSubjectPage = () => {
    const { id } = useParams()
    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());
    const [create] = usePostMutation();

    const { data: _data, isLoading } = useGetQuery({
        method: SUBJECT.GET,
        body: { id },
        providesTags: [SUBJECT_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {})

    const stringifyData = JSON.stringify(data)

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            subject_code: Yup.string().required('Subject code is required'),
            subject_name: Yup.string().required("Subject name is required"),
            exam_start_date_and_time: Yup.string().required("Exam start date and time is required"),
            exam_end_date_and_time: Yup.string().required("Exam end date and time is required"),
            exam_center: Yup.string().required("Exam center is required"),
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: SUBJECT.UPDATE,
                    body: values,
                    invalidatesTags: [SUBJECT_TAGS.VIEW, SUBJECT_TAGS.LIST, DROPDOWN_TAGS.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription("Subject details have been saved").showSuccessToast();

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const deleteHandler = async () => {
        try {
            await create({
                method: SUBJECT.DELETE,
                body: { id },
                invalidatesTags: [SUBJECT_TAGS.LIST, DROPDOWN_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Subject is deleted successfully.").showSuccessToast();

            setTimeout(() => {
                navigate(`/app/subject`);
            }, 1500)

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        }
    }

    const { isChanged, setInitialObj } = useObjChange(formik.values)

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
        }
    }, [stringifyData])

    const actions: PageHeaderActions = [
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Delete",
            onClick: deleteHandler,
            buttonVariant: "solid",
            buttonColorScheme: "red"
        }
    ]

    if (isLoading) {
        return (
            <>
                <PageHeader isLoading={isLoading} enableBackButton actions={actions} title={''} />
                <InnerLoading />
            </>
        )
    }

    return (
        <div>
            <PageHeader enableBackButton actions={actions} title={_.get(data, 'subject_name', '')} subtitle={'Subject'} />

            <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormInput isRequired label="Subject Code" formik={formik} name="subject_code" />
                    <FormInput isRequired label="Subject Name" formik={formik} name="subject_name" />
                    <FormInput type={'datetime-local'} isRequired label="Exam Start Date and Time" formik={formik} name="exam_start_date_and_time" />
                    <FormInput type={'datetime-local'} isRequired label="Exam End Date and Time" formik={formik} name="exam_end_date_and_time" />
                    <FormInput isRequired label="Exam Center" formik={formik} name="exam_center" />
                </SimpleGrid>
            </SectionCard>
        </div>
    )
}

export default ViewSubjectPage
