import axios from "axios";
import _, { get } from "lodash";
import React from "react";
import { BASE_URL } from "../../../consts/app.consts";
import { BadgeCell, DefaultCell, ResourcePersonCell } from "./components/cells";

const ResourcePersonConfig = {
  searchApi: "xceed.api.report.resource_person.generate_resource_person_report",
  alert:
    "Reminder: Specify a date range to receive reports. Use other fields to further narrow down data within the required date range.",
  excelFileName: "resource-person-report",
  title: "Resource Person Allocation Report",
  extraActionsAsMenu: true,
  sections: [
    {
      selectionTitle: "Date Range",
      grid: { base: 1, md: 2, lg: 4, xl: 4 },
      filters: [
        {
          is_required: true,
          label: "From",
          type: "date",
          key: "from",
        },
        {
          is_required: true,
          label: "To",
          type: "date",
          key: "to",
        },
      ],
    },
    {
      selectionTitle: "Filters",
      grid: { base: 1, md: 2, lg: 4, xl: 4 },
      filters: [
        {
          is_required: true,
          label: "Resource Person",
          key: "resource_person",
          type: "form-select",
          isMulti: true,
          optionMethod: "xceed.api.report.resource_person.get_resource_person",
        },
      ],
    },
  ],

  tableConfig: {
    column: [
      {
        header: "Resource Person",
        accessor: "rs_name",
        width: 200,
      },
      {
        header: "Session",
        accessor: "title",
        // customRenderer: (value: any, item: any) => {
        //     if (get(item, 'programmed_module')) {
        //         return React.createElement(DefaultCell, { navigateTo: `/app/session/${_.get(item, 'session')}?pm=${_.get(item, 'programmed_module')}`, value: value })
        //     }

        //     return value

        // },
        width: 300,
      },
      {
        header: "Start",
        accessor: "start_date_andtime",
        width: 200,
      },
      {
        header: "End",
        accessor: "end_date_and_time",
        width: 200,
      },
    ],
  },
  actions: [
    {
      text: "Export to Excel",
      onClick: async (filter: any, data: any, setLoading: any) => {
        if (filter.to && filter.from && _.isArray(filter.resource_person)) {
          setLoading(true);
          try {
            let response = await axios.post(
              `${BASE_URL}/method/xceed.api.export_excel.resource_person.export_excel_generic_report`,
              { filter },
              {
                withCredentials: true,
                responseType: "blob",
              }
            );
            if (response) {
              console.log(response);
              const href = URL.createObjectURL(response.data);

              const link = document.createElement("a");
              link.href = href;
              link.setAttribute("download", `resource-person-report.xlsx`);
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            }
            setLoading(false);
          } catch (error: any) {
            setLoading(false);
            const newError: any = new Error("Unable to Generate Excel file");
            newError.title = "Error";
            newError.status = "error";
            throw newError;
          }
        } else {
          const error: any = new Error(
            "Please select from date, to date and at least one classroom"
          );
          error.title = "Warning";
          error.status = "warning";
          throw error;
        }
      },
      buttonVariant: "solid",
      buttonColorScheme: "teal",
    },
    {
      text: "Generate Resource person report",
      onClick: async (filter: any, data: any, setLoading: any) => {
        if (filter.to && filter.from && _.isArray(filter.resource_person)) {
          setLoading(true);
          try {
            let response = await axios.post(
              `${BASE_URL}/method/xceed.api.export_excel.resource_person.export_resource_person_report`,
              { filter },
              {
                withCredentials: true,
                responseType: "blob",
              }
            );
            if (response) {
              console.log(response);
              const href = URL.createObjectURL(response.data);
              const link = document.createElement("a");
              link.href = href;
              link.setAttribute(
                "download",
                `resource-person-report-v2-report.xlsx`
              );
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
              URL.revokeObjectURL(href);
            }
            setLoading(false);
          } catch (error: any) {
            setLoading(false);
            const newError: any = new Error("Unable to Generate Excel file");
            newError.title = "Error";
            newError.status = "error";
            throw newError;
          }
        } else {
          const error: any = new Error(
            "Please select from date, to date and at least one classroom"
          );
          error.title = "Warning";
          error.status = "warning";
          throw error;
        }
      },
      buttonVariant: "solid",
      buttonColorScheme: "teal",
    },
  ],
};

export default ResourcePersonConfig;

const statusBadgeConfig = {
  NO: "bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded",
  YES: "bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded",
};
