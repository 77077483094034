import { FormControl, FormErrorMessage, FormLabel, Tooltip } from "@chakra-ui/react"
import { ChakraStylesConfig, ControlProps, InputProps, Select, StylesConfig, components } from "chakra-react-select"
import _, { debounce, forEach } from "lodash"
import { MouseEventHandler, useEffect, useMemo, useRef, useState } from "react"
import "./form-controller-styles.css"
import { useDispatch } from "react-redux"
import { getFormOptions } from "./actions/form-select.actions"
import { retry } from "@reduxjs/toolkit/dist/query"

interface IFormSelect {
    classNames?: string,
    formik: any,
    name: string,
    label?: string,
    isRequired?: boolean,
    isDisabled?: boolean,
    isSearchable?: boolean,
    metaDispatcher?: any,
    isMulti?: boolean,
    isClearable?: boolean,
    components?: any,
    placeholder?: string | number | undefined
    options?: FormSelectOptions,
    size?: "sm" | "md" | "lg",
    method?: string,
    body?: any,
    optionPath?: Array<String>,
    returnArray?: boolean
    extraProps?: Object

}

type option = { label: string | number, value: string | number }
export type FormSelectOptions = Array<option>

const FormOnlineSearch = ({ extraProps, body, isClearable = true, size = "sm", returnArray = false, placeholder = '', components, isMulti = false, metaDispatcher = () => { }, isSearchable = true, formik, name, label, isRequired = false, isDisabled = false, options: _options = [], method, optionPath = ["message"] }: IFormSelect) => {
    const dispatch = useDispatch();
    const [options, setOptions] = useState<any>([
    ]);
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [txt, setTxt] = useState()

    useEffect(() => {
        getOptions()
    }, [JSON.stringify(_options), method, body, txt])

    const getOptions = async () => {
        if (method) {
            try {
                setIsLoading(true);
                const res = await dispatch(getFormOptions(method, { ...body, "txt": txt ? txt : _.get(formik.values, 'student') }) as any)
                const data = _.get(res, ['data', ...optionPath as any], [])
                setOptions(data);
            } catch (error) {
                console.log(error);
            } finally {
                setIsLoading(false)
            }
        } else {
            setOptions(_options)
        }
    }

    useEffect(() => {
        const elements: any = document.querySelectorAll(".fcc") || [];


        if (elements?.length > 0) {
            elements.forEach((element: any, index: any) => {
                const tagContainer = element["children"][2];
                const tagContainerParent = tagContainer["children"][0]


                if (tagContainer && tagContainerParent) {
                    tagContainer.classList.add("mfstcc")
                    tagContainerParent.classList.add("mfstccp")
                }
            })
        }

    }, [formik.values[name]])

    const defaultValueSelector = () => {
        let data: any = [];
        let values = _.get(formik, ["values", name])

        try {
            if (isMulti) {
                if (_.isArray(values)) {
                    values.forEach((val: any) => {
                        data.push(_.find(options, { 'value': val }))
                    })
                } else {
                    console.error("Error: Default values for FormSelect should be a type of array")
                }
            } else {
                data = _.find(options, { 'value': values });
            }
        } catch (e: any) {
            console.error(e);
        }

        return data;
    }

    const onChangeHandler = (e: any) => {
        if (!_.isEmpty(e)) {

            if (isMulti) {
                formik.setFieldValue(name, e.map((i: any) => i["value"]));
            } else {
                formik.setFieldValue(name, returnArray ? [e["value"]] : e["value"]);
            }

            metaDispatcher(e)
        } else {
            if (isMulti) {
                formik.setFieldValue(name, []);
            } else {
                formik.setFieldValue(name, returnArray ? [] : '');
            }
        }
    }

    const styles: StylesConfig<any, false> = {
        control: (css: any) => {
            return {
                ...css,
                minHeight: '0px',
                padding: '0px',
                height: 'auto',
                borderRadius: '5px',
                backgroundColor: "white",
                opacity: isDisabled ? '0.4' : '1',
                '&:focus': {
                    border: '2px solid blue !important',
                },
            }
        },
    };

    const onInputHandler = (e: any) => {
        let deb = _.debounce(() => {
            setTxt(e)
        }, 500)

        deb()
    }

    return (
        <FormControl isRequired={isRequired} isInvalid={Boolean(formik.submitCount && formik.errors[name])}>
            {label && <FormLabel className={'!opacity-75 !text-[13px]'}>{label}</FormLabel>}
            <Select
                className="border-gray-300  shadow-sm fcc"
                chakraStyles={chakraStyles}
                onChange={onChangeHandler}
                onInputChange={onInputHandler}
                // navigationHandler={navigationHandler}
                {...extraProps}
                isLoading={(method && isLoading) ? true : false}
                isDisabled={isDisabled}
                placeholder={placeholder}
                isClearable={isClearable}
                isSearchable={isSearchable}
                name={name}
                size={size}
                selectedOptionColorScheme="teal"
                // @ts-ignore
                isMulti={isMulti}
                styles={styles}
                tagVariant="solid"
                colorScheme={"teal"}
                components={components ? components : undefined}
                options={options}
                value={_.isEmpty(formik.values[name]) ? [] : defaultValueSelector() as any}
            />
            {(formik.submitCount && formik.errors[name]) ? <FormErrorMessage mt={0}>{formik.errors[name]}</FormErrorMessage> : null}
        </FormControl>
    )
}


export default FormOnlineSearch;

const chakraStyles: ChakraStylesConfig = {
    dropdownIndicator: (provided, state) => ({
        ...provided,
        p: 0,
        w: "30px",
    }),
};