import { Alert, AlertIcon, SimpleGrid, useDisclosure, useToast } from "@chakra-ui/react"
import axios from "axios"
import { useFormik } from "formik"
import _, { get } from "lodash"
import { useEffect, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import * as Yup from "yup"
import { BASE_URL } from "../../../../consts/app.consts"
import { REPORT_TAGS } from "../../../../consts/tags.consts"
import { useGetQuery } from "../../../../services/api.service"
import { ToastService } from "../../../../services/toast.service"
import ChakraTable from "../../../common/chakra-table/ChakraTable"
import { FormInput } from "../../../common/form-controllers/FormInput"
import FormSelect from "../../../common/form-controllers/FormSelect"
import InnerLoading from "../../../common/inner-loading/InnterLoading"
import PageHeader, { PageHeaderActions } from "../../../common/page-header/PageHeader"
import { SectionCard } from "../../../common/section-card/SectionCard"
import { useTableDataProvider } from "../../../hooks"

const SessionReportV2Page = () => {
    const [length, setLength] = useState(15)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [filter, setFilter]: any = useState({})
    const toast = new ToastService(useToast());
    const [searchParams, setSearchParams] = useSearchParams();
    const from = searchParams.get('from')
    const to = searchParams.get('to')

    const formik = useFormik({
        initialValues: {
        },
        validationSchema: Yup.object({
            from: Yup.string().required('From date is required'),
            to: Yup.string().required('To date is required'),
        }),
        onSubmit: async (values: any) => {
            setFilter(values)
        }
    })

    const { data, isLoading, isFetching } = useGetQuery({
        method: 'xceed.api.report.session.get_session_report_v2',
        body: {
            page: page,
            length: Number(length),
            filter: filter
        },
        providesTags: [REPORT_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const onLengthChange = (length: any) => {
        setPage(0)
        setLength(Number(length))
    }

    const onPage = (page: any) => {
        setPage(page)
    }

    useEffect(() => {
        formik.setValues({ from: from, to: to })
        setFilter({ from: from, to: to })

    }, [from, to])


    useEffect(() => {
        formik.setFieldValue('program', '')

    }, [_.get(formik.values, 'pa')])

    useEffect(() => {
        formik.setFieldValue('pa', '')

    }, [_.get(formik.values, 'from'), _.get(formik.values, 'to')])

    useEffect(() => {
        formik.setFieldValue('module', '')

    }, [_.get(formik.values, 'program')])

    const exportToExcel = async () => {
        if (get(formik.values, 'from') && get(formik.values, 'to')) {
            setLoading(true)
            try {
                let response = await axios.post(`${BASE_URL}/method/xceed.api.export_excel.session_report.export_session_report_v2`, { filter: formik.values }, {
                    withCredentials: true,
                    responseType: "blob"
                })
                if (response) {
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `consultant-session-report-v2.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                }
                setLoading(false)

            } catch (error: any) {
                setLoading(false)
                toast.setDescription('Unable to generate consultant session report v2').setTitle('Error').setStatus("error").show()
            }
        }
        else {
            toast.setDescription('please select from date and to date ').setTitle('Warning').setStatus("warning").show()
        }
    }

    const executeReportHandler = () => {
        formik.submitForm()
    }


    const actions: PageHeaderActions = [
        {
            text: "Export to Excel",
            onClick: exportToExcel,
            buttonVariant: "solid",
            isDisabled: loading || isFetching || isLoading,
            buttonColorScheme: "teal",
            confirmation: false
        },
        {
            text: "Execute Report",
            onClick: executeReportHandler,
            buttonVariant: "solid",
            isDisabled: loading || isFetching || isLoading,
            buttonColorScheme: "teal",
            confirmation: false
        },
        {
            text: "Reset",
            onClick: () => formik.resetForm(),
            isDisabled: loading || isFetching || isLoading,
            buttonVariant: "solid",
            buttonColorScheme: "red",
            confirmation: false
        }
    ]

    if (loading || isLoading) {
        return (
            <>
                <PageHeader enableBackButton isLoading={isFetching || isLoading || loading}
                    actions={actions}
                    subtitle="View and Manage Report" title={'Sessions V2 report'} />
                <InnerLoading />
            </>
        )
    }

    return (
        <>
            <div>
                <PageHeader enableBackButton isLoading={isFetching || isLoading || loading}
                    actions={actions}
                    subtitle="View and Manage Report" title={'Sessions Report V2'} />

                <Alert status='warning' rounded={'md'}>
                    <AlertIcon />
                    Reminder: Specify a date range to receive reports. Use other fields to further narrow down data within the required date range.
                </Alert>

                <SectionCard backgroundColor=" bg-white" title={'Date Range'} p={3} mb={4}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                        <FormInput formik={formik}
                            isRequired
                            name={'from'}
                            type={'date'}
                            label={'From'} />
                        <FormInput formik={formik}
                            isRequired
                            name={'to'}
                            type={'date'}
                            label={'To'} />
                    </SimpleGrid>
                </SectionCard>

                <SectionCard backgroundColor=" bg-white" title={'Filters'} p={3} mb={4}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                        <FormSelect method={'xceed.api.report.session.get_program_assistance_session_report_v2'} formik={formik}
                            body={{ from: _.get(formik.values, 'from'), to: _.get(formik.values, 'to') }}
                            name={'pa'}
                            label={'Program Assistant'} />
                        <FormSelect method={"xceed.api.report.session.get_program_report_v2"} formik={formik}
                            body={{ from: _.get(formik.values, 'from'), to: _.get(formik.values, 'to'), pa: _.get(formik.values, 'pa') }}
                            name={'program'}
                            label={'Program'} />
                        <FormSelect method={'xceed.api.report.session.get_module_report_v2'} formik={formik}
                            body={{
                                program: get(formik.values, 'program'),
                                from: _.get(formik.values, 'from'),
                                to: _.get(formik.values, 'to')
                            }}
                            name={'module'}
                            label={'Module'} />
                    </SimpleGrid>
                </SectionCard>
                <ChakraTable onPage={onPage} onLength={onLengthChange} lengthPaginations isFetching={isFetching} isLoading={isLoading || loading} size={"sm"}
                    paginationDetails={paginationDetails}
                    pagination
                    data={dataList || []}
                    title="Report"
                    columns={column} />

            </div>
        </>
    )
}

export default SessionReportV2Page

const column = [
    {
        header: "Start Date",
        accessor: "start_date",
        width: 150,
    },
    {
        header: "Start Time",
        accessor: "start_time",
        width: 100,
    },
    {
        header: "End Time",
        accessor: "end_time",
        width: 100,
    },
    {
        header: "Program Name",
        accessor: "title",
        width: 200,
    },
    {
        header: "Module Named",
        accessor: "module",
        width: 200,
    },
    {
        header: "Session",
        accessor: "session",
        width: 200,
    },
    {
        header: "Duration (Hours)",
        accessor: "session_durationhours",
        width: 50,
    },
    {
        header: "Program Assistant",
        accessor: "assistant",
        width: 100,
    },
    {
        header: "Classroom",
        accessor: "class_name",
        width: 100,
    },
]