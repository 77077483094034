import { Link, useToast } from "@chakra-ui/react";
import _, { get } from "lodash";
import { useState } from "react";
import { NavigateFunction } from "react-router";
import { Link as ReactRouterLink, useNavigate, useParams } from 'react-router-dom'
import { SESSION } from "../../../consts/methods.consts";
import { SESSION_TAGS } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { useTableDataProvider } from "../../hooks";
import { SectionCard } from "../../index";

const SessionListPage = ({ parentData }: any) => {
    const allowedToAmend = get(parentData, 'allowedToAmend')
    const allowedToSave = get(parentData,'allowedToSave')

    const navigate: NavigateFunction = useNavigate()
    const { id } = useParams()
    const [page, setPage] = useState(1)
    const [length, setLength] = useState(15)
    const toast = new ToastService(useToast())
    const [create] = usePostMutation();

    const { data, isLoading, isFetching } = useGetQuery({
        method: SESSION.LIST,
        body: {
            page: page,
            program: _.get(parentData, 'program'),
            program_module: _.get(parentData, 'programModule'),
            length: Number(length),
        },
        providesTags: [SESSION_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const actions =
    {
        text: "Create New",
        onClick: () => navigate(`/app/session/new?p=${_.get(parentData, 'program')}&pm=${_.get(parentData, 'programModule')}`),
        buttonVariant: "solid",
        buttonColorScheme: "teal"
    }


    const tableLineClickHandler = (line: any) => {
        navigate(`/app/session/${_.get(line, 'name')}?pm=${id}`)
    }

    const ondeleteHandler = async (item: any) => {
        try {
            const res = await create({
                method: SESSION.DELETE,
                body: { id: item?.name },
                invalidatesTags: [SESSION_TAGS.LIST, SESSION_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Session deleted successfully.").showSuccessToast();

        } catch (e) {
            toast.setTitle("Error").setDescription("Failed to delete Session").showErrorToast();
        }
    }

    const onLengthChange = (length: any) => {
        setPage(1)
        setLength(Number(length))
    }

    const onPage = (page: any) => {
        setPage(page)
    }

    const PROGRAM_LIST_COLUMNS: ChakraTableColumns = [
        {
            header: "Name",
            accessor:
                "title",
        },
        {
            header: "Session Number",
            accessor:
                "session_number",
        },
        {
            header: "Start Date and Time",
            accessor:
                "Formatted_date_and_time",
        },
        {
            header: "Resource Persons",
            width: 250,
            accessor:
                "resource_persons",
            customRenderer(value: any) {
                const valueMarkup = value.map((item: any, index: number) =>
                    <div key={index}>
                        <Link className='w-full'
                            as={ReactRouterLink}
                            to={`/app/employee/${_.get(item, 'name')}`} >
                            <p className='py-1'>{_.get(item, 'full_name', '')}</p>
                        </Link>
                    </div>)

                return (
                    <div>
                        {valueMarkup}
                    </div>
                )
            },
        },
       ...(allowedToAmend || allowedToSave) ? [ {
            header: "Actions",
            accessor: '',
            disabled: false,
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick: ondeleteHandler,
                }
            ]
        }] :[]
    ]


    return (

        <div>
            <SectionCard {...((allowedToAmend || allowedToSave) && { creatable: actions })} backgroundColor="bg-white" title="Sessions" p={3}>
                <ChakraTable onPage={onPage} onLength={onLengthChange} lengthPaginations isFetching={isFetching} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"}
                    onSelected={tableLineClickHandler} columns={PROGRAM_LIST_COLUMNS} data={dataList} />

            </SectionCard>
        </div>

    )
}

export default SessionListPage

const statusByRole = {
    0: "Pending - MC",
    1: "Pending - ADG",
    "-1": "Rejected - MC",
    2: "Pending- DG ",
    "-2": "Rejected -DG",
    3: "Approved",
    "-3": "Rejected -DG",

}