import { Alert, AlertIcon, SimpleGrid, useDisclosure, useToast } from "@chakra-ui/react"
import axios from "axios"
import { useFormik } from "formik"
import _, { get } from "lodash"
import { useEffect, useState } from "react"
import * as Yup from "yup"
import { BASE_URL } from "../../../../consts/app.consts"
import { REPORT_TAGS } from "../../../../consts/tags.consts"
import { useGetQuery } from "../../../../services/api.service"
import { ToastService } from "../../../../services/toast.service"
import ChakraTable from "../../../common/chakra-table/ChakraTable"
import { FormInput } from "../../../common/form-controllers/FormInput"
import FormOnlineSearch from "../../../common/form-controllers/FormOnlineSearch"
import FormSelect from "../../../common/form-controllers/FormSelect"
import InnerLoading from "../../../common/inner-loading/InnterLoading"
import PageHeader, { PageHeaderActions } from "../../../common/page-header/PageHeader"
import { SectionCard } from "../../../common/section-card/SectionCard"
import { useTableDataProvider } from "../../../hooks"

const StudentEnrollmentReportPage = () => {
    const [length, setLength] = useState(15)
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [filter, setFilter]: any = useState({})
    const toast = new ToastService(useToast());

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            from: Yup.string().required('From date is required'),
            to: Yup.string().required('To date is required'),
            pa: Yup.string().required('Program assistant is required')
        }),
        onSubmit: async (values: any) => {
            setFilter(values)
        }
    })

    const { data, isLoading, isFetching } = useGetQuery({
        method: 'xceed.api.report.module_enrollment.generate_student_enrolment_report',
        body: {
            page: page,
            length: Number(length),
            filter: filter
        },
        providesTags: [REPORT_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const onLengthChange = (length: any) => {
        setPage(0)
        setLength(Number(length))
    }

    const onPage = (page: any) => {
        setPage(page)
    }

    useEffect(() => {
        formik.setFieldValue('program', '')

    }, [_.get(formik.values, 'pa')])

    useEffect(() => {
        formik.setFieldValue('pa', '')

    }, [_.get(formik.values, 'from'), _.get(formik.values, 'to')])

    useEffect(() => {
        formik.setFieldValue('module', '')

    }, [_.get(formik.values, 'program')])

    const generateBulkReport = async () => {
        if (get(formik.values, 'pa')) {
            setLoading(true)
            try {
                let response = await axios.post(`${BASE_URL}/method/xceed.api.export_excel.module_enrollment.export_enrollment_and_attendance_bulk_report`, { filter: formik.values }, {
                    withCredentials: true,
                    responseType: "blob"
                })
                if (response) {
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `student-enrolment-&-attendance-bulk-report.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                }
                setLoading(false)

            } catch (error: any) {
                setLoading(false)
                toast.setDescription('Unable to generate student enrollment & attendance bulk report').setTitle('Error').setStatus("error").show()
            }
        }
        else {
            toast.setDescription('please select a program.').setTitle('Warning').setStatus("warning").show()
        }
    }

    const generateReport = async () => {
        if (get(formik.values, 'module')) {
            setLoading(true)
            try {
                let response = await axios.post(`${BASE_URL}/method/xceed.api.export_excel.module_enrollment.export_enrollment_and_attendance_report`, { filter: formik.values }, {
                    withCredentials: true,
                    responseType: "blob"
                })
                if (response) {
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `student-enrolment-&-attendance-report.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                }
                setLoading(false)

            } catch (error: any) {
                setLoading(false)
                toast.setDescription('Unable to generate student enrollment & attendance report').setTitle('Error').setStatus("error").show()
            }
        }
        else {
            toast.setDescription('please select a module ').setTitle('Warning').setStatus("warning").show()
        }
    }

    const executeReportHandler = () => {
        formik.submitForm()
    }

    const moreActions: PageHeaderActions = [
        {
            text: "Generate bulk report",
            onClick: generateBulkReport,
            isDisabled: loading || isFetching || isLoading,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            confirmation: false
        },
        {
            text: "Generate report",
            onClick: generateReport,
            isDisabled: loading || isFetching || isLoading,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            confirmation: false
        },
    ]

    const actions: PageHeaderActions = [
        {
            text: "Execute Report",
            onClick: executeReportHandler,
            buttonVariant: "solid",
            isDisabled: loading || isFetching || isLoading,
            buttonColorScheme: "teal",
            confirmation: false
        },
        {
            text: "Reset",
            onClick: () => formik.resetForm(),
            isDisabled: loading || isFetching || isLoading,
            buttonVariant: "solid",
            buttonColorScheme: "red",
            confirmation: false
        }
    ]

    if (loading || isLoading) {
        return (
            <>
                <PageHeader enableBackButton isLoading={isFetching || isLoading || loading}
                    actions={actions} moreActions={moreActions}
                    subtitle="View and Manage Report" title={'Student Enrolment & Attendance Report'} />
                <InnerLoading />
            </>
        )
    }

    return (
        <>
            <div>
                <PageHeader enableBackButton isLoading={isFetching || isLoading || loading}
                    actions={actions} moreActions={moreActions}
                    subtitle="View and Manage Report" title={'Student Enrolment & Attendance Report'} />

                <Alert status='warning' rounded={'md'}>
                    <AlertIcon />
                    Reminder: Specify a date range to receive reports. Use other fields to further narrow down data within the required date range.
                </Alert>

                <SectionCard backgroundColor=" bg-white" title={'Date Range'} p={3} mb={4}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                        <FormInput formik={formik}
                            isRequired
                            name={'from'}
                            type={'date'}
                            label={'From'} />
                        <FormInput formik={formik}
                            isRequired
                            name={'to'}
                            type={'date'}
                            label={'To'} />
                    </SimpleGrid>
                </SectionCard>

                <SectionCard backgroundColor=" bg-white" title={'Filters'} p={3} mb={4}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                        <FormSelect method={"xceed.api.report.module_enrollment.get_pa_list"} formik={formik}
                            body={{ from: _.get(formik.values, 'from'), to: _.get(formik.values, 'to') }}
                            name={'pa'}
                            label={'Program Assistant'} isRequired />
                        <FormSelect method={'xceed.api.report.module_enrollment.get_program_list'} formik={formik}
                            body={{
                                pa: get(formik.values, 'pa'),
                                from: _.get(formik.values, 'from'),
                                to: _.get(formik.values, 'to')
                            }}
                            name={'program'}
                            label={'Program'} />
                        <FormSelect method={'xceed.api.report.module_enrollment.get_module_list'} formik={formik}
                            body={{
                                program: get(formik.values, 'program')
                            }}
                            name={'module'}
                            label={'Module'} />
                    </SimpleGrid>
                </SectionCard>
                <ChakraTable onPage={onPage} onLength={onLengthChange} lengthPaginations isFetching={isFetching} isLoading={isLoading || loading} size={"sm"}
                    paginationDetails={paginationDetails}
                    pagination
                    data={dataList || []}
                    title="Report"
                    columns={column} />

            </div>
        </>
    )
}

export default StudentEnrollmentReportPage

const column = [
    {
        header: "Program Name",
        accessor: "program",
        width: 400,
    },
    {
        header: "Start Date",
        accessor: "start_date",
        width: 200,
    },
    {
        header: "End Date",
        accessor: "end_date",
        width: 200,
    },
    {
        header: "Student Enrolled",
        accessor: "student_enrolled",
        width: 50,
    },
    {
        header: "No. of Student enrolled",
        accessor: "no_of_student_enrolled",
        width: 50,
    },
    {
        header: "Total No. of Sessions",
        accessor: "no_of_sessions",
        width: 50,
    },
    {
        header: "Update Attendance",
        accessor: "update_attendance",
        width: 50,
    },
    {
        header: "No. of Present Students",
        accessor: "no_of_present_student",
        width: 50,
    },
    {
        header: "No. of Absent Students",
        accessor: "no_of_absent_student",
        width: 50,
    },
    {
        header: "Update Assessment Result (Yes/No)",
        accessor: "update_assessment_result",
        width: 50,
    }
]