import _ from "lodash";

export const itemSelector = (item: any, setSelected: any) => {
    setSelected((prev: any) => {
        const index = prev.findIndex((i: any) => i.name == item.name)

        if (index == -1) {
            return [item, ...prev];
        }

        return prev.filter((i: any) => i.name != item.name)
    })
}

export const onIsAllCheckChanges = (event: any, data: any, selected: any, setSelected: any) => {
    if (event.target.checked) {
        setSelected([...data]);
        return;
    }
    setSelected([])
}

export const isAllChecked = (selected: any, data: any) => {
    const dataCount = (data || []).length
    return (dataCount ==  (selected || []).length)
}
