import { Alert, AlertIcon, SimpleGrid } from "@chakra-ui/react";
import { useFormik } from "formik"
import _, { get } from "lodash"
import React from "react";
import { useState } from "react"
import * as Yup from "yup";
import { CALENDAR } from "../../../../consts/methods.consts"
import { MODULE_TAGS, PROGRAM_TAGS, SESSION_TAGS } from "../../../../consts/tags.consts";
import { useGetQuery } from "../../../../services/api.service"
import ChakraTable, { ChakraTableColumns } from "../../../common/chakra-table/ChakraTable"
import { FormInput } from "../../../common/form-controllers/FormInput";
import { SectionCard } from "../../../common/section-card/SectionCard";
import { useTableDataProvider } from "../../../hooks";
import { DefaultCell } from "../../report/components/cells";
import CellDateTimeFormatter from "./CellDateTimeFormatter";

const ModuleTable = () => {

    const [page, setPage] = useState(1)

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
        }),
        onSubmit: async (values) => {

        }
    })

    const { data, isLoading, isFetching } = useGetQuery({
        method: CALENDAR.MODULE_LIST,
        body: {
            filter: {
                "startDateOfMonth": get(formik.values, 'from'),
                "endDateOfMonth": get(formik.values, 'to'),
            },
            page: page
        },
        providesTags: [MODULE_TAGS.LIST, SESSION_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);
    const sortedDataList = _.sortBy(dataList, (session) => new Date(session.start));


    const onPage = (page: any) => {
        setPage(page)
    }


    return (
        <>
            <Alert status='warning' rounded={'md'}>
                <AlertIcon /> <p>
                    Note: For one day, in
                    <span className="font-bold">&nbsp;FROM&nbsp;  </span>
                    choose the needed date and in
                    <span className="font-bold">&nbsp;TO&nbsp;  </span>
                    choose the next day. For more than one day,
                    <span className="font-bold">&nbsp;FROM&nbsp;  </span>
                    should be first day and <span className="font-bold">&nbsp;TO&nbsp;  </span> should be the day after the last day.
                </p>
            </Alert>
            <SectionCard backgroundColor=" bg-white" title="Date Range" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormInput type="date" isRequired label="From" formik={formik} name="from" />
                    <FormInput type="date" isRequired label="To" formik={formik} name="to" />
                </SimpleGrid>
            </SectionCard>
            <div className="pt-4">
                <ChakraTable onPage={onPage} pagination paginationDetails={paginationDetails} isLoading={isLoading} size={"sm"} header title="Modules"
                    columns={MODULE_TABLE_COLUMNS} data={sortedDataList} searchable={false} />
            </div>

        </>

    )
}

const MODULE_TABLE_COLUMNS: ChakraTableColumns = [
    {
        header: "Start Date",
        accessor: "start",
        customRenderer: CellDateTimeFormatter,
        width: 100
    },
    {
        header: "End Date",
        accessor: "end",
        customRenderer: CellDateTimeFormatter,
        width: 100
    },
    {
        header: "Module Name",
        accessor: "title",
        customRenderer: (value: any, item: any) => (
            React.createElement(DefaultCell, { navigateTo: get(item, 'navigate_module'), value: value })
        ),
        width: 400
    }
]


export default ModuleTable