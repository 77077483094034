export const customeJsonParser = (str: any, def: any = '') => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return def;
    }
}

export const COLOR_SCHEMAS: any = {
    'green': 'bg-green-600 text-white hover:bg-green-700 hover:text-white',
    'red': 'bg-red-600 text-white hover:bg-red-700 hover:text-white',
    'yellow': 'bg-yellow-600 text-white hover:bg-yellow-700 hover:text-white',
    'blue': 'bg-blue-600 text-white hover:bg-blue-700 hover:text-white',
    'indigo': 'bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white',
    'purple': 'bg-purple-600 text-white hover:bg-purple-700 hover:text-white',
    'pink': 'bg-pink-600 text-white hover:bg-pink-700 hover:text-white',
    'gray': 'bg-gray-600 text-white hover:bg-gray-700 hover:text-white',
    'black': 'bg-black text-white hover:bg-gray-700 hover:text-white',
    'orange': 'bg-orange-600 text-white hover:bg-orange-700 hover:text-white',
}

export const ICON_COLOR_SCHEMAS: any = {
    'green': 'text-green-400',
    'red': 'text-red-500',
    'yellow': 'text-yellow-500',
    'blue': 'text-blue-400',
    'indigo': 'text-indigo-500',
    'purple': 'text-purple-500',
    'pink': 'text-pink-500',
    'gray': 'text-gray-500',
    'black': 'text-black',
    'orange': 'text-orange-500',
}