import _ from "lodash";
import * as Yup from "yup";
import ChakraModal from "../../../../common/chakra-modal/ChakraModal";
import { FormInput } from "../../../../common/form-controllers/FormInput";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { setLocalStoreModel } from "@dladio/hooks/dist/store/slices/local-store";
import { useEffect, useState } from "react";
import { SimpleGrid, useDisclosure } from "@chakra-ui/react";
import ChakraTable, { ChakraTableColumns } from "../../../../common/chakra-table/ChakraTable";
import { SectionCard, SectionCardCreatable } from "../../../../common/section-card/SectionCard";

const Experience = ({ experienceRef, initialValues }: any) => {
    const disclosure = useDisclosure()
    const [tableData, setTableData]: any = useState([])
    const dispatch: any = useDispatch()

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            place_of_work: Yup.string().required("Place of Work Level is required"),
            designation: Yup.string().required("Designation is required"),
            work_area: Yup.string().required("Work Area is required"),
            nature_of_work: Yup.string().required("Nature of Work is required"),
            period_of_work: Yup.string().required("Period of Work is required")
        }),
        onSubmit: async (values: any) => {
            setTableData([values, ..._.isArray(tableData) ? tableData : []])
            disclosure.onClose()
            formik.resetForm()
        }
    })

    useEffect(() => {
        if (!_.isEmpty(initialValues)) {
            setTableData(initialValues)
        }
    }, [initialValues])

    experienceRef.current = tableData

    const experienceCreatable: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            disclosure.onOpen();
        }
    }
    useEffect(() => {
        dispatch(setLocalStoreModel({ path: 'resource-person.experience', model: tableData }))
    }, [tableData])

    const onDeleteExperience = (item: any) => {
        const newItems = tableData.filter((i: any) => (
            i !== item
        ))
        setTableData(newItems)
    }

    const experienceTableActions = {
        header: "Action",
        accessor: "",
        width: 100,
        options: [
            {
                label: "Delete",
                onClick: onDeleteExperience,
            }
        ]
    }

    return (
        <SectionCard creatable={experienceCreatable} title="Experience" p={3}>
            <ChakraTable size={"sm"} searchable={false}
                columns={[...EXPERIENCE, experienceTableActions]}
                data={tableData} />
            <ChakraModal primaryAction={formik.submitForm} primaryButtonText={"Add"}
                modalTitle={'Add Experience'} {...disclosure}>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                    <FormInput isRequired label="Place of Work" formik={formik} name="place_of_work" />
                    <FormInput isRequired label="Designation" formik={formik} name="designation" />
                    <FormInput isRequired label="Work Area" formik={formik} name="work_area" />
                    <FormInput isRequired label="Nature of Work" formik={formik} name="nature_of_work" />
                    <FormInput isRequired label="Period of Work" formik={formik} name="period_of_work" />
                </SimpleGrid>
            </ChakraModal>
        </SectionCard>
    )
}


const EXPERIENCE: ChakraTableColumns = [
    {
        header: "Place of Work",
        accessor: "place_of_work",
    },
    {
        header: "Designation",
        accessor: "designation",
    },
    {
        header: "Work Area",
        accessor: "work_area",
    },
    {
        header: "Nature of Work",
        accessor: "nature_of_work",
    },
    {
        header: "Period of Work",
        accessor: "period_of_work",
    }

]
export default Experience