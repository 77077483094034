import _ from "lodash"
import { useGetQuery } from "../../../services/api.service"
import { DATAIMPORT, ORGANIZATION } from "../../../consts/methods.consts"
import { DATA_IMPORT_TAG, ORGANIZATION_TAGS } from "../../../consts/tags.consts"
import { useTableDataProvider } from "../../hooks"
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader"
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable"
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom"
import { useState } from "react"

const ORGANIZATION_COLUMNS: ChakraTableColumns = [
    {
        header: "Name",
        accessor: "name",
        width: 40
    },
    {
        header: "Status",
        accessor: "status",
        customRenderer: (text: any) => {
            return (
                <div >
                    <span style={{ backgroundColor: _.get(colourByStatus, text) }} className="text-xs text-white font-bold mr-2 px-2.5 py-0.5 rounded">
                        {text}
                    </span>

                </div>
            )
        }
    },
]

const ImportDataListPage = () => {
    const navigate: NavigateFunction = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchKey, setSearchKey]: any = useState()

    const { data, isLoading ,isFetching } = useGetQuery({
        method: DATAIMPORT.LIST,
        body: {
            page: Number(searchParams.get("page"))
        },
        providesTags: [DATA_IMPORT_TAG.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const actions: PageHeaderActions = [

        {
            text: "Create New",
            onClick: () => navigate("new"),
            confirmation: false,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const tableLineClickHandler = (line: any) => {
        navigate(`${_.get(line, 'name')}`)
    }

    const onSearchHandler = (value: any) => {
        setSearchParams({ "page": "1" })
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }

    return (
        <div>
            <PageHeader subtitle="View and Manage Data Import" title={`Data Import`} actions={actions} />
            <ChakraTable isFetching={isFetching} onSearch={onSearchHandler} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} title="Data imports"
                onSelected={tableLineClickHandler} columns={ORGANIZATION_COLUMNS} data={dataList} />
        </div>
    )
}

export default ImportDataListPage

const colourByStatus: any = {
    "Success": 'green',
    "Pending": 'red'
}