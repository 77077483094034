import * as Yup from "yup";
import { useFormik } from "formik";
import { SimpleGrid } from "@chakra-ui/react";
import { SectionCard } from "../../../../common/section-card/SectionCard";
import { FormCheckBox } from "../../../../common/form-controllers/FormCheckBox";

const SubjectArea = ({ subjectAreaRef }: any) => {

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({

        }),
        onSubmit: async (values: any) => {
            return values
        }
    })

    subjectAreaRef.current = formik

    return (
        <SectionCard backgroundColor=" bg-white" title="Subject Area Preference" p={3}>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                <FormCheckBox label="Human Resource Development" formik={formik} name="human_resource_development" />
                <FormCheckBox label="Environment Management" formik={formik} name="environment_management" />
                <FormCheckBox label="Policy Analysis / Policy making" formik={formik}
                    name="policy_analysis__policy_making" />
                <FormCheckBox label="Positive Thinking" formik={formik} name="positive_thinking" />
                <FormCheckBox label="Disciplinary Procedure" formik={formik} name="disciplinary_procedure" />
                <FormCheckBox label="Financial Analysis" formik={formik} name="financial_analysis" />
                <FormCheckBox label="Global Trends" formik={formik} name="global_trends" />
                <FormCheckBox label="Language (Tamil, Sinhala, English)" formik={formik}
                    name="language_tamil_sinhala_english" />
                <FormCheckBox label="Accountancy" formik={formik} name="accountancy" />
                <FormCheckBox label="Organization Analysis" formik={formik} name="organization_analysis" />
                <FormCheckBox label="Law / Legal Practices" formik={formik} name="law__legal_practices" />
                <FormCheckBox label="Procurement" formik={formik} name="procurement" />
                <FormCheckBox label="Advertising" formik={formik} name="advertising" />
                <FormCheckBox label="Land / Land Management" formik={formik} name="land__land_management" />
                <FormCheckBox label="Information Technology" formik={formik} name="information_technology" />
                <FormCheckBox label="Marketing" formik={formik} name="marketing" />
                <FormCheckBox label="Disaster Management" formik={formik} name="disaster_management" />
                <FormCheckBox label="Data Analytics / Data Mining" formik={formik}
                    name="data_analytics__data_mining" />
            </SimpleGrid>
        </SectionCard>
    )
}

export default SubjectArea