import _ from "lodash";
import { useState } from "react";
import { BiHide, BiShow } from 'react-icons/bi';
import { GrFormNextLink } from 'react-icons/gr'
import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputProps, InputRightElement, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper } from "@chakra-ui/react";
import { Link } from "react-router-dom";

type MutatedTypes = Omit<InputProps, 'onChange' | 'name' | 'isRequired' | 'isInvalid' | 'defaultValue'>

interface FormInputProps extends MutatedTypes {
    containerClassNames?: string,
    formik: any,
    max?: any,
    min?: any,
    defaultValue?: any
    name: string,
    label?: string,
    isRequired?: boolean,
    customMessage?: any,
    type?: string
}

export const FormNumberInput = ({ max = "", min = "", defaultValue = "", rounded = "md", size = 'sm', customMessage, type = 'text', formik, isRequired = false, name, label, containerClassNames = '', ...restProps }: FormInputProps) => {

    const handleChange = (value: any) => formik.setFieldValue(name, value)
    return (
        <>

            <FormControl isRequired={isRequired} className={containerClassNames} isInvalid={Boolean(formik.submitCount && formik.errors[name])}>
                {label && <FormLabel className={'!opacity-75 !text-[13px]'}>{label}</FormLabel>}

                {/* <Input _ shadow={"sm"} borderColor={'gray.300'} size={size} {...restProps} name={name} value={_.isEmpty(formik.values) ? '' : formik.values[name]} onChange={formik.handleChange} rounded={rounded} /> */}
                <NumberInput allowMouseWheel defaultValue={defaultValue} max={max} min={min} value={_.isEmpty(formik.values) ? '' : formik.values[name]} size={size} rounded={rounded} borderColor={'gray.300'} name={name} onChange={handleChange}  >
                    <NumberInputField shadow={"sm"} rounded={rounded} />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>


                {(formik.submitCount && formik.errors[name]) ? <FormErrorMessage mt={0}>{formik.errors[name]}</FormErrorMessage> : customMessage && customMessage}
            </FormControl></>
    )
}