import { SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect, useRef, useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { STUDENT } from "../../../consts/methods.consts";
import { STUDENT_TAG } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { customeJsonParser } from "../../../utils/utils";
import ChakraModal from "../../common/chakra-modal/ChakraModal";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { FormDropdown } from "../../common/form-controllers/FormDropdown";
import { FormInput } from "../../common/form-controllers/FormInput";
import { FormNumberInput } from "../../common/form-controllers/FormNumberInput";
import FormSelect from "../../common/form-controllers/FormSelect";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";
import MaskFormInput from "../../common/form-controllers/MaskFormInput";
import FormLiveSelect from "../../common/form-controllers/FormLiveSelect";
import InnerLoading from "../../common/inner-loading/InnterLoading";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { SectionCard, SectionCardCreatable } from "../../common/section-card/SectionCard";
import { useObjChange } from "../../hooks";
import PrintAttendanceCertificateModal from "./components/PrintAttendanceCertificateModal";
import { chakraComponents } from "chakra-react-select";

const ViewStudentPage = () => {
    const { id } = useParams()
    const disclosure = useDisclosure()
    const [userSearchKey, setUserSearchKey] = useState("")
    const attendaceCertDisclosure = useDisclosure()
    const navigate: NavigateFunction = useNavigate();
    const selectRef = useRef(false)
    const [loading, setLoading]: any = useState(false)
    const indexRef: any = useRef<number>()
    const workExperienceDisclosure = useDisclosure()
    const toast = new ToastService(useToast());
    const [create] = usePostMutation();

    const { data: _data, isLoading } = useGetQuery({
        method: STUDENT.GET,
        body: { id },
        providesTags: [STUDENT_TAG.VIEW]
    })

    const data = _.get(_data, ['message'], {})

    const stringifyData = JSON.stringify(data)

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            nic: Yup.string().matches(/^([0-9]{9}[x|X|v|V]|[0-9]{12})$/, 'Enter valid NIC number').required('NIC is required'),
            full_name: Yup.string().required('Full name is required'),
            mobile_number: Yup.string().matches(/^[0-9]{10}$/, 'Enter valid mobile number').nullable(),
            whatsapp_number: Yup.string().matches(/^[0-9]{10}$/, 'Enter valid Whatsapp mobile number').nullable(),
            place_of_work_official_number: Yup.string().matches(/^[0-9]{10}$/, 'Enter valid mobile number').nullable(),
            email: Yup.string().email('Enter valid email address').required('Email is required')
        }),
        onSubmit: async (values: any) => {
            try {
                setLoading(true)
                await create({
                    method: STUDENT.UPDATE,
                    body: values,
                    invalidatesTags: [STUDENT_TAG.VIEW, STUDENT_TAG.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription("Student details have been saved.").showSuccessToast();

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            } finally {
                setLoading(false)
            }
        }
    })

    const deleteHandler = async () => {
        try {
            await create({
                method: STUDENT.DELETE,
                body: { id },
                invalidatesTags: [STUDENT_TAG.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Student is deleted successfully.").showSuccessToast();

            setTimeout(() => {
                navigate(`/app/student`);
            }, 1500)

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        }
    }


    const { isChanged, setInitialObj } = useObjChange(formik.values)

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
            // if (data?.user) setUserSearchKey(data?.user?.value)
        }
    }, [stringifyData])

    const { data: initialData, isFetching: isFetchingInitData } = useGetQuery({
        method: STUDENT.INIT,
        body: {
            userSearchKey,
            id
        },
        providesTags: [STUDENT_TAG.LIST]
    })

    const dropDownData = _.get(initialData, 'message', [])


    const moreActions: PageHeaderActions = [
        {
            text: "Generate Attendance Certificate",
            onClick: attendaceCertDisclosure.onOpen,
            buttonVariant: "solid",
            confirmation: false,
            buttonColorScheme: "teal"
        },
    ]


    const actions: PageHeaderActions = [
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Delete",
            onClick: deleteHandler,
            buttonVariant: "solid",
            buttonColorScheme: "red"
        }
    ]

    const creatable: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            disclosure.onOpen();
        }
    }

    const workExperienceCreatable: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            workExperienceDisclosure.onOpen();
        }
    }


    const EDUCATION_TABLE_COLUMNS: ChakraTableColumns = [
        {
            header: "Level",
            accessor: "level",
        },
        {
            header: "Title",
            accessor: "title",
        },
        {
            header: "Obtained Year",
            accessor: "obtained_year",
        },
        {
            header: "Action",
            accessor: "",
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick(item: any) {
                        const newItems = _.get(formik.values, 'educational_qualifications', []).filter((i: any) => (
                            i !== item
                        ))
                        formik.setFieldValue('educational_qualifications', newItems)
                    },
                }
            ]
        },
    ]

    const WORK_EXPERIENCE_TABLE_COLUMNS: ChakraTableColumns = [
        {
            header: "Designation",
            accessor: "designation",
        },
        {
            header: "Work Place",
            accessor: "work_place",
        },
        {
            header: "from",
            accessor: "from",
        },
        {
            header: "To",
            accessor: "to",
        },
        {
            header: "Action",
            accessor: "",
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick(item: any) {
                        const newItems = _.get(formik.values, 'student_work_experience', []).filter((i: any) => (
                            i !== item
                        ))
                        formik.setFieldValue('student_work_experience', newItems)
                    },
                },
                {
                    label: "Edit",
                    confirmation: false,
                    onClick(item: any) {
                        selectRef.current = true
                        indexRef.current = _.get(formik.values, 'student_work_experience', []).findIndex(
                            (line: any) =>
                                (line.designation === item.designation && line.work_place === item.work_place && line.from === item.from && line.to === item.to)
                        )
                        workExperienceChildFormik.setValues({ ...item })
                        workExperienceDisclosure.onOpen()
                    },
                }
            ]
        },
    ]

    const childFormik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            level: Yup.string().required("Level is required"),
            title: Yup.string().required("title is required"),
            obtained_year: Yup.number().required("Obtained Year is required"),
        }),
        onSubmit: async (values: any) => {

            disclosure.onClose()
            formik.setFieldValue('educational_qualifications', [
                ..._.get(formik.values, 'educational_qualifications', []), values
            ])

            childFormik.resetForm()
        }
    })

    const workExperienceChildFormik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            designation: Yup.string().required("Designation is required"),
            work_place: Yup.string().required("Work Place is required"),
            from: Yup.string().required("From Year is required"),
            to: Yup.string().required("to Year is required"),
        }),
        onSubmit: async (values: any) => {
            const alreadyExsist = _.get(formik.values, 'student_work_experience', []).filter((item: any) => (
                values.from === item.from &&
                values.to === item.to &&
                values.designation === item.designation &&
                values.work_place === item.work_place
            ))

            if (_.size(alreadyExsist) > 0) {
                toast.setDescription('This is already exists').setStatus('warning').setTitle('Warning').show()
                return
            }
            if (selectRef.current) {
                const ModuleContent: any = [..._.get(formik.values, 'student_work_experience', [])]
                ModuleContent[indexRef.current] = { ...values }
                formik.setFieldValue('student_work_experience', ModuleContent)
                workExperienceDisclosure.onClose()
                workExperienceChildFormik.resetForm()
                selectRef.current = false
                return
            }

            formik.setFieldValue('student_work_experience', [
                ..._.get(formik.values, 'student_work_experience', []), values
            ])

            workExperienceDisclosure.onClose()
            workExperienceChildFormik.resetForm()
        }
    })

    const onCloseworkExperienceModal = () => {
        selectRef.current = false
        workExperienceChildFormik.resetForm()
        workExperienceDisclosure.onClose()
    }

    const onInputChangeUser = _.debounce((text: string) => {
        setUserSearchKey(text)
    }, 250)


    if (isLoading || loading) {
        return (
            <>
                <PageHeader moreActions={moreActions} isLoading={isLoading || loading} enableBackButton actions={actions} title={'Student'} />
                <InnerLoading />
            </>
        )
    }

    return (
        <div>
            <PageHeader moreActions={moreActions} backButtonCofirmation={isChanged} isLoading={isLoading || loading} enableBackButton actions={actions} title={_.get(data, 'full_name', '')}
                subtitle={'Student'} />

            <Tabs className="mt-3">
                <TabList>
                    <Tab>Personal Information</Tab>
                    {/* <Tab>Summary</Tab> */}
                </TabList>

                <TabPanels className="animation-form">
                    <TabPanel className="!p-0 !pt-3">
                        <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                                <FormInput isRequired label="NIC" formik={formik} name="nic" />
                                <FormLiveSelect components={customComponents} label="User" formik={formik} name={"user"} placeholder={"Search user by email"}
                                    options={_.get(dropDownData, 'users')} isLoading={isFetchingInitData} onInputChange={onInputChangeUser} />
                                <FormDropdown options={salutation} formik={formik}
                                    name={'salutation'}
                                    autoFocus
                                    label={'Salutation'} />
                                <FormInput label="First Name" formik={formik} name="first_name" />
                                <FormInput label="Last Name" formik={formik} name="last_name" />
                                <FormInput isRequired label="Full Name" formik={formik} name="full_name" />
                                {/* <FormInput label="Name with Initials" formik={formik} name="name_with_initials" /> */}
                                <FormInput type="date" label="Date of Birth" formik={formik} name="date_of_birth" />
                                <FormDropdown options={gender} formik={formik}
                                    name={'gender'}
                                    autoFocus
                                    label={'Gender'} />
                                <FormInput type="date" label="From" formik={formik} name="from" />
                                <FormInput type="date" label="To" formik={formik} name="to" />
                                <FormSelect method={STUDENT.GET_SERVICES}
                                    label="Service" formik={formik}
                                    name="service" />
                            </SimpleGrid>
                        </SectionCard>
                        <SectionCard backgroundColor="bg-white" title="Official Information" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                                <FormInput label="Place of Work" formik={formik} name="place_of_work" />
                                <FormInput label="Present Designation" formik={formik} name="present_post" />
                                <MaskFormInput maskOption={{
                                    mask: '000 0000000',
                                }} label="Official Number" formik={formik} name="place_of_work_official_number" />
                                <FormTextArea label="Office Address" formik={formik} name="office_address" />
                            </SimpleGrid>
                        </SectionCard>
                        <SectionCard backgroundColor="bg-white" title="Contact Information" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                                <MaskFormInput maskOption={{
                                    mask: '000 0000000',
                                }} label="Mobile Number" formik={formik} name="mobile_number" />
                                <MaskFormInput maskOption={{
                                    mask: '000 0000000',
                                }} label="WhatsApp Number" formik={formik} name="whatsapp_number" />
                                <FormInput isRequired label="Email" formik={formik} name="email" />
                                <FormTextArea label="Personal Address" formik={formik} name="personal_address" />
                            </SimpleGrid>
                        </SectionCard>
                        <SectionCard creatable={creatable} title="Educational Qualification" p={3}>
                            <ChakraTable size={"sm"}
                                columns={EDUCATION_TABLE_COLUMNS}
                                data={_.get(formik.values, 'educational_qualifications', [])} />
                            <ChakraModal primaryAction={childFormik.submitForm} primaryButtonText={"Add"}
                                modalTitle={'Add new module'} {...disclosure}>
                                <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                                    <FormDropdown isRequired options={levels} formik={childFormik}
                                        name={'level'}
                                        autoFocus
                                        label={'Level'} />
                                    <FormInput isRequired label="Title" formik={childFormik} name="title" />
                                    <FormNumberInput type="number" max={2050} min={1900} isRequired label="Obtained Year" formik={childFormik} name="obtained_year" />
                                </SimpleGrid>
                            </ChakraModal>
                        </SectionCard>
                        <SectionCard creatable={workExperienceCreatable} title="Work Experience" p={3}>
                            <ChakraTable size={"sm"}
                                columns={WORK_EXPERIENCE_TABLE_COLUMNS}
                                data={_.get(formik.values, 'student_work_experience', [])} />
                            <ChakraModal primaryAction={workExperienceChildFormik.submitForm} primaryButtonText={"Add"}
                                modalTitle={selectRef.current ? 'Edit Work Experience' : 'Add new Work Experience'} {...workExperienceDisclosure} onClose={onCloseworkExperienceModal}>
                                <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                                    <FormInput isRequired label="Designation" formik={workExperienceChildFormik} name="designation" />
                                    <FormInput isRequired label="Work Place" formik={workExperienceChildFormik} name="work_place" />
                                    <FormInput type="date" isRequired label="From" formik={workExperienceChildFormik} name="from" />
                                    <FormInput type="date" isRequired label="To" formik={workExperienceChildFormik} name="to" />
                                </SimpleGrid>
                            </ChakraModal>
                            <PrintAttendanceCertificateModal student={id} disclosure={attendaceCertDisclosure} setLoading={setLoading} />
                        </SectionCard>

                    </TabPanel>
                    {/* <TabPanel className="!p-0 !pt-3">
                        <ProgramSummary />
                    </TabPanel> */}
                    {/* <TabPanel className="!p-0 !pt-3">
                        <ModuleSummary/>
                    </TabPanel> */}
                </TabPanels>
            </Tabs>
        </div>
    )
}

export default ViewStudentPage

const gender = [{ value: "Male", label: "Male" }, { value: "Female", label: "Female" }]

const salutation = [
    { value: "Mr", label: "Mr" },
    { value: "Ms", label: "Ms" },
    { value: "Rev", label: "Rev" },
    { value: "Dr", label: "Dr" },
    { value: "Prof", label: "Prof" },
    { value: "Eng", label: "Eng" },
    { value: "Comm", label: "Comm" }
]

const levels = [
    { value: "A/L", label: "A/L" },
    { value: "Diploma", label: "Diploma" },
    { value: "Bachelor's Degree", label: "Bachelor's Degree" },
    { value: "Master's Degree", label: "Master's Degree" },
    { value: "Doctoral Degree", label: "Doctoral Degree" },
]

const customComponents = {
    Option: ({ children, ...props }: any) => (
        <chakraComponents.Option {...props}>
            <div className="gap-2">
                <div className="">
                    {children}
                </div>
                <div className="italic flex  gap-3">
                    {props.data?.full_name}
                </div>

            </div>
        </chakraComponents.Option >
    )
};