import _ from "lodash"
import { Link } from "react-router-dom"
import ChakraTable, { ChakraTableColumns } from "../../../common/chakra-table/ChakraTable"

const ProgrammedModule = ({ data }: any) => {

    const MODULE_LIST_TABLE_COLUMNS: ChakraTableColumns = [
        {
            header: "Module Code",
            accessor: "code",
            width: 200,
            customRenderer(value, item) {
                return (
                    <Link className=" text-blue-400" to={`/app/program-module/${_.get(item, 'name')}`} >
                        {value}
                    </Link>
                )
            },
        },
        {
            header: "Name",
            accessor: "title",
        },
        {
            header: "Status",
            width: 250,
            accessor:
                "approved_status",
            customRenderer(value: any, item) {
                const className = (value == 5) ? 'bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded' :
                    'bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded'
                return (
                    <div>
                        <span className={className}>{_.get(statusByRole, value)}</span>
                    </div>
                )
            },
        },
        // {
        //     header: "Credit",
        //     accessor: "credit",
        // },
        {
            header: "Duration(hours)",
            accessor: "duration_hours",
        },
        // {
        //     header: "Type",
        //     accessor: "type"
        // },
    ]

    return (
        <ChakraTable size={"sm"} header
            columns={MODULE_LIST_TABLE_COLUMNS}
            data={data} />
    )
}

export default ProgrammedModule

const statusByRole = {
    0: "Draft",
    1: "Submitted to Registrar",
    2: "PENDING - MC",
    "-2": "Not Recommended",
    3: "PENDING - ADG",
    "-3": "REJECTED - MC",
    4: "PENDING - DG",
    "-4": "REJECTED - ADG",
    5: "Approved",
    "-5":'REJECTED - DG'

}