import _ from "lodash"
import StudentTab from "./StudentTab"
import { callMethod } from "../../../../actions"
import { useDispatch } from "react-redux"
import { ToastService } from "../../../../services/toast.service"
import { usePostMutation } from "../../../../services/api.service"
import { customeJsonParser } from "../../../../utils/utils"
import { MODULE_ENROLLMENT } from "../../../../consts/methods.consts"
import { useEffect, useState } from "react"
import { DROPDOWN_TAGS, MODULE_ENROLLMENT_TAGS } from "../../../../consts/tags.consts"
import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs, UseDisclosureProps, useToast } from "@chakra-ui/react"
interface UserSelectorProps {
    isOpen: UseDisclosureProps["isOpen"],
    onClose: UseDisclosureProps["onClose"] | undefined,
    setSelectedItems: any
    parentData: { program: string, programModule: string }
    selectedItems: any
}

const StudentSelector = ({ isOpen = false, onClose = () => { }, parentData }: UserSelectorProps) => {
    const [selected, setSelected] = useState<any>([]);
    const toast = new ToastService(useToast());
    const [create] = usePostMutation();
    const dispatch: any = useDispatch()

    useEffect(() => {
        if (isOpen == true) {
            dispatch(callMethod(MODULE_ENROLLMENT.GET_ENROLLED_STUDENTS, parentData, [MODULE_ENROLLMENT_TAGS.LIST])).then((data: any) => {
                setSelected([...data])
            })
        }

    }, [isOpen])

    const onSaveHandler = () => {

        if (_.isArray(selected) && selected?.length > 0) {
            create({
                method: MODULE_ENROLLMENT.ADD,
                body: { data: selected, ...parentData },
                invalidatesTags: [MODULE_ENROLLMENT_TAGS.VIEW, MODULE_ENROLLMENT_TAGS.LIST, DROPDOWN_TAGS.LIST]
            }).unwrap().then(() => {
                toast.setTitle("Success").setDescription("Enrollments has been created successfully.").showSuccessToast();
            }).catch((error: any) => {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            })
            onClose();
        } else {
            toast.setTitle("Warning").setStatus("warning").setDescription("Please select at least one student").show();
        }

    }

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            motionPreset='slideInBottom'
            onClose={onClose}
            size={"5xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Select Students</ModalHeader>
                <ModalCloseButton autoFocus={false} _focus={{ border: 'none' }} />
                <ModalBody pb={0}>
                    <StudentTab isOpen={isOpen} selected={selected} setSelected={setSelected} />
                </ModalBody>

                <ModalFooter>
                    <HStack spacing={3}>
                        <Button variant={"solid"} size={"sm"} w={20} colorScheme="teal" onClick={onSaveHandler}>Add</Button>
                        <Button variant={"solid"} size={"sm"} colorScheme="red" onClick={onClose}>Cancel</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent >
        </Modal >
    )
}


export default StudentSelector