import _ from "lodash";
import { PROGRAM } from "../../../../consts/methods.consts";
import apiService from "../../../../services/api.service";

export const getContactNumber = (organization: any, organization_contact: any) => (
    async (dispatch: any) => {
        try {
            const { data } = await dispatch(apiService.endpoints.get.initiate({
                method: PROGRAM.GET_CONTACT_NUMBER,
                body: {
                    organization: organization,
                    organization_contact: organization_contact
                },
                providesTags: []
            }, { forceRefetch: true }),)
            return _.get(data, 'message')

        } catch (e) {
            throw e
        }
    }
)