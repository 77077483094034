import _ from "lodash"
import { useFormik } from "formik"
import { useEffect } from "react"
import { FormInput } from "../../../../common/form-controllers/FormInput"
import { SimpleGrid } from "@chakra-ui/react"
import { useDispatch } from "react-redux"
import { SectionCard } from "../../../../common/section-card/SectionCard"
import { setLocalStoreModel } from "@dladio/hooks/dist/store/slices/local-store"

const PaymentDetails = ({paymentDetailsRef,initialValues}:any)=>{
    const dispatch: any = useDispatch()

    const formik = useFormik({
        initialValues: {},

        onSubmit: async (values: any) => {
            return values

        }
    })

    paymentDetailsRef.current = formik

    useEffect(() => {
        dispatch(setLocalStoreModel({ path: 'resource-person.payment_details', model: formik.values }))
    }, [formik.values])


    useEffect(() => {
        if (!_.isEmpty(initialValues)) {
            formik.setValues(initialValues)
        }

    }, [])

    return(
        <SectionCard backgroundColor="bg-white" title="Payment Details" p={3}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 3 }} spacing={3}>
            <FormInput label="Account Number" formik={formik} name="account_number" />
            <FormInput label="Bank Name" formik={formik} name="bank_name" />
            <FormInput label="Branch" formik={formik} name="branch" />
        </SimpleGrid>
    </SectionCard>
    )

}

export default PaymentDetails