import { SimpleGrid, useDisclosure, useToast } from "@chakra-ui/react";
import { chakraComponents } from "chakra-react-select";
import { useFormik } from "formik";
import _ from "lodash";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { ChakraModal, ChakraTable, PageHeader } from "../..";
import { ATTENDANCE } from "../../../consts/methods.consts";
import { ATTENDANCE_TAGS, DROPDOWN_TAGS } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { customeJsonParser } from "../../../utils/utils";
import { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import FormSelect from "../../common/form-controllers/FormSelect";
import { PageHeaderActions } from "../../common/page-header/PageHeader";
import { useTableDataProvider } from "../../hooks";


const AttendanceListPage = () => {
    const disclosure = useDisclosure()
    const toast = new ToastService(useToast())
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [create] = usePostMutation();
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchKey, setSearchKey]: any = useState()
    const [page, setPage] = useState(1)

    const { data, isLoading, isFetching } = useGetQuery({
        method: ATTENDANCE.LIST,
        body: {
            page: page,
            key: searchKey
        },
        providesTags: [ATTENDANCE_TAGS.LIST]
    })

    const { data: initData } = useGetQuery({
        method: ATTENDANCE.INIT,
        providesTags: [DROPDOWN_TAGS.LIST]
    })

    const dropDownData = _.get(initData, 'message', [])

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            session: Yup.string().required("Session is required"),
            user: Yup.string().required("Student is required"),
        }),
        onSubmit: async (values) => {
            try {
                await create({
                    method: isSelected ? ATTENDANCE.UPDATE : ATTENDANCE.ADD,
                    body: values,
                    invalidatesTags: [ATTENDANCE_TAGS.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription(`${isSelected ? "Attendance details have been saved." : "Attendance has been created successfully."}`).showSuccessToast();

            } catch (error: any) {
                let message = JSON.parse(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(JSON.parse(message)?.message).showErrorToast();
            } finally {
                disclosure.onClose();
                setIsSelected(false);
            }
        }
    })

    const ondeleteHandler = async (item: any) => {
        try {
            const res = await create({
                method: ATTENDANCE.DELETE,
                body: { "name": item?.name },
                invalidatesTags: [ATTENDANCE_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Attendance is deleted successfully.").showSuccessToast();

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            // let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            // if (message) toast.setTitle("Error").setDescription(JSON.parse(message)?.message).showErrorToast();
        }

    }

    const actions: PageHeaderActions = [
        {
            text: "Create New",
            confirmation: false,
            onClick: () => {
                setIsSelected(false)
                formik.resetForm();
                disclosure.onOpen()
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]
    const tableLineClickHandler = (line: any) => {
        // navigate(`${_.get(line, 'name')}`)
    }

    const ATTENDANCE_TABLE_COLUMNS: ChakraTableColumns = [
        {
            header: "Student",
            accessor: "student",
        },
        {
            header: "Session Topic",
            accessor: "session_title",
        },
        {
            header: "Actions",
            accessor: '',
            disabled: false,
            options: [
                {
                    label: "Edit",
                    confirmation: false,
                    onClick(item) {
                        formik.setFieldValue('user', _.get(item, 'user'))
                        formik.setFieldValue('name', _.get(item, 'name'))
                        formik.setFieldValue('session', _.get(item, 'session'))
                        setIsSelected(true)
                        disclosure.onOpen()
                    },
                },
                {
                    label: "Delete",
                    onClick: ondeleteHandler,
                }
            ]
        }
    ]

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }

    const onPage = (page:any)=>{
        setPage(page)
    }

    return (
        <div>
            <PageHeader subtitle="View and Manage Attendance" title={`Attendance`} actions={actions} />
            <ChakraTable onPage={onPage} onSearch={onSearchHandler} paginationDetails={paginationDetails} isLoading={isLoading} isFetching={isFetching} pagination size={"sm"} header title="Attendance"
                onSelected={tableLineClickHandler} columns={ATTENDANCE_TABLE_COLUMNS} data={dataList} />
            <ChakraModal primaryAction={formik.submitForm} primaryButtonText={"Save"} modalTitle={`${isSelected ? 'Edit Attendance' : 'New Attendance'}`} {...disclosure}>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                    <FormSelect options={_.get(dropDownData, 'students', [])}
                        label="Student" formik={formik}
                        name="user" />
                    <FormSelect components={customComponents} options={_.get(dropDownData, 'session', [])} isRequired formik={formik} name={'session'}
                        label={'Session'} />
                </SimpleGrid>
            </ChakraModal>
        </div>
    )
}

export default AttendanceListPage

const customComponents = {
    Option: ({ children, ...props }: any) => (
        <chakraComponents.Option {...props}>
            <div className="gap-2">
                <div className=" font-bold">
                    {children}


                </div>
                <div className=" italic flex  gap-3">
                    <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                        {props?.data?.code}
                    </span>
                    {/* {props.data.group && (<span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                        {props?.data?.group}
                    </span>)} */}
                </div>
            </div>
        </chakraComponents.Option >
    )
};