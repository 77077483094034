import _ from "lodash";
import { StatCard } from "../..";
import { DASHBOARD } from "../../../consts/methods.consts";
import { SimpleGrid } from "@chakra-ui/react";
import { useGetQuery } from "../../../services/api.service";
import StudentByYearChart from "./components/StudentByYearChart";
import { useSelector } from "react-redux";
import OtherDashboard from "./dashboards/OtherDashboard";
import ConsultantDashboard from "./dashboards/ConsultantDashboard";

const HomePage = () => {
	const user = useSelector((state: any) => _.get(state, 'common.user'))

	const { data, isLoading } = useGetQuery({
		method: DASHBOARD.GET_STAT,
		providesTags: []
	}, { refetchOnMountOrArgChange: true })

	const stats: any = _.get(data, 'message', [])


	// if (_.get(user, 'roles', []).some((role: any) => ["Consultant"].includes(role))) {
	// 	return (
	// 		<ConsultantDashboard/>
	// 	)
	// }

	return (
		<OtherDashboard/>
	)


}

export default HomePage;
