import { Button, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import * as Yup from "yup";
import { COMMENTS } from "../../../consts/methods.consts";
import { usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { customeJsonParser } from "../../../utils/utils";
import { FormTextArea } from "../form-controllers/FormTextArea";

const NewComment = ({ doctype, id, tag }: any) => {
    const toast = new ToastService(useToast());
    const [create ,{isLoading}] = usePostMutation();

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            comment: Yup.string().required("Comment is required"),
        }),
        onSubmit: async (values: any) => {
            try {
                await create({
                    method: COMMENTS.CREATE,
                    body: { doctype, id, data: values.comment },
                    invalidatesTags: [tag]
                }).unwrap();

                toast.setTitle("Success").setDescription("Comment have been saved").showSuccessToast();

                formik.resetForm()

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const onClickSave = () => {
        formik.submitForm()
    }


    return (
        <div className=" space-y-3">
            <FormTextArea rows={7} isRequired label="Comment" formik={formik} name="comment" />
            <Button isDisabled={isLoading} onClick={onClickSave} colorScheme="gray" size={'sm'}>Save</Button>
        </div>
    )
}

export default NewComment