import _ from "lodash"
import { useGetQuery } from "../../../services/api.service"
import { PROGRAMMED_MODULE } from "../../../consts/methods.consts"
import { useTableDataProvider } from "../../hooks"
import { PROGRAMMED_MODULE_TAGS } from "../../../consts/tags.consts"
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader"
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable"
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom"


const MODULE_LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        header: "Program",
        accessor: "program",
    },
    {
        header: "Title",
        accessor: "title",
    },
    {
        header: "Lecturer",
        accessor: "lecturer",
    },
    {
        header: "Start Date",
        accessor: "start_date",
    },
    {
        header: "End Date",
        accessor: "end_date",
    }
]


const ProgrammedModuleListPage = () => {

    const navigate: NavigateFunction = useNavigate()
    const [searchParams] = useSearchParams()

    const { data, isLoading, isFetching } = useGetQuery({
        method: PROGRAMMED_MODULE.LIST,
        body: {
            page: Number(searchParams.get("page"))
        },
        providesTags: [PROGRAMMED_MODULE_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const actions: PageHeaderActions = [

        {
            text: "Create New",
            onClick: () => navigate("new"),
            confirmation:false,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const tableLineClickHandler = (line: any) => {
        navigate(`${_.get(line, 'name')}`)
    }

    return (
        <div>
            <PageHeader subtitle="View and Manage Program Module" title={`Program Module`} actions={actions} />
            <ChakraTable isFetching={isFetching} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} header title="Program Modules"
                onSelected={tableLineClickHandler} columns={MODULE_LIST_TAB_COLUMNS} data={dataList} />
        </div>
    )
}

export default ProgrammedModuleListPage