import { FormControl, Radio, RadioProps, VStack } from "@chakra-ui/react";

type MutatedTypes = Omit<RadioProps, 'onChange' | 'name' | 'isRequired' | 'isInvalid' | 'defaultValue'>

export interface FormRadioProps extends MutatedTypes {
    containerClassNames?: string,
    formik: any,
    name: string,
    label: string,
}

export const FormRadioButton = ({ rounded = "md", size = "md", label, formik, name, containerClassNames = '', ...restProps }: FormRadioProps) => {

    return (
        <FormControl display={'flex'} alignItems={"end"} className={containerClassNames}>
            <VStack alignItems={"flex-start"}>
                    <Radio style={{ fontSize: '13px' }} size={size} {...restProps} name={name} onChange={formik.handleChange} label={'label'}>{label}</Radio>
            </VStack>
        </FormControl>
    )
}