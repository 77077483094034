import _ from "lodash";
import * as Yup from "yup";
import ChakraModal from "../../../../common/chakra-modal/ChakraModal";
import { useState } from "react";
import { useFormik } from "formik";
import { FormInput } from "../../../../common/form-controllers/FormInput";
import { SimpleGrid, useDisclosure } from "@chakra-ui/react";
import ChakraTable, { ChakraTableColumns } from "../../../../common/chakra-table/ChakraTable";
import { SectionCard, SectionCardCreatable } from "../../../../common/section-card/SectionCard";

const AcademicQualification = ({ academicQualificationRef }: any) => {
    const disclosure = useDisclosure()
    const [tableData, setTableData]: any = useState([])

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            qualification_level: Yup.string().required("Qualification Level is required"),
            country: Yup.string().required("Country is required"),
            university: Yup.string().required("University is required"),
            year_qualified: Yup.string().required("Year Qualified is required"),
            qualification_class: Yup.string().required("Qualification Class is required"),
            duration: Yup.string().required("Duration is required"),
            qualification_type: Yup.string().required("Qualification Type is required")
        }),

        onSubmit: async (values: any) => {
            setTableData([values, ..._.isArray(tableData) ? tableData : []])
            disclosure.onClose()
            formik.resetForm()
        }
    })

    academicQualificationRef.current = tableData

    const onDeleteAcadamicQualification = (item: any) => {
        const newItems = tableData.filter((i: any) => (
            i !== item
        ))
        setTableData(newItems)
    }

    const acadamicQualificationTableActions = {
        header: "Action",
        accessor: "",
        width: 100,
        options: [
            {
                label: "Delete",
                onClick: onDeleteAcadamicQualification,
            }
        ]
    }

    const acadamicQualificationCreatable: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            disclosure.onOpen();
        }
    }

    return (
        <SectionCard creatable={acadamicQualificationCreatable} title="Academic Qualification" p={3}>
            <ChakraTable size={"sm"} searchable={false}
                columns={[...ACADAMIC_QUALIFICATIONS, acadamicQualificationTableActions]}
                data={tableData} />
            <ChakraModal primaryAction={formik.submitForm} primaryButtonText={"Add"}
                modalTitle={'Academic Qualification'} {...disclosure}>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                    <FormInput isRequired label="Qualification Level" formik={formik} name="qualification_level" />
                    <FormInput isRequired label="Country" formik={formik} name="country" />
                    <FormInput isRequired label="University" formik={formik} name="university" />
                    <FormInput isRequired label="Year Qualified" formik={formik} name="year_qualified" />
                    <FormInput isRequired label="Qualification Class" formik={formik} name="qualification_class" />
                    <FormInput isRequired label="Duration" formik={formik} name="duration" />
                    <FormInput isRequired label="Qualification Type" formik={formik} name="qualification_type" />
                </SimpleGrid>
            </ChakraModal>

        </SectionCard>
    )
}

export default AcademicQualification

const ACADAMIC_QUALIFICATIONS: ChakraTableColumns = [
    {
        header: "Qualification Level",
        accessor: "qualification_level",
    },
    {
        header: "Country",
        accessor: "country",
    },
    {
        header: "University",
        accessor: "university",
    },
    {
        header: "Year Qualified",
        accessor: "year_qualified",
    },
    {
        header: "Qualification Class",
        accessor: "qualification_class",
    },
    {
        header: "Duration",
        accessor: "duration",
    },
    {
        header: "Qualification Type",
        accessor: "qualification_type",
    }
]
