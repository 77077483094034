import PageHeader, { PageHeaderActions } from "../../../../common/page-header/PageHeader"

const ResourcePersonHeader = ({ onSubmit }: any) => {

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: onSubmit,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    return (
        <PageHeader enableBackButton actions={actions} title={'Create New Resource Person'}
            subtitle={'Resource Person'} />

    )
}

export default ResourcePersonHeader