import _ from "lodash";
import * as Yup from "yup";
import ChakraModal from "../../../../common/chakra-modal/ChakraModal";
import { FormInput } from "../../../../common/form-controllers/FormInput";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { setLocalStoreModel } from "@dladio/hooks/dist/store/slices/local-store";
import { useEffect, useState } from "react";
import { SimpleGrid, useDisclosure } from "@chakra-ui/react";
import ChakraTable, { ChakraTableColumns } from "../../../../common/chakra-table/ChakraTable";
import { SectionCard, SectionCardCreatable } from "../../../../common/section-card/SectionCard";

const ProfessionalQualification = ({ professionalQualificationRef, initialValues }: any) => {

    const disclosure = useDisclosure()
    const [tableData, setTableData]: any = useState([])
    const dispatch: any = useDispatch()

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            institute: Yup.string().required("Institute Level is required"),
            qualification: Yup.string().required("Qualification is required"),
            description: Yup.string().required("University is required"),
            year_qualified: Yup.string().required("Year Qualified is required")
        }),
        onSubmit: async (values: any) => {
            setTableData([values, ..._.isArray(tableData) ? tableData : []])
            disclosure.onClose()
            formik.resetForm()
        }
    })

    useEffect(() => {
        if (!_.isEmpty(initialValues)) {
            setTableData(initialValues)
        }
    }, [initialValues])

    useEffect(() => {
        dispatch(setLocalStoreModel({ path: 'resource-person.professional_qualifications', model: tableData }))
    }, [tableData])

    professionalQualificationRef.current = tableData

    const onDeleteProfessionalQualification = (item: any) => {
        const newItems = tableData.filter((i: any) => (
            i !== item
        ))
        setTableData(newItems)
    }

    const professionalQualificationTableActions = {
        header: "Action",
        accessor: "",
        width: 100,
        options: [
            {
                label: "Delete",
                onClick: onDeleteProfessionalQualification,
            }
        ]
    }

    const professionalQualificationCreatable: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            disclosure.onOpen();
        }
    }

    return (
        <SectionCard creatable={professionalQualificationCreatable} title="Professional Qualification" p={3}>
            <ChakraTable size={"sm"} searchable={false}
                columns={[...PROFESSIONAL_QUALIFICATIONS, professionalQualificationTableActions]}
                data={tableData} />
            <ChakraModal primaryAction={formik.submitForm} primaryButtonText={"Add"}
                modalTitle={'Add Professional Qualification'} {...disclosure}>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                    <FormInput isRequired label="Institute" formik={formik} name="institute" />
                    <FormInput isRequired label="Qualification" formik={formik} name="qualification" />
                    <FormInput isRequired label="Description" formik={formik} name="description" />
                    <FormInput isRequired label="Year Qualified" formik={formik} name="year_qualified" />
                </SimpleGrid>
            </ChakraModal>
        </SectionCard>
    )
}

export default ProfessionalQualification

const PROFESSIONAL_QUALIFICATIONS: ChakraTableColumns = [
    {
        header: "Institute",
        accessor: "institute",
    },
    {
        header: "Qualification",
        accessor: "qualification",
    },
    {
        header: "Description",
        accessor: "description",
    },
    {
        header: "Year Qualified",
        accessor: "year_qualified",
    }

]