import { Button, SimpleGrid, useDisclosure, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { callMethod, postMutation } from "../../../actions";
import { DATAIMPORT, ORGANIZATION } from "../../../consts/methods.consts";
import { DATA_IMPORT_TAG, DROPDOWN_TAGS, ORGANIZATION_TAGS } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import ChakraModal from "../../common/chakra-modal/ChakraModal";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { FormInput } from "../../common/form-controllers/FormInput";
import InnerLoading from "../../common/inner-loading/InnterLoading";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { SectionCard, SectionCardCreatable } from "../../common/section-card/SectionCard";
import { useObjChange } from "../../hooks";
import { customeJsonParser } from "../../../utils/utils";

const ViewImportDataPage = () => {
    const { id } = useParams()
    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());
    const disclosure = useDisclosure()
    const dispatch: any = useDispatch()
    const [create] = usePostMutation();
    const [file, setFile]: any = useState(null);

    const { data: _data, isLoading, isFetching } = useGetQuery({
        method: DATAIMPORT.GET,
        body: { id },
        providesTags: [DATA_IMPORT_TAG.VIEW]
    })

    const data = _.get(_data, 'message.doc', {})
    const dataTable = _.get(_data, 'message.other.data_table', [])
    const dataTableColumn = _.get(_data, 'message.other.data_table_columns', [])
    const logs = _.get(_data, 'message.other.import_log', [])


    const stringifiedData = JSON.stringify(data)

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
        }),
        onSubmit: async (values: any) => {

        }
    })

    const deleteHandler = async () => {
        try {
            await create({
                method: DATAIMPORT.DELETE,
                body: { id },
                invalidatesTags: [DATA_IMPORT_TAG.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Data import is deleted successfully.").showSuccessToast();

            setTimeout(() => {
                navigate(`/app/data-import`);
            }, 1500)

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        }
    }

    const { isChanged, setInitialObj } = useObjChange(formik.values)

    const startUploadHandler = () => {
        dispatch(callMethod(DATAIMPORT.START_IMPORT, {
            id
        })).then(() => {
            toast.setTitle("Succsess").setDescription("Uploading started").showSuccessToast();

        }).catch((error: any) => {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        })

    }

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
        }
    }, [stringifiedData])

    const actions: PageHeaderActions = [
        {
            text: "Start Upload",
            isDisabled: (_.get(data, 'status') !== 'Pending' || !_.get(data, 'import_file', false)),
            onClick: startUploadHandler,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Delete",
            onClick: deleteHandler,
            buttonVariant: "solid",
            buttonColorScheme: "red"
        }
    ]

    const creatable: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            disclosure.onOpen();
        }
    }

    if (isLoading) {
        return (
            <>
                <PageHeader isLoading={isLoading} enableBackButton actions={actions} title={'Organization'} />
                <InnerLoading />
            </>
        )
    }

    const clearFileHandler = () => {
        dispatch(postMutation(DATAIMPORT.REMOVE_ATTCHMENT, {
            id,
            file: _.get(data, 'file', '')
        }, [DATA_IMPORT_TAG.VIEW])).then(() => {
            toast.setTitle("Succsess").setDescription("File Removed").showSuccessToast();

        }).catch((error:any)=>{
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        })
    }

    const onclickAttach = () => {
        disclosure.onOpen();
    }

    const handleFileChange = (e: any) => {
        setFile(e.target.files[0]);
    };

    const colourByStatus: any = {
        "Success": 'green',
        "Pending": 'red'
    }
    console.log(file, 'file')

    const onSumbitAttachment = async () => {
        if (file && _.get(file, 'type') == "text/csv") {
            let formData = new FormData();
            formData.append('file', file);
            formData.append('is_private', '1');
            formData.append('folder', 'Home');
            formData.append('doctype', 'Data Import');
            // @ts-ignore
            formData.append('docname', id);
            formData.append('fieldname', 'import_file');

            dispatch(postMutation(DATAIMPORT.ADD_ATTACHMENT, formData, [DATA_IMPORT_TAG.VIEW])).then(() => {
                disclosure.onClose()
                toast.setTitle("Succsess").setDescription("File attached.").showSuccessToast();

            }).catch((error:any) => {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            })

        } else {
            toast.setTitle("Error").setDescription("Please select a valid file").setStatus('warning').show();
        }

    }

    return (
        <div>
            <PageHeader backButtonCofirmation={isChanged} status={_.get(data, 'status')} statusColorSchemes={colourByStatus[_.get(data, 'status')]} isLoading={isLoading} enableBackButton actions={actions} title={_.get(data, 'name', '')}
                subtitle={'Data Import'} />
            <SectionCard backgroundColor="bg-white" title="Basic Information" p={3}>
                <SimpleGrid className={'rounded p-3'} columns={{ base: 1, md: 1, lg: 2, xl: 2 }} spacing={3}>
                    <div className="flex justify-center items-end gap-2">
                        <FormInput isDisabled isRequired label="File Path" formik={formik} name="import_file" />
                        {(_.get(data, 'status') === 'Pending') && (_.get(formik.values, 'import_file', false) ? (<Button colorScheme={"blackAlpha"} size={"sm"} onClick={clearFileHandler}>
                            Clear
                        </Button>) : (
                            <Button colorScheme={"blackAlpha"} size={"sm"} onClick={onclickAttach}>
                                Attach
                            </Button>
                        ))}
                    </div>

                </SimpleGrid>
            </SectionCard>
            <ChakraModal primaryAction={onSumbitAttachment} primaryButtonText={"Save"} modalTitle={`${'Edit Attendance'}`} {...disclosure}>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                    <input type="file" accept=".csv" placeholder="Document Name" onChange={handleFileChange} />
                </SimpleGrid>
            </ChakraModal>
            <SectionCard backgroundColor="bg-white" title="Preview" p={3}>
                <ChakraTable maxH={300} isLoading={isLoading} size={"sm"}
                    columns={dataTableColumn} data={dataTable} />
            </SectionCard>
            <SectionCard backgroundColor="bg-white" title="Import Logs" p={3}>
                <ChakraTable maxH={300} isLoading={isLoading} size={"sm"}
                    columns={IMPORT_LOGS_COLUMNS} data={logs} />
            </SectionCard>
        </div>
    )
}

export default ViewImportDataPage

const IMPORT_LOGS_COLUMNS = [
    {
        header: "Row Index",
        accessor: "row_indexes",
        width: 200
    },
    {
        header: "Status",
        accessor: "success",
        customRenderer: (text: any) => {
            return (
                <div >
                    <span style={{ backgroundColor: `${text ? 'green' : 'red'}` }} className="text-xs text-white font-medium mr-2 px-2.5 py-0.5 rounded">
                        {text ? 'Succsess' : 'Failed'}
                    </span>

                </div>
            )
        }
    },
]