import _, { get } from "lodash"
import React, { useState } from "react"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { AMENDMENTS } from "../../../consts/methods.consts"
import { AMENDMENT_TAG } from "../../../consts/tags.consts"
import { useGetQuery } from "../../../services/api.service"
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable"
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader"
import { useTableDataProvider } from "../../hooks"
import { DefaultCell } from "../report/components/cells"

const AmendmentRequestHistoryPage = () => {
    const navigate: NavigateFunction = useNavigate()
    const [page, setPage] = useState(1)
    const [searchKey, setSearchKey]: any = useState()
    const [length, setLength] = useState(15)

    const PROGRAM_LIST_COLUMNS: ChakraTableColumns = [
        {
            header: "ID",
            width: 200,
            accessor:
                "name",
            customRenderer: (value: any, item: any) => (
                React.createElement(DefaultCell, { navigateTo: `/app/amendment-request/${_.get(item, 'name')}`, value: value })
            ),
        },
        {
            header: "Document",
            width: 500,
            accessor:
                "document_title",
            customRenderer: (value: any, item: any) => (
                React.createElement(DefaultCell, { navigateTo: get(item, 'navigate_to'), value: value })
            ),
        },
        {
            header: "type",
            width:200,
            accessor:
                "type",
        },
        {
            header: "Workflow State",
            width:100,
            customRenderer(value, item: any) {
                return (
                    <div className={'max-w-[200px] truncate'}>
                        <span className={`${get(colourByStatus, get(item, 'approved_status'))} px-2.5 py-0.5 max-w-[200px] green text-xs font-medium me-2 rounded truncate`}>{get(statusByAmendmentStatus,get(item, 'approved_status'))}</span>
                    </div>
                )
            },
            accessor:
                "workflow_state",
        },

    ]

    const { data, isLoading, isFetching } = useGetQuery({
        method: AMENDMENTS.GET_AMENDMENTS_HISTORY,
        body: {
            page: page,
            length: Number(length),
            key: searchKey
        },
        providesTags: [AMENDMENT_TAG.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const actions: PageHeaderActions = [

    ]

    const tableLineClickHandler = (line: any) => {
        navigate(`${_.get(line, 'name')}`)
    }

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }

    const onLengthChange = (length: any) => {
        setPage(1)
        setLength(Number(length))
    }

    const onPage = (page: any) => {
        setPage(page)
    }

    return (
        <div>
            <PageHeader subtitle="View and Manage Amendment Request" title={`Amendment Request History`} actions={actions} />
            <ChakraTable onPage={onPage} onLength={onLengthChange} lengthPaginations isFetching={isFetching} onSearch={onSearchHandler} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} header
                title="Amendment Requests"
                onSelected={tableLineClickHandler} columns={PROGRAM_LIST_COLUMNS} data={dataList} />
        </div>
    )
}

export default AmendmentRequestHistoryPage


const colourByStatus = {
    0: "bg-green-200",
    1: "bg-green-200",
    "-1": "bg-red-200",
    2: "bg-green-200",
    "-2": "bg-red-200",
    3: "bg-green-200",
    "-3": "bg-red-200",
    "-4":"bg-green-200",
    5:"bg-gray-200"

}

const statusByAmendmentStatus = {
    0: "Revision Request Pending – Registrar",
    1: "Revision Request Pending – MC",
    "-1": "Revision Request Not Recommended – Registrar",
    2: "Revision Request Pending – ADG",
    "-2": "Revision Request Not Recommended – MC",
    3: "Revision Request Approved– ADG",
    "-3": "Revision Request Rejected– ADG",
    "-4": "Revised",
    "5": "No Revision"
}