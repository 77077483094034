import _ from "lodash"
import { useGetQuery } from "../../../services/api.service"
import { ORGANIZATION } from "../../../consts/methods.consts"
import { ORGANIZATION_TAGS } from "../../../consts/tags.consts"
import { useTableDataProvider } from "../../hooks"
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader"
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable"
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom"
import { useState } from "react"

const ORGANIZATION_COLUMNS: ChakraTableColumns = [
    {
        header: "Name",
        accessor: "title",
    }
]

const OrganizationListPage = () => {
    const navigate: NavigateFunction = useNavigate()
    const [page, setPage] = useState(1)
    const [searchParams, setSearchParams] = useSearchParams()
    const [searchKey, setSearchKey]: any = useState()

    const { data, isLoading, isFetching } = useGetQuery({
        method: ORGANIZATION.LIST,
        body: {
            page: page,
            key: searchKey
        },
        providesTags: [ORGANIZATION_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const actions: PageHeaderActions = [

        {
            text: "Create New",
            onClick: () => navigate("new"),
            confirmation: false,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const tableLineClickHandler = (line: any) => {
        navigate(`${_.get(line, 'name')}`)
    }

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }

    const onPage = (page: any) => {
        setPage(page)
    }

    return (
        <div>
            <PageHeader subtitle="View and Manage Organizations" title={`Organization`} actions={actions} />
            <ChakraTable onPage={onPage} onSearch={onSearchHandler} paginationDetails={paginationDetails} isLoading={isLoading} isFetching={isFetching} pagination size={"sm"} header title="Organizations"
                onSelected={tableLineClickHandler} columns={ORGANIZATION_COLUMNS} data={dataList} />
        </div>
    )
}

export default OrganizationListPage