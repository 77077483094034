import React, { useState, useEffect } from "react";
import _ from "lodash";
import moment from "moment";
import { CALENDAR } from "../../../../consts/methods.consts";
import { useGetQuery } from "../../../../services/api.service";
import ChakraTable, { ChakraTableColumns } from "../../../common/chakra-table/ChakraTable";
import { CALENDAR_TAGS } from "../../../../consts/tags.consts";
import { Link } from "react-router-dom";
import CellDateTimeFormatter from "./CellDateTimeFormatter";

const WeekTable = ({ date }: { date: Date }) => {


    const [filter, setFilter] = useState({
        startDateOfMonth: moment(date).startOf('week').format('YYYY-MM-DD'),
        endDateOfMonth: moment(date).endOf('week').format('YYYY-MM-DD')
    });

    useEffect(() => {
        setFilter({
            startDateOfMonth: moment(date).startOf('week').format('YYYY-MM-DD'),
            endDateOfMonth: moment(date).endOf('week').format('YYYY-MM-DD')
        });
    }, [date]);

    const { data: _data } = useGetQuery({
        method: CALENDAR.SESSIONS,
        body: {
            filter: {
                startDateOfMonth: filter.startDateOfMonth,
                endDateOfMonth: filter.endDateOfMonth
            }
        },
        providesTags: [CALENDAR_TAGS.EVENTS]
    });

    const sessions = _.get(_data, ['message'], []);

    const sortedSessions = _.sortBy(sessions, (session) => new Date(session.start));



    return (
        <div className="pt-4">
            <ChakraTable
                size="sm"
                columns={WEEK_TABLE_COLUMN}
                data={sortedSessions}
            />
        </div>
    );
};


WeekTable.title = (date: Date) => {
    const start = moment(date).startOf('week');
    const end = moment(date).endOf('week');
    return `${start.format('MMMM D')} - ${end.format('MMMM D, YYYY')}`;
};

WeekTable.navigate = (date: Date, action: string) => {
    switch (action) {
        case 'PREV':
            return moment(date).subtract(1, 'week').toDate();
        case 'NEXT':
            return moment(date).add(1, 'week').toDate();
        default:
            return date;
    }
};

const sessionNameColor = (value: any, item: any) => {

    let backgroundColor = '';
    let color = '#000000'

    if (item.session_type === 'Individual') {
        backgroundColor = '#9FD4FA';
    } else if (item.session_type === 'Combine') {
        backgroundColor = '#FFEAC2';
    }

    return (
        <Link to={item.navigate_session}>
            <div className="px-2 rounded text-sm " style={{ backgroundColor, color }}>
                {value}
            </div>
        </Link>
    );
};


const WEEK_TABLE_COLUMN: ChakraTableColumns = [
    {
        header: "Start Time",
        accessor: "start",
        customRenderer: CellDateTimeFormatter,
        width: 100,
    },
    {
        header: "End Time",
        accessor: "end",
        customRenderer: CellDateTimeFormatter,
        width: 100,
    },
    {
        header: "Session Topic",
        accessor: "title",
        customRenderer: sessionNameColor,
        width: 350
    }
];

export default WeekTable;