import _ from "lodash"
import {useState} from "react"
import {NavigateFunction, useNavigate} from "react-router-dom"
import {RESERVED_CLASSROOM} from "../../../consts/methods.consts"
import {RESERVED_CLASSROOM_TAG} from "../../../consts/tags.consts"
import {useGetQuery} from "../../../services/api.service"
import ChakraTable, {ChakraTableColumns} from "../../common/chakra-table/ChakraTable"
import PageHeader, {PageHeaderActions} from "../../common/page-header/PageHeader"
import {useTableDataProvider} from "../../hooks"

const ReservedClassroomListPage = () => {
    const navigate: NavigateFunction = useNavigate()
    const [page, setPage] = useState(1)
    const [searchKey, setSearchKey]: any = useState()
    const [length, setLength] = useState(15)

    const PROGRAM_LIST_COLUMNS: ChakraTableColumns = [
        {
            header: "Class Room",
            accessor:
                "class_name",
        },
        {
            header: "Start",
            accessor:
                "formatted_from",

        },
        {
            header: "End",
            accessor:
                "formatted_to",
        },
        {
            header: "Reserved By",
            accessor:
                "reserved_by",
        },
        {
            header: "Contact Number",
            accessor:
                "contact_number",
        },
        // {
        //     header: "Email",
        //     accessor:
        //         "email",
        // }
    ]

    const {data, isLoading, isFetching} = useGetQuery({
        method: RESERVED_CLASSROOM.GET_LIST,
        body: {
            page: page,
            length: Number(length),
            key: searchKey
        },
        providesTags: [RESERVED_CLASSROOM_TAG.LIST]
    })

    const {dataList, paginationDetails} = useTableDataProvider(data);

    const actions: PageHeaderActions = [

        {
            text: "Create New",
            onClick: () => navigate("new"),
            confirmation: false,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const tableLineClickHandler = (line: any) => {
        navigate(`${_.get(line, 'name')}`)
    }

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }

    const onLengthChange = (length: any) => {
        setPage(1)
        setLength(Number(length))
    }

    const onPage = (page: any) => {
        setPage(page)
    }

    return (
        <div>
            <PageHeader subtitle="View and Manage Class Room Reservation" title={`Class Room Reservation`}
                        actions={actions}/>
            <ChakraTable onPage={onPage} onLength={onLengthChange} lengthPaginations isFetching={isFetching}
                         onSearch={onSearchHandler} paginationDetails={paginationDetails} isLoading={isLoading}
                         pagination size={"sm"} header
                         title="Class Room Reservations"
                         onSelected={tableLineClickHandler} columns={PROGRAM_LIST_COLUMNS} data={dataList}/>
        </div>
    )
}

export default ReservedClassroomListPage