import _ from "lodash";
import * as Yup from "yup";
import ChakraModal from "../../common/chakra-modal/ChakraModal";
import { useState } from "react";
import { FormInput } from "../../common/form-controllers/FormInput";
import { useFormik } from "formik";
import { CLASS_ROOM } from "../../../consts/methods.consts";
import { ToastService } from "../../../services/toast.service";
import { useTableDataProvider } from "../../hooks";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { CLASS_ROOM_TAGS, DROPDOWN_TAGS } from "../../../consts/tags.consts";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { SimpleGrid, useDisclosure, useToast } from "@chakra-ui/react";
import { customeJsonParser } from "../../../utils/utils";

const ClassRoomListPage = () => {
    const disclosure = useDisclosure()
    const [page, setPage] = useState(1)
    const toast = new ToastService(useToast())
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [create] = usePostMutation();

    const { data, isLoading } = useGetQuery({
        method: CLASS_ROOM.LIST,
        body: {
            page: page,
        },
        providesTags: [CLASS_ROOM_TAGS.LIST]
    })

    const { data: initData } = useGetQuery({
        method: CLASS_ROOM.INIT,
        providesTags: [DROPDOWN_TAGS.LIST]
    })

    const dropDownData = _.get(initData, 'message', [])

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            class_name: Yup.string().required("Class Name is required"),
        }),
        onSubmit: async (values) => {
            try {
                await create({
                    method: isSelected ? CLASS_ROOM.UPDATE : CLASS_ROOM.ADD,
                    body: values,
                    invalidatesTags: [CLASS_ROOM_TAGS.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription(`${isSelected ? "Class room details have been saved." : "Class room has been created successfully."}`).showSuccessToast();

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            } finally {
                disclosure.onClose();
                setIsSelected(false);
            }
        }
    })

    const ondeleteHandler = async (item: any) => {
        try {
            const res = await create({
                method: CLASS_ROOM.DELETE,
                body: { "name": item?.name },
                invalidatesTags: [CLASS_ROOM_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Class room is deleted successfully.").showSuccessToast();

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        }

    }

    const actions: PageHeaderActions = [
        {
            text: "Create New",
            confirmation: false,
            onClick: () => {
                setIsSelected(false)
                formik.resetForm();
                disclosure.onOpen()
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]
    const tableLineClickHandler = (line: any) => {
        // navigate(`${_.get(line, 'name')}`)
    }

    const ATTENDANCE_TABLE_COLUMNS: ChakraTableColumns = [
        {
            header: "Code",
            accessor: "name",
        },
        {
            header: "Class Name",
            accessor: "class_name",
        },
        {
            header: "Actions",
            accessor: '',
            disabled: false,
            options: [
                {
                    label: "Edit",
                    confirmation: false,
                    onClick(item) {
                        formik.setFieldValue('class_name', _.get(item, 'class_name'))
                        formik.setFieldValue('name', _.get(item, 'name'))
                        setIsSelected(true)
                        disclosure.onOpen()
                    },
                },
                {
                    label: "Delete",
                    onClick: ondeleteHandler,
                }
            ]
        }
    ]

    const onPage = (page: any) => {
        setPage(page)
    }

    return (
        <div>
            <PageHeader subtitle="View and Manage Class Room" title={`Class Room`} actions={actions} />
            <ChakraTable onPage={onPage} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} header title="Class Room"
                onSelected={tableLineClickHandler} columns={ATTENDANCE_TABLE_COLUMNS} data={dataList} />
            <ChakraModal primaryAction={formik.submitForm} primaryButtonText={"Save"} modalTitle={`${isSelected ? 'Edit Class Room' : 'New Class Room'}`} {...disclosure}>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                    <FormInput isRequired formik={formik} name={'class_name'}
                        autoFocus
                        label={'Class Name'} />
                </SimpleGrid>
            </ChakraModal>
        </div>
    )
}

export default ClassRoomListPage