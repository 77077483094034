import { Box, Step, StepDescription, StepIndicator, StepSeparator, StepTitle, Stepper, Text } from "@chakra-ui/react"
import _ from "lodash"
import moment from "moment"
import { GoComment } from "react-icons/go"
import { COMMENTS } from "../../../consts/methods.consts"
import { useGetQuery } from "../../../services/api.service"

const CommentSelection = ({ doctype, doc, tag }: any) => {

    const { data: _data, isLoading } = useGetQuery({
        method: COMMENTS.GET_LIST,
        body: { id: doc, doctype },
        providesTags: [tag]
    })

    const data = _.get(_data, 'message', [])

    return (
        <div className="mt-8 h-auto flex justify-center">
            <Stepper mb={100} index={-1} orientation='vertical' gap='0'>
                {data.map((item: any, index: number) => (
                    <Step className="!h-[50px]" key={index} >
                        <StepIndicator>
                            <GoComment />
                        </StepIndicator>

                        <Box mb={'200px'} flexShrink='0'>
                            <CommentForm item={item} tag={tag} />
                        </Box>

                        <StepSeparator />
                    </Step>
                ))}
            </Stepper>
        </div>

    )
}

export default CommentSelection


const CommentForm = ({ item }: any) => {

    return (
        <>
            <StepTitle className="flex gap-3">{item?.comment_by + ' Commented'}
                {/* <Button onClick={onClickSave} colorScheme="gray" isDisabled={!isChanged} size={'xs'}>Save</Button>
                <Button onClick={onDeleteHandler} colorScheme="gray" size={'xs'}>Delete</Button> */}
            </StepTitle>
            <StepDescription>
                <Box width={{ base: 300, md: 400, lg: 500 }}>
                    <Text className="text-gray-700 ">{moment(item?.creation).format('YYYY-MM-DD')}</Text>
                    <Text  mt={2} className="text-gray-500 line-clamp-[4]">{item?.content}</Text>
                </Box>
            </StepDescription>
        </>

    )
}