import _ from 'lodash'
import { FormControl, FormErrorMessage, FormLabel, Select, SelectProps } from "@chakra-ui/react"

type MutatedTypes = Omit<SelectProps, 'onChange' | 'name' | 'isRequired' | 'isInvalid' | 'defaultValue'>

export type FormDropdownOptions = Array<JSX.IntrinsicElements['option']>

interface FormDropdownProps extends MutatedTypes {
    formik: any,
    name: string,
    isRequired?: boolean,
    label?: string,
    options: FormDropdownOptions,
    customMessage?: any
}

export const FormDropdown = ({ formik, w = "full", size = "sm", className, borderWidth = "1px", borderRadius = "md", placeholder = "Select", customMessage = '', name, label, options = [], isRequired = false, ...selectProps }: FormDropdownProps) => {

    const optionMarkup = options?.map((line: JSX.IntrinsicElements['option'], index: any) => <option key={index} {...line}>{line["label"]}</option>)

    return (
        <FormControl isRequired={isRequired} isInvalid={Boolean(formik.submitCount && formik.errors[name])}>
            {label && <FormLabel className={'!opacity-75 !text-[13px]'}>{label}</FormLabel>}
            <Select shadow={"sm"} style={{ backgroundImage: 'none'}} size={size} borderColor={'gray.300'} {...selectProps} value={_.get(formik, ["values", name],'Select')} className={`!pb-[9px] !pt-[5px] ${className}`} w={w} placeholder={placeholder} onChange={formik.handleChange} name={name} borderRadius={borderRadius} borderWidth={borderWidth}  autoFocus={false}>
                {!_.isEmpty(options) ? optionMarkup : null}
            </Select>
            {(formik.submitCount && formik.errors[name]) ? <FormErrorMessage mt={0}>{formik.errors[name]}</FormErrorMessage> : customMessage && customMessage}
        </FormControl>
    )
}