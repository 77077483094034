import _ from "lodash"
import { useState } from "react"
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom"
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable"
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader"
import { useTableDataProvider } from "../../hooks"
import { EXAM_ENROLLMENT_TAGS } from "../../../consts/tags.consts"
import { useGetQuery } from "../../../services/api.service"
import { EXAM_ENROLLMENT } from "../../../consts/methods.consts"

const TABLE_COLUMNS: ChakraTableColumns = [
    {
        header: "Admission No",
        accessor: "name",
    },
    {
        header: "NIC",
        accessor: "nic",
    },
    {
        header: "Name",
        accessor: "full_name",
    },
    {
        header: "Examination Name",
        accessor: "name_of_the_examination",
    },
    {
        header: "medium",
        accessor: "medium",
    },
    {
        header: "Accepted",
        accessor: "accept",
        customRenderer(value, item) {
            const className = (value === 'Yes') ? 'bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded' :
                'bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded'
            return (
                <div>
                    <span className={className}>{value}</span>
                </div>
            )
        },
    }
]

const ExamRegistrationsListPage = () => {
    const navigate: NavigateFunction = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [page, setPage] = useState(1)
    const [length, setLength] = useState(15)
    const [searchKey, setSearchKey]: any = useState()

    const { data, isLoading, isFetching } = useGetQuery({
        method: EXAM_ENROLLMENT.LIST,
        body: {
            page: page,
            length: Number(length),
            key: searchKey
        },
        providesTags: [EXAM_ENROLLMENT_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const tableLineClickHandler = (line: any) => {
        navigate(`view?id=${_.get(line, 'name')}`)
    }

    const onLengthChange = (length: any) => {
        setPage(1)
        setLength(Number(length))
    }

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }

    const onPage = (page: any) => {
        setPage(page)
    }

    return (
        <div>
            <PageHeader subtitle="View and Manage Exam Registrations" title={`Exam Registrations`} />
            <ChakraTable onPage={onPage} onLength={onLengthChange} lengthPaginations isFetching={isFetching} onSearch={onSearchHandler} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} header title="Exam Registrations"
                onSelected={tableLineClickHandler} columns={TABLE_COLUMNS} data={dataList} />
        </div>
    )
}

export default ExamRegistrationsListPage