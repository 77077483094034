import { Alert, AlertIcon, SimpleGrid } from "@chakra-ui/react";
import { useFormik } from "formik"
import _, { get } from "lodash"
import React from "react";
import { useState } from "react"
import * as Yup from "yup";
import { CALENDAR } from "../../../../consts/methods.consts"
import { PROGRAM_TAGS } from "../../../../consts/tags.consts";
import { useGetQuery } from "../../../../services/api.service"
import ChakraTable, { ChakraTableColumns } from "../../../common/chakra-table/ChakraTable"
import { FormDropdown } from "../../../common/form-controllers/FormDropdown";
import { FormInput } from "../../../common/form-controllers/FormInput";
import FormSelect from "../../../common/form-controllers/FormSelect";
import { SectionCard } from "../../../common/section-card/SectionCard";
import { useTableDataProvider } from "../../../hooks";
import { DefaultCell } from "../../report/components/cells";

const ProgramTable = () => {

    const [searchKey, setSearchKey]: any = useState()
    const [page, setPage] = useState(1)

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
        }),
        onSubmit: async (values) => {

        }
    })

    const { data, isLoading, isFetching } = useGetQuery({
        method: CALENDAR.PROGRAM_AGENDA,
        body: {
            filter: {
                "startDateOfMonth": get(formik.values, 'from'),
                "endDateOfMonth": get(formik.values, 'to'),
            },
            key: searchKey,
            page: page
        },
        providesTags: [PROGRAM_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const onPage = (page: any) => {
        setPage(page)
    }

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }

    const tableLineClickHandler = (item: any) => {
        // onSelectEvent(item)
    }

    return (
        <>
            <Alert status='warning' rounded={'md'}>
                <AlertIcon /> <p>
                    Note: For one day, in
                    <span className="font-bold">&nbsp;FROM&nbsp;  </span>
                    choose the needed date and in
                    <span className="font-bold">&nbsp;TO&nbsp;  </span>
                    choose the next day. For more than one day,
                    <span className="font-bold">&nbsp;FROM&nbsp;  </span>
                    should be first day and <span className="font-bold">&nbsp;TO&nbsp;  </span> should be the day after the last day.
                </p>
            </Alert>
            <SectionCard backgroundColor=" bg-white" title="Date Range" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormInput type="date" isRequired label="From" formik={formik} name="from" />
                    <FormInput type="date" isRequired label="To" formik={formik} name="to" />
                </SimpleGrid>
            </SectionCard>
            <div className="pt-4">
                <ChakraTable onPage={onPage} isFetching={isFetching} onSearch={onSearchHandler} onSelected={tableLineClickHandler} pagination paginationDetails={paginationDetails} isLoading={isLoading} size={"sm"} header title="Programs"
                    columns={PROGRAM_TABLE_COLUMNS} data={dataList} />
            </div>

        </>

    )
}

const PROGRAM_TABLE_COLUMNS: ChakraTableColumns = [
    {
        header: "Start Date",
        accessor: "start",
        width: 100
    },
    {
        header: "End Date",
        accessor: "end",
        width: 100
    },
    {
        header: "Program Name",
        accessor: "title",
        customRenderer: (value: any, item: any) => (
            React.createElement(DefaultCell, { navigateTo: get(item, 'navigate'), value: value })
        ),
        width: 400
    },
    {
        header: "Program Assistant",
        accessor: "program_assistant",
        width: 200
    }
]


export default ProgramTable