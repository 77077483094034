import _ from "lodash";
import { useRef } from "react";
import { useToast } from "@chakra-ui/react";
import Experience from "./components/Experience";
import GeneralForm from "./components/GeneralForm";
import SubjectArea from "./components/SubjectArea";
import PaymentDetails from "./components/paymentDetails";
import KeyFunctionAreas from "./components/KeyFunctionAreas";
import OtherInformation from "./components/OtherInformation";
import { ToastService } from "../../../../services/toast.service";
import { usePostMutation } from "../../../../services/api.service";
import { RESOURCE_PERSON } from "../../../../consts/methods.consts";
import LanguageProficiency from "./components/LanguageProficiency";
import ResourcePersonHeader from "./components/Header";
import AcademicQualification from "./components/AcademicQualification";
import ProfessionalQualification from "./components/ProfessionalQualification";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { DROPDOWN_TAGS, RESOURCE_PERSON_TAGS } from "../../../../consts/tags.consts";
import { customeJsonParser } from "../../../../utils/utils";

const NewResourcePersonPage = () => {
    const navigate: NavigateFunction = useNavigate()
    const toast = new ToastService(useToast());
    const keyFunctionRef: any = useRef()
    const subjectAreaRef: any = useRef()
    const languageProfRef: any = useRef()
    const academicQualificationRef: any = useRef()
    const professionalQualificationRef: any = useRef()
    const mainFormRef: any = useRef()
    const otherInformationRef: any = useRef()
    const experienceRef: any = useRef()
    const paymentDetailsRef: any = useRef()
    const [create] = usePostMutation();

    const onSubmit = async () => {

        const generalInformation = await mainFormRef.current.submitForm()
        const keyfunctionAreas = await keyFunctionRef.current.submitForm()
        const subjectAreas = await subjectAreaRef.current.submitForm()
        const otherInformation = await otherInformationRef.current.submitForm()
        const paymentDetails = await paymentDetailsRef.current.submitForm()
        const academicQualifications = academicQualificationRef.current
        const professionalQualifications = professionalQualificationRef.current
        const experience = experienceRef.current
        const languageProficiency = languageProfRef.current

        if (mainFormRef.current.isValid && keyFunctionRef.current.isValid && subjectAreaRef.current.isValid && otherInformationRef.current.isValid) {
            try {

                const res = await create({
                    method: RESOURCE_PERSON.ADD,
                    body: {
                        ...keyfunctionAreas,
                        ...generalInformation,
                        ...subjectAreas,
                        ...otherInformation,
                        ...paymentDetails,
                        academicQualifications,
                        professionalQualifications,
                        experience,
                        languageProficiency
                    },
                    invalidatesTags: [RESOURCE_PERSON_TAGS.VIEW, RESOURCE_PERSON_TAGS.LIST, DROPDOWN_TAGS.LIST]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Resource person creation failed.")
                }

                toast.setTitle("Success").setDescription("Resource person has been created successfully.").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/resource-person/${newId}`);
                }, 1500)

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    }

    return (
        <div>
            <ResourcePersonHeader onSubmit={onSubmit} />

            <GeneralForm mainFormRef={mainFormRef} />

            <KeyFunctionAreas keyFunctionRef={keyFunctionRef} />

            <SubjectArea subjectAreaRef={subjectAreaRef} />

            <PaymentDetails paymentDetailsRef={paymentDetailsRef} />

            <OtherInformation OtherInformationRef={otherInformationRef} />

            <AcademicQualification academicQualificationRef={academicQualificationRef} />

            <ProfessionalQualification professionalQualificationRef={professionalQualificationRef} />

            <Experience experienceRef={experienceRef} />

            <LanguageProficiency languageProfRef={languageProfRef} />


        </div>
    )
}

export default NewResourcePersonPage