import _ from "lodash";
import * as Yup from "yup";
import ChakraModal from "../../common/chakra-modal/ChakraModal";
import { FormInput } from "../../common/form-controllers/FormInput";
import { useFormik } from "formik";
import { ORGANIZATION } from "../../../consts/methods.consts";
import { ToastService } from "../../../services/toast.service";
import { usePostMutation } from "../../../services/api.service";
import { DROPDOWN_TAGS, ORGANIZATION_TAGS } from "../../../consts/tags.consts";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { NavigateFunction, useNavigate } from "react-router-dom";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { SectionCard, SectionCardCreatable } from "../../common/section-card/SectionCard";
import { SimpleGrid, useDisclosure, useToast } from "@chakra-ui/react";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";
import { customeJsonParser } from "../../../utils/utils";

const NewOrganizationPage = () => {

    const navigate: NavigateFunction = useNavigate()
    const toast = new ToastService(useToast());
    const disclosure = useDisclosure()
    const [create, { isLoading }] = usePostMutation();

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: ORGANIZATION.ADD,
                    body: values,
                    invalidatesTags: [ORGANIZATION_TAGS.VIEW, ORGANIZATION_TAGS.LIST, DROPDOWN_TAGS.LIST]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Organization creation failed")
                }

                toast.setTitle("Success").setDescription("New Organization has been created").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/organization/${newId}`);
                }, 1500)

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const childFormik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            contact_name: Yup.string().required('Name is required'),
            personal_mobile_number: Yup.string().matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits'),
            official_mobile_number: Yup.string().matches(/^[0-9]{10}$/, 'Mobile number must be 10 digits'),
            email: Yup.string().email('Enter valid email address')
        }),
        onSubmit: async (values: any) => {

            const alreadyExsist = _.get(formik.values, 'contacts', []).findIndex((line: any) => (line.contact_name === values.contact_name))

            if (alreadyExsist > -1) {
                toast.setTitle("Error").setDescription("This contact Already exists").showErrorToast();
                return
            }

            disclosure.onClose()

            formik.setFieldValue('contacts', [values,
                ..._.get(formik.values, 'contacts', [])
            ])

            childFormik.resetForm()
        }
    })

    const CONTACT_LIST_TABLE_COLUMNS: ChakraTableColumns = [
        {
            header: "Contact Name",
            accessor: "contact_name",
        },
        {
            header: "Designation",
            accessor: "designation",
        },
        {
            header: "Personal Mobile Number",
            accessor: "personal_mobile_number",
        },
        {
            header: "Official Number",
            accessor: "official_mobile_number",
        },
        {
            header: "Email",
            accessor: "email",
        },
        {
            header: "Action",
            accessor: "",
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick(item: any) {
                        const newItems = _.get(formik.values, 'contacts', []).filter((i: any) => (
                            i?.name !== item?.name
                        ))
                        formik.setFieldValue('contacts', newItems)
                    },
                }
            ]
        },
    ]

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const creatable: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            disclosure.onOpen();
        }
    }

    return (
        <div>
            <PageHeader isLoading={isLoading} enableBackButton actions={actions} title={'Create New Organization'} subtitle={'Organization'} />
            <SectionCard backgroundColor="bg-white" title="Basic Information" p={3}>
                <SimpleGrid className={'rounded p-3'} columns={{ base: 1, md: 1, lg: 2, xl: 2 }} spacing={3}>
                    <FormInput isRequired label="Organization Name" formik={formik} name="title" />
                    <FormTextArea label="Address" formik={formik} name="address" />
                </SimpleGrid>
            </SectionCard>

            <SectionCard creatable={creatable} title="Contact Persons" p={3}>
                <ChakraTable size={"sm"} header
                    columns={CONTACT_LIST_TABLE_COLUMNS}
                    data={_.get(formik.values, 'contacts', [])} />
                <ChakraModal primaryAction={childFormik.submitForm} primaryButtonText={"Add"}
                    modalTitle={'Add Contact Person'} {...disclosure}>
                    <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                        <FormInput isRequired label="Name" formik={childFormik} name="contact_name" />
                        <FormInput label="Designation" formik={childFormik} name="designation" />
                        <FormInput label="Personal Mobile Number" formik={childFormik} name="personal_mobile_number" />
                        <FormInput label="Official Mobile Number" formik={childFormik} name="official_mobile_number" />
                        <FormInput label="Email" formik={childFormik} name="email" />
                    </SimpleGrid>
                </ChakraModal>
            </SectionCard>

        </div>
    )

}

export default NewOrganizationPage