import _, { } from "lodash";
import moment from "moment";
import { CALENDAR } from "../../../../consts/methods.consts";
import { useGetQuery } from "../../../../services/api.service";
import { CALENDAR_TAGS } from "../../../../consts/tags.consts";
import { useMemo, useState } from "react";
import { Calendar, momentLocalizer, DateCellWrapperProps } from "react-big-calendar";
import { NavigateFunction, useNavigate } from "react-router-dom";
import "react-big-calendar/lib/css/react-big-calendar.css";
import React from "react";
import WeekTable from "./WeekTable";


moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const SessionCalendar = () => {
    const navigate: NavigateFunction = useNavigate()
    const [filter, setFilter]: any = useState({
        startDateOfMonth: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
        endDateOfMonth: moment().add(1, 'months').endOf('month').format('YYYY-MM-DD')
    })

    const { data: _data, isLoading } = useGetQuery({
        method: CALENDAR.SESSIONS,
        body: {
            filter: {
                startDateOfMonth: filter.startDateOfMonth,
                endDateOfMonth: filter.endDateOfMonth
            }
        },
        providesTags: [CALENDAR_TAGS.EVENTS]
    })

    const data = _.get(_data, ['message'], [])

    const onRangeChange = (range: any) => {
        setFilter({
            startDateOfMonth: moment(_.get(range, 'start')).subtract(1, 'months').format('YYYY-MM-DD'),
            endDateOfMonth: moment(_.get(range, 'end')).add(1, 'months').format('YYYY-MM-DD'),
        });
    }

    const tooltipAccessor = (event: any) => {
        return event?.title
    }

    const convertedSessionData = data.map((convertedSession: { start: moment.MomentInput; end: moment.MomentInput; }, index: any) => ({
        ...convertedSession,
        start: moment(convertedSession.start).toDate(),
        key: index,
        end: moment(convertedSession.end).toDate(),
    }))


    const onSelectEvent = (event: any) => {
        navigate(event.navigate_session)
    }


    const getEventColors = (sessionType: string) => {
        let backgroundColor = '';
        let color = '#000000'

        if (sessionType === 'Individual') {
            backgroundColor = '#9FD4FA';
        } else if (sessionType === 'Combine') {
            backgroundColor = '#FFEAC2';
        }

        return { backgroundColor, color };
    };

    const AgendaEventComponent = ({ event, title }: any) => {

        const { backgroundColor, color } = getEventColors(event.session_type);

        return <div className="max-w-20 px-2 rounded " style={{
            backgroundColor, color
        }}>{title}</div>
    }

    const eventWrapper = ({ event, children }: any) => {

        const { backgroundColor, color } = getEventColors(event.session_type);

        const newStyle = {
            ...children?.props.style,
            backgroundColor,
            color,
        };

        const clonedChild = React.cloneElement(children, {
            style: newStyle,
        });

        return (
            clonedChild
        );

    }
    const { views }: any = useMemo(
        () => ({
            views: {
                month: true,
                week: WeekTable,
                day: true,
                agenda: true,
            },
        }), [])


    return (
        <div>
            <div className="flex justify-end items-start gap-8 p-1">
                <div className="flex items-center">
                    <div className="bg-[#9FD4FA] w-[18px] h-[18px] rounded-full "></div>
                    <p className="ml-4 " style={{ fontSize: '14px' }}>Individual Session</p>
                </div>
                <div className="flex items-center">
                    <div className="bg-[#FFEAC2] w-[18px] h-[18px] rounded-full "></div>
                    <p className="ml-4 " style={{ fontSize: '14px' }}>Combine Session</p>
                </div>
            </div>

            <div className="border mt-4 rounded p-3 animation-form">

                {!isLoading && <Calendar
                    localizer={localizer}
                    startAccessor='start'
                    endAccessor='end'
                    length={30}
                    onRangeChange={onRangeChange}
                    tooltipAccessor={tooltipAccessor}
                    events={convertedSessionData}
                    defaultDate={new Date()}
                    style={{ height: "100vh" }}
                    defaultView={'day'}
                    onSelectEvent={onSelectEvent}
                    views={views}
                    enableAutoScroll={true}
                    components={{
                        eventWrapper: eventWrapper,

                        "agenda": {
                            event: AgendaEventComponent,
                        },
                    }}

                />}
            </div>

        </div>
    )
}

export default SessionCalendar
