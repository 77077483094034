import _ from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormInput } from "../../common/form-controllers/FormInput";
import { SectionCard } from "../../common/section-card/SectionCard";
import { FormDropdown } from "../../common/form-controllers/FormDropdown";
import { ToastService } from "../../../services/toast.service";
import { PROGRAMMED_MODULE } from "../../../consts/methods.consts";
import { SimpleGrid, useToast } from "@chakra-ui/react";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { NavigateFunction, useNavigate } from "react-router-dom";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { DROPDOWN_TAGS, PROGRAMMED_MODULE_TAGS, PROGRAM_TAGS, RESOURCE_PERSON_TAGS } from "../../../consts/tags.consts";

const CreateProgrammedModulePage = () => {
    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());
    const [create] = usePostMutation();

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            code: Yup.string().required("Code is required"),
            title: Yup.string().required("Title is required"),
            program: Yup.string().required("Program is required"),
            lecturer: Yup.string().required("Lecturer Code is required"),
            end_date: Yup.string().required("End Date Number is required"),
            start_date: Yup.string().required("Start Date Number is required")
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: PROGRAMMED_MODULE.ADD,
                    body: values,
                    invalidatesTags: [PROGRAMMED_MODULE_TAGS.LIST, DROPDOWN_TAGS.LIST]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Programmed module creation failed")
                }

                toast.setTitle("Success").setDescription("New Programmed module has been created").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/program-module/${newId}`);
                }, 1500)

            } catch (error: any) {
                // let message = JSON.parse(_.get(error?.data, '_server_messages', {}))
                // if (message) toast.setTitle("Error").setDescription(JSON.parse(message)?.message).showErrorToast();
                toast.setTitle("Error").setDescription('Programmed module creation failed').showErrorToast();
            }
        }
    })


    const { data: initialData } = useGetQuery({
        method: PROGRAMMED_MODULE.INIT,
        providesTags: [PROGRAM_TAGS.LIST, RESOURCE_PERSON_TAGS.LIST, DROPDOWN_TAGS.LIST]
    })

    const dropDownData = _.get(initialData, 'message')

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    return (
        <div>
            <PageHeader enableBackButton actions={actions} title={'Program Module'} subtitle={'Program Module'} />
            <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormInput isRequired label="Code" formik={formik} name="code" />
                    <FormInput isRequired label="Title" formik={formik} name="title" />

                    <FormDropdown options={_.get(dropDownData, 'programes')} isRequired formik={formik}
                        name={'program'}
                        autoFocus
                        label={'Program'} />

                    <FormDropdown options={_.get(dropDownData, 'resource_persons')} isRequired formik={formik}
                        name={'lecturer'}
                        autoFocus
                        label={'Lecturer'} />

                    <FormInput isRequired type={'date'} label="Start Date" formik={formik}
                        name="start_date" />

                    <FormInput isRequired type={'date'} label="End Date" formik={formik}
                        name="end_date" />
                </SimpleGrid>
            </SectionCard>
        </div>
    )
}

export default CreateProgrammedModulePage