import { useToast } from "@chakra-ui/react";
import _ from "lodash";
import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { MODULE_ASSESMENT_RESULTS } from "../../../../../consts/methods.consts";
import { MODULE_ASSESMENT_RESULT_TAGS } from "../../../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../../../services/api.service";
import { ToastService } from "../../../../../services/toast.service";
import ChakraTable, { ChakraTableColumns } from "../../../../common/chakra-table/ChakraTable";
import { SectionCard } from "../../../../common/section-card/SectionCard";
import { useTableDataProvider } from "../../../../hooks";

const AssessmentListTable = ({ parentData }: any) => {
    const navigate: NavigateFunction = useNavigate()
    const [page, setPage] = useState(1)
    const [create] = usePostMutation();
    const [searchKey, setSearchKey]: any = useState('')
    const toast = new ToastService(useToast());

    const { data, isLoading, isFetching } = useGetQuery({
        method: MODULE_ASSESMENT_RESULTS.LIST,
        body: {
            page: page,
            program: _.get(parentData, 'program'),
            program_module: _.get(parentData, 'programModule'),
            key: searchKey
        },
        providesTags: [MODULE_ASSESMENT_RESULT_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const ondeleteHandler = async (item: any) => {
        try {
            const res = await create({
                method: MODULE_ASSESMENT_RESULTS.DELETE,
                body: { id: item?.name },
                invalidatesTags: [MODULE_ASSESMENT_RESULT_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Module assessment result is deleted successfully.").showSuccessToast();

        } catch (error: any) {
            let message = JSON.parse(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(JSON.parse(message)?.message).showErrorToast();
        }

    }

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }


    const MODULE_RESULT_COLUMNS: ChakraTableColumns = [
        {
            header: "Student",
            accessor: "student",
        },
        {
            header: "Full Name",
            accessor: "full_name",
        },
        {
            header: "NIC",
            accessor: "nic",
        },
        {
            header: "Marks",
            accessor: "mark",
        },
        {
            header: "Actions",
            accessor: '',
            disabled: false,
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick: ondeleteHandler,
                }
            ]
        }
    ]

    const tableLineClickHandler = (line: any) => {
        navigate(`/app/student/${line?.student}`)
    }

    const onPage = (page:any)=>{
        setPage(page)
    }


    return (
        <div>
            <SectionCard bg={'white'} subTitle="" title="Assessment Results" p={3}>
                <ChakraTable onPage={onPage} onSearch={onSearchHandler} onSelected={tableLineClickHandler} paginationDetails={paginationDetails} isFetching={isFetching} isLoading={isLoading} pagination size={"sm"} header
                    columns={MODULE_RESULT_COLUMNS} data={dataList} />
            </SectionCard>
        </div>
    )

}

export default AssessmentListTable