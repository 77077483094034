import { SimpleGrid } from "@chakra-ui/layout";
import { FormLabel, Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { PROGRAM } from "../../../consts/methods.consts";
import { CALENDAR_TAGS, DROPDOWN_TAGS, PROGRAM_TAGS } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { FormDropdown } from "../../common/form-controllers/FormDropdown";
import { FormInput } from "../../common/form-controllers/FormInput";
import FormSelect from "../../common/form-controllers/FormSelect";
import MaskFormInput from "../../common/form-controllers/MaskFormInput";
import { FormSeletectNavigationController } from "../../common/form-seletect-navigation-controller/FormSeletectNavigationController";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { useObjChange } from "../../hooks";
import { InnerLoading, SectionCard } from "../../index";
import { getContactNumber } from "./actions";
import ProgrammedModule from "./programmed-module/ProgrammedModule";
import { customeJsonParser } from "../../../utils/utils";
import axios from "axios";
import { BASE_URL } from "../../../consts/app.consts";
import { chakraComponents } from "chakra-react-select";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";
import DocumentHeaderTitle from "../../common/document-header-title/DocumentHeaderTitle";
import { FormSelectColourController } from "../../common/form-select-colour-controller/form-select-colour-controller";

const ViewProgramPage = () => {
    const { id } = useParams()
    const navigate: NavigateFunction = useNavigate();
    const dispatch: any = useDispatch()
    const toast = new ToastService(useToast());
    const [create, { isLoading: creating }] = usePostMutation();

    const { data: _data, isLoading } = useGetQuery({
        method: PROGRAM.GET,
        body: { id },
        providesTags: [PROGRAM_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {})

    const stringifiedData = JSON.stringify(data)

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            from: Yup.date()
                .required("From Date is required")
                .test(
                    "from-date-validation",
                    "Start date must be before end date",
                    (value, context) => {
                        const { to } = context.parent;
                        return to ? value <= to : true;
                    }
                ),
            to: Yup.date()
                .required("To Date is required")
                .test(
                    "to-date-validation",
                    "End date must be after start date",
                    (value, context) => {
                        const { from } = context.parent;
                        return from ? value >= from : true;
                    }
                ),
            code: Yup.string().required("Code is required"),
            module_bucket: Yup.string().required("Module Bucket is required"),
            mode: Yup.string().required("Mode is required"),
            credit: Yup.number().required("Credit is required"),
            coordinator: Yup.string().required("Coordinator is required"),
            assistance: Yup.string().required("Assistant is required"),
            // registration_closing_date: Yup.date().required("Registration Closing Date is required"),
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: PROGRAM.UPDATE,
                    body: values,
                    invalidatesTags: [PROGRAM_TAGS.VIEW, PROGRAM_TAGS.LIST, DROPDOWN_TAGS.LIST, CALENDAR_TAGS.EVENTS]
                }).unwrap();

                toast.setTitle("Success").setDescription("Program details have been saved.").showSuccessToast();

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const viewOnlyformik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    const deleteHandler = async () => {
        try {
            await create({
                method: PROGRAM.DELETE,
                body: { id },
                invalidatesTags: [PROGRAM_TAGS.LIST, DROPDOWN_TAGS.LIST, CALENDAR_TAGS.EVENTS]
            }).unwrap();

            toast.setTitle("Success").setDescription("Program is deleted successfully.").showSuccessToast();

            setTimeout(() => {
                navigate(`/app/program`);
            }, 1500)

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            // toast.setTitle("Error").setDescription('Failed to delete the Program').showErrorToast();
        }
    }

    const { data: initialData } = useGetQuery({
        method: PROGRAM.INIT,
        body: {
            id,
            organization: _.get(formik.values, 'organization', ''),
            organization_contact: _.get(formik.values, 'organization_contact', ''),
            category: _.get(formik.values, 'category', ''),
            program_type: _.get(formik.values, 'type', ''),
        },
        providesTags: [DROPDOWN_TAGS.LIST]
    })

    const { data: programModulesCompleteStatus } = useGetQuery({
        method: PROGRAM.PROGRAM_STATUS,
        body: {
            id,
        },
        providesTags: [PROGRAM_TAGS.VIEW]
    })


    const dropDownData = _.get(initialData, 'message')
    const programModuleStatus = _.get(programModulesCompleteStatus, 'message')

    const { isChanged, setInitialObj } = useObjChange(formik.values)

    useEffect(() => {
        if (!_.isEmpty(_.get(data, 'program'))) {
            formik.setValues(_.get(data, 'program'))
            setInitialObj(_.get(data, 'program'))
        }
    }, [stringifiedData])

    const updatedOrganization = _.get(formik.values, 'organization')

    useEffect(() => {
        dispatch(getContactNumber(_.get(formik.values, 'organization'), _.get(formik.values, 'organization_contact'))).then((data: any) => {
            if (data) {
                viewOnlyformik.setFieldValue('personal_mobile_number', _.get(data, 'personal_mobile_number'))
                viewOnlyformik.setFieldValue('official_mobile_number', _.get(data, 'official_mobile_number'))
                viewOnlyformik.setFieldValue('designation', _.get(data, 'designation'))
                viewOnlyformik.setFieldValue('email', _.get(data, 'email'))
            }
            else {
                viewOnlyformik.setFieldValue('organization_contact_number', '')
                viewOnlyformik.setFieldValue('personal_mobile_number', '')
                viewOnlyformik.setFieldValue('designation', '')
                viewOnlyformik.setFieldValue('email', '')

            }
        })
    }, [_.get(formik.values, 'organization_contact')])

    useEffect(() => {
        if (!_.isEmpty(formik.values) && (_.get(formik.values, 'organization') !== _.get(data, 'program.organization'))) {
            viewOnlyformik.setFieldValue('personal_mobile_number', _.get(data, 'personal_mobile_number'))
            viewOnlyformik.setFieldValue('official_mobile_number', _.get(data, 'official_mobile_number'))
            formik.setFieldValue('organization_contact', '')
            viewOnlyformik.setFieldValue('designation', '')
            viewOnlyformik.setFieldValue('email', '')
        }
    }, [updatedOrganization]);

    const moreActions: PageHeaderActions = [
        {
            text: "Export Program Schedule",
            isDisabled: isLoading,
            onClick: () => exportProgramSchedule(id, toast),
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
    ]


    const actions: PageHeaderActions = [

        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Delete",
            onClick: deleteHandler,
            buttonVariant: "solid",
            buttonColorScheme: "red"
        }
    ]

    const navigationHandler = (e: any, value: any) => {
        e.preventDefault();
        e.stopPropagation();
        console.log(value)
    }

    if (isLoading) {
        return (
            <>
                <PageHeader isLoading={isLoading} enableBackButton actions={actions} title={'Program'} />
                <InnerLoading />
            </>
        )
    }

    return (
        <div>
            <PageHeader moreActions={moreActions} backButtonCofirmation={isChanged} statusColorSchemes={_.get(programModuleStatus, 'color', '')} status={_.get(programModuleStatus, 'message', '')} isLoading={creating} enableBackButton actions={actions} title={_.get(data, 'program.title', '')}
                subtitle={'Program'} />

            <Tabs className="mt-3">
                <TabList>
                    <Tab>Program</Tab>
                    <Tab>Modules</Tab>
                </TabList>

                <TabPanels className="animation-form">
                    <TabPanel className="!p-0">
                        <DocumentHeaderTitle>
                            {_.get(data, 'program.title', '')}
                        </DocumentHeaderTitle>

                        <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                                <FormInput isRequired label="Code" formik={formik} name="code" />
                                <FormInput isRequired label="Program Name" formik={formik} name="title" />
                                <FormInput isRequired type="number" label="Credit" formik={formik}
                                    name="credit" />
                                <FormSelect isDisabled options={_.get(dropDownData, 'module_buckets')} isRequired formik={formik}
                                    extraProps={{ linkTo: `/app/module-basket/${_.get(formik.values, 'module_bucket')}` }}
                                    components={{ Control: FormSeletectNavigationController }}
                                    name={'module_bucket'}
                                    label={'Module Basket'} />
                                <FormDropdown options={programModes} isRequired formik={formik} name={'mode'}
                                    autoFocus
                                    label={'Mode'} />

                                <FormDropdown options={_.get(dropDownData, 'categories')} formik={formik}
                                    name={'category'}
                                    autoFocus
                                    label={'Category'} />

                                <FormDropdown options={_.get(dropDownData, 'program_types')} formik={formik}
                                    name={'type'}
                                    autoFocus
                                    label={'Type'} />

                                <FormDropdown options={_.get(dropDownData, 'program_subtypes')} formik={formik}
                                    name={'sub_type'}
                                    autoFocus
                                    label={'Sub Type'} />

                                <MaskFormInput maskOption={{
                                    mask: Number,
                                    thousandsSeparator: ',',
                                    mapToRadix: ['.'],
                                    radix: '.',
                                    padFractionalZeros: true,
                                    scale: 2,
                                }} label="Budgeted Amount (RS.)" formik={formik} name="budgeted_amount" />


                                <FormInput label="File Number" formik={formik}
                                    name="file_number" />
                                {/* <FormTextArea label="Description" formik={formik} name="description" /> */}

                            </SimpleGrid>
                        </SectionCard>


                        <SectionCard backgroundColor=" bg-white" title="Organization Information" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                                <FormSelect options={_.get(dropDownData, 'organizations')} isRequired formik={formik} name={'organization'}
                                    extraProps={{ linkTo: `/app/organization/${_.get(formik.values, 'organization')}` }}
                                    components={{ Control: FormSeletectNavigationController }}
                                    label={'Organization'} />
                                <FormSelect options={_.get(dropDownData, 'organization_contacts')}
                                    label="Organization Contact" formik={formik}
                                    name="organization_contact" />

                                <MaskFormInput maskOption={{
                                    mask: '000 0000000',
                                }} isDisabled label="Personal Contact Number" formik={viewOnlyformik}
                                    name="personal_mobile_number" />

                                <MaskFormInput maskOption={{
                                    mask: '000 0000000',
                                }} isDisabled label="Official Contact Number" formik={viewOnlyformik}
                                    name="official_mobile_number" />

                                <FormInput isDisabled label="Designation" formik={viewOnlyformik}
                                    name="designation" />
                                <FormInput isDisabled label="Email" formik={viewOnlyformik}
                                    name="email" />
                            </SimpleGrid>
                        </SectionCard>

                        <SectionCard backgroundColor=" bg-white" title="Employee Allocation" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                                <FormSelect isRequired options={_.get(dropDownData, 'program_coodinators')}
                                    extraProps={{ linkTo: `/app/employee/${_.get(formik.values, 'coordinator')}` }}
                                    components={{ Control: FormSeletectNavigationController }}
                                    label="Program Coordinator" formik={formik}
                                    name="coordinator" />

                                <FormSelect options={_.get(dropDownData, 'program_coodinators')}
                                    label="Co-Coordinator" formik={formik}
                                    name="co_coordinator" />

                                <FormSelect options={_.get(dropDownData, 'program_offices_and_assistance')}
                                    extraProps={{ linkTo: `/app/employee/${_.get(formik.values, 'officer')}` }}
                                    components={{ Control: FormSeletectNavigationController }}
                                    label="Program Officer" formik={formik}
                                    name="officer" />

                                <FormSelect isRequired options={_.get(dropDownData, 'program_offices_and_assistance')}
                                    extraProps={{ linkTo: `/app/employee/${_.get(formik.values, 'assistance')}` }}
                                    components={{ Control: FormSeletectNavigationController }}
                                    label="Program Assistant" formik={formik}
                                    name="assistance" />
                            </SimpleGrid>
                        </SectionCard>

                        <SectionCard backgroundColor=" bg-white" title="Duration Information" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                                <FormInput isRequired type={'date'} label="From" formik={formik} name="from" />
                                <FormInput isRequired type={'date'} label="To" formik={formik} name="to" />
                                {/* <FormInput isRequired type={'date'} label="Registration Closing Date" formik={formik} name="registration_closing_date" /> */}
                            </SimpleGrid>
                        </SectionCard>

                        <SectionCard backgroundColor=" bg-white" title="Color & Description" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 1, xl: 1 }} spacing={3}>
                                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                                    <FormSelect components={{ Control: FormSelectColourController, Option: customComponents.Option }} options={colourOptions}
                                        label="Color" formik={formik}
                                        name="colour" />
                                    <FormDropdown options={[{ "value": 0, "label": "No" }, { "value": 1, "label": "Yes" }]} formik={formik}
                                        name={'allow_feedback'}
                                        autoFocus
                                        label={'Allow Feedback '} />
                                </SimpleGrid>

                                <FormTextArea rows={7} label="Description" formik={formik} name="description" />

                            </SimpleGrid>

                        </SectionCard>
                    </TabPanel>

                    <TabPanel className="!p-0 !pt-4">
                        <ProgrammedModule data={_.get(data, 'programmed_modules', [])} />
                    </TabPanel>
                </TabPanels>
            </Tabs>

        </div>
    )
}
export default ViewProgramPage

const programModes = [{
    value: "Online",
    label: "Online"
}, {
    value: "Physical",
    label: "Physical"
}, {
    value: "Hybrid",
    label: "Hybrid"
}]

const colourOptions = [
    { "value": "#b3e6ff", "label": "" },
    { "value": "#cceeff", "label": "" },
    { "value": "#e6f7ff", "label": "" },
    { "value": "#ffb3ec", "label": "" },
    { "value": "#e6b3ff", "label": "" },
    { "value": "#b3ffb3", "label": "" },
    { "value": "#ffd1b3", "label": " " },
    { "value": "#ffb3d1", "label": "" },
    { "value": "#ccffee", "label": "" },
    { "value": "#ffffcc", "label": "" },
    { "value": "#e6ccff", "label": "" },
    { "value": "#ccffff", "label": "" }
]


const exportProgramSchedule = async (_id: any, toast: any) => {
    try {
        let response = await axios.post(`${BASE_URL}/method/xceed.api.export_excel.program.export_programme_schedule`, { id: _id }, {
            withCredentials: true,
            responseType: "blob"
        })
        if (response) {
            console.log(response)
            const href = URL.createObjectURL(response.data);

            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `programme_schedule.xlsx`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }

    } catch (error: any) {
        toast.setTitle("Error").setDescription('Failed to export program schedule').showErrorToast();
    }
}

const customComponents = {
    Option: ({ children, ...props }: any) => {
        return (<chakraComponents.Option {...props}>
            <div className=" h-[32px] w-[32px] rounded-full" style={{ backgroundColor: props.data.value }}>
            </div>
        </chakraComponents.Option >)
    }
};
