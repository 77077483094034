import { SimpleGrid } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/react";
import { chakraComponents } from "chakra-react-select";
import { useFormik } from "formik";
import _ from "lodash";
import { NavigateFunction, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { SUBJECT } from "../../../consts/methods.consts";
import { DROPDOWN_TAGS, SUBJECT_TAGS } from "../../../consts/tags.consts";
import { usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { FormCheckBox } from "../../common/form-controllers/FormCheckBox";
import { FormDropdown } from "../../common/form-controllers/FormDropdown";
import { FormInput } from "../../common/form-controllers/FormInput";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { SectionCard } from "../../common/section-card/SectionCard";
import { customeJsonParser } from "../../../utils/utils";

const NewSubjectPage = () => {

    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());
    const [create] = usePostMutation();

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            subject_code: Yup.string().required('Subject code is required'),
            subject_name: Yup.string().required("Subject name is required"),
            exam_start_date_and_time: Yup.string().required("Exam start date and time is required"),
            exam_end_date_and_time: Yup.string().required("Exam end date and time is required"),
            exam_center: Yup.string().required("Exam center is required"),
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: SUBJECT.ADD,
                    body: values,
                    invalidatesTags: [SUBJECT_TAGS.VIEW, SUBJECT_TAGS.LIST, DROPDOWN_TAGS.LIST]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("New Subject creation failed")
                }

                toast.setTitle("Success").setDescription("New Subject has been created successfully.").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/subject/${newId}`);
                }, 1500)

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    return (
        <div>
            <PageHeader enableBackButton actions={actions} title={'Create New Exam Registration'} subtitle={'Exam Registration'} />
            <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormInput isRequired label="Subject Code" formik={formik} name="subject_code" />
                    <FormInput isRequired label="Subject Name" formik={formik} name="subject_name" />
                    <FormInput type={'datetime-local'} isRequired label="Exam Start Date and Time" formik={formik} name="exam_start_date_and_time" />
                    <FormInput type={'datetime-local'} isRequired label="Exam End Date and Time" formik={formik} name="exam_end_date_and_time" />
                    <FormInput isRequired label="Exam Center" formik={formik} name="exam_center" />
                </SimpleGrid>
            </SectionCard>
        </div>
    )
}

export default NewSubjectPage

const mediums = [
    { value: 'Sinhala', label: 'Sinhala' },
    { value: 'English', label: 'English' },
]

const customComponents = {
    Option: ({ children, ...props }: any) => (
        <chakraComponents.Option {...props}>
            <div className="gap-2">
                <div className=" font-bold">
                    {children}
                </div>
                <div className=" italic flex  gap-3">
                    <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                        {props.data.code}
                    </span>
                    {props.data.group && (<span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                        {props.data.group}
                    </span>)}
                </div>
            </div>
        </chakraComponents.Option >
    )
};