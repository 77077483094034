import { Checkbox, HStack, Input, Stack, VStack } from "@chakra-ui/react";
import { Badge } from "flowbite-react";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect } from "react";
import { MODULE_ENROLLMENT } from "../../../../consts/methods.consts";
import { DROPDOWN_TAGS } from "../../../../consts/tags.consts";
import { useGetQuery } from "../../../../services/api.service";
import { isAllChecked, itemSelector, onIsAllCheckChanges } from "../../../../utils/student-selector.utils";
import SelectedItem from "./SelectedItem";

interface StudentTabProps {
    isOpen: boolean,
    selected: [],
    setSelected: any
}

const StudentTab = ({ isOpen, selected, setSelected = () => { } }: StudentTabProps) => {

    const formik = useFormik({
        initialValues: {
            key: '',
        },
        onSubmit: () => { }
    })


    const { data, isLoading ,isFetching } = useGetQuery({
        method: MODULE_ENROLLMENT.GET_STUDENT_LIST,
        body:formik.values,
        providesTags: [DROPDOWN_TAGS.LIST]
    })

    const _data =  _.get(data,'message',[])

    useEffect(() => {
        formik.resetForm();
    }, [isOpen])

    const searchInputOnChange = (event: any) => {
        _.debounce(() => {
            formik.setFieldValue("key", (event.target.value || '').trim())
        }, 1000)()
    }

    const selectedItemMarkup = selected.map((line: any, index: number) => <SelectedItem key={index} onClick={() => itemSelector(line, setSelected)} end={(selected?.length || 0) == (index + 1)} selected={selected} item={line} />)

    const itemMarkup = _data.map((line: any, index: number) => <Item key={index} onClick={() => itemSelector(line, setSelected)} end={(_data?.length || 0) == (index + 1)} selected={selected} item={line} />)

    return (
        <>
            <HStack>
                <Input className="!border-gray-300" onChange={searchInputOnChange} placeholder="Search by name or nic" size={"sm"} rounded={"md"} shadow={"sm"} />
            </HStack>

            <div className="grid grid-cols-2 gap-4 pt-2">
                <div className="border rounded shadow-sm">
                    <HStack my={0} py={1} px={3} className="border-b mt-2 border-gray-300 bg-slate-100">
                        <Checkbox borderColor={'gray.300'} colorScheme="teal" onChange={(event: any) => onIsAllCheckChanges(event, _data, selected, setSelected)} isChecked={isAllChecked(selected, _data)} size={"md"} />
                        <div className="flex justify-between w-full items-center">
                            <p className="ml-1 select-none font-semibold text-gray-600 text-sm">Students</p>
                            {/* <p className="ml-1 select-none font-semibold text-gray-600 text-sm">Class</p> */}
                        </div>
                    </HStack>
                    <Stack gap={0} className="max-h-[45vh] !min-h-[45vh] overflow-y-auto">
                        {(!isLoading && !isFetching) && itemMarkup}
                        {(!isLoading && !isFetching) && _.isEmpty(data) && <p className="text-sm text-center mt-3">No students found!</p>}
                    </Stack>
                </div>

                <div className="border rounded shadow-sm">
                    <HStack my={0} py={1} px={3} className="border-b mt-2 border-gray-300 bg-slate-100">
                        <div className="flex justify-between w-full items-center">
                            <p className="select-none font-semibold text-gray-600 text-sm">Selected Students({selected?.length || 0})</p>
                        </div>
                    </HStack>
                    <Stack gap={0} className="max-h-[45vh] min-h-[45vh] overflow-y-auto">
                        {selectedItemMarkup}
                    </Stack>
                </div>
            </div>
        </>
    )
}

interface ItemProps {
    end?: boolean,
    item: any,
    selected?: any,
    onClick?: any
}

const Item = ({ end, item, selected = [], onClick }: ItemProps) => {
    const isChecked = selected.findIndex((i: any) => i.name == item.name)

    return (
        <VStack className="hover:bg-slate-50 transition-all" px={3} onClick={() => onClick(item)} cursor={"pointer"} borderBottom={end ? 'unset' : '1px'} borderColor={"gray.300"} alignItems={"flex-start"}>
            <HStack gap={3} py={2} justifyContent={"space-between"} w={'full'}>
                <div className="flex items-center gap-3">
                    <Checkbox onChange={() => onClick(item)} className="outline-none " colorScheme="teal" isChecked={isChecked != -1} size={"md"} />
                    <VStack spacing={0} alignItems={"flex-start"}>
                        <p className="select-none text-gray-600 text-sm">{item.full_name}</p>
                        <p className="text-gray-500 select-none text-xs">{item.nic}</p>
                    </VStack>
                </div>
                <Badge color={"purple"} className="select-none">{item.class}</Badge>
            </HStack>
        </VStack>
    )
}

export default StudentTab;