import { Link } from '@chakra-ui/react'
import _ from 'lodash'
import { Link as ReactRouterLink, useNavigate } from 'react-router-dom'
import { customeJsonParser } from '../../../../utils/utils'

export const DefaultCell = ({ navigateTo, value }: any) => {
    const navigate = useNavigate()

    const navigationHandler = () => {
        navigate(navigateTo)
    }

    return (
        <Link className='w-full'
            as={ReactRouterLink}
            to={navigateTo} >{value}</Link>
    )
}


export const ResourcePersonCell = ({ values }: any) => {

    const parsedValues = customeJsonParser(values, [])
    const length: number = _.isArray(parsedValues) ? parsedValues.length : 0

    const valueMarkup = _.size(parsedValues) > 0 ? parsedValues.map((item: any, index: number) =>
        <div key={index}>
                <p className='py-1'>{_.get(item, 'resource_person', '')}</p>
        </div>) : 'N/A'

    return (
        // <Link className='w-full'
        //     as={ReactRouterLink}
        //     to={navigateTo} >{values}</Link>
        <div>
            {valueMarkup}
        </div>
    )
}

export const BadgeCell = ({ value, config }: any) => {

    const className = _.get(config, value)
    return (
        <div>
            <span className={className}>{value}</span>
        </div>
    )
}

