import _ from "lodash"
import { useEffect } from "react"
import { useObjChange } from "../../../../hooks"
import { useLocalStore } from "@dladio/hooks"
import PageHeader, { PageHeaderActions } from "../../../../common/page-header/PageHeader"

const ResourcePersonHeader = ({ data, onSubmit }: any) => {
    const { model }: any = useLocalStore('resource-person')
    const { isChanged, setInitialObj } = useObjChange({ ...data })

    useEffect(() => {
        setInitialObj(
            model
        )
    }, [model])


    const actions: PageHeaderActions = [
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: onSubmit,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
    ]

    return (
        <PageHeader backButtonCofirmation={isChanged} enableBackButton actions={actions} title={_.get(data, 'general.full_name', '')}
            subtitle={'Resource Person'} />

    )
}

export default ResourcePersonHeader