import { Box, SimpleGrid, Stack } from "@chakra-ui/react"
import { chakraComponents } from "chakra-react-select"
import { useFormik } from "formik"
import { useState } from "react"
import * as Yup from 'yup'
import { REPORT_TAGS } from "../../../../consts/tags.consts"
import { useGetQuery } from "../../../../services/api.service"
import ChakraTable from "../../../common/chakra-table/ChakraTable"
import FormOnlineSearch from "../../../common/form-controllers/FormOnlineSearch"
import InnerLoading from "../../../common/inner-loading/InnterLoading"
import PageHeader, { PageHeaderActions } from "../../../common/page-header/PageHeader"
import { SectionCard } from "../../../common/section-card/SectionCard"
import { useTableDataProvider } from "../../../hooks"
import _ from "lodash"

const LibraryAccessReportPage = () => {

    const [loading] = useState(false)
    const [filter, setFilter]: any = useState({})

    const formik = useFormik({
        initialValues: {
        },
        validationSchema: Yup.object({
            student: Yup.string().required('Student is required'),
        }),
        onSubmit: async (values: any) => {
            setFilter(values)
        }
    })

    let { data, isLoading, isFetching, isError } = useGetQuery({
        method: 'xceed.api.report.library_access.get_library_access_report',
        body: {
            filter: filter
        },
        providesTags: [REPORT_TAGS.LIST]
    })

    console.log(isError, 'isError')

    if (isError) {
        data = data
    }

    const { dataList } = useTableDataProvider(data);

    const executeReportHandler = () => {
        formik.submitForm()
    }


    const actions: PageHeaderActions = [
        {
            text: "Execute Report",
            onClick: executeReportHandler,
            buttonVariant: "solid",
            isDisabled: loading || isFetching || isLoading,
            buttonColorScheme: "teal",
            confirmation: false
        },
        {
            text: "Reset",
            onClick: () => {
                formik.resetForm()
                setFilter({})
            },
            isDisabled: loading || isFetching || isLoading,
            buttonVariant: "solid",
            buttonColorScheme: "red",
            confirmation: false
        }
    ]

    if (loading || isLoading) {
        return (
            <>
                <PageHeader enableBackButton isLoading={isFetching || isLoading || loading}
                    actions={actions}
                    subtitle="View and Manage Report" title={'Sessions V2 report'} />
                <InnerLoading />
            </>
        )
    }

    return (
        <>
            <div>
                <PageHeader enableBackButton isLoading={isFetching || isLoading || loading}
                    actions={actions}
                    subtitle="View and Manage Report" title={'Library Access Report'} />

                <SectionCard backgroundColor=" bg-white" title={'Filters'} p={3} mb={4}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                        <FormOnlineSearch components={customComponents} extraProps={{ filterOption: null }} method={"xceed.api.report.library_access.get_student_list_by_type"} formik={formik}
                            body={{}}
                            name={'student'}
                            label={'Search by NIC or Name'} />
                    </SimpleGrid>
                </SectionCard>
                {_.get(formik.values, 'student') && <StudentDetails student={_.get(formik.values, 'student')} />}
                <ChakraTable isFetching={isFetching} isLoading={isLoading || loading} size={"sm"}
                    data={isError ? [] : dataList || []}
                    title="Report"
                    columns={column} />
            </div>
        </>
    )
}

export default LibraryAccessReportPage

const column = [
    {
        header: "Program",
        accessor: "program",
        width: 300,
    },
    {
        header: "Module",
        accessor: "module",
        width: 300,
    },
    {
        header: "Start Date",
        accessor: "start_date",
        width: 200,
    },
    {
        header: "End Date",
        accessor: "end_date",
        textAlign: 'end',
        width: 200,
    }
]

const customComponents = {
    Option: ({ children, ...props }: any) => (
        <chakraComponents.Option {...props}>
            <div className="gap-2">
                <div className=" font-bold">
                    {children}
                </div>
                <div className=" italic flex  gap-3">
                    <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                        {props.data.full_name}
                    </span>
                    {props.data.group && (<span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                        {props.data.group}
                    </span>)}
                </div>
            </div>
        </chakraComponents.Option >
    )
};

const StudentDetails = ({ student }: any) => {

    const { data, isLoading, isFetching } = useGetQuery({
        method: 'xceed.api.report.library_access.get_student_information',
        body: {
            student
        },
        providesTags: [REPORT_TAGS.LIST]
    })

    const studentData = _.get(data, 'message', [])
    return (
        <>
            <Stack className="w-full mb-4 bg-gray-50 border border-gray-200 rounded-md p-3 "
                direction={{
                    base: "column",
                    sm: "column",
                    md: "row"
                }}

            >
                <Box className=" flex items-center justify-start w-full md:border-r border-r-black border-r-1 border-dashed " >
                    <Box className="flex flex-col justify-between h-full">
                        <Box className="text-black opacity-75 text-sm font-medium">
                            Student Name
                        </Box>
                        <Box className="text-black text-md font-semibold" >
                            {_.get(studentData, 'full_name', '')}
                        </Box>
                    </Box>
                </Box>
                <Box className=" flex items-center justify-start md:justify-center w-full md:border-r border-r-black border-r-1 border-dashed " >
                    <Box className="flex flex-col justify-between h-full">
                        <Box className="text-black opacity-75 text-sm font-medium">
                            NIC
                        </Box>
                        <Box className="text-black text-md font-semibold">
                            {_.get(studentData, 'nic', '')}
                        </Box>
                    </Box>
                </Box>
                <Box className=" flex items-center justify-start md:justify-center w-full md:border-r border-r-black border-r-1 border-dashed ">
                    <Box className="flex flex-col justify-between h-full">
                        <Box className="text-black opacity-75 text-sm font-medium">
                            Designation
                        </Box>
                        <Box className="text-black text-md font-semibold">
                            {_.get(studentData, 'designation', '')}
                        </Box>
                    </Box>
                </Box>
                <Box className=" flex items-center justify-start md:justify-center w-full md:border-r border-r-black border-r-1 border-dashed ">
                    <Box className="flex flex-col justify-between h-full">
                        <Box className="text-black opacity-75 text-sm font-medium">
                            Mobile Number
                        </Box>
                        <Box className="text-black text-md font-semibold">
                            {_.get(studentData, 'mobile_number', '')}
                        </Box>
                    </Box>
                </Box>
                <Box className=" flex items-center justify-start md:justify-center w-full">
                    <Box className="flex flex-col justify-between h-full">
                        <Box className="text-black opacity-75 text-sm font-medium">
                            Email Address
                        </Box>
                        <Box className="text-black text-md font-semibold">
                            {_.get(studentData, 'email', '')}
                        </Box>
                    </Box>
                </Box>
            </Stack>

        </>
    )
}