export const XceedLogo = () => {

    const logoSvg = (
        <svg width="110" height="32" viewBox="0 0 146 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_15_7739)">
                <path d="M27.8259 2.95245C26.8237 1.98682 25.8428 1.04226 24.7595 -1.23978e-05C23.1722 1.59596 21.5154 3.28008 19.8392 4.94694C19.399 5.38378 18.9587 5.82252 18.5184 6.25936L16.767 8.04501L14.9924 6.28427C12.0785 3.39312 9.58172 0.913891 8.79966 0.137936L8.78614 0.151348C8.78228 0.149432 8.77842 0.1456 8.77456 0.141768C8.63745 0.283547 8.49456 0.432989 8.3536 0.580517L5.86646 3.04825L16.7767 13.8752L19.7311 10.986L21.5192 9.23673C23.5835 7.18859 26.1073 4.68446 27.5227 3.28008C27.6347 3.16895 27.7371 3.04825 27.8259 2.95245Z" fill="#09B562" />
                <path d="M14.8996 16.3506L11.9027 13.3771C12.005 13.4173 3.02585 22.7096 3.08957 22.6924L5.9996 25.8901L14.8996 16.3506Z" fill="#09B562" />
                <path d="M9.97559 11.465L6.9806 8.49336C6.9806 8.63706 0 15.9387 0 15.9866L2.91003 19.1843L9.97559 11.465Z" fill="#09B562" />
                <path d="M30.3477 9.7732L27.4377 6.5755L18.7771 15.8601L21.7586 18.8183C23.1296 17.2933 30.3477 9.81918 30.3477 9.7732Z" fill="#09B562" />
                <path d="M33.4374 16.479L30.5274 13.2813L23.6974 20.742L26.6769 23.6982C27.8722 22.3494 33.4374 16.5231 33.4374 16.479Z" fill="#09B562" />
                <path d="M27.7313 29.0916C26.0802 27.465 21.1678 22.6004 18.2944 19.761L17.6475 19.077L16.8249 18.2072L16.7979 18.234L12.1963 22.7882L6.30671 28.6165C6.1503 28.7697 6.01127 28.9422 5.89734 29.0686C5.94755 29.1184 5.99968 29.1702 6.04989 29.2181C6.97677 30.1396 7.87662 31.0344 8.84792 32C9.05068 31.8103 9.32681 31.5651 9.58943 31.3064L16.964 23.9914C19.1518 26.1468 23.0428 29.9921 25.0066 31.9253C25.8717 31.0248 26.8121 30.0477 27.7313 29.0916Z" fill="#09B562" />
                <path d="M16.7979 18.234L17.6475 19.077L16.8249 18.2072L16.7979 18.234Z" fill="#09B562" />
                <path d="M60.0602 26.6622H54.8696L49.7679 19.5713L44.5754 26.6622H39.1802L46.8521 16.0805L38.7863 5.04084H44.1873L49.6269 12.6988L55.14 5.04084H60.57L52.423 16.2088L60.0602 26.6622Z" fill="#09B562" />
                <path d="M66.9751 20.8531C67.6046 21.47 68.3597 21.9528 69.219 22.29C70.0802 22.6272 71.0476 22.7997 72.0942 22.7997C73.1157 22.7997 74.0851 22.6042 74.9734 22.2211C75.8616 21.8359 76.69 21.2497 77.4316 20.4756L77.6961 20.1997L80.7645 23.0641L80.5289 23.3419C79.5132 24.5355 78.2503 25.4475 76.7789 26.0548C75.3152 26.6564 73.6796 26.963 71.9166 26.963C70.254 26.963 68.7015 26.689 67.3015 26.1468C65.8996 25.6046 64.6541 24.821 63.5997 23.817C62.5454 22.8112 61.7189 21.6271 61.1416 20.2955C60.5623 18.962 60.2687 17.4676 60.2687 15.8544C60.2687 14.2393 60.5623 12.7448 61.1416 11.4132C61.7189 10.0817 62.5415 8.89762 63.5843 7.89367C64.627 6.88971 65.8687 6.10418 67.2706 5.56197C68.6706 5.02167 70.2231 4.74578 71.8857 4.74578C73.6719 4.74578 75.319 5.05616 76.7808 5.66926C78.2522 6.2862 79.5132 7.20393 80.5289 8.39756L80.7645 8.67537L77.6922 11.5416L77.4277 11.26C76.6862 10.4687 75.8597 9.87091 74.9734 9.48773C74.087 9.10454 73.1177 8.90911 72.0942 8.90911C71.0476 8.90911 70.0802 9.07963 69.219 9.41875C68.3597 9.75596 67.6046 10.2388 66.9751 10.8557C66.3437 11.4726 65.8416 12.2122 65.4844 13.0552C65.1252 13.8982 64.9437 14.8389 64.9437 15.8563C64.9437 16.8718 65.1252 17.8144 65.4844 18.6574C65.8416 19.4947 66.3437 20.2361 66.9751 20.8531Z" fill="#09B562" />
                <path d="M88.2761 14.0381H98.4255V17.9926H88.2761V22.6196H99.7173V26.6622H83.5992V5.04084H100.136V9.08347H88.2761V14.0381Z" fill="#09B562" />
                <path d="M108.395 14.0381H118.545V17.9926H108.395V22.6196H119.837V26.6622H103.718V5.04084H120.256V9.08347H108.395V14.0381Z" fill="#09B562" />
                <path d="M143.773 21.5332C142.779 23.1579 141.367 24.434 139.577 25.3268C137.799 26.2139 135.735 26.6641 133.442 26.6641H123.838V5.04083H133.442C135.735 5.04083 137.799 5.49108 139.577 6.37816C141.367 7.27098 142.779 8.547 143.773 10.1717C144.768 11.7945 145.27 13.7047 145.27 15.8525C145.27 17.9983 144.766 19.9104 143.773 21.5332ZM128.514 22.6196H133.203C134.715 22.6196 136.034 22.3341 137.123 21.7689C138.206 21.2075 139.064 20.4239 139.676 19.4353C140.286 18.4486 140.595 17.2511 140.595 15.8812C140.595 14.5094 140.286 13.3024 139.674 12.2946C139.064 11.2887 138.204 10.4936 137.123 9.93414C136.032 9.36894 134.713 9.08346 133.203 9.08346H128.514V22.6196Z" fill="#09B562" />
            </g>
            <defs>
                <clipPath id="clip0_15_7739">
                    <rect width="145.27" height="32" fill="white" transform="matrix(1 0 0 -1 0 32)" />
                </clipPath>
            </defs>
        </svg>

    )

    return (
        <>
            {logoSvg}
        </>
    )
}