import _ from "lodash";
import React from "react";
import { printAction } from "../../../actions";
import { PRINT } from "../../../consts/methods.consts";
import { DefaultCell, ResourcePersonCell } from "./components/cells";

const dailyProgramConfig = {
  searchApi: "xceed.api.report.daily_program_generic_report",
  excelFileName: "session-report",
  title: "Daily Program Schedule",
  sections: [
    {
      selectionTitle: "Date Range",
      grid: { base: 1, md: 2, lg: 4, xl: 4 },
      filters: [
        {
          is_required: true,
          label: "Date",
          type: "date",
          key: "from",
        },
      ],
    },
  ],

  tableConfig: {
    column: [
      {
        header: "Start",
        accessor: "start",
        width: 200,
      },
      {
        header: "End",
        accessor: "end",
        width: 200,
      },
      {
        header: "Session Topic",
        accessor: "title",
        width: 400,
      },
      {
        header: "Module Name",
        accessor: "module_name",
        // customRenderer: (value: any, item: any) => (
        //     React.createElement(DefaultCell, { navigateTo: `/app/modules/${_.get(item, 'module')}`, value: value })
        // ),
        width: 400,
      },
      {
        header: "Program Name",
        accessor: "Program_name",
        // customRenderer: (value: any, item: any) => (
        //     React.createElement(DefaultCell, { navigateTo: `/app/program/${_.get(item, 'program')}`, value: value })
        // ),
        width: 400,
      },
      {
        header: "Resource Person",
        accessor: "resource_persons",
        customRenderer: (value: any, item: any) =>
          React.createElement(ResourcePersonCell, {
            navigateTo: `/app/employee/${_.get(item, "name")}`,
            values: value,
          }),
        width: 200,
      },
      {
        header: "Program Assistant",
        accessor: "program_assistant",
        // customRenderer: (value: any, item: any) => (
        //     React.createElement(DefaultCell, { navigateTo: `/app/employee/${_.get(item, 'program_assistant_name')}`, value: value })
        // ),
        width: 200,
      },
      {
        header: "Class Room",
        accessor: "class_name",
        width: 50,
      },
    ],
  },
  actions: [
    {
      text: "Print Daily Program",
      onClick: async (filter: any, data: any, setLoading: any) => {
        if (filter.from) {
          try {
            await printAction(PRINT.PRINT_DAILY_PROGRAM, filter, setLoading);
          } catch (error: any) {
            throw error;
          }
        } else {
          const error: any = new Error("Please select date ");
          error.title = "Warning";
          error.status = "warning";
          throw error;
        }
      },
      buttonVariant: "solid",
      buttonColorScheme: "teal",
    },
  ],
};
export default dailyProgramConfig;
