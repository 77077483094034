import { SimpleGrid } from "@chakra-ui/layout";
import { useDisclosure, useToast } from "@chakra-ui/react";
import { chakraComponents } from "chakra-react-select";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { EXAM, SUBJECT } from "../../../consts/methods.consts";
import { DROPDOWN_TAGS, EXAM_TAGS } from "../../../consts/tags.consts";
import apiService, { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import ChakraModal from "../../common/chakra-modal/ChakraModal";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { FormInput } from "../../common/form-controllers/FormInput";
import FormSelect from "../../common/form-controllers/FormSelect";
import InnerLoading from "../../common/inner-loading/InnterLoading";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { SectionCard, SectionCardCreatable } from "../../common/section-card/SectionCard";
import { useObjChange } from "../../hooks";
import { customeJsonParser } from "../../../utils/utils";


const ViewExamPage = () => {
    const { id } = useParams()
    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());
    const dispatch: any = useDispatch()
    const disclosure = useDisclosure()
    const tableActionref: any = useRef('0')
    const [create] = usePostMutation();

    const { data: _data, isLoading, isFetching } = useGetQuery({
        method: EXAM.GET,
        body: { id },
        providesTags: [EXAM_TAGS.VIEW]
    })

    const { data: initData } = useGetQuery({
        method: EXAM.INIT,
        body: { id },
        providesTags: [EXAM_TAGS.VIEW, DROPDOWN_TAGS.LIST]

    })


    const data = _.get(_data, ['message'], {})
    const services = _.get(initData, 'message.services', [])
    const subjects = _.get(initData, 'message.subjects', [])

    const stringifiedData = JSON.stringify(data)

    const formik = useFormik({
        initialValues: { exam_subjects: [] },
        validationSchema: Yup.object({

        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: EXAM.UPDATE,
                    body: values,
                    invalidatesTags: [EXAM_TAGS.VIEW, EXAM_TAGS.LIST, DROPDOWN_TAGS.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription("Exam have been saved.").showSuccessToast();

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const deleteHandler = async () => {
        try {
            await create({
                method: EXAM.DELETE,
                body: { id },
                invalidatesTags: [EXAM_TAGS.LIST, DROPDOWN_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Exam is deleted successfully.").showSuccessToast();

            setTimeout(() => {
                navigate(`/app/exam`);
            }, 1500)

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            // toast.setTitle("Error").setDescription('Failed to delete the module basket').showErrorToast();
        }
    }

    const childFormik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            subject: Yup.string().required("Subject is required"),
            start_date_and_time: Yup.string().required("Exam start date is required"),
            end_date_and_time: Yup.string().required("Exam end date is required"),
            exam_center: Yup.string().required("Exam end date is required"),
        }),
        onSubmit: async (values: any) => {

            if (tableActionref.current === 1) {
                disclosure.onClose()
                try {
                    const res = await dispatch(apiService.endpoints.post.initiate({
                        method: EXAM.EDIT_SUBJECT,
                        body: { ...values, id },
                        invalidatesTags: [EXAM_TAGS.VIEW]
                    })).unwrap()

                    childFormik.resetForm()

                } catch (error: any) {
                    let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                    if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
                }


            } else {
                const alreadyExsist = _.get(formik.values, 'exam_subjects', [])?.findIndex((line: any) => (line.subject === values.subject))

                if (alreadyExsist > -1) {
                    toast.setTitle("Error").setDescription("This Subject Already exists").showErrorToast();
                    return
                }
                disclosure.onClose()
                try {

                    const res = await dispatch(apiService.endpoints.post.initiate({
                        method: EXAM.ADD_SUBJECT,
                        body: { ...values, id },
                        invalidatesTags: [EXAM_TAGS.VIEW]
                    })).unwrap()

                    childFormik.resetForm()

                } catch (error: any) {
                    let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                    if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
                }
            }


        }
    })

    const subjectOnDelete = async (item: any) => {
        try {
            const res = await dispatch(apiService.endpoints.post.initiate({
                method: EXAM.DELETE_SUBJECTS,
                body: { id: item.name },
                invalidatesTags: [EXAM_TAGS.VIEW]
            })).unwrap()
        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        }
    }

    const { isChanged, setInitialObj } = useObjChange(formik.values)

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
        }
    }, [stringifiedData])

    const MODULE_LIST_TABLE_COLUMNS: ChakraTableColumns = [
        {
            header: "Subject",
            accessor: "subject_name",
        },
        {
            header: "Start Date and Time",
            accessor: "start_date_and_time",
        },
        {
            header: "End Date and Time",
            accessor: "end_date_and_time",
        },
        {
            header: "Exam Center",
            accessor: "exam_center",
        },
        {
            header: "Action",
            accessor: "",
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick(item: any) {
                        subjectOnDelete(item)
                        // const newItems = _.get(formik.values, 'exam_subjects', []).filter((i: any) => (
                        //     i?.subject !== item?.subject
                        // ))
                        // formik.setFieldValue('exam_subjects', newItems)
                    },

                },
                {
                    label: "Edit",
                    confirmation: false,
                    onClick: (item: any) => {
                        tableActionref.current = 1
                        childFormik.setValues(item)
                        disclosure.onOpen();
                    }
                }
            ]
        },
    ]

    const actions: PageHeaderActions = [
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Delete",
            onClick: deleteHandler,
            buttonVariant: "solid",
            buttonColorScheme: "red"
        },
    ]

    const creatable: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            childFormik.setValues({})
            tableActionref.current = 0
            disclosure.onOpen();
        }
    }

    if (isLoading) {
        return (
            <>
                <PageHeader isLoading={isLoading} enableBackButton actions={actions} title={'Module Basket'} />
                <InnerLoading />
            </>
        )
    }

    return (
        <div>
            <PageHeader backButtonCofirmation={isChanged} isLoading={isLoading} enableBackButton actions={actions} title={_.get(data, 'title', '')}
                subtitle={'Exam'} />
            <SectionCard backgroundColor="bg-white" title="Basic Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 3 }} spacing={3}>
                    <FormInput isRequired label="Exam Name" formik={formik} name="title" />
                    <FormSelect components={customComponents} options={services} isRequired formik={formik} name={'service'}
                        label={'Service'} />
                </SimpleGrid>
            </SectionCard>

            <SectionCard creatable={creatable} title="Subjects" p={3}>
                <ChakraTable size={"sm"} header
                    columns={MODULE_LIST_TABLE_COLUMNS}
                    data={_.get(formik.values, 'exam_subjects', [])} />
                <ChakraModal primaryAction={childFormik.submitForm} primaryButtonText={"Add"}
                    modalTitle={_.get(modalTitle, tableActionref.current, '')} {...disclosure}>
                    <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                        <FormSelect components={customComponents} options={subjects} isRequired formik={childFormik} name={'subject'}
                            label={'Subject'} />
                        <FormInput type={'datetime-local'} isRequired label="Exam Start Date and Time" formik={childFormik} name="start_date_and_time" />
                        <FormInput type={'datetime-local'} isRequired label="Exam End Date and Time" formik={childFormik} name="end_date_and_time" />
                        <FormInput isRequired label="Exam Center" formik={childFormik} name="exam_center" />
                    </SimpleGrid>
                </ChakraModal>
            </SectionCard>
        </div>
    )
}

const customComponents = {
    Option: ({ children, ...props }: any) => (
        <chakraComponents.Option {...props}>
            <div className="gap-2">
                <div className=" font-bold">
                    {children}
                </div>
                <div className=" italic flex  gap-3">
                    <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                        {props.data.code}
                    </span>
                    {props.data.group && (<span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                        {props.data.group}
                    </span>)}
                </div>
            </div>
        </chakraComponents.Option >
    )
};

export default ViewExamPage

const modalTitle = {
    0: 'Add new Subject',
    1: 'Edit Subject'
}