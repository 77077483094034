import { SimpleGrid, useDisclosure, useToast } from "@chakra-ui/react"
import axios from "axios"
import { useFormik } from "formik"
import _, { get } from "lodash"
import React, { useState } from "react"
import * as Yup from "yup"
import { BASE_URL } from "../../../../consts/app.consts"
import { PRINT } from "../../../../consts/methods.consts"
import { REPORT_TAGS } from "../../../../consts/tags.consts"
import { useGetQuery } from "../../../../services/api.service"
import { ToastService } from "../../../../services/toast.service"
import ChakraModal from "../../../common/chakra-modal/ChakraModal"
import ChakraTable from "../../../common/chakra-table/ChakraTable"
import FormSelect from "../../../common/form-controllers/FormSelect"
import PageHeader, { PageHeaderActions } from "../../../common/page-header/PageHeader"
import { SectionCard } from "../../../common/section-card/SectionCard"
import { useTableDataProvider } from "../../../hooks"
import { BadgeCell, DefaultCell } from "../components/cells"

const AttendanceReportPage = () => {
    const [length, setLength] = useState(15)
    const disclosure = useDisclosure()
    const [loading, setLoading] = useState(false)
    const [page, setPage] = useState(0)
    const [filter, setFilter]: any = useState({})
    const toast = new ToastService(useToast());

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            program: Yup.string().required('Program is required')
        }),
        onSubmit: async (values: any) => {
            setFilter(values)
        }
    })

    const studentFormik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            student: Yup.string().required('Student is required')
        }),
        onSubmit: async (values: any) => {
            disclosure.onClose()
            if (get(formik.values, 'program') && get(formik.values, 'program_module') && get(values, 'student')) {
                setLoading(true)
                try {
                    let response = await axios.post(`${BASE_URL}/method/${PRINT.PRINT_ATTENDANCE_CERTIFICATE}`, {
                        module: get(formik.values, 'program_module'),
                        program: get(formik.values, 'program'),
                        student: get(values, 'student'),
                    }, {
                        withCredentials: true,
                        responseType: "blob"
                    })
                    if (response.data) {
                        const blobURL = URL.createObjectURL(response.data);

                        const iframe: any = document.createElement('iframe');
                        document.body.appendChild(iframe);

                        iframe.style.display = 'none';
                        iframe.src = blobURL;

                        iframe.onload = () => {
                            setTimeout(() => {
                                iframe.focus();
                                // @ts-ignore
                                iframe.contentWindow.print();

                            }, 1);
                        }

                    }
                    setLoading(false)

                } catch (error: any) {
                    setLoading(false)
                    toast.setDescription('Unable to print attendance certificate').setTitle('Error').setStatus("error").show()
                } finally {
                    studentFormik.resetForm()
                }
            }
            else {
                toast.setDescription('please select program, program module and student').setTitle('Warning').setStatus("warning").show()
            }
        }
    })

    const { data, isLoading, isFetching } = useGetQuery({
        method: 'xceed.api.report.attendance.generate_attendance_report',
        body: {
            page: page,
            length: Number(length),
            filter: filter
        },
        providesTags: [REPORT_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const onLengthChange = (length: any) => {
        setPage(0)
        setLength(Number(length))
    }

    const onPage = (page: any) => {
        setPage(page)
    }

    const generateAttendanceReport = async () => {
        if (get(formik.values, 'program') && get(formik.values, 'program_module')) {
            setLoading(true)
            try {
                let response = await axios.post(`${BASE_URL}/method/xceed.api.export_excel.attendance.export_attendance_report`, { filter: formik.values }, {
                    withCredentials: true,
                    responseType: "blob"
                })
                if (response) {
                    const href = URL.createObjectURL(response.data);
                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `attendance-summary.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                }
                setLoading(false)

            } catch (error: any) {
                setLoading(false)
                toast.setDescription('Unable to generate attendance report').setTitle('Error').setStatus("error").show()
            }
        }
        else {
            toast.setDescription('please select program and program module ').setTitle('Warning').setStatus("warning").show()
        }
    }

    const onClickModalOpen = () => {
        if (!get(formik.values, 'program') || !get(formik.values, 'program_module')) {
            toast.setDescription('Please select program and program module').setTitle('Warning').setStatus('warning').show()
            return
        }

        disclosure.onOpen()
    }

    const executeReportHandler = () => {
        if (!get(formik.values, 'program')) {
            toast.setDescription('Please select at least a program').setTitle('Warning').setStatus('warning').show()
        }
        formik.submitForm()
    }

    const moreActions: PageHeaderActions = [
        {
            text: "Generate Attendance Report",
            onClick: generateAttendanceReport,
            isDisabled: loading || isFetching || isLoading,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            confirmation: false
        },
        {
            text: "Generate Attendance Certificate",
            onClick: onClickModalOpen,
            isDisabled: loading || isFetching || isLoading,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            confirmation: false
        },
    ]

    const actions: PageHeaderActions = [
        {
            text: "Execute Report",
            onClick: executeReportHandler,
            buttonVariant: "solid",
            isDisabled: loading || isFetching || isLoading,
            buttonColorScheme: "teal",
            confirmation: false
        },
        {
            text: "Reset",
            onClick: () => formik.resetForm(),
            isDisabled: loading || isFetching || isLoading,
            buttonVariant: "solid",
            buttonColorScheme: "red",
            confirmation: false
        }
    ]

    return (
        <>
            <div>
                <PageHeader enableBackButton isLoading={isFetching || isLoading || loading}
                    actions={actions} moreActions={moreActions}
                    subtitle="View and Manage Report" title={'Attendance Report'} />

                <SectionCard backgroundColor=" bg-white" title={'Filters'} p={3} mb={4}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                        <FormSelect method={'xceed.api.report.attendance.get_programs_by_student'} formik={formik} body={{}}
                            name={'program'}
                            label={'Program'} isRequired />
                        <FormSelect method={'xceed.api.report.attendance.get_program_modules_by_student_and_program'} formik={formik} body={{
                            program: get(formik.values, 'program')
                        }}
                            name={'program_module'}
                            label={'Program Module'} />
                        <FormSelect method={'xceed.api.report.attendance.get_session_by_program_module'} formik={formik} body={{
                            program_module: get(formik.values, 'program_module')
                        }}
                            name={'session'}
                            label={'Session'} />
                    </SimpleGrid>
                </SectionCard>
                <ChakraTable onPage={onPage} onLength={onLengthChange} lengthPaginations isFetching={isFetching} isLoading={isLoading || loading} size={"sm"}
                    paginationDetails={paginationDetails}
                    pagination
                    data={dataList || []}
                    title="Report"
                    columns={column} />
                <ChakraModal primaryAction={studentFormik.submitForm} primaryButtonText={"Submit"} modalTitle="Select Student" {...disclosure}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 1, xl: 1 }} spacing={3}>
                        <FormSelect body={{ programmed_module: get(formik.values, 'program_module') }} method="xceed.api.report.attendance.get_enrolled_student" isRequired formik={studentFormik} name="student" label="Student" />
                    </SimpleGrid>
                </ChakraModal>

            </div>
        </>
    )
}

export default AttendanceReportPage

const column = [
    {
        header: "NIC",
        accessor: "nic",
        width: 200
    },
    {
        header: "Student Name",
        accessor: "full_name",
        customRenderer: (value: any, item: any) => (
            React.createElement(DefaultCell, { navigateTo: `/app/student/${_.get(item, 'student_id')}`, value: value })
        ),
        width: 200
    },
    {
        header: "Module",
        accessor: "module_title",
        width: 200
    },
    {
        header: "Session",
        accessor: "session_title",
        // customRenderer: (value: any, item: any) => (
        //     React.createElement(DefaultCell, { navigateTo: `/app/session/${_.get(item, 'session_id')}?pm=${_.get(item, 'program_module_id')}`, value: value })
        // ),
        width: 200
    },
    {
        header: "Attendance",
        accessor: "status",
        customRenderer: (value: any, item: any) => (
            React.createElement(BadgeCell, { config: attendanceStatusBadgeConfig, value: value })
        ),
        width: 200
    }
]

const attendanceStatusBadgeConfig = {
    "Present": "bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded",
    "Absent": "bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded"
}