import moment from "moment";
import PageHeader from "../../common/page-header/PageHeader";
import ProgramCalendar from "./components/ProgramCalendar";
import SessionCalendar from "./components/SessionCalendar";
import { momentLocalizer } from "react-big-calendar";
import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import ProgramTable from "./components/ProgramTable";
import ModuleTable from "./components/ModuleTable";


moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const CalendarPage = () => {

    return (
        <div>
            <PageHeader enableBackButton title={'Programs & Sessions Calendar'}
                subtitle={'Calendar'} />

            <Tabs className="mt-3">
                <TabList>
                    <Tab>Programs</Tab>
                    <Tab>Sessions</Tab>
                    <Tab>Modules</Tab>
                </TabList>

                <TabPanels className="animation-form">
                    <TabPanel className="!p-0 !pt-3">
                        <ProgramTable />
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <SessionCalendar />
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ModuleTable />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    )
}

export default CalendarPage