import { SimpleGrid } from "@chakra-ui/layout";
import { Alert, AlertIcon, Button, CloseButton, ListItem, OrderedList, useToast } from "@chakra-ui/react";
import { chakraComponents } from "chakra-react-select";
import { useFormik } from "formik";
import _ from "lodash";
import { useRef, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { DATAIMPORT, EXAM, EXAM_ENROLLMENT } from "../../../consts/methods.consts";
import { DATA_IMPORT_TAG, DROPDOWN_TAGS, EXAM_ENROLLMENT_TAGS, EXAM_TAGS } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { FormCheckBox } from "../../common/form-controllers/FormCheckBox";
import { FormDropdown } from "../../common/form-controllers/FormDropdown";
import { FormInput } from "../../common/form-controllers/FormInput";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { SectionCard } from "../../common/section-card/SectionCard";
import FormSelect from "../../common/form-controllers/FormSelect";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";
import { FormFile } from "../../common/form-controllers/FormInputFile";
import { useDispatch } from "react-redux";
import { postMutation } from "../../../actions";

const UserNewExamRegisterPage = () => {

    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());
    const dispatch: any = useDispatch()
    const [show, setShow] = useState<boolean>(true)
    const [create] = usePostMutation();
    const firstAttemptRef: any = useRef('')

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            ...(firstAttemptRef.current === "NO") ? {
                number_of_times_that_sat_for_the_examination: Yup.number().required("Number of times that sat for the examination is required"),
                paid_amount: Yup.number().required("Paid Amount is required"),
                payment_receipt_no: Yup.string().required("Payment receipt no is required"),
                attach: Yup.string().required("Payment receipt no is required"),
            } : {},
            first_attempt: Yup.string().required("Is this your first attempt in this examination is required"),
            name_with_initials: Yup.string().required("Name with Initials is required"),
            nic: Yup.string().matches(/^([0-9]{9}[x|X|v|V]|[0-9]{12})$/, 'Enter valid NIC number').required('NIC is required'),
            full_name: Yup.string().required("Full name is required"),
            mobile: Yup.string().matches(/^\d{9}$/, 'Please enter a valid mobile number').required("Mobile number is required"),
            telephone_personal_land_line: Yup.string().matches(/^\d{9}$/, 'Please enter a valid mobile number'),
            institution: Yup.string().required("Institution is required"),
            email_address: Yup.string().email('Invalid email address').required("Email address is required"),
            designation: Yup.string().required("Designation is required"),
            official_address: Yup.string().required("Official address is required"),
            telephone_office_land_line: Yup.string().matches(/^\d{10}$/, 'Please enter a valid mobile number').required("Telephone (Office Land Line) is required"),
            service: Yup.string().required("service is required"),
            name_of_the_examination: Yup.string().required("Examination name is required"),
            medium: Yup.string().required("medium is required"),

        }),
        onSubmit: async (values: any) => {
            try {
                let formData = new FormData();
                // @ts-ignore
                formData.append('file', _.get(formik.values, 'attach'));
                formData.append('is_private', '1');
                formData.append('folder', 'Home');
                formData.append('doctype', 'Exam Enrollment');
                formData.append('fieldname', 'receipt');
                formData.append('doc_data', JSON.stringify(values));

                let res: any = await dispatch(postMutation(EXAM_ENROLLMENT.ADD, formData, []))

                if (!res) {
                    throw new Error("Your application submition failed")
                }

                formik.resetForm()

                toast.setTitle("Success").setDescription("Your application has been submitted successfully.").showSuccessToast();

                setTimeout(() => {
                    navigate(`/`);
                }, 1500)

            } catch (error: any) {
                console.log(error, 'err')
                // let message = JSON.parse(_.get(error?.data, '_server_messages', {}))
                // if (message) toast.setTitle("Error").setDescription(JSON.parse(message)?.message).showErrorToast();
                toast.setTitle("Error").setDescription('Your application submition failed').showErrorToast();
            }
        }
    })

    const { data: initData } = useGetQuery({
        method: EXAM_ENROLLMENT.INIT,
        body: {
            exam: _.get(formik.values, 'name_of_the_examination'),
            service: _.get(formik.values, 'service'),
        },
        providesTags: [EXAM_ENROLLMENT_TAGS.VIEW, DROPDOWN_TAGS.LIST]

    })

    const services = _.get(initData, 'message.services', [])
    const subjects = _.get(initData, 'message.subjects', [])
    const exams = _.get(initData, 'message.exams', [])
    const is_first_attempt = _.get(formik.values, 'first_attempt')
    firstAttemptRef.current = _.get(formik.values, 'first_attempt')

    const alertCloseHandler = () => {
        setShow(false)
    }

    const actions: PageHeaderActions = [
        {
            text: "Submit",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const pymentDetailsMarkup = (
        <>
            <FormInput isRequired type="number" label="Number of Times that sat for the Examination:" formik={formik} name="number_of_times_that_sat_for_the_examination" />
            <FormInput isRequired type="number" label="Paid Amount" formik={formik} name="paid_amount" />
            <FormInput isRequired label="Payment Receipt No" formik={formik} name="payment_receipt_no" />
            <FormFile size="sm" sx={{
                '&::-webkit-file-upload-button': {
                    fontSize: '12px !important',
                    textAlign: 'center !important',
                    lineHeight: '100% !important',
                    paddingRight: '20px !important'
                },
            }} isRequired type="file" id="attach" name="attach" label="Payment Receipt No" formik={formik} />
        </>

    )

    return (
        <div className=" px-[25vw] py-5 ">
            <PageHeader enableBackButton title={'SLIDA Examination Online Application'} subtitle={'Online Application'} />
            <SectionCard title="SLIDA Examination Online Application">
                <p>Instructions:</p>
                <OrderedList marginLeft={12} spacing={2}>
                    <ListItem>Please read the information specified in the government gazette dated 05.05.2023 carefully before submitting the application. Necessary payments should be made, if it is applicable.</ListItem>
                    <ListItem>You should complete all required fields in the application and should double check the entered information before submitting the application.</ListItem>
                    <ListItem>After submitting the application, the admission card can be downloaded. The signed copy of the admission card should be brought to the Examination Hall.</ListItem>
                    <ListItem>Please Note that if the provided information is incorrect, your application will be rejected without notifying you. </ListItem>
                </OrderedList>

            </SectionCard>
            <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 2 }} spacing={3}>
                    <FormInput isRequired label="Name with Initials" formik={formik} name="name_with_initials" />
                    <FormInput isRequired label="Full Name" formik={formik} name="full_name" />
                    <FormInput isRequired label="National Identity Card No" formik={formik} name="nic" />

                    <FormInput isRequired label="Institution" formik={formik} name="institution" />
                    <FormInput isRequired label="Designation" formik={formik} name="designation" />

                    <FormSelect components={customComponents} options={services} isRequired formik={formik} name={'service'}
                        label={'Service'} />
                    <FormSelect components={customComponents} options={exams} isRequired formik={formik} name={'name_of_the_examination'}
                        label={'Name of the Examination'} />
                    <FormSelect isMulti components={customComponents} options={subjects} isRequired formik={formik} name={'subjects'}
                        label={'Subjects'} />
                    <FormDropdown options={mediums} formik={formik}
                        name={'medium'}
                        autoFocus
                        label={'Medium'} />
                </SimpleGrid>
            </SectionCard>
            <SectionCard backgroundColor=" bg-white">
                <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                    {show && <Alert status='warning' rounded={'md'} fontSize={'small'}>
                        <AlertIcon />
                        Please note that if you have sat for the examination previously, the necessary fees have to be paid. Please select option “No” to provide/upload payment details.
                        <CloseButton
                            alignSelf='flex-start'
                            position='relative'
                            right={-1}
                            top={-1}
                            onClick={alertCloseHandler}
                        />
                    </Alert>}
                    <SimpleGrid columns={{ base: 1, md: 1, lg: 2, xl: 2 }} spacing={3}>
                        <FormDropdown options={attempt} formik={formik} isRequired
                            name={'first_attempt'}
                            autoFocus
                            label={'Is this your first attempt in this examination'} />
                        {
                            is_first_attempt == 'NO' && (pymentDetailsMarkup)
                        }
                    </SimpleGrid>


                    {/* <FormCheckBox className="" isRequired label="Is this your first attempt in this examination" formik={formik} name="first_attempt" /> */}


                </SimpleGrid>
                <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} mt={3} spacing={3}>
                    <FormCheckBox isRequired label="I certified that the above information is accurate" formik={formik} name="is_certified" />
                </SimpleGrid>
            </SectionCard>
            <SectionCard backgroundColor=" bg-white" title="Contact Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 2 }} spacing={3}>
                    <FormInput isRequired type="number" label="Mobile" formik={formik} name="mobile" />
                    <FormInput label="Telephone (Personal Land Line)" type="number" formik={formik} name="telephone_personal_land_line" />
                    <FormInput isRequired label="Email Address" formik={formik} name="email_address" />
                    <FormInput isRequired label="Telephone (Office Land Line)" formik={formik} name="telephone_office_land_line" />
                    <FormTextArea rows={7} isRequired label="Official Address" formik={formik} name="official_address" />
                </SimpleGrid>
                <Button className="mt-5" colorScheme={'teal'} size={"md"}
                    onClick={formik.submitForm}>
                    Submit
                </Button>
            </SectionCard>
        </div>
    )
}

export default UserNewExamRegisterPage

const mediums = [
    { value: 'Sinhala', label: 'Sinhala' },
    { value: 'English', label: 'English' },
    { value: 'Tamil', label: 'Tamil' },
]

const attempt = [
    { value: 'YES', label: 'YES' },
    { value: 'NO', label: 'NO' },
]


const customComponents = {
    Option: ({ children, ...props }: any) => (
        <chakraComponents.Option {...props}>
            <div className="gap-2">
                <div className=" font-bold">
                    {children}
                </div>
                <div className=" italic flex  gap-3">
                    <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                        {props.data.code}
                    </span>
                    {props.data.group && (<span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                        {props.data.group}
                    </span>)}
                </div>
            </div>
        </chakraComponents.Option >
    )
};


const is_first_attempt_validation = {


}

const is_not_first_attempt_validation = {
    is_first_attempt: Yup.string().required("Is this your first attempt in this examination is required"),
    name_with_initials: Yup.string().required("Name with Initials is required"),
    nic: Yup.string().matches(/^([0-9]{9}[x|X|v|V]|[0-9]{12})$/, 'Enter valid NIC number').required('NIC is required'),
    full_name: Yup.string().required("Full name is required"),
    mobile: Yup.string().matches(/^\d{9}$/, 'Please enter a valid mobile number').required("Mobile number is required"),
    telephone_personal_land_line: Yup.string().matches(/^\d{9}$/, 'Please enter a valid mobile number'),
    institution: Yup.string().required("Institution is required"),
    email_address: Yup.string().email('Invalid email address').required("Email address is required"),
    designation: Yup.string().required("Designation is required"),
    official_address: Yup.string().required("Official address is required"),
    telephone_office_land_line: Yup.string().matches(/^\d{10}$/, 'Please enter a valid mobile number').required("Telephone (Office Land Line) is required"),
    service: Yup.string().required("service is required"),
    name_of_the_examination: Yup.string().required("Examination name is required"),
    medium: Yup.string().required("medium is required"),
}
