import { combineReducers } from "redux";
import { formStore, localStore } from '@dladio/hooks';

import _common from '../reducers/common/common-store';
import apiService from "../../services/api.service";
import _confirmationDialog from './confirm-modal'

const rootReducer = combineReducers({
    common: _common,
    formStore: formStore,
    localStore: localStore,
    confirmDialog: _confirmationDialog,
    [apiService.reducerPath]: apiService.reducer,
});

export default rootReducer;