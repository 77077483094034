import { SimpleGrid, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { printAction } from "../../../../../actions";
import { PRINT } from "../../../../../consts/methods.consts";
import { ToastService } from "../../../../../services/toast.service";
import ChakraModal from "../../../../common/chakra-modal/ChakraModal";
import { FormDropdown } from "../../../../common/form-controllers/FormDropdown";

const PrintTemplateSelector = ({ disclosure, programed_module, setLoading }: any) => {

    const toast = new ToastService(useToast())

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            template: Yup.string().required('Template is required'),
        }),
        onSubmit: (values: any) => {
            disclosure.onClose()
            printAction(PRINT.PRINT_SESSION_PLAN, { ...values, pm: programed_module }, setLoading).catch((error: any) => {
                if (error.message) toast.setTitle("Error").setDescription(error.message).showErrorToast();
            })
        }
    })

    return (
        <ChakraModal primaryAction={formik.submitForm} primaryButtonText={"Print"} modalTitle={`Select a template`} {...disclosure}>
            <SimpleGrid className={'rounded p-3'} columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                <FormDropdown options={[
                    { "value": 1, "label": 'Default' },
                    { "value": 2, "label": 'Training Consultancy - With Module' },
                    { "value": 3, "label": 'Training Consultancy - Without Module' }
                ]} formik={formik}
                    name={'template'}
                    autoFocus
                    label={'Template'} />
            </SimpleGrid>
        </ChakraModal>
    )

}

export default PrintTemplateSelector