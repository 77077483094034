import _ from "lodash"
import { useEffect } from "react"
import { RESERVED_CLASSROOM } from "../../../../consts/methods.consts"
import { DROPDOWN_TAGS } from "../../../../consts/tags.consts"
import { useGetQuery } from "../../../../services/api.service"
import FormSelect from "../../../common/form-controllers/FormSelect"

const ClassRoomSelector = ({ formik, body, name }: any) => {
    const { data: _data, isLoading } = useGetQuery({
        method: RESERVED_CLASSROOM.GET_AVAILABLE_CLASS_ROOMS,
        body,
        providesTags: [DROPDOWN_TAGS.LIST]
    })

    const options = _.get(_data, 'message')
    const selectedItemIndexOnOption = _.isArray(options) ? options.findIndex((item: any) => item.value == _.get(formik.values, name)) : 0

    useEffect(() => {
        if (selectedItemIndexOnOption > -1) {
            return
        }

        formik.setFieldValue(name, '')
    }, [selectedItemIndexOnOption])

    return (
        <FormSelect 
            isDisabled={!(_.get(formik.values, 'from') && _.get(formik.values, 'to'))}
            isRequired options={options}
            label="Class Room" formik={formik}
            name={name} />
    )

}

export default ClassRoomSelector