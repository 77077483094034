import { SimpleGrid } from "@chakra-ui/react";
import _ from "lodash";
import { useSelector } from "react-redux";
import { StatCard } from "../../..";
import { DASHBOARD } from "../../../../consts/methods.consts";
import { useGetQuery } from "../../../../services/api.service";

const OtherDashboard = () => {

    const { data, isLoading } = useGetQuery({
        method: DASHBOARD.GET_STAT,
        providesTags: []
    }, { refetchOnMountOrArgChange: true })

    const stats: any = _.get(data, 'message', [])
    return (
        <div className="">
            <SimpleGrid columns={{ base: 1, md: 4 }} gap={{ base: '4', md: '4' }}>
                {stats.map((stat: any, id: any) => (
                    <StatCard key={id} {...stat} />
                ))}
            </SimpleGrid>

            {/* <div className=" mt-10">
					<StudentByYearChart />
				</div> */}

        </div>

    )
}

export default OtherDashboard;
