import { Badge, Box, Button, HStack, Heading, Menu, MenuButton, MenuItem, MenuList, Stack, Text, ThemeTypings, ThemingProps, VStack, useBreakpointValue } from "@chakra-ui/react";
import { FiArrowLeft } from "react-icons/fi";
import { NavigateFunction, useNavigate } from "react-router";
import { boolean } from "yup";
import { showConfirmDialog } from "../../../store/reducers/confirm-modal";
import { useDispatch } from "react-redux";
import { IoIosArrowDown } from "react-icons/io";

type PageHeaderAction = {
    text: string,
    onClick: () => any,
    isDisabled?: boolean,
    confirmation?: boolean,
    buttonColorScheme?: ThemeTypings["colorSchemes"]
    buttonVariant?: ThemingProps<"Button">["variant"]
}

export type PageHeaderActions = Array<PageHeaderAction>;

export interface IPageHeaderProps {
    title: string,
    subtitle?: string,
    status?: string,
    statusRenderer?: JSX.Element,
    statusColorSchemes?: ThemeTypings["colorSchemes"],
    backButtonCofirmation?: boolean,
    actions?: PageHeaderActions
    moreActions?: PageHeaderActions
    actionRenderer?: JSX.Element,
    titleRenderer?: JSX.Element,
    subTitleRenderer?: JSX.Element,
    enableBackButton?: boolean,
    isLoading?: boolean
}

const PageHeader = ({
    moreActions = [],
    title,
    isLoading,
    backButtonCofirmation,
    enableBackButton,
    subtitle,
    statusColorSchemes = "green",
    status,
    statusRenderer,
    actions = [],
    actionRenderer,
    titleRenderer,
    subTitleRenderer }: IPageHeaderProps) => {

    const navigate: NavigateFunction = useNavigate();
    const dispatch = useDispatch()

    const onClickHandler = (confirm: Boolean | undefined, action: Function) => {
        if (confirm || confirm === undefined || confirm === null) {
            dispatch(
                showConfirmDialog({
                    title: "Confirmation Required!",
                    subtitle: "Are you sure you want to continue the action?",
                    onConfirm: action || (() => { })
                }))

        } else {
            action()
        }

    }

    const backButtonHandler = () => {
        if (backButtonCofirmation) {
            dispatch(
                showConfirmDialog({
                    title: "Confirmation Required!",
                    subtitle: "You have made changes but have not saved. Do you want to go back?",
                    onConfirm: () => navigate(-1)
                }))
        } else {
            navigate(-1)
        }
    }

    const actionMarkup = actions.map((action: PageHeaderAction, index: number) =>
        <Button key={index} colorScheme={action.buttonColorScheme || "blackAlpha"} size={"sm"} isDisabled={Boolean(action.isDisabled)}
            variant={action.buttonVariant || 'outline'} onClick={() => { onClickHandler(action?.confirmation, action.onClick) }}>
            {action.text}
        </Button>)

    return (
        <Box p={3} mb={4} w={"full"} className="bg-gray-50 border rounded relative" as="section" bg="bg-surface" >
            <Stack spacing="2" direction={{ base: 'column', lg: 'row' }} justify="space-between">
                <HStack alignItems={"center"} spacing={3} width={{ base: '50%', md: '100%', lg: '38%', xl: '55%' }}>
                    {/* Back button */}
                    {enableBackButton && (
                        <div onClick={backButtonHandler} className="border rounded bg-white p-3 hover:bg-gray-100 transition-all cursor-pointer">
                            <FiArrowLeft />
                        </div>
                    )}

                    {!isLoading && (
                        <VStack alignItems={"flex-start"} width={'100%'} className="truncate" spacing="1" >
                            <HStack alignItems={"center"} width={'100%'} className="truncate" spacing={2}>
                                {/* Title Renderer */}
                                {titleRenderer && (
                                    <HStack alignItems={"center"} spacing={2}>
                                        {titleRenderer}
                                    </HStack>
                                )}
                                {/* Title */}
                                {(!titleRenderer && title) && (
                                    <Heading size={"md"} fontWeight="semibold" className="truncate" width={'100%'}>
                                        {title}
                                    </Heading>
                                )}
                                {/* Status */}

                            </HStack>

                            <Stack
                                direction={{ base: 'column', sm: 'row' }}
                                spacing={{ base: '2', sm: '6' }}
                                color="muted">
                                {/* Subtitle Renderer */}
                                {subTitleRenderer && (
                                    <HStack alignItems={"center"} spacing={2}>
                                        {subTitleRenderer}
                                    </HStack>
                                )}
                                {/* Subtitle */}
                                <HStack>
                                    {(!subTitleRenderer && title) && <Text>{subtitle}</Text>}
                                </HStack>

                                {statusRenderer && (
                                    <HStack>
                                        {statusRenderer}
                                    </HStack>
                                )}
                                <HStack>
                                    {(status && !statusRenderer) && (
                                        <Badge className="mt-[6px]" variant='solid' colorScheme={statusColorSchemes}>
                                            {status}
                                        </Badge>
                                    )}
                                </HStack>
                            </Stack>
                        </VStack>
                    )}

                    {isLoading && (
                        <VStack spacing={'3'} alignItems={"flex-start"}>
                            <div className="!h-[22px] border rounded-md w-28 bg-gray-200 animate-pulse"></div>
                            <div className="!h-[15px] border rounded-md w-20 bg-gray-200 animate-pulse"></div>
                        </VStack>
                    )}
                </HStack>
                {(!actionRenderer && actions.length > 0) && (
                    <Stack direction="row" spacing="3">
                        {actionMarkup}
                        {(moreActions?.length > 0) && <Menu>
                            <MenuButton size={"sm"} colorScheme="teal" variant={"solid"} rightIcon={<IoIosArrowDown />} as={Button} >
                                More
                            </MenuButton>
                            <MenuList>
                                {
                                    moreActions?.map(({ isDisabled, confirmation, text, onClick = () => { } }: any, index: any) => {
                                        return (
                                            <MenuItem isDisabled={isDisabled} key={index} onClick={() => onClickHandler(confirmation, onClick)}>{text}</MenuItem>
                                        )
                                    })
                                }
                            </MenuList>
                        </Menu>}
                    </Stack>
                )}
                {actionRenderer}
            </Stack>

            {/* <div className=" absolute rounded-sm text-center bottom-[-25px] text-amber-600 px-2 text-xs py-0.5 bg-amber-200 left-0 right-0">Not Saved</div> */}
        </Box>
    )
}

export default PageHeader;