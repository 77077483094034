import * as Yup from "yup";
import { useState } from 'react';
import { FormInput } from '../../../common/form-controllers/FormInput';
import { useFormik } from 'formik';
import { ToastService } from '../../../../services/toast.service';
import { useLoginMutation, usePostMutation } from '../../../../services/api.service';
import { json, NavigateFunction, useNavigate } from 'react-router-dom';
import {
    Box,
    Button,
    Checkbox,
    Flex,
    Heading,
    HStack,
    Text,
    Stack,
    StackProps,
    useBreakpointValue,
    useToast,
    FormLabel,
    useDisclosure
} from '@chakra-ui/react';
import { AUTH } from "../../../../consts/methods.consts";
import { customeJsonParser } from "../../../../utils/utils";
import _ from "lodash";

export const SignInForm = (props: StackProps) => {
    const navigator: NavigateFunction = useNavigate()
    const toast = new ToastService(useToast())
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [create] = usePostMutation();
    const [login] = useLoginMutation();
    const isResetDisclosure = useDisclosure()
    const [isForgetForm, setIsForgetForm] = useState(false)


    const formik = useFormik({
        initialValues: {
            usr: "",
            pwd: "",
            device: "mobile"
        },
        validationSchema: Yup.object({
            usr: Yup.string().required("Email is required"),
            pwd: Yup.string().required("Password is required")
        }),
        onSubmit: async (values: any) => {
            try {
                setIsLoading(true);
                let data = await login(values).unwrap();
                if (typeof data == 'object' && data.hasOwnProperty("multiple_school")) {
                } else {
                    navigator("/app");
                }

            } catch (error:any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
                else  toast.setTitle("Error").setDescription("Invalid credentials. Try again").showErrorToast()
                // toast.setTitle("Error").setDescription("Invalid credentials. Try again").showErrorToast()
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        }
    })

    const forgetPasswordformik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            user: Yup.string().required("Email is required")
        }),
        onSubmit: async (values: any) => {
            try {
                setIsLoading(true);
                await create({
                    method: AUTH.FORGOT_PASSWORD,
                    body: values,
                    invalidatesTags: []
                }).unwrap()
                toast.setTitle("Success").setTitle("Password reset link sent")
                    .setDescription("We have sent a password reset link to your email address. Please click on the link in the email to reset your password.")
                    .setDuration(4500).showSuccessToast();
                isResetDisclosure.onClose()
            } catch (error) {
                toast.setTitle("Error").setDescription("Can't Reset password. Try again").showErrorToast()
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        }
    })

    const loginFormMarkup: any = (
        <div className="flex flex-col h-full">
            <Box px={{ base: '30px', md: '0' }}
                py={{ base: '40px', md: '0' }} className={' flex items-center justify-center flex-1'}>

                <div className=' space-y-4 lg:space-y-4 lg:mb-[20px]'>
                    <Text className={'font-[Poppins] text-opacity-60 text-gray-400 text-center lg:text-left font-normal text-xs lg:text-base leading-none'}>
                        Welcome back
                    </Text>
                    <Text className={'font-[Poppins] text-center lg:text-left font-semibold text-xl lg:text-3xl leading-none'}>
                        Login to your account
                    </Text>
                    <form onSubmit={formik.handleSubmit}>
                        <Stack spacing="6">
                            <Stack spacing="4">
                                <Box>
                                    <FormLabel fontFamily={'Poppins'} className={'!font-[Poppins] !text-sm lg:!text-lg !font-medium text-[#5A5A5D]'}>Email</FormLabel>
                                    <FormInput fontFamily={'Poppins'} fontSize={{ base: '12px', md: '16px' }} _placeholder={{ base: { color: '#BEBEBF', fontFamily: 'Poppins', fontStyle: 'normal' }, md: { color: '#BEBEBF', fontFamily: 'Poppins', fontSize: '16px', fontStyle: 'normal' } }} height={'48px'} placeholder="example@slida.gov.lk" focusBorderColor="#7CA7FA38" borderWidth={'0.746px'} isRequired formik={formik} name='usr' borderColor={"#BEBEBF"} borderRadius={'5.969px'} />
                                </Box>
                                <Box>
                                    <FormLabel fontFamily={'Poppins'} className={'!font-[Poppins] !text-sm lg:!text-lg !font-medium text-[#5A5A5D]'}>Password</FormLabel>
                                    <FormInput fontSize={{ base: '12px', md: '16px' }} fontFamily={'Poppins'} _placeholder={{ base: { color: '#BEBEBF', fontStyle: 'normal' }, md: { color: '#BEBEBF', fontFamily: 'Poppins', fontStyle: 'normal' } }} height={'48px'} placeholder="Enter your password" type={'password'} borderWidth={'0.746px'} focusBorderColor="#7CA7FA38" isRequired formik={formik} name='pwd' borderColor={"#BEBEBF"} borderRadius={'5.969px'} />
                                </Box>


                            </Stack>
                            <HStack justify="space-between">
                                <Button onClick={isResetDisclosure.onOpen} variant="link" colorScheme="blue" size="sm">
                                    Forgot password
                                </Button>
                            </HStack>
                            <Stack spacing="4">
                                <Button isLoading={isLoading} type={'submit'}
                                    className="!rounded-lg !font-[Poppins] !bg-[#09B562] !text-white hover:!bg-green-500 !transition-all !h-[45px] lg:!h-[52px] !text-base lg:!text-lg !font-semibold !leading-none">Login now</Button>
                            </Stack>
                        </Stack>
                    </form>
                </div>
            </Box>
        </div>
    )

    const forgotPasswordMarkup: any = (
        <div className="flex flex-col h-full">
            <Box px={{ base: '30px', md: '0' }}
                py={{ base: '40px', md: '0' }} className={' flex items-center justify-center flex-1'}>

                <div className=' space-y-4 lg:space-y-4 lg:mb-[20px]'>
                    <Text className={'font-[Poppins] text-opacity-60 text-gray-400 text-center lg:text-left font-normal text-xs lg:text-base leading-none'}>
                        Welcome back
                    </Text>
                    <Text className={'font-[Poppins] text-center lg:text-left font-semibold text-xl lg:text-3xl leading-none'}>
                        Forgot Password
                    </Text>
                    <form onSubmit={forgetPasswordformik.handleSubmit}>
                        <Stack spacing="6">
                            <Stack spacing="4">
                                <Box>
                                    <FormLabel fontFamily={'Poppins'} className={'!font-[Poppins] !text-sm lg:!text-lg !font-medium text-[#5A5A5D]'}>Email</FormLabel>
                                    <FormInput fontFamily={'Poppins'} fontSize={{ base: '12px', md: '16px' }} _placeholder={{ base: { color: '#BEBEBF', fontFamily: 'Poppins', fontStyle: 'normal' }, md: { color: '#BEBEBF', fontFamily: 'Poppins', fontSize: '16px', fontStyle: 'normal' } }} height={'48px'} placeholder="example@slida.gov.lk" focusBorderColor="#7CA7FA38" borderWidth={'0.746px'} isRequired formik={forgetPasswordformik} name='user' borderColor={"#BEBEBF"} borderRadius={'5.969px'} />
                                </Box>

                            </Stack>
                            <Stack spacing="4">
                                <Button isLoading={isLoading} type={'submit'}
                                    className="!rounded-lg !font-[Poppins] !bg-[#09B562] !text-white hover:!bg-green-500 !transition-all !h-[45px] lg:!h-[52px] !text-base lg:!text-lg !font-semibold !leading-none">Submit</Button>
                            </Stack>
                            <Button onClick={isResetDisclosure.onClose} variant="link" colorScheme="blue" size="sm">
                                Back to xceed Sign In
                            </Button>
                        </Stack>
                    </form>
                </div>
            </Box>
        </div>
    )

    if (!isResetDisclosure.isOpen) {
        return (
            <div className="animation-form-l3" key={1}>
                {loginFormMarkup}
            </div>

        )
    }

    return (
        <div className="animation-form-l3" key={2}>
            {forgotPasswordMarkup}
        </div>
    )
}
