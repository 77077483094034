import { Button, Stack, StackProps, ThemeTypings, Text, HStack } from "@chakra-ui/react"
import { FaPlus } from "react-icons/fa"


interface SectionCardProps extends StackProps {
    children: any
    title?: string,
    subTitle?: string,
    creatable?: SectionCardCreatable,
    backgroundColor?: string
}

export type SectionCardCreatable = {
    text?: string | undefined,
    onClick: any,
    backgroundColor?: string,
    isLoading?: boolean,
    buttonColorScheme?: ThemeTypings["colorSchemes"]
}

const EMPTY_FUNC = () => { }

export const SectionCard = ({ title, children, subTitle, creatable, backgroundColor, ...rest }: SectionCardProps) => {
    return (
        <Stack gap={0} className={`border rounded mt-4 ${backgroundColor ? backgroundColor : 'bg-slate-50'}  p-3 transition-all`} {...rest}>
            <div className="flex justify-between items-center">
                <div className="flex flex-col py-1">
                    {title && <p className="font-semibold !p-0 !m-0">{title}</p>}
                    {subTitle && <p className="font-medium !p-0 !m-0 mb-2">{subTitle}</p>}
                </div>

                {creatable && (<Button size={'sm'} aria-label="Add new" isDisabled={creatable?.isLoading} onClick={creatable?.onClick || EMPTY_FUNC} colorScheme={creatable?.buttonColorScheme || 'teal'} leftIcon={<FaPlus />}>
                    <HStack alignItems={"center"}>
                        <Text>{creatable?.text || 'New'}</Text>
                    </HStack>
                </Button>)}
            </div>
            <div className="mt-2">
                {children}
            </div>
        </Stack>
    )
}