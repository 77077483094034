import axios from "axios";
import { BASE_URL } from "../../../consts/app.consts";

const PendingAmendmentRequestConfig = {
    searchApi: "xceed.api.report.amendment_request.consultant_pending_amendment_report",
    excelFileName: "resource-person-report",
    title: "Pending Amendment Request Report",
    sections: [
        {
            selectionTitle: "Filters",
            grid: { base: 1, md: 2, lg: 4, xl: 4 },
            filters: [
                {
                    label: "Status",
                    key: "status",
                    type: "drop-down",
                    options: [
                        { "label": "Pending - MC", "value": 1 },
                        { "label": "Pending - ADG", "value": 2 },
                    ]
                },
            ],
        },
    ],

    tableConfig: {
        column: [
            {
                header: "Pending With",
                accessor: "pending_with",
                width: 100,
            },
            {
                header: "Pending Since",
                accessor: "pending_since",
                width: 130,
            },
            {
                header: "Code",
                accessor: "code",
            },
            {
                header: "Program Name",
                accessor: "program_name",
                width: 300,
            },
            {
                header: "Module Name",
                accessor: "module_name",
                width: 400,
            },
            {
                header: "Type",
                accessor: "type",
            },
            {
                header: "Module Coordinator",
                accessor: "mc",
                width: 100,
            },
            {
                header: "Overall Coordinator",
                accessor: "overall_mc",
                width: 100,
            },
        ],
    },
    actions: [
        {
            text: "Export to Excel",
            onClick: async (filter: any, data: any, setLoading: any) => {
                setLoading(true);
                try {
                    let response = await axios.post(
                        `${BASE_URL}/method/xceed.api.export_excel.amendment_request.export_pending_amendment_request_report`,
                        { filter },
                        {
                            withCredentials: true,
                            responseType: "blob",
                        }
                    );
                    if (response) {
                        console.log(response);
                        const href = URL.createObjectURL(response.data);

                        const link = document.createElement("a");
                        link.href = href;
                        link.setAttribute("download", `pending-amendment-request.xlsx`);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        URL.revokeObjectURL(href);
                    }
                    setLoading(false);
                } catch (error: any) {
                    setLoading(false);
                    const newError: any = new Error("Unable to Generate Excel file");
                    newError.title = "Error";
                    newError.status = "error";
                    throw newError;
                }
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal",
        }
    ],
}

export default PendingAmendmentRequestConfig