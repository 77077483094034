import _ from "lodash";
import * as Yup from "yup";
import { useToast } from "@chakra-ui/react";
import { EMPLOYEE } from "../../../consts/methods.consts";
import { useFormik } from "formik";
import { FormInput } from "../../common/form-controllers/FormInput";
import { SimpleGrid } from "@chakra-ui/layout";
import { SectionCard } from "../../common/section-card/SectionCard";
import { ToastService } from "../../../services/toast.service";
import { FormDropdown } from "../../common/form-controllers/FormDropdown";
import { usePostMutation } from "../../../services/api.service";
import { DROPDOWN_TAGS, EMPLOYEE_TAGS } from "../../../consts/tags.consts";
import { NavigateFunction, useNavigate } from "react-router-dom";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import MaskFormInput from "../../common/form-controllers/MaskFormInput";
import FormSelect from "../../common/form-controllers/FormSelect";
import { chakraComponents } from "chakra-react-select";
import { customeJsonParser } from "../../../utils/utils";
import InnerLoading from "../../common/inner-loading/InnterLoading";

const NewEmployeePage = () => {
    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());
    const [create,{ isLoading }] = usePostMutation();

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            nic: Yup.string().matches(/^([0-9]{9}[x|X|v|V]|[0-9]{12})$/, 'Enter valid NIC number').required('NIC is required'),
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            abbreviation: Yup.string().required("Abbreviation is required"),
            // user:Yup.string().required("User is required")
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: EMPLOYEE.ADD,
                    body: values,
                    invalidatesTags: [EMPLOYEE_TAGS.VIEW, EMPLOYEE_TAGS.LIST, DROPDOWN_TAGS.LIST]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Employee creation failed")
                }

                toast.setTitle("Success").setDescription("New Employee has been created successfully.").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/employee/${newId}`);
                }, 1500)

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    return (
        <div>
            <PageHeader isLoading={isLoading} enableBackButton actions={actions} title={'Create New Employee'} subtitle={'Employee'} />
            <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                    <FormInput isRequired label="NIC" formik={formik} name="nic" />
                    <FormDropdown options={title} formik={formik}
                        name={'salutation'}
                        autoFocus
                        label={'Salutation'} />
                    <FormInput isRequired label="First Name" formik={formik} name="first_name" />
                    <FormInput isRequired label="Last Name" formik={formik} name="last_name" />
                    <FormDropdown label="Type" formik={formik} name="type" options={type} />
                    <FormDropdown label="Location" formik={formik} name="location" options={location} />
                    <FormInput isRequired label="Abbreviation" formik={formik} name="abbreviation" />
                    <FormSelect method="xceed.api.employee.get_resource_persons_for_select" formik={formik} name={'resource_person'}
                        label={'Resource Person'} />
                    <FormSelect  method="xceed.api.employee.get_users_for_select" formik={formik} name={'user'}
                        label={'User'} />
                </SimpleGrid>
            </SectionCard>
        </div>
    )
}

export default NewEmployeePage

const title = [
    { value: 'Mr', label: 'Mr' },
    { value: 'Ms', label: 'Ms' },
    { value: 'Dr', label: 'Dr' },
    { value: 'Prof', label: 'Prof' },
    { value: 'Rev', label: 'Rev' },
    { value: "Eng", label: "Eng" },
    { value: "Comm", label: "Comm" }
]

const type = [
    { value: 'Academic', label: 'Academic' },
    { value: 'Non Academic', label: 'Non Academic' },
]

const location = [
    { value: 'Internal', label: 'Internal' },
    { value: 'External', label: 'External' },
]

const customComponents = {
    Option: ({ children, ...props }: any) => (
        <chakraComponents.Option {...props}>
            <div className="gap-2">
                <div className=" font-bold">
                    {children}
                </div>
                <div className=" italic flex  gap-3">
                    <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                        {props.data.code}
                    </span>
                    {props.data.group && (<span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                        {props.data.group}
                    </span>)}
                </div>
            </div>
        </chakraComponents.Option >
    )
};