import { SimpleGrid, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect } from "react";
import * as Yup from "yup";
import { printAction } from "../../../../actions";
import { ATTENDANCE, PRINT, STUDENT } from "../../../../consts/methods.consts";
import { DROPDOWN_TAGS } from "../../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../../services/api.service";
import { ToastService } from "../../../../services/toast.service";
import { customeJsonParser } from "../../../../utils/utils";
import ChakraModal from "../../../common/chakra-modal/ChakraModal";
import FormSelect from "../../../common/form-controllers/FormSelect";

const PrintAttendanceCertificateModal = ({ disclosure, student, setLoading }: any) => {
    const toast = new ToastService(useToast());
    const [create] = usePostMutation();

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            program: Yup.string().required("program is required"),
            module: Yup.string().required("module is required"),
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: ATTENDANCE.CHECK_CERTIFICATE_VALIDITY,
                    body: { ...values, student },
                    invalidatesTags: []
                }).unwrap();

                disclosure.onClose()

                printAction(PRINT.PRINT_ATTENDANCE_CERTIFICATE, { ...values, student }, setLoading).catch((error: any) => {
                    if (error.message) toast.setTitle("Error").setDescription(error.message).showErrorToast();
                })

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const { data: _data } = useGetQuery({
        method: STUDENT.GET_ENROLLED_PROGRAM_LIST,
        body: { student, program: _.get(formik.values, 'program') },
        providesTags: [DROPDOWN_TAGS.LIST]
    })

    const data = _.get(_data, 'message')
    const programs = _.get(data, 'programs', [])
    const program_modules = _.get(data, 'program_modules', [])

    useEffect(() => {
        formik.setFieldValue('module', '')
    }, [_.get(formik.values, 'program')])


    return (
        <ChakraModal primaryAction={formik.submitForm} primaryButtonText={"Generate"} modalTitle={'Select Program and Module'}
            {...disclosure}>
            <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                <FormSelect options={programs}
                    label="Program" formik={formik}
                    name="program" />
                <FormSelect options={program_modules}
                    label="Module" formik={formik}
                    name="module" />
            </SimpleGrid>

        </ChakraModal>
    )
}

export default PrintAttendanceCertificateModal