import _ from "lodash";
import { MODULE } from "../../../consts/methods.consts";
import { PageHeader } from "../../index";
import { MODULE_TAGS } from "../../../consts/tags.consts";
import { useGetQuery } from "../../../services/api.service";
import { useSearchParams } from "react-router-dom";
import { PageHeaderActions } from "../../common/page-header/PageHeader";
import { useTableDataProvider } from "../../hooks";
import { NavigateFunction, useNavigate } from "react-router";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { useState } from "react";

export const MODULE_LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        header: "Code",
        accessor: "code",
    },
    {
        header: "Name",
        accessor: "title",
    },
    {
        header: "Duration (hours)",
        accessor: "duration_hours",
    }
]
const ModuleListPage = () => {

    const navigate: NavigateFunction = useNavigate()
    const [page, setPage] = useState(1)
    const [searchKey, setSearchKey]: any = useState()
    const [length, setLength] = useState(15)

    const { data, isLoading, isFetching } = useGetQuery({
        method: MODULE.LIST,
        body: {
            page: page,
            key: searchKey,
            length: Number(length),
        },
        providesTags: [MODULE_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const actions: PageHeaderActions = [

        {
            text: "Create New",
            onClick: () => navigate("new"),
            confirmation: false,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const tableLineClickHandler = (line: any) => {
        navigate(`${_.get(line, 'name')}`)
    }

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }

    const onLengthChange = (length: any) => {
        setPage(1)
        setLength(Number(length))
    }

    const onPage = (page:any)=>{
        setPage(page)
    }

    return (
        <div>
            <PageHeader subtitle="View and Manage modules" title={`Module`} actions={actions} />
            <ChakraTable onPage={onPage} onLength={onLengthChange} lengthPaginations isFetching={isFetching} onSearch={onSearchHandler} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} header title="Modules"
                onSelected={tableLineClickHandler} columns={MODULE_LIST_TAB_COLUMNS} data={dataList} />
        </div>
    )
}

export default ModuleListPage