import _ from "lodash"
import { FiArrowDown, FiArrowUp } from "react-icons/fi";
import { useState } from "react";
import { IColumn } from "../ChakraTable";

enum SORT_TYPES {
    asc = 'asc',
    desc = 'desc'
}

interface ColumnSortProps {
    setTableData: any,
    tableData: any,
    item: IColumn
}

const ColumnSort = ({ setTableData = () => { }, tableData = [], item }: ColumnSortProps) => {
    const [sortType, setSortType] = useState(SORT_TYPES.asc)

    const sort = (accessor: any) => {
        let sorted = _.orderBy(tableData, `['${accessor}']`, sortType === SORT_TYPES.asc ? 'asc' : 'desc')
        setTableData(sorted)
    }

    const onAscSort = () => {
        setSortType((val) => SORT_TYPES.desc)
        sort(item.accessor)
    }

    const onDescSort = () => {
        setSortType((val) => SORT_TYPES.asc)
        sort(item.accessor)
    }

    return (
        <>
            {sortType == SORT_TYPES.asc && <FiArrowUp className="ml-1 text-gray-500" onClick={onAscSort} fontSize="1rem" />}
            {sortType == SORT_TYPES.desc && <FiArrowDown className="ml-1 text-gray-500" onClick={onDescSort} fontSize="1rem" />}
        </>
    )
}

export default ColumnSort;