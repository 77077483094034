import moment from "moment";

const CellDateTimeFormatter = (value: any) => {

    const formattedDateTime = moment(value, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD hh:mm A');

    return (
        <div>
            {formattedDateTime}
        </div>
    )
};

export default CellDateTimeFormatter