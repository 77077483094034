import { useDisclosure, useToast } from "@chakra-ui/react";
import _ from "lodash";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import { MODULE_ENROLLMENT } from "../../../consts/methods.consts";
import { MODULE_ENROLLMENT_TAGS } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { SectionCard, SectionCardCreatable } from "../../common/section-card/SectionCard";
import { useTableDataProvider } from "../../hooks";
import StudentSelector from "../programmed-module/components/StudentSelectorModal";
import { useState } from "react";

const ModuleEnrollmentListPage = ({ parentData }: any) => {

    const navigate: NavigateFunction = useNavigate()
    const [page, setPage] = useState(1)
    const studentDisclosure = useDisclosure()
    const [create] = usePostMutation();
    const [searchKey, setSearchKey]: any = useState('')
    const toast = new ToastService(useToast());

    const { data, isLoading, isFetching } = useGetQuery({
        method: MODULE_ENROLLMENT.LIST,
        body: {
            page: page,
            program: _.get(parentData, 'program'),
            program_module: _.get(parentData, 'programModule'),
            key: searchKey
        },
        providesTags: [MODULE_ENROLLMENT_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);


    const createContent: SectionCardCreatable = {
        text: "Add",
        onClick: () => {
            studentDisclosure.onOpen()
        }
    }

    const ondeleteHandler = async (item: any) => {
        try {
            const res = await create({
                method: MODULE_ENROLLMENT.DELETE,
                body: { id: item?.name },
                invalidatesTags: [MODULE_ENROLLMENT_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Module Enrollment is deleted successfully.").showSuccessToast();

        } catch (error: any) {
            let message = JSON.parse(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(JSON.parse(message)?.message).showErrorToast();
        }

    }

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }


    const onPage = (page: any) => {
        setPage(page)
    }


    const MODULE_ENROLLMENT_COLUMNS: ChakraTableColumns = [
        {
            header: "Student",
            accessor: "student",
        },
        {
            header: "Full Name",
            accessor: "full_name",
        },
        {
            header: "NIC",
            accessor: "nic",
        },
        {
            header: "Enrollment Date",
            accessor: "enrollment_date",
        },
        {
            header: "Actions",
            accessor: '',
            disabled: false,
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick: ondeleteHandler,
                }
            ]
        }
    ]

    const tableLineClickHandler = (line: any) => {
        navigate(`/app/student/${line?.student}`)
    }

    return (
        <div>
            <SectionCard bg={'white'} creatable={createContent} subTitle="" title="Enrollments" p={3}>
                <ChakraTable onPage={onPage} onSearch={onSearchHandler} onSelected={tableLineClickHandler} paginationDetails={paginationDetails} isFetching={isFetching} isLoading={isLoading} pagination size={"sm"} header
                    columns={MODULE_ENROLLMENT_COLUMNS} data={dataList} />
            </SectionCard>
            <StudentSelector parentData={parentData} selectedItems={[]} setSelectedItems={[]} {...studentDisclosure} />
        </div>
    )
}

export default ModuleEnrollmentListPage