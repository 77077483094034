import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { FormDropdown, FormDropdownOptions } from "../../form-controllers/FormDropdown";

interface LengthPaginationProps {
    totalResult: number,
    isLoading?: boolean,
    onLength?: (length: number) => void
}

const LengthPaginations: React.FC<LengthPaginationProps> = ({ totalResult, isLoading, onLength }) => {

    const onChangePageLengthHandler = (value: number) => {
        onLength && onLength(value)
    }

    const styles = {
        opacity: isLoading ? 0 : 1,
        height: isLoading ? 0 : 52
    }

    const options: FormDropdownOptions = [
        { label: "15", value: 15 },
        { label: "50", value: 50 },
        { label: "100", value: 100 },
        { label: "All", value: totalResult },
    ]

    const formik = useFormik({
        initialValues: {
            length: 15
        },
        validationSchema: Yup.object({
        }),
        onSubmit: async (values: any) => {

        },
    })

    useEffect(() => {
        const { length } = formik.values
        onChangePageLengthHandler(length)
    }, [formik.values])

    return (
        <>
            <div style={{ ...styles }} className="flex items-center gap-3 lg:gap-2 bg-gray-50 pl-3">
                <FormDropdown size={'sm'} options={options} formik={formik} name="length" />
            </div>
        </>
    )
}

export default LengthPaginations