import _ from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import { FormInput } from "../../common/form-controllers/FormInput";
import { SimpleGrid } from "@chakra-ui/layout";
import { useDispatch } from "react-redux";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";
import { ToastService } from "../../../services/toast.service";
import { FormDropdown } from "../../common/form-controllers/FormDropdown";
import { FormRadioGroup } from "../../common/form-controllers/FormRadioGroup";
import { FormRadioButton } from "../../common/form-controllers/FormRadioButton";
import { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { MODULE, MODULE_BUCKET } from "../../../consts/methods.consts";
import { ChakraModal, ChakraTable } from "../../index";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { NavigateFunction, useNavigate } from "react-router";
import { Stack, useDisclosure, useToast } from "@chakra-ui/react";
import { SectionCard, SectionCardCreatable } from "../../common/section-card/SectionCard";
import { apiService, useGetQuery, usePostMutation } from "../../../services/api.service";
import { DROPDOWN_TAGS, MODULE_BUCKET_TAGS, MODULE_TAGS } from "../../../consts/tags.consts";
import { chakraComponents } from "chakra-react-select";
import FormSelect from "../../common/form-controllers/FormSelect";
import { customeJsonParser } from "../../../utils/utils";

const NewModuleBucketPage = () => {
    const toast = new ToastService(useToast());
    const navigate: NavigateFunction = useNavigate();
    const disclosure = useDisclosure()
    const dispatch: any = useDispatch()
    const [create, { isLoading }] = usePostMutation();

    const { data: initData } = useGetQuery({
        method: MODULE_BUCKET.INIT,
        providesTags: [MODULE_TAGS.VIEW, DROPDOWN_TAGS.LIST]

    })

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: MODULE_BUCKET.CREATE,
                    body: values,
                    invalidatesTags: [MODULE_BUCKET_TAGS.VIEW, MODULE_BUCKET_TAGS.LIST, DROPDOWN_TAGS.LIST]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Module Basket creation failed")
                }

                toast.setTitle("Success").setDescription("New Module Basket has been created successfully").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/module-basket/${newId}`);
                }, 1500)

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const moduleOptions = (_.get(initData, 'message.modules', [])).filter((OptionModule: any) => !_.get(formik.values, 'modules', []).some((tableModule: any) => OptionModule.value === tableModule.name))
    const moduleBucketTypes = _.get(initData, 'message.module_bucket_types', [])


    console.log(_.get(formik.values, 'modules', []), 'asas')

    const childFormik = useFormik({
        initialValues: { type: 'Elective' },
        validationSchema: Yup.object({
            name: Yup.string().required("Please select a module"),
        }),
        onSubmit: async (values: any) => {

            const alreadyExsist = _.get(formik.values, 'modules', []).findIndex((line: any) => (line.name === values.name))

            if (alreadyExsist > -1) {
                toast.setTitle("Error").setDescription("This Module Already exists").showErrorToast();
                return
            }
            disclosure.onClose()
            try {
                const res = await dispatch(apiService.endpoints.get.initiate({
                    method: MODULE.GET,
                    body: { id: values.name },
                    providesTags: []
                })).unwrap()

                const currentModules: any = _.get(formik.values, 'modules', [])

                if (res) formik.setValues({
                    ...formik.values,
                    modules: [
                        ...currentModules,
                        { ..._.get(res, 'message'), type: values?.type }
                    ]
                })

                childFormik.resetForm()

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to create Module Basket. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const MODULE_LIST_TABLE_COLUMNS: ChakraTableColumns = [
        {
            header: "Module Code",
            accessor: "code",
        },
        {
            header: "Name",
            accessor: "title",
        },
        {
            header: "Credit",
            accessor: "credit",
        },
        {
            header: "Duration",
            accessor: "duration_hours",
        },
        {
            header: "Type",
            accessor: "type",

        },
        {
            header: "Action",
            accessor: "",
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick(item: any) {

                        const newItems = _.get(formik.values, 'modules', []).filter((i: any) => (
                            i?.name !== item?.name
                        ))
                        formik.setFieldValue('modules', newItems)
                    },
                }
            ]
        },
    ]

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const creatable: SectionCardCreatable = {
        text: "Add New",
        onClick: () => {
            disclosure.onOpen();
        }
    }

    return (
        <div>
            <PageHeader isLoading={isLoading} enableBackButton subtitle={"Module Basket"} actions={actions} title={'Create New Module Basket'}
            />
            <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                <SimpleGrid className={'rounded p-3'} columns={{ base: 1, md: 1, lg: 3, xl: 3 }} spacing={3}>
                    <FormInput isRequired label="Basket Name" formik={formik} name="title" />
                    <FormDropdown options={moduleBucketTypes} formik={formik} name={'type'}
                        autoFocus
                        label={'Basket Type'} />
                    {/* <FormTextArea label="Description" rows={8} formik={formik} name="description" /> */}
                </SimpleGrid>
            </SectionCard>

            <SectionCard creatable={creatable} title="Modules" p={3}>
                <ChakraTable size={"sm"} header
                    columns={MODULE_LIST_TABLE_COLUMNS}
                    data={_.get(formik.values, 'modules', [])} />
                <ChakraModal primaryAction={childFormik.submitForm} primaryButtonText={"Add"}
                    modalTitle={'Add module'} {...disclosure}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 1 }} spacing={3}>
                        <FormSelect components={customComponents} options={moduleOptions} isRequired formik={childFormik} name={'name'}
                            label={'Module'} />
                        <FormRadioGroup name={"type"} defaultValue="Elective" formik={childFormik}>
                            <Stack direction='row'>
                                <FormRadioButton name="type" formik={childFormik} value='Mandatory' label='Mandatory' />
                                <FormRadioButton name="type" formik={childFormik} value='Elective' label='Elective' />
                            </Stack>
                        </FormRadioGroup>
                    </SimpleGrid>
                </ChakraModal>
            </SectionCard>
        </div>
    )
}

export default NewModuleBucketPage

const customComponents = {
    Option: ({ children, ...props }: any) => (
        <chakraComponents.Option {...props}>
            <div className="gap-2">
                <div className=" font-bold">
                    {children}
                </div>
                <div className=" italic flex  gap-3">
                    <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                        {props.data.code}
                    </span>
                    {props.data.group && (<span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                        {props.data.group}
                    </span>)}
                </div>
            </div>
        </chakraComponents.Option >
    )
};