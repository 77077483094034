// import { components } from "chakra-react-select";
import { useEffect, useRef } from "react";
import { GrFormNextLink } from "react-icons/gr";
import '../form-controllers/form-controller-styles.css'
import _ from "lodash";

import { ControlProps, components } from "chakra-react-select";
import { Link } from "react-router-dom";

export const FormSeletectNavigationController = ({ children, ...props }: any) => {
    const { linkTo ,isMulti } = props.selectProps;
    const { hasValue } = props

    const onmousedownHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }

    return (
        <components.Control className="css-1a75yps mfstcc" {...props}>
            {(hasValue && !isMulti) &&
                (<span className="px-1 ml-1 hover:cursor-pointer rounded bg-amber-400" onMouseDown={onmousedownHandler} >
                    <Link to={linkTo}>
                        <GrFormNextLink style={{color: 'white'}}/>
                    </Link>
                </span>)}
            {children}
        </components.Control>
    );

}
