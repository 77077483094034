import _ from "lodash";
import { useGetQuery } from "../../../services/api.service";
import { RESOURCE_PERSON } from "../../../consts/methods.consts";
import { RESOURCE_PERSON_TAGS } from "../../../consts/tags.consts";
import { useTableDataProvider } from "../../hooks";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom";
import { useState } from "react";

const RESOURCE_PERSON_COLUMNS: ChakraTableColumns = [
    {
        header: "NIC",
        accessor: "nic",
    },
    {
        header: "Seniority Tier",
        accessor: "seniority",
    },
    {
        header: "Surame",
        accessor: "surname",
    },
    {
        header: "Display Name",
        accessor: "full_name",
    },
    {
        header: "City",
        accessor: "city",
    },
    {
        header: "Country",
        accessor: "country",
    }
]


const ResourcePersonListPage = () => {
    const navigate: NavigateFunction = useNavigate()
    const [searchKey, setSearchKey]: any = useState()
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)

    const { data, isLoading, isFetching } = useGetQuery({
        method: RESOURCE_PERSON.LIST,
        body: {
            page: page,
            length: Number(length),
            key: searchKey
        },
        providesTags: [RESOURCE_PERSON_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const actions: PageHeaderActions = [

        {
            text: "Create New",
            onClick: () => navigate("new"),
            confirmation: false,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const tableLineClickHandler = (line: any) => {
        navigate(`${_.get(line, 'name')}`)
    }

    const onPage = (page: any) => {
        setPage(page)
    }

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }

    const onLengthChange = (length: any) => {
        setPage(1)
        setLength(Number(length))
    }

    return (
        <div>
            <PageHeader subtitle="View and Manage Resource Persons" title={`Resource Person`} actions={actions} />
            <ChakraTable onPage={onPage} onLength={onLengthChange} lengthPaginations isFetching={isFetching} onSearch={onSearchHandler} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} header title="Resource Persons"
                onSelected={tableLineClickHandler} columns={RESOURCE_PERSON_COLUMNS} data={dataList} />
        </div>
    )
}

export default ResourcePersonListPage