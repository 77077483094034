import { useToast } from "@chakra-ui/react";
import _ from "lodash";
import { useState } from "react";
import { NavigateFunction, useNavigate, useParams } from "react-router";
import { ATTENDANCE, SESSION } from "../../../../consts/methods.consts";
import { ATTENDANCE_TAGS } from "../../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../../services/api.service";
import { ToastService } from "../../../../services/toast.service";
import ChakraTable, { ChakraTableColumns } from "../../../common/chakra-table/ChakraTable";
import PageHeader from "../../../common/page-header/PageHeader";
import { useTableDataProvider } from "../../../hooks";
import ProgrammedModule from "../../program/programmed-module/ProgrammedModule";

const CombineSessionTable = ()=>{
    const navigate: NavigateFunction = useNavigate()
    const { id } = useParams()
    const { data, isLoading, isFetching } = useGetQuery({
        method: SESSION.GET_COMBINE_SESSIONS,
        body: {
            id
        },
        providesTags: [SESSION.LIST]
    })

    const dataList = _.get(data,'message')


    const ATTENDANCE_LIST_TAB_COLUMNS: ChakraTableColumns = [
        {
            header: "Program",
            accessor: "program",
            width:300,
        },
        {
            header: "Module",
            accessor: "program_module",
            width:300,
        },
        {
            header: "Title",
            accessor: "title",
            width:300,
        },
        {
            header: "Session Number",
            accessor: "session_number",
            width:100,
        },

    ]

    const tableLineClickHandler = (line: any) => {
        navigate(`/app/session/${_.get(line, 'name')}?pm=${_.get(line, 'programmed_module')}`)
    }


    return (
        <div>
            <PageHeader subtitle="View and Manage Combined Session" title={`Combined Sessions`} />
            <ChakraTable isFetching={isFetching} isLoading={isLoading} header title="Sessions"
                columns={ATTENDANCE_LIST_TAB_COLUMNS} data={dataList}  onSelected={tableLineClickHandler} />
        </div>
    )
}

export default CombineSessionTable