import _ from "lodash";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useEffect } from "react";
import { FormInput } from "../../../../common/form-controllers/FormInput";
import { SimpleGrid } from "@chakra-ui/react";
import { useGetQuery } from "../../../../../services/api.service";
import { SectionCard } from "../../../../common/section-card/SectionCard";
import { DROPDOWN_TAGS, PROGRAM_TAGS } from "../../../../../consts/tags.consts";
import { FormCheckBox } from "../../../../common/form-controllers/FormCheckBox";
import { FormDropdown } from "../../../../common/form-controllers/FormDropdown";
import { FormTextArea } from "../../../../common/form-controllers/FormTextArea";
import { RESOURCE_PERSON } from "../../../../../consts/methods.consts";
import FormSelect from "../../../../common/form-controllers/FormSelect";
import { chakraComponents } from "chakra-react-select";
import MaskFormInput from "../../../../common/form-controllers/MaskFormInput";

const GeneralForm = ({ mainFormRef }: any) => {

    const { data: initialData } = useGetQuery({
        method: RESOURCE_PERSON.INIT,
        body: {
        },
        providesTags: [DROPDOWN_TAGS.LIST]
    })

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            // resource_person_code: Yup.string().required("Resource Person code is required"),
            title: Yup.string().required("Title is required"),
            residential_phone_number: Yup.string().matches(/^[0-9]{10}$/, 'Enter valid mobile number'),
            birthday: Yup.date().required("Birthday is required"),
            seniority: Yup.number().required("Seniority is required"),
            nic: Yup.string().matches(/^([0-9]{9}[x|X|v|V]|[0-9]{12})$/, 'Enter valid NIC number')
                .required('NIC is required'),
            mobile_number: Yup.string().matches(/^[0-9]{10}$/, 'Enter valid mobile number').required('Mobile number is required'),
            surname: Yup.string().required("Surname is required"),
            initials_without_space: Yup.string().required("Initials (Without Space) is required"),
            whatsapp_number: Yup.string().matches(/^[0-9]{10}$/, 'Enter valid mobile number').required('Whatsapp number is required'),
            full_name: Yup.string().required("Full Name is required"),
            email_address: Yup.string().email('Enter valid email address').required('Email is required'),
            names_denoted_by_initials: Yup.string().required("Names Denoted by Initials is required"),
            residential_address: Yup.string().required("Residential Address is required"),
        }),
        onSubmit: async (values: any) => {
            return values
        }
    })

    const resourcePersonType = _.get(formik.values, 'resource_person_type')
    const isVisitingLecturer = _.get(formik.values, 'visiting_lecturer')

    mainFormRef.current = formik

    useEffect(() => {
        formik.setFieldValue('resource_person_category', '')
    }, [resourcePersonType])


    const visitingLecturerMarkup = (
        <>
            <FormSelect components={programSelectMenu} options={_.get(initialData, 'message.programs')} isRequired formik={formik} name={'program'}
                label={'Program'} />
            <FormInput label="Institute Name" formik={formik} name="institute_name" />
            <FormInput type="number" label="Duration(Hrs)" formik={formik} name="durationhrs" />
            <FormInput type="number" label="Budgeted Amount" formik={formik} name="budgeted_amount" />
            <FormDropdown options={lectureModes} formik={formik}
                name={'lecture_mode'}
                autoFocus
                label={'Lecture mode'} />
            <FormInput label="Institutional charges" formik={formik} name="institutional_charges" />
            <FormInput label="Consultant Charges" formik={formik} name="consultant_charges" />

        </>

    )

    return (
        <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
            <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                {/* <FormInput isRequired label="Resource Person code" formik={formik} name="resource_person_code" /> */}
                <FormDropdown options={title} isRequired formik={formik}
                    name={'title'}
                    autoFocus
                    label={'Title'} />
                <FormInput isRequired label="NIC" formik={formik} name="nic" />
                <FormDropdown options={SeniorityOptions} isRequired formik={formik}
                    name={'seniority'}
                    autoFocus
                    label={'Seniority Tier'} />
                <FormInput isRequired label="Surname" formik={formik} name="surname" />
                <FormInput isRequired label="Initials (Without Space)" formik={formik}
                    name="initials_without_space" />
                <FormInput isRequired label="Names Denoted by Initials" formik={formik}
                    name="names_denoted_by_initials" />
                <FormInput isRequired label="Display Name" formik={formik} name="full_name" />

                <FormInput type="date" isRequired label="Birthday" formik={formik} name="birthday" />
                <MaskFormInput maskOption={{
                    mask: '000 0000000',
                }} isRequired label="Mobile Number" formik={formik} name="mobile_number" />

                <MaskFormInput maskOption={{
                    mask: '000 0000000',
                }} isRequired label="Whatsapp Number" formik={formik} name="whatsapp_number" />

                <MaskFormInput maskOption={{
                    mask: '000 0000000',
                }} label="Residential Phone Number" formik={formik} name="residential_phone_number" />
                <FormInput isRequired label="Email Address" formik={formik} name="email_address" />
                <FormDropdown options={maritalStatus} formik={formik}
                    name={'marital_status'}
                    autoFocus
                    label={'Marital Status'} />
                <FormDropdown options={resourcePersonTypes} formik={formik}
                    name={'resource_person_type'}
                    autoFocus
                    label={'Resource Person Type'} />
                {resourcePersonType == 'Internal' && (<FormDropdown options={resourcePersonCategory} formik={formik}
                    name={'resource_person_category'}
                    autoFocus
                    label={'Resource Person Category '} />)}
                <FormDropdown options={[{ "value": "Yes", "label": "Yes" }, { "value": "No", "label": "No" }]} formik={formik}
                    name={'visiting_lecturer'}
                    autoFocus
                    label={'Visiting lecturer'} />

                {
                    isVisitingLecturer === "Yes" && visitingLecturerMarkup
                }

                <FormDropdown options={countries} formik={formik}
                    name={'country'}
                    autoFocus
                    label={'Country'} />
                <FormInput label="City" formik={formik} name="city" />
                <FormInput label="Designation" formik={formik} name="designation" />
                <FormInput label="Workplace" formik={formik} name="workplace" />
                <FormTextArea isRequired label="Residential Address" formik={formik} name="residential_address" />
                <FormTextArea label="Expertise Area" formik={formik} name="expertise_area" />
            </SimpleGrid>
        </SectionCard>
    )
}
export default GeneralForm

const programSelectMenu = {
    Option: ({ children, ...props }: any) => (
        <chakraComponents.Option {...props}>
            <div className="gap-2">
                <div className=" font-bold">
                    {children}
                </div>
                <div className=" italic flex  gap-3">
                    <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                        {props?.data?.code}
                    </span>
                    {/* {props.data.group && (<span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                        {props?.data?.group}
                    </span>)} */}
                </div>
            </div>
        </chakraComponents.Option >
    )
};


const title = [
    { value: 'Mr', label: 'Mr' },
    { value: 'Ms', label: 'Ms' },
    { value: 'Dr', label: 'Dr' },
    { value: 'Prof', label: 'Prof' },
    { value: 'Rev', label: 'Rev' },
    { value: "Eng", label: "Eng" },
    { value: "Comm", label: "Comm" }
]

const maritalStatus = [
    { value: 'Single', label: 'Single' },
    { value: 'Married', label: 'Married' }
]

const countries = [
    { value: 'Sri Lanka', label: 'Sri Lanka' },
    { value: 'USA', label: 'USA' },
    { value: 'Australia', label: 'Australia' },
    { value: 'India', label: 'India' },
    { value: 'Cambodia', label: 'Cambodia' },
    { value: 'Other', label: 'Other' }
]

const resourcePersonTypes = [
    { value: 'Internal', label: 'Internal' },
    { value: 'External', label: 'External' }
]

const resourcePersonCategory = [
    { value: 'ADG', label: 'ADG' },
    { value: 'Consultant', label: 'Consultant' },
    { value: 'Management', label: 'Management' }
]


const lectureModes = [
    { value: 'Office Hours', label: 'Office Hours' },
    { value: 'Non-Office Hours', label: 'Non-Office Hours' },
]

const SeniorityOptions: any = Array.from({ length: 20 }, (_, i) => ({ label: i + 1, value: i + 1 }));