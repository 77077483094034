// import { components } from "chakra-react-select";
import '../form-controllers/form-controller-styles.css';

import { components } from "chakra-react-select";

export const FormSelectColourController = ({ children, ...props }: any) => {
    const {isMulti ,value } = props.selectProps;
    const { hasValue } = props

    console.log(value,'props')
    const onmousedownHandler = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
    }

    return (
        <components.Control className="css-1a75yps mfstcc !h-[50px]" {...props}>
            {(hasValue && !isMulti) &&
                (<div className=" px-2 py-1 ml-2 rounded-md hover:cursor-pointer" style={{backgroundColor:value?.value,color:value?.value}} onMouseDown={onmousedownHandler} >
                  Selected Colour
                </div>)}
                {children}
        </components.Control>
    );

}
