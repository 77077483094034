import { SimpleGrid, useToast } from "@chakra-ui/react"
import { useFormik } from "formik"
import _ from "lodash"
import { NavigateFunction, useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { DATAIMPORT } from "../../../consts/methods.consts"
import { DATA_IMPORT_TAG } from "../../../consts/tags.consts"
import { usePostMutation } from "../../../services/api.service"
import { ToastService } from "../../../services/toast.service"
import { FormDropdown } from "../../common/form-controllers/FormDropdown"
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader"
import { SectionCard } from "../../common/section-card/SectionCard"
import { customeJsonParser } from "../../../utils/utils"

const NewImportDataPage = ()=>{
    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());
    const [create] = usePostMutation();

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            reference_doctype: Yup.string().required("Document Type is required"),
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: DATAIMPORT.CREATE,
                    body: {import_type:"Insert New Records" ,...values},
                    invalidatesTags: [DATA_IMPORT_TAG.VIEW, DATA_IMPORT_TAG.LIST]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Data Import Document creation failed")
                }

                toast.setTitle("Success").setDescription("New Data Import Document has been created successfully.").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/data-import/${newId}`);
                }, 1500)

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
    ]


    return (
        <div>
            <PageHeader  enableBackButton actions={actions} title={'Create New Data Import'}
                subtitle={'Data Import'} />
            <SectionCard backgroundColor="bg-white" title="Basic Information" p={3}>
                <SimpleGrid className={'rounded p-3'} columns={{ base: 1, md: 1, lg: 2, xl: 2 }} spacing={3}>
                <FormDropdown options={doctypes} formik={formik} name={'reference_doctype'}
                        autoFocus
                        label={'Document Type'} />
                </SimpleGrid>
            </SectionCard>
        </div>
    )
}

export default NewImportDataPage

const doctypes = [
    {value:'Student',label:'Students'},
    {value:'Attendance',label:'Attendance'}
]