import _ from "lodash";
import { BASE_URL } from '../consts/app.consts'
import apiService from "../services/api.service";
import axios from "axios";

export const callMethod = (method: string, body: any, providesTags :any = []) => {
    return async (dispatch: any) => {
        try {
            const { data }: any = await dispatch(apiService.endpoints.get.initiate({
                method,
                body: body,
                providesTags: providesTags

            }, { forceRefetch: true }))

            return _.get(data, 'message');

        } catch (error) {
            throw error
        }
    }
}

export const postMutation = (method: string, body: any, invalidatesTags: any = []) => {
    return async (dispatch: any) => {
        try {
            const { data }: any = await dispatch(apiService.endpoints.post.initiate({
                method,
                body: body,
                invalidatesTags
            }))
            return _.get(data, 'message');
        } catch (error) {
            throw error
        }
    }
}

export const printAction = async (method: any, body: any = {}, setLoading: any = () => { }) => {
    setLoading(true)
    try {
        const response = await axios.post(`${BASE_URL}/method/${method}`, body, {
            responseType: "blob",
            withCredentials: true,
        })

        if (response.data) {
            const blobURL = URL.createObjectURL(response.data);

            const iframe: any = document.createElement('iframe');
            document.body.appendChild(iframe);

            iframe.style.display = 'none';
            iframe.src = blobURL;

            iframe.onload = () => {
                setTimeout(() => {
                    iframe.focus();
                    // @ts-ignore
                    iframe.contentWindow.print();

                }, 1);
            }

        }

        setLoading(false)
    } catch (e: any) {
        const error: any = new Error('Faild to generate print');
        error.title = 'Error';
        error.status = 'error';
        throw error

    } finally {
        setLoading(false)
    }
}