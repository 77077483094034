const reportTableRows = [
  // {
  //     report_name: "Employee report",
  //     report: 'employee',

  // },
  {
    report_name: "Session Details Report",
    report: "session",
  },
  {
    report_name: "Program Report",
    report: "program",
  },
  {
    report_name: "Daily Program Schedule",
    report: "daily-program",
  },
  {
    report_name: "Attendance Report",
    report: "custom/attendance-report",
  },
  {
    report_name: "Attendance Report",
    report: "attendance-report",
  },
  {
    report_name: "Classroom Allocation Report",
    report: "classroom",
  },
  {
    report_name: "Resource Person Allocation Report",
    report: "resource-person",
  },
  {
    report_name: "Assessment Report",
    report: "assesment",
  },
  {
    report_name: "Module Plan Amendment Request Report",
    report: "module-plan-amendment",
  },
  {
    report_name: "Module Plan Approval/Recommendation Pending Report",
    report: "module-plan",
  },
  {
    report_name: "Module Allocation for Consultants Report",
    report: "module-allocation-consultant",
  },
  {
    report_name: "Module Allocation for Program Assistant Report",
    report: "module-allocation-program-assistant",
  },
  {
    report_name: "Student Enrolment & Attendance Report",
    report: "custom/student-enrollment-report",
  },
  {
    report_name: "Library Access Report",
    report: "custom/library-access-report",
  }
  // {
  //   report_name: "Pending Module Plan Report",
  //   report: "pending-module-plan",
  // },
  // {
  //   report_name: "Pending Amendment Request Report",
  //   report: "pending-amendment-request",
  // },
  // {
  //   report_name: "Session Report V2",
  //   report: "custom/session-report-v2",
  // }
];

export default reportTableRows;
