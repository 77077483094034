import _ from "lodash";
import { FormControl, FormErrorMessage, HStack, RadioGroup, RadioProps, VStack } from "@chakra-ui/react";

type MutatedTypes = Omit<RadioProps, 'defaultValue'>

export interface FormRadioGroupProps extends MutatedTypes {
    containerClassNames?: string,
    name: string,
    formik: any,
    children: any,
    defaultValue:string,
    customMessage?: any
}

export const FormRadioGroup = ({ name, defaultValue = '', containerClassNames = '', formik, customMessage, children }: FormRadioGroupProps) => {

    return (
        <FormControl display={'flex'} alignItems={"end"} className={containerClassNames} >
            <VStack alignItems={"flex-start"}>
                <HStack>{}
                    <RadioGroup defaultValue={_.get(formik.values, 'type', defaultValue)}>
                        {children}
                    </RadioGroup>
                </HStack>
            </VStack>
            {(formik.submitCount && formik.errors[name]) ? <FormErrorMessage mt={0}>{formik.errors[name]}</FormErrorMessage> : customMessage && customMessage}
        </FormControl>
    )
}