import axios from "axios";
import _ from "lodash";
import React from "react";
import { BASE_URL } from "../../../consts/app.consts";
import { DefaultCell } from "./components/cells";

const examRegistrationConfig = {
    searchApi: 'xceed.api.report.exam_registration_generic_report',
    alert: 'Reminder: Specify a date range to receive reports. Use other fields to further narrow down data within the required date range.',
    excelFileName: 'exam-registration-report',
    title: 'Exam Registration Report',
    extraActionsAsMenu: true,
    sections: [{
        selectionTitle: "Date Range",
        grid: { base: 1, md: 2, lg: 4, xl: 4 },
        filters: [
            {
                is_required: true,
                label: 'From',
                type: 'date',
                key: 'from'
            },
            {
                is_required: true,
                label: 'To',
                type: 'date',
                key: 'to'
            },
        ]
    }, {
        selectionTitle: "Filters",
        grid: { base: 1, md: 2, lg: 4, xl: 4 },
        filters: [{
            label: 'Admission No',
            key: 'adm_no',
        },
        {
            label: 'Student',
            key: 'student',
            type: 'form-select',
            optionMethod: 'xceed.api.report.get_student_list'
        },
        {
            label: 'NIC',
            key: 'nic',
        },
        {
            label: 'Service',
            key: 'service',
            type: 'form-select',
            optionMethod: 'xceed.api.report.get_service_list'
        },
        {
            label: 'Exam',
            key: 'exam',
            type: 'form-select',
            optionMethod: 'xceed.api.report.get_exam_list',

        },
        {
            label: 'Medium',
            type: 'drop-down',
            key: 'medium',
            options: [
                { value: 'Sinhala', label: 'Sinhala' },
                { value: 'English', label: 'English' },
                { value: 'Tamil', label: 'Tamil' },
            ]
        },
        {
            label: 'From',
            type: 'date',
            key: 'from'
        },
        {
            label: 'To',
            type: 'date',
            key: 'to'
        },
        {
            label: 'Order By',
            type: 'drop-down',
            key: 'order_by',
            options: [
                { value: 'XE.name', label: 'Admission NO' },
                { value: 'XE.full_name', label: 'Student Name' },
                { value: 'XE.medium', label: 'Medium' },
                { value: 'XE.creation', label: 'Registered Date' },
                { value: 'XE.nic', label: 'NIC' }
            ]

        },
        {
            label: 'Order',
            type: 'drop-down',
            key: 'order',
            options: [
                { value: 'DESC', label: 'DESC' },
                { value: 'ASC', label: 'ASC' },
            ]
        }]
    }],

    tableConfig: {
        column: [
            {
                header: "Registered Date",
                accessor: "creation",
                width: 200
            },
            {
                header: "Admission NO",
                accessor: "adm_no",
                customRenderer: (value: any, item: any) => (
                    React.createElement(DefaultCell, { navigateTo: `/app/exam-registration/view?id=${_.get(item, 'adm_no')}`, value: value })
                ),
                width: 200
            },
            {
                header: "NIC",
                accessor: "nic",
                width: 200
            },
            {
                header: "Full Name",
                accessor: "full_name",
                customRenderer: (value: any, item: any) => (
                    React.createElement(DefaultCell, { navigateTo: `/app/student/${_.get(item, 'student_id')}`, value: value })
                ),
                width: 400
            },
            {
                header: "Service",
                accessor: "service",
                customRenderer: (value: any, item: any) => (
                    React.createElement(DefaultCell, { navigateTo: `/app/service/${_.get(item, 'service_name')}`, value: value })
                ),
                width: 400
            },
            {
                header: "Name of the Examination",
                accessor: "exam_name",
                customRenderer: (value: any, item: any) => (
                    React.createElement(DefaultCell, { navigateTo: `/app/exam/${_.get(item, 'name_of_the_examination')}`, value: value })
                ),
                width: 400
            },
            {
                header: "Medium",
                accessor: "medium",
                width: 100
            },
            {
                header: "Mobile",
                accessor: "mobile",
                width: 200
            },
            {
                header: "Telephone (Personal Land Line)",
                accessor: "telephone_personal_land_line",
                width: 200
            },
            {
                header: "Telephone (Office Land Line)",
                accessor: "telephone_office_land_line",
                width: 50
            }
        ]
    },
    actions: [

        {
            text: "Export to Excel",
            onClick: async (filter: any, data: any, setLoading: any) => {
                if (filter.to && filter.from) {
                    setLoading(true)
                    try {
                        let response = await axios.post(`${BASE_URL}/method/xceed.api.export_excel.exam_registration.export_exam_registration_report`, { filter }, {
                            withCredentials: true,
                            responseType: "blob"
                        })
                        if (response) {
                            console.log(response)
                            const href = URL.createObjectURL(response.data);

                            const link = document.createElement('a');
                            link.href = href;
                            link.setAttribute('download', `Exam-medium-count-export.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);
                        }
                        setLoading(false)

                    } catch (error: any) {
                        setLoading(false)
                        const newError: any = new Error('Unable to Generate Excel file');
                        newError.title = 'Error';
                        newError.status = 'error';
                        throw newError
                    }
                }
                else {
                    const error: any = new Error('Please select from date and to date');
                    error.title = 'Warning';
                    error.status = 'warning';
                    throw error
                }
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Generate Exam Report",
            onClick: async (filter: any, data: any, setLoading: any) => {
                if (filter.to && filter.from) {
                    setLoading(true)
                    try {
                        let response = await axios.post(`${BASE_URL}/method/xceed.api.export_excel.exam_registration.export_exam_report`, { filter }, {
                            withCredentials: true,
                            responseType: "blob"
                        })
                        if (response) {
                            console.log(response)
                            const href = URL.createObjectURL(response.data);

                            const link = document.createElement('a');
                            link.href = href;
                            link.setAttribute('download', `Exam-medium-count-export.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);
                        }
                        setLoading(false)

                    } catch (error: any) {
                        setLoading(false)
                        const newError: any = new Error('Unable to Generate Excel file');
                        newError.title = 'Error';
                        newError.status = 'error';
                        throw newError
                    }
                }
                else {
                    const error: any = new Error('Please select from date and to date');
                    error.title = 'Warning';
                    error.status = 'warning';
                    throw error
                }
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Subject Medium Wise Application Report",
            onClick: async (filter: any, data: any, setLoading: any) => {
                if (filter.to && filter.from) {
                    setLoading(true)
                    try {
                        let response = await axios.post(`${BASE_URL}/method/xceed.api.export_excel.exam_registration.export_subject_and_medium_wise_total_no_of_applications`, { filter }, {
                            withCredentials: true,
                            responseType: "blob"
                        })
                        if (response) {
                            console.log(response)
                            const href = URL.createObjectURL(response.data);

                            const link = document.createElement('a');
                            link.href = href;
                            link.setAttribute('download', `subject-medium-wise-application-count.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);
                        }
                        setLoading(false)

                    } catch (error: any) {
                        setLoading(false)
                        const newError: any = new Error('Unable to Generate Excel file');
                        newError.title = 'Error';
                        newError.status = 'error';
                        throw newError
                    }
                }
                else {
                    const error: any = new Error('Please select from date and to date');
                    error.title = 'Warning';
                    error.status = 'warning';
                    throw error
                }
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Exams Wise Application Report",
            onClick: async (filter: any, data: any, setLoading: any) => {
                if (filter.to && filter.from) {
                    setLoading(true)
                    try {
                        let response = await axios.post(`${BASE_URL}/method/xceed.api.export_excel.exam_registration.export_exam_wise_total_no_of_applications`, { filter }, {
                            withCredentials: true,
                            responseType: "blob"
                        })
                        if (response) {
                            console.log(response)
                            const href = URL.createObjectURL(response.data);

                            const link = document.createElement('a');
                            link.href = href;
                            link.setAttribute('download', `Exam-wise-application-count-export.xlsx`);
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(href);
                        }
                        setLoading(false)

                    } catch (error: any) {
                        setLoading(false)
                        const newError: any = new Error('Unable to Generate Excel file');
                        newError.title = 'Error';
                        newError.status = 'error';
                        throw newError
                    }
                }
                else {
                    const error: any = new Error('Please select from date and to date');
                    error.title = 'Warning';
                    error.status = 'warning';
                    throw error
                }
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
    ]
}

export default examRegistrationConfig