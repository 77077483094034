import _, { create } from "lodash"
import { useState } from "react"
import { NavigateFunction, useNavigate, useParams, useSearchParams } from "react-router-dom"
import { ATTENDANCE, MODULE_ENROLLMENT, SESSION } from "../../../../consts/methods.consts"
import { ATTENDANCE_TAGS, MODULE_ENROLLMENT_TAGS, MODULE_TAGS } from "../../../../consts/tags.consts"
import { useGetQuery, usePostMutation } from "../../../../services/api.service"
import ChakraTable, { ChakraTableColumns } from "../../../common/chakra-table/ChakraTable"
import PageHeader from "../../../common/page-header/PageHeader"
import { useTableDataProvider } from "../../../hooks"
import { useToast } from "@chakra-ui/react"
import { ToastService } from "../../../../services/toast.service"

const AttendanceTable = () => {
    const navigate: NavigateFunction = useNavigate()
    const { id } = useParams()
    const [page, setPage] = useState(1)
    const [create] = usePostMutation();
    const [searchKey, setSearchKey]: any = useState()
    const [length, setLength] = useState(15)
    const toast = new ToastService(useToast());

    const { data, isLoading, isFetching } = useGetQuery({
        method: SESSION.SESSION_ATTENDANCE_LIST,
        body: {
            page: page,
            key: searchKey,
            id,
            length: Number(length),
        },
        providesTags: [ATTENDANCE_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const ondeleteHandler = async (item: any) => {
        if (!item?.attendance_id) {
            toast.setTitle("Warning").setDescription("The attendance document you are trying to delete does not exist in the system")
            .setStatus('warning').show();
            return
        }
        try {
            const res = await create({
                method: ATTENDANCE.DELETE,
                body: { name: item?.attendance_id },
                invalidatesTags: [ATTENDANCE_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Attendance Document is deleted successfully.").showSuccessToast();

        } catch (error: any) {
            let message = JSON.parse(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(JSON.parse(message)?.message).showErrorToast();
        }

    }


    const ATTENDANCE_LIST_TAB_COLUMNS: ChakraTableColumns = [
        {
            header: "Student",
            accessor: "full_name",
        },
        {
            header: "Status",
            accessor: "status",
            width:400,
            customRenderer(value: any, item) {
                const className = (value == 'Present') ? 'bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded' :
                    'bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded'
                return (
                    <div>
                        <span className={className}>{value}</span>
                    </div>
                )
            },
        },

        {
            header: "Actions",
            accessor: '',
            disabled: false,
            width: 100,
            options: [
                {
                    label: "Delete",
                    onClick: ondeleteHandler,
                }
            ]
        }
    ]

    const tableLineClickHandler = (line: any) => {
        navigate(`/app/student/${_.get(line, 'student_id')}`)
    }

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }

    const onLengthChange = (length: any) => {
        setPage(1)
        setLength(Number(length))
    }

    const onPage = (page: any) => {
        setPage(page)
    }

    return (
        <div>
            <PageHeader subtitle="View and Manage Attendance" title={`Attendance`} />
            <ChakraTable onPage={onPage} onLength={onLengthChange} lengthPaginations isFetching={isFetching} onSearch={onSearchHandler} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} header title="Attendance"
                onSelected={tableLineClickHandler} columns={ATTENDANCE_LIST_TAB_COLUMNS} data={dataList} />
        </div>
    )
}

export default AttendanceTable

