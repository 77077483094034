import { useToast } from "@chakra-ui/react"
import _ from "lodash"
import { useState } from "react"
import { NavigateFunction, useNavigate } from "react-router-dom"
import { PROGRAM, PROGRAM_CATEGORY, PROGRAM_MODULE_APPROVES } from "../../../consts/methods.consts"
import { PROGRAMMED_MODULE_TAGS, PROGRAM_CATEGORY_TAGS, PROGRAM_TAGS } from "../../../consts/tags.consts"
import { useGetQuery } from "../../../services/api.service"
import { ToastService } from "../../../services/toast.service"
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable"
import PageHeader from "../../common/page-header/PageHeader"
import { useTableDataProvider } from "../../hooks"

const ProgramModuleWFHistoryPage = () => {

    const navigate: NavigateFunction = useNavigate()
    const [searchKey, setSearchKey]: any = useState()
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)
    const [loading, setLoading] = useState(false)

    const { data, isLoading, isFetching } = useGetQuery({
        method: PROGRAM_MODULE_APPROVES.GET_LIST,
        body: {
            page: page,
            key: searchKey,
            length: Number(length),
        },
        providesTags: [PROGRAMMED_MODULE_TAGS.LIST]
    })
    const { dataList, paginationDetails } = useTableDataProvider(data);

    const tableLineClickHandler = (line: any) => {
        navigate(`/app/program-module/${_.get(line, 'program_module')}`)
    }

    const onLengthChange = (length: any) => {
        setPage(1)
        setLength(Number(length))
    }

    const onPage = (page: any) => {
        setPage(page)
    }

    const PROGRAM_MODULE_APPROVAL_LIST_COLUMNS: ChakraTableColumns = [
        {
            header: "Code",
            width: 200,
            accessor:
                "module_code",
            // customRenderer: (value: any, item: any) => (
            //     React.createElement(DefaultCell, { navigateTo: `/app/modules/${_.get(item, 'module')}`, value: value })
            // ),
        },
        {
            header: "Name",
            width: 500,
            accessor:
                "title",
            // customRenderer: (value: any, item: any) => (
            //     React.createElement(DefaultCell, { navigateTo: `/app/program-module/${_.get(item, 'program_module')}`, value: value })
            // ),
        },
        {
            header: "Program",
            width: 700,
            accessor:
                "program_title",
            // customRenderer: (value: any, item: any) => (
            //     React.createElement(DefaultCell, { navigateTo: `/app/program/${_.get(item, 'program')}`, value: value })
            // ),
        },
        {
            header: "Start Date and Time",
            width: 300,
            accessor:
                "formatted_start_date",
        },
        {
            header: "Status",
            width: 180,
            accessor:
                "approved_status",
            customRenderer(value: any, item) {
                const className = (value == 5) ? 'bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded' :
                    'bg-red-100 text-red-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded'
                return (
                    <div>
                        <span className={className}>{_.get(statusByRole, value)}</span>
                    </div>
                )
            },
        },
    ]

    return (
        <>
            <div>
                <PageHeader subtitle="View and Manage Program Module activity History" title={`Programs Module activity History`} />
                <ChakraTable onLength={onLengthChange} onSelected={tableLineClickHandler} onPage={onPage} isFetching={isFetching} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} header
                    title="Programs Module Activity History"
                    lengthPaginations
                    columns={PROGRAM_MODULE_APPROVAL_LIST_COLUMNS} data={dataList} />
            </div>
        </>
    )

}

export default ProgramModuleWFHistoryPage

const statusByRole = {
    0: "Draft",
    1: "Submitted to Registrar",
    2: "PENDING - MC",
    "-2": "Not Recommended",
    3: "PENDING - ADG",
    "-3": "REJECTED - MC",
    4: "PENDING - DG",
    "-4": "REJECTED - ADG",
    5: "Approved",
    "-5":'REJECTED - DG'

}