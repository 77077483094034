import _ from "lodash";
import { useState } from "react";
import { BiHide, BiShow } from 'react-icons/bi';
import { GrFormNextLink } from 'react-icons/gr'
import { FormControl, FormErrorMessage, FormLabel, Input, InputGroup, InputProps, InputRightElement } from "@chakra-ui/react";
import { Link } from "react-router-dom";

type MutatedTypes = Omit<InputProps, 'onChange' | 'name' | 'isRequired' | 'isInvalid' | 'defaultValue'>

export interface FormInputProps extends MutatedTypes {
    containerClassNames?: string,
    formik: any,
    name: string,
    label?: string,
    isRequired?: boolean,
    customMessage?: any,
    type?: string
    linkTo?:string
}

export const FormInput = ({ linkTo ,rounded = "md", size = 'sm', customMessage, type = 'text', formik, isRequired = false, name, label, containerClassNames = '', ...restProps }: FormInputProps) => {
    const [showPassword, setShowPassowrd] = useState<boolean>(false)

    return (
        <FormControl isRequired={isRequired} className={containerClassNames} isInvalid={Boolean(formik.submitCount && formik.errors[name])}>
            {label && <FormLabel className={'!opacity-75 !text-[13px]'}>{label}</FormLabel>}
            <InputGroup size='md'>
                <Input type={showPassword ? 'text' : type} _ shadow={"sm"} borderColor={'gray.300'} size={size} {...restProps} name={name} value={_.isEmpty(formik.values) ? '' : formik.values[name]} onChange={formik.handleChange} rounded={rounded} />
                {type === 'password' && (<InputRightElement className="!h-[100%] !justify-end pr-2 hover:cursor-pointer" width='2.5rem' onClick={() => setShowPassowrd(!showPassword)}>
                    {showPassword ? <BiHide className={'w-[24px] h-[24px]'} fill-opacity="0.5" fill={'#BEBEBF'} size='1.4rem' /> : <BiShow className={'w-[24px] h-[24px]'} fill-opacity="0.5" fill={'#BEBEBF'} size='1.4rem' />}
                </InputRightElement>)}
                {linkTo && (<InputRightElement className="!h-[100%] !justify-end pr-2 hover:cursor-pointer" width='2.5rem' onClick={() => setShowPassowrd(!showPassword)}>
                   <Link to={linkTo} ><GrFormNextLink/></Link>
                </InputRightElement>)}
            </InputGroup>
            {(formik.submitCount && formik.errors[name]) ? <FormErrorMessage mt={0}>{formik.errors[name]}</FormErrorMessage> : customMessage && customMessage}
        </FormControl>
    )
}