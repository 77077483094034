import { SimpleGrid } from "@chakra-ui/layout";
import { Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, useToast } from "@chakra-ui/react";
import axios from "axios";
import { chakraComponents } from "chakra-react-select";
import { useFormik } from "formik";
import _, { get } from "lodash";
import { useEffect, useRef } from "react";
import { NavigateFunction, useNavigate, useParams, useSearchParams } from "react-router-dom";
import * as Yup from "yup";
import { BASE_URL } from "../../../consts/app.consts";
import { ATTENDANCE, SESSION } from "../../../consts/methods.consts";
import { ATTENDANCE_TAGS, CALENDAR_TAGS, DROPDOWN_TAGS, PROGRAMMED_MODULE_TAGS, SESSION_TAGS } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { customeJsonParser } from "../../../utils/utils";
import CommentSelection from "../../common/comment-section/CommentSection";
import DocumentHeaderTitle from "../../common/document-header-title/DocumentHeaderTitle";
import { FormInput } from "../../common/form-controllers/FormInput";
import FormSelect from "../../common/form-controllers/FormSelect";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";
import { FormSeletectNavigationController } from "../../common/form-seletect-navigation-controller/FormSeletectNavigationController";
import NewComment from "../../common/new-comment/NewComment";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { useObjChange } from "../../hooks";
import { InnerLoading, SectionCard } from "../../index";
import AttendanceTable from "./components/AttendanceTable";
import UploadAttendanceModal from "./components/UploadAttendanceModal";
import moment from "moment";
import CombineSessionTable from "./components/CombineSessionTable";

const ViewSessionPage = () => {
    const { id } = useParams()
    const [searchParams] = useSearchParams()
    const attendanceUploadDisclosure = useDisclosure()
    const navigate: NavigateFunction = useNavigate();
    const toast = new ToastService(useToast());

    const [create, { isLoading: creating }] = usePostMutation();

    const { data: _data, isLoading } = useGetQuery({
        method: SESSION.GET,
        body: { id },
        providesTags: [SESSION_TAGS.VIEW]
    })

    const data = _.get(_data, ['message', 'data'], {})

    const allowed_to_save = get(data, 'allowed_to_save')
    const allowed_to_delete = get(data, 'allowed_to_delete')
    const is_amend = get(data, 'is_amend')
    const stringifyData = JSON.stringify(data)
    const isCombinedSession = get(data, 'session_type') == 'Combine'

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            programmed_module: Yup.string().required("Programmed Module is required"),
            program: Yup.string().required("Module is required"),
            session_day: Yup.number().required("Session day is required").typeError("Invalid format"),
            // session_code: Yup.string().required("Session Code is required"),
            session_number: Yup.string().required("Session Number is required"),
            class_room: Yup.string().required("Class Room is required"),
            start_date_andtime: Yup.date()
                .required("Start Date and time is required")
                .test(
                    "from-date-validation",
                    "Start date and time must be before end date and time",
                    (value, context) => {
                        const { end_date_and_time } = context.parent;
                        return end_date_and_time ? value <= end_date_and_time : true;
                    }
                ),
            end_date_and_time: Yup.date()
                .required("End Date and Time is required")
                .test(
                    "to-date-validation",
                    "End date and time must be after start date and time",
                    (value, context) => {
                        const { start_date_andtime } = context.parent;
                        return start_date_andtime ? value >= start_date_andtime : true;
                    }
                ),
        }),
        onSubmit: async (values: any) => {
            try {
                await create({
                    method: SESSION.UPDATE,
                    body: values,
                    invalidatesTags: [SESSION_TAGS.VIEW, SESSION_TAGS.LIST, DROPDOWN_TAGS.LIST, CALENDAR_TAGS.EVENTS, PROGRAMMED_MODULE_TAGS.VIEW]
                }).unwrap();

                toast.setTitle("Success").setDescription("Session details have been saved").showSuccessToast();

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            }
        }
    })

    const printQRHandler = async () => {
        axios.post(`${BASE_URL}/method/${SESSION.PRINT_QR}`, { id }, {
            withCredentials: true,
            responseType: "blob"
        }).then((response: any) => {
            if (response) {
                const href = URL.createObjectURL(response?.data);

                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${data?.title}-qr.png`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }

        }).catch((error: any) => {
            toast.setDescription('Unable to print QR code').setTitle('Error').showErrorToast()
        })

    }

    const downloadAttendanceSheet = async () => {
        try {
            let response = await axios.post(`${BASE_URL}/method/xceed.api.export_excel.session.export_attendance_sheet`, { "session": id }, {
                withCredentials: true,
                responseType: "blob"
            })
            if (response) {
                const href = URL.createObjectURL(response.data);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `attendance-sheet.xlsx`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            }

        } catch (error: any) {
            toast.setDescription('Unable to Download attendance sheet').setTitle('Error').showErrorToast()
        }

    }

    const deleteHandler = async () => {
        try {
            await create({
                method: SESSION.DELETE,
                body: { id },
                invalidatesTags: [SESSION_TAGS.LIST, DROPDOWN_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Session is deleted successfully").showSuccessToast();

            setTimeout(() => {
                navigate(`/app/program-module/${searchParams.get('pm')}`);
            }, 1500)

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            // toast.setTitle("Error").setDescription('Failed to delete the session').showErrorToast();
        }
    }

    const { data: initialData } = useGetQuery({
        method: SESSION.INIT,
        body: {
            id,
            program: _.get(formik.values, 'program', ''),
            startDateAndTime: _.get(formik.values, 'start_date_andtime'),
            endDateAndTime: _.get(formik.values, 'end_date_and_time'),
        },
        providesTags: [DROPDOWN_TAGS.LIST]
    })

    const dropDownData = _.get(initialData, 'message')

    const { isChanged, setInitialObj } = useObjChange(formik.values)

    const updatedProgram = _.get(formik.values, 'program')

    const selectedClassRoomIndexOnOption = _.isArray(_.get(dropDownData, 'class_rooms')) ? _.get(dropDownData, 'class_rooms', []).findIndex((item: any) => item.value == _.get(formik.values, 'class_room')) : 0
    const filteredSelectedResourcePersons = _.isArray(_.get(dropDownData, 'resource_persons')) ? _.get(formik.values, 'resource_person', [])?.filter((sr: any) => _.get(dropDownData, 'resource_persons', [])?.map((dr: any) => dr.value).includes(sr)) : []

    const selectedClassRoomIndexOnOptionCombine = _.isArray(_.get(dropDownData, 'class_room_for_combine_session')) ? _.get(dropDownData, 'class_room_for_combine_session', []).findIndex((item: any) => item.value == _.get(formik.values, 'class_room')) : 0
    const filteredSelectedResourcePersonsCombine = _.isArray(_.get(dropDownData, 'resource_persons_combine_session')) ? _.get(formik.values, 'resource_persons_combine_session', [])?.filter((sr: any) => _.get(dropDownData, 'resource_persons', [])?.map((dr: any) => dr.value).includes(sr)) : []

    const startDateAndTime = get(formik.values, 'start_date_andtime')
    const endDateAndTime = get(formik.values, 'end_date_and_time')

    useEffect(() => {
        const startDateTime = moment(startDateAndTime);
        const endDateTime = moment(endDateAndTime);

        const sessionDuration = endDateTime.diff(startDateTime, 'hours', true);
        const formatSessionDuration = parseFloat(sessionDuration.toFixed(2));
        formik.setFieldValue('session_durationhours', formatSessionDuration.toString())

    }, [startDateAndTime, endDateAndTime])

    useEffect(() => {
        if (get(formik.values, 'session_type') == 'Combine') return
        if (selectedClassRoomIndexOnOption > -1) {
            return
        }
        formik.setFieldValue('class_room', '')

    }, [selectedClassRoomIndexOnOption, stringifyData])

    useEffect(() => {
        if (get(formik.values, 'session_type') == 'Combine') {
            if (selectedClassRoomIndexOnOptionCombine > -1) {
                return
            }
            formik.setFieldValue('class_room', '')

        }

    }, [selectedClassRoomIndexOnOptionCombine, stringifyData])

    useEffect(() => {
        if (get(formik.values, 'session_type') == 'Combine') return
        formik.setFieldValue('resource_person', filteredSelectedResourcePersons)

    }, [JSON.stringify(filteredSelectedResourcePersons), stringifyData])

    useEffect(() => {
        if (get(formik.values, 'session_type') == 'Combine') {
            formik.setFieldValue('resource_person', filteredSelectedResourcePersonsCombine)
        }
    }, [JSON.stringify(filteredSelectedResourcePersonsCombine), stringifyData])

    useEffect(() => {
        if (_.get(formik.values, 'session_type') !== _.get(data, 'session_type')) {
            formik.setFieldValue('resource_person', [])
            formik.setFieldValue('class_room', '')
        }
    }, [JSON.stringify(_.get(formik.values, 'session_type'))])

    useEffect(() => {
        if (!_.isEmpty(data)) {

            formik.setValues({
                ...data, start_date_andtime: moment(_.get(data, 'start_date_andtime')).format("YYYY-MM-DDTHH:mm:ss"),
                end_date_and_time: moment(_.get(data, 'end_date_and_time')).format("YYYY-MM-DDTHH:mm:ss")
            })
            setInitialObj({
                ...data, start_date_andtime: moment(_.get(data, 'start_date_andtime')).format("YYYY-MM-DDTHH:mm:ss"),
                end_date_and_time: moment(_.get(data, 'end_date_and_time')).format("YYYY-MM-DDTHH:mm:ss")
            })
        }
    }, [stringifyData])

    useEffect(() => {
        if (_.get(formik.values, 'program') && (_.get(formik.values, 'program') !== _.get(data, 'program'))) {
            formik.setFieldValue('programmed_module', '')
        }
    }, [updatedProgram]);

    const moreActions: PageHeaderActions = [
        {
            text: "Print QR",
            onClick: printQRHandler,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Download Attendance sheet",
            onClick: downloadAttendanceSheet,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Upload Attendance from Excel",
            onClick: () => attendanceUploadDisclosure.onOpen(),
            buttonVariant: "solid",
            confirmation: false,
            buttonColorScheme: "teal"
        },
    ]


    const actions: PageHeaderActions = [

        {
            text: is_amend ? "Save Amendments" : "Save",
            isDisabled: !isChanged || !allowed_to_save,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
        {
            text: "Delete",
            onClick: deleteHandler,
            isDisabled: !allowed_to_delete,
            buttonVariant: "solid",
            buttonColorScheme: "red"
        }
    ]

    const attendanceModalconfig: any = {
        session: id,
        programModule: _.get(formik.values, 'programmed_module', ''),
        uploadMethod: ATTENDANCE.UPLOAD_EXCEL,
        invalidateTags: [ATTENDANCE_TAGS.LIST]
    }

    if (isLoading) {
        return (
            <>
                <PageHeader isLoading={isLoading} enableBackButton actions={actions} title={'Session'} />
                <InnerLoading />
            </>
        )
    }


    return (
        <div key={id}>
            <PageHeader
                moreActions={moreActions} backButtonCofirmation={isChanged} isLoading={creating} enableBackButton actions={actions} title={_.get(data, 'title')}
                subtitle={'Session'} />
            <Tabs className="mt-3">
                <TabList>
                    <Tab >Session</Tab>
                    {isCombinedSession && <Tab>Combined Sessions</Tab>}
                    <Tab>Attendance</Tab>
                </TabList>

                <TabPanels className="animation-form">
                    <TabPanel className="!p-0 ">
                        <DocumentHeaderTitle>
                            {_.get(data, 'title')}
                        </DocumentHeaderTitle>
                        <SectionCard backgroundColor=" bg-white" title="Dates & Times" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                                <FormInput isDisabled={!allowed_to_save} isRequired type={'datetime-local'} label="Start Date and Time" formik={formik}
                                    name="start_date_andtime" />

                                <FormInput isDisabled={!allowed_to_save} isRequired type={'datetime-local'} label="End Date and Time" formik={formik}
                                    name="end_date_and_time" />
                                <FormInput isDisabled={true} label="Session Duration(Hours)" value={get(formik.values, 'session_durationhours', '')} formik={formik} name="session_durationhours" />

                                <FormInput isDisabled={!allowed_to_save} isRequired type="number" label="Session Day" formik={formik} name="session_day" />
                            </SimpleGrid>
                        </SectionCard>
                        <SectionCard backgroundColor=" bg-white" title="Basic Information" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                                {/* <FormInput isRequired label="Code" formik={formik} name="session_code" /> */}
                                <FormInput isDisabled={!allowed_to_save} isRequired label="Session Title" formik={formik} name="title" />

                                <FormInput isDisabled={!allowed_to_save} type={'number'} isRequired label="Session Number" formik={formik}
                                    name="session_number" />

                                <FormSelect isDisabled={!allowed_to_save} isRequired formik={formik} name='session_type' label="Session Type" options={[
                                    {
                                        "label": "Combine",
                                        "value": "Combine"
                                    },
                                    {
                                        "label": "Individual",
                                        "value": "Individual"
                                    }
                                ]} />

                                <FormSelect isDisabled={!(_.get(formik.values, 'start_date_andtime') && _.get(formik.values, 'end_date_and_time')) || !allowed_to_save}
                                    isMulti
                                    options={_.get(formik.values, 'session_type') == 'Combine' ? _.get(dropDownData, 'resource_persons_combine_session', []) : _.get(dropDownData, 'resource_persons', [])}
                                    label="Resource person" formik={formik}
                                    name="resource_person" />
                                <FormSelect isRequired
                                    isDisabled={!(_.get(formik.values, 'start_date_andtime') && _.get(formik.values, 'end_date_and_time')) || !allowed_to_save}
                                    options={_.get(formik.values, 'session_type') == 'Combine' ? _.get(dropDownData, 'class_room_for_combine_session', []) : _.get(dropDownData, 'class_rooms', [])}
                                    label="Class Room" formik={formik}
                                    name="class_room" />
                                {/* <FormTextArea label="Evaluation Criteria" formik={formik} name="evaluation_criteria" /> */}
                            </SimpleGrid>
                        </SectionCard>
                        <SectionCard backgroundColor=" bg-white" title="Session Content" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 2 }} spacing={3}>
                                <FormTextArea isDisabled={!allowed_to_save} rows={7} label="" formik={formik} name="description" />
                            </SimpleGrid>
                        </SectionCard>
                        <SectionCard backgroundColor=" bg-white" title="Program Related Inforamtion" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 4, xl: 4 }} spacing={3}>
                                <FormSelect options={_.get(dropDownData, 'programs')} isRequired formik={formik}
                                    extraProps={{ linkTo: `/app/program/${_.get(formik.values, 'program')}` }}
                                    components={{ Control: FormSeletectNavigationController }}
                                    isDisabled
                                    name={'program'}
                                    label={'Program'} />

                                <FormSelect options={_.get(dropDownData, 'programmed_modules')} isRequired formik={formik}
                                    extraProps={{ linkTo: `/app/program-module/${_.get(formik.values, 'programmed_module')}` }}
                                    components={{ Control: FormSeletectNavigationController }}
                                    isDisabled
                                    name={'programmed_module'}
                                    label={'Program Module'} />
                            </SimpleGrid>
                        </SectionCard>
                        <SectionCard backgroundColor=" bg-white" title="Add a comment" p={3}>
                            <SimpleGrid columns={{ base: 1, md: 1, lg: 1, xl: 1 }} spacing={3}>
                                <NewComment doctype={'Session'} id={id} tag={SESSION_TAGS.COMMENTS} />
                            </SimpleGrid>
                        </SectionCard>
                        <CommentSelection doctype={'Session'} doc={id} tag={SESSION_TAGS.COMMENTS} />
                    </TabPanel>
                    {isCombinedSession && (<TabPanel className="!p-0 !pt-4">
                        <CombineSessionTable />
                    </TabPanel>)}
                    <TabPanel className="!p-0 !pt-4">
                        <AttendanceTable />
                    </TabPanel>
                </TabPanels>
            </Tabs>
            <UploadAttendanceModal config={attendanceModalconfig} disclosure={attendanceUploadDisclosure} />
        </div>
    )
}

export default ViewSessionPage

const customComponents = {
    Option: ({ children, ...props }: any) => (
        <chakraComponents.Option {...props}>
            <div className="gap-2">
                <div className=" font-bold">
                    {props.data.name}

                </div>
                <div className=" italic flex  gap-3">
                    <span className="bg-blue-100 text-blue-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">
                        {children}
                    </span>
                    {props.data.group && (<span className="bg-yellow-100 text-yellow-800 text-xs font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                        {props.data.group}
                    </span>)}
                </div>
            </div>
        </chakraComponents.Option >
    )
};
