import React from "react";
import { HiOutlineCheckCircle, HiOutlineInformationCircle, HiOutlineXCircle } from "react-icons/hi";

export class ToastService {
    private title;
    private description;
    private toast;
    private duration;
    private status;
    private icon;

    constructor(toast: any) {
        this.toast = toast;
        this.title = '';
        this.duration = 2000;
        this.description = ''
        this.status = "success"
        this.icon = React.createElement(HiOutlineInformationCircle)
    }

    public setTitle(title: string) {
        this.title = title;
        return this;
    }

    public setDuration(duration: number) {
        this.duration = duration;
        return this;
    }

    public setDescription(desc: string) {
        this.description = desc;
        return this;
    }

    public setStatus(status: "success" | "error" | "warning" | "info") {
        this.status = status
        return this;
    }

    public setIcon(icon: JSX.Element) {
        this.icon = icon;
        return this;
    }

    public showErrorToast() {
        this.toast({
            title: this.title ? this.title : 'Error',
            description: this.description,
            position: "bottom",
            status: 'error',
            duration: this.duration,
            isClosable: true,
            // icon: React.createElement(HiOutlineXCircle)
        })
    }

    public showSuccessToast() {
        this.toast({
            title: this.title ? this.title : 'Success',
            description: this.description,
            position: "bottom",
            status: 'success',
            duration: this.duration,
            isClosable: true,
            icon: React.createElement(HiOutlineCheckCircle)
        })
    }

    public show() {
        this.toast({
            title: this.title ? this.title : 'Success',
            description: this.description,
            position: "bottom",
            status: this.status,
            duration: this.duration,
            isClosable: true,
            // icon: this.icon as any
        })
    }
}