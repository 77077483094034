import _ from "lodash";
import * as Yup from "yup";
import { useState } from "react";
import { useFormik } from "formik";
import { FormInput } from "../../common/form-controllers/FormInput";
import { FormDropdown } from "../../common/form-controllers/FormDropdown";
import { EXAM, PROGRAM_TYPE, SERVICE } from "../../../consts/methods.consts";
import { ToastService } from "../../../services/toast.service";
import { useSearchParams } from "react-router-dom";
import { DROPDOWN_TAGS, EXAM_TAGS, PROGRAM_TYPE_TAGS, SERVICE_TAGS } from "../../../consts/tags.consts";
import { PageHeaderActions } from "../../common/page-header/PageHeader";
import { useTableDataProvider } from "../../hooks";
import { SimpleGrid, useDisclosure, useToast } from "@chakra-ui/react";
import { ChakraModal, PageHeader } from "../../index";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { customeJsonParser } from "../../../utils/utils";

const ServiceListPage = () => {

    const disclosure = useDisclosure()
    const [searchKey, setSearchKey]: any = useState()
    const [page, setPage] = useState(1)
    const toast = new ToastService(useToast())
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [create] = usePostMutation();

    const { data, isLoading, isFetching } = useGetQuery({
        method: SERVICE.LIST,
        body: {
            page: page,
            key: searchKey
        },
        providesTags: [SERVICE_TAGS.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const formik = useFormik({
        initialValues: {
            title: '',
            service_code: ''
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Name is required"),
            service_code: Yup.number().required("Service code is required")
        }),
        onSubmit: async (values) => {
            try {
                disclosure.onClose();
                await create({
                    method: isSelected ? SERVICE.UPDATE : SERVICE.ADD,
                    body: values,
                    invalidatesTags: [SERVICE_TAGS.LIST, DROPDOWN_TAGS.LIST]
                }).unwrap();

                formik.resetForm()

                toast.setTitle("Success").setDescription(`${isSelected ? "Service have been saved" : "Service has been created successfully"}`).showSuccessToast();

            } catch (error: any) {
                let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
                if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
            } finally {
                setIsSelected(false);
            }
        }
    })

    const ondeleteHandler = async (item: any) => {
        try {
            const res = await create({
                method: SERVICE.DELETE,
                body: { id: item?.name },
                invalidatesTags: [SERVICE_TAGS.LIST, DROPDOWN_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Service is deleted successfully.").showSuccessToast();

        } catch (error: any) {
            let message = customeJsonParser(_.get(error?.data, '_server_messages', {}))
            if (message) toast.setTitle("Error").setDescription(customeJsonParser(message)?.message).showErrorToast();
        }

    }

    const SERVICE_COLUMNS: ChakraTableColumns = [
        {
            header: "Name",
            accessor:
                "title",
        },
        {
            header: "Service Code",
            accessor:
                "service_code",
        },
        {
            header: "Actions",
            accessor: '',
            disabled: false,
            width: 100,
            options: [
                {
                    label: "Edit",
                    confirmation: false,
                    onClick(item) {
                        formik.setFieldValue("title", _.get(item, "title"))
                        formik.setFieldValue("name", _.get(item, "name"))
                        formik.setFieldValue("service_code", _.get(item, "service_code"))
                        setIsSelected(true)
                        disclosure.onOpen()
                    },
                },
                {
                    label: "Delete",
                    onClick: ondeleteHandler,
                }
            ]
        }
    ]


    const actions: PageHeaderActions = [

        {
            text: "Create New",
            confirmation: false,
            onClick: () => {
                setIsSelected(false)
                formik.resetForm();
                disclosure.onOpen()
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }

    const onPage = (page:any)=>{
        setPage(page)
    }

    return (
        <>
            <div>
                <PageHeader subtitle="View and Manage Service" title={`Service`} actions={actions} />
                <ChakraTable onPage={onPage} onSearch={onSearchHandler} isFetching={isFetching} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} header
                    title="Services"
                    columns={SERVICE_COLUMNS} data={dataList} />
                <ChakraModal primaryAction={formik.submitForm} primaryButtonText={"Save"} modalTitle={`${isSelected ? 'Edit service' : 'New service'}`} {...disclosure}>
                    <SimpleGrid columns={{ base: 1, md: 2, lg: 1, xl: 1 }} spacing={3}>
                        <FormInput isRequired formik={formik} name="title" autoFocus label="Name" />
                        <FormInput isRequired formik={formik} name="service_code" autoFocus label="Service Code" />
                    </SimpleGrid>
                </ChakraModal>
            </div>
        </>
    )
}

export default ServiceListPage