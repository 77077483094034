import _ from "lodash"
import { STUDENT } from "../../../consts/methods.consts"
import { STUDENT_TAG } from "../../../consts/tags.consts"
import { useGetQuery } from "../../../services/api.service"
import { useTableDataProvider } from "../../hooks"
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader"
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable"
import { NavigateFunction, useNavigate, useSearchParams } from "react-router-dom"
import { useState } from "react"

const StudentListPage = () => {
    const navigate: NavigateFunction = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [page, setPage] = useState(1)
    const [searchKey, setSearchKey]: any = useState()
    const [length, setLength] = useState(15)

    const PROGRAM_LIST_COLUMNS: ChakraTableColumns = [
        {
            header: "nic",
            accessor:
                "nic",
        },
        {
            header: "Full Name",
            accessor:
                "full_name",
        },
        // {
        //     header: "First Name",
        //     accessor:
        //         "first_name",
        // },
        // {
        //     header: "Last Name",
        //     accessor:
        //         "last_name",
        // },
        {
            header: "Mobile No",
            accessor:
                "mobile_number",
        },
        {
            header: "email",
            accessor:
                "email",
        }
    ]

    const { data, isLoading, isFetching } = useGetQuery({
        method: STUDENT.LIST,
        body: {
            page: page,
            length: Number(length),
            key: searchKey
        },
        providesTags: [STUDENT_TAG.LIST]
    })

    const { dataList, paginationDetails } = useTableDataProvider(data);

    const actions: PageHeaderActions = [

        {
            text: "Create New",
            onClick: () => navigate("new"),
            confirmation: false,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const tableLineClickHandler = (line: any) => {
        navigate(`${_.get(line, 'name')}`)
    }

    const onSearchHandler = (value: any) => {
        setPage(1)
        let deb = _.debounce(() => {
            setSearchKey(value)
        }, 250)

        deb()
    }

    const onLengthChange = (length: any) => {
        setPage(1)
        setLength(Number(length))
    }

    const onPage = (page: any) => {
        setPage(page)
    }

    return (
        <div>
            <PageHeader subtitle="View and Manage Students" title={`Student`} actions={actions} />
            <ChakraTable onPage={onPage} onLength={onLengthChange} lengthPaginations isFetching={isFetching} onSearch={onSearchHandler} paginationDetails={paginationDetails} isLoading={isLoading} pagination size={"sm"} header
                title="Students"
                onSelected={tableLineClickHandler} columns={PROGRAM_LIST_COLUMNS} data={dataList} />
        </div>
    )

}

export default StudentListPage